import React from 'react';
import { Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import DiscrepancyService from '../../services/Discrepancy.service';
import { toast } from 'react-toastify';
import { customToastId, configTimers, cancelErrorMessages } from '../../constants';
import '../../styles/discrepancy.scss';
import moment from "moment";

var regex = /\S+@\S+\.\S+/;

class DiscrepancyReport extends React.Component {
    constructor(props) {
        super(props);
        this.DiscrepancyService = new DiscrepancyService();
        this.state = {
            receiver: props.reportPopupData ? props.reportPopupData.receiver : '',
            notes: props.reportPopupData ? props.reportPopupData.notes : '',
            sender: props.reportPopupData ? props.reportPopupData.sender : '',
            submitted: false
        };
    }

    componentDidUpdate(prevProps) {
        let stateUpdate = false;
        if (prevProps.reportPopupData && this.props.reportPopupData && (this.props.reportPopupData._id !== prevProps.reportPopupData._id || this.props.reportPopupData.found !== prevProps.reportPopupData.found)) {
            stateUpdate = true;
            if (stateUpdate) {
                this.setState({
                    receiver: this.props.reportPopupData.found ? this.props.reportPopupData.receiver : '',
                    notes: this.props.reportPopupData.notes,
                    sender: this.props.reportPopupData.found ? this.props.reportPopupData.sender : '',
                    submitted: false
                })
                stateUpdate = false;
            }
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    sendReport = () => {
        this.setState({
            submitted: true
        })
        let report = this.props.reportPopupData;
        let params = {
            'receiver': this.state.receiver,
            'notes': this.state.notes,
            'type': report.type,
            'id': `${report.id}`,
            'sender': this.state.sender,
            'time': report.time ? report.time :  moment().format('ddd MMM Do h:mm:ss A YYYY'),
            'location': `${report.lngLat.lat}, ${report.lngLat.lng}`

        }
        let idTokenFeatures = sessionStorage.getItem('idToken');
        if (this.state.receiver !== '' && regex.test(this.state.receiver) && this.state.sender !== '' && idTokenFeatures) {
            this.DiscrepancyService.sendReport(report.id, params, idTokenFeatures).then(response => {
                if (response.status === 200 && response.data) {
                    var obj = {...params, found : true, receiver: this.state.receiver};
                    this.props.onExit(false, obj);
                    toast.success(response.data, { toastId: customToastId });
                }
            }).catch(error => {
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.discrepencyReportTimeOutMsg, { toastId: customToastId });
                } else if (error.response) {
                    toast.error(error.response.data.message, { toastId: customToastId });
                }
            })
        }
    }

    render() {
        const report = this.props.reportPopupData;
        return (
            <>
                {this.props.show ?
                    <Modal className='discrepancy-popup' enforceFocus={false} backdrop={false} show={this.props.show} onHide={this.props.hide} animation={false}>
                        <Modal.Body>
                            <div>
                                <h4>Discrepancy Report</h4>
                                <div className='discrepancy-content'>
                                    <div className='report-info'>
                                        <div><span className="street-label">Type: </span>{report.type}</div>
                                        <div><span className="street-label">Lat/Lon: </span>{report.lngLat ? parseFloat(report.lngLat.lat).toFixed(6) : ''}, {report.lngLat ? parseFloat(report.lngLat.lng).toFixed(6) : ''}</div>
                                        <div><span className="street-label">Time: </span>{report.time}</div>
                                    </div>
                                    <Form>
                                        <Form.Group className="mb-1">
                                            <Form.Label>To</Form.Label>
                                            <Form.Control type='text' name='receiver' className='form-control' onChange={this.handleChange}
                                                value={this.state.receiver} />
                                            {!regex.test(this.state.receiver) && this.state.submitted ?
                                                <div className='error-msg m-0'>Enter valid email address</div>
                                                : ''}
                                        </Form.Group>
                                        <Form.Group className="mb-1">
                                            <Form.Label>From</Form.Label>
                                            <Form.Control type='text' name='sender' className='form-control' onChange={this.handleChange}
                                                value={this.state.sender} />
                                            {this.state.sender === '' && this.state.submitted ?
                                                <div className='error-msg m-0'>Enter from address</div>
                                                : ''}
                                        </Form.Group>
                                        <Form.Group className="mb-1">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control as="textarea" rows="3" name='notes' className="form-control" onChange={this.handleChange} value={this.state.notes} />
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div className='mt-2 report-footer'>
                                    <span className='hand-cursor cancel' onClick={this.props.hide}>Cancel</span>
                                    <span className='ms-5 hand-cursor report-submit' onClick={this.sendReport}>{report.found ? 'Update' : 'Submit'}</span>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ''}
            </>
        );
    }
}

export default DiscrepancyReport;