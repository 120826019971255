import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../styles/modal.scss';
import '../../styles/drawPolygon.scss';
import '../../assets/icon_trash.png';
import StreamingComponent from './StreamingComponent';
import { serverconfigs, configTimers } from '../../constants';
import axios from 'axios';
import { consoleLog } from '../../utils/commonUtils';

export default class EditPolygon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNumber: '',
            mobileNumberDisplay: '',
            message: '',
            submitted: false,
            showVideoDialog: false,
            videoUrl: ""
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.mobileNumber) {
    //         state.mobileNumber= props.mobileNumber;
    //     }
    //     return state
    // }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.props.polygon.id === nextProps.polygon.id) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    handleChange = (e) => {
        let field = e.target;
        this.setState({
            [field.name]: field.value
        }, () => {
            consoleLog(":::::::: mobile :::: " + this.state.mobileNumber)
        })
    }

    deletePolygon = () => {
        this.forceUpdate();
        this.props.delete(this.state.id);
        this.setState({
            showDeleteDialog: false
        })
    }

    sendSmsBtnClick = () => {
        this.setState({
            submitted: true
        })
        if (this.state.mobileNumber !== '') {
            this.sendSms(this.state.mobileNumber)
        } else if (this.props.mobileNumber !== '') {
            this.setState({
                mobileNumber: this.props.mobileNumber
            })
            this.sendSms(this.props.mobileNumber)
        }
    }

    sendSms = (mdn) => {
        // Axios call which will send the api request and get the streaming url from response
        let sendSmsUrl = serverconfigs.sendSmsUrl + mdn;
        let idToken = sessionStorage.getItem('idToken');
        axios.post(sendSmsUrl, {
        }, {
            headers: {
                Authorization: 'Bearer '+idToken
            }
        })
            .then(response => {
                if (response.status == 200 && response.data) {
                    this.getStreamingUrl(response.data.StreamURL)
                } else {
                    this.setState({
                        showVideoDialog: false,
                        videoUrl: ""
                    })
                }
            })
            .catch(error => {
                this.setState({
                    showVideoDialog: false,
                    videoUrl: ""
                })
            })
    }

    getStreamingUrl = (viewerUrl) => {
        this.setState({
            showVideoDialog: true,
            videoUrl: viewerUrl
        })
    }

    handleClose = () => {
        this.props.hide();
        this.setState({
            showVideoDialog: false,
            videoUrl: "",
            mobileNumber: ""
        })
        try{
        let deleteViewerChannel = serverconfigs.streamingUrl + "del/" + this.state.mobileNumber.replace(/\+/g, "");
        axios.get(deleteViewerChannel)
        .then(response => {
                // do nothing
            })
        .catch(error => {
            consoleLog("::::::::: error deleting channel ::::::::::: ")
            })
        } catch (error) {
            consoleLog("::::::::: error deleting channel ::::::::::: ")
        }
    }
    render() {
        return (
            <>
                <Modal className={`edit-polygon-dialog ${this.state.showVideoDialog ? 'hide' : ''}`} backdrop={false} show={this.props.show}>
                    <Modal.Header closeButton onClick={this.handleClose}></Modal.Header>
                    <Modal.Body>
                        <div className='right-content'>
                            <div className='text-left form-field position-relative'>
                                <input type='text' name='mobileNumber' className='form-control polygon-name' placeholder='Enter Mobile Number'
                                    onChange={this.handleChange} defaultValue={this.props.mobileNumber} />
                                {this.state.mobileNumber === '' && this.state.submitted ?
                                    <div className='error-msg'>please enter mobile number</div>
                                    : ''}
                            </div>
                            <div className='text-left form-field'>
                                <textarea rows="2" name='message' className="form-control"
                                    placeholder='Message' onChange={this.handleChange} value={this.state.message}></textarea>
                            </div>
                            <div className='mt-4'>
                                <Button variant="light" onClick={this.handleClose}>Close</Button>
                                <Button variant="primary" onClick={this.sendSmsBtnClick}>Send SMS</Button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
                <StreamingComponent show={this.state.showVideoDialog} videoUrl={this.state.videoUrl} hide={this.handleClose} />
            </>
        )
    }
}
