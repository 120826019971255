import axios from 'axios';
import {serverconfigs, configTimers} from '../constants';

export default class TrackingServices {
  getTrackingData = (vendor, deviceId) => {
    return axios.get(`${serverconfigs.trackingUrl}&vendor=${vendor}${deviceId ? `&selected_device=${deviceId}` : ''}`);
  }

  getDeviceLocationHistory = (vendor, deviceId, startTime, endTime) => {
    const suggestionUrl = `${serverconfigs.deviceDetails}/${vendor}/${deviceId}`;
        return axios.get(suggestionUrl, {
            params: {
                api_key: serverconfigs.searchKey,
                start: startTime,
                end: endTime
            }
        });
  }

  getDeviceHistoryData = (vendor, startTs, endTs, geofenceScan, filter) => {
    const suggestionUrl = `${serverconfigs.devicesAnalyticsUrl}/${vendor}`;
    return axios.get(suggestionUrl, {
        params: {
            api_key: serverconfigs.searchKey,
            start: startTs,
            end: endTs,
            geofence_scan:geofenceScan,
            device_filter: filter
        }
    });
  }
}