import React from "react";
// import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
// import ConfirmSignUp from "./ConfirmSignUp";
import ReactDirective from "react-directive";
import { Auth } from "aws-amplify";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: "signUp",
      alertMsg: "",
      alertType: "",
      loader: false,
      submitted: false,
      username: "",
      password: "",
      email: "",
      firstname: "",
      lastname: "",
      company: "",
      terms: false,
      authCode: "",
      isUsernameValid: false,
      userNameExists: false,
      isPasswordValid: false,
      isEmailValid: false,
      isFirstnameValid: false,
      isLastnameValid: false,
      isCompanyValid: false,
      isTermsValid: false,
      isFormValid: false,
      showpwd: false,
      pwdText: 'password'
    };

    this.signUp = this.signUp.bind(this);
    this.checkUsernameExists = this.checkUsernameExists.bind(this);
    this.validateField = this.validateField.bind(this);
  }

  toggleIcon = (val, e) => {
    if (val === 'show') {
      this.setState({
        showpwd: true,
        pwdText: 'text'
      })
    }
    else {
      this.setState({
        showpwd: false,
        pwdText: 'password'
      })
    }
  }

  validateField(fieldName, value) {
    switch (fieldName) {
      case "username":
        if (value) {
          this.setState({ isUsernameValid: true });
        }
        break;
      case "password":
        if (value && value.length > 8) {
          this.setState({ isPasswordValid: true });
        }
        break;
      case "email":
        const checkEmail = /.+@.+\..+/.test(value);
        if (checkEmail) {
          this.setState({ isEmailValid: true });
        }
        break;
      case "firstname":
        if (value) {
          this.setState({ isFirstnameValid: true });
        }
        break;
      case "lastname":
        if (value) {
          this.setState({ isLastnameValid: true });
        }
        break;
      case "company":
        if (value) {
          this.setState({ isCompanyValid: true });
        }
        break;
      case "terms":
        if (value === true) {
          this.setState({ isTermsValid: true });
        }
        else {
          this.setState({ isTermsValid: false });
        }
        break;
      default:
        break;
    }

    // this.setState({
    //   isFormValid:
    //     this.state.isUsernameValid &&
    //     this.state.isPasswordValid &&
    //     this.state.isEmailValid &&
    //     this.state.isFirstnameValid &&
    //     this.state.isLastnameValid &&
    //     this.state.isCompanyValid &&
    //     this.state.isTermsValid &&
    //     !this.state.userNameExists
    // });
  }
  signIn = () => {
    window.location.href = "/auth";
  }
  signUp(e) {
    this.setState({ submitted: true });
    if (this.state.isUsernameValid &&
      this.state.isPasswordValid &&
      this.state.isEmailValid &&
      this.state.isFirstnameValid &&
      this.state.isLastnameValid &&
      this.state.isCompanyValid &&
      this.state.isTermsValid &&
      !this.state.userNameExists) {
      this.setState({ loader: true });
      Auth.signUp({
        username: this.state.username,
        password: this.state.password,
        attributes: {
          email: this.state.email,
          name: this.state.firstname,
          family_name: this.state.lastname,
          "custom:company": this.state.company,
        },
      })
        .then((resp) => {
          this.setState({ loader: false });
          this.setState({ formState: "confirmSignUp" });
          window.location.href = "/otp";
          sessionStorage.setItem('createUser', this.state.username.toLowerCase());
        })
        .catch((err) => {
          if (err.code === 'UsernameExistsException') {
            this.setState({
              userNameExists: true
            })
          }
          this.setState({ loader: false });
          this.setState({ alertMsg: err.message });
          this.setState({ alertType: "danger" });
        });
    }
  }
  async checkUsernameExists(value) {
    const code = "000000";
    await Auth.confirmSignUp(value, code, {
      // If set to False, the API will throw an AliasExistsException error if the phone number/email used already exists as an alias with a different user
      forceAliasCreation: false,
    }).then((user) => {
    })
      .catch((err) => {
        switch (err.code) {
          case "UserNotFoundException":
            this.setState({ userNameExists: false });
            break;
          case "NotAuthorizedException":
            this.setState({ userNameExists: true });
            break;
          case "AliasExistsException":
            // Email alias already exists
            this.setState({ userNameExists: false });

            break;
          case "CodeMismatchException":
            this.setState({ userNameExists: true });
            break;
          case "ExpiredCodeException":
            this.setState({ userNameExists: true });
            break;
          default:
            this.setState({ userNameExists: true });
            break;
        }
      });
  }

  render() {
    return (
      <div>
        <div className="sign-up">
          <Alert
            show={this.state.alertMsg}
            key="signUpAlert"
            variant={this.state.alertType}
          >
            {this.state.alertMsg}
          </Alert>
          <ReactDirective>
            <div
              data-react-if={this.state.formState === "signUp"}
            >
              <h3 className="py-3">Create your account</h3>
              <p>
                Please fill in the form below and submit. You will receive a
                verification email.
              </p>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <form>
                    <div className="form-group">
                      <label>
                        <span className="req">*</span> Required fields
                      </label>
                    </div>
                    <div className="form-group">
                      <label>
                        Username <span className="req">*</span>
                      </label>
                      <input
                        name="username"
                        id="username"
                        // defaultValue={this.state.username}
                        onChange={(e) => {
                          this.setState({ username: e.target.value });
                          this.checkUsernameExists(e.target.value);
                          this.validateField("username", e.target.value);
                        }}
                        disabled={this.state.loader}
                        className={`form-control ${
                          this.state.submitted && (!this.state.isUsernameValid || this.state.userNameExists) ? "is-invalid" : ""
                          }`}
                      />
                      <div
                        className="error-msg"
                        data-react-if={this.state.userNameExists}
                      >
                        Username already exists.
                      </div>
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && this.state.username === ""
                        }
                      >
                        Field is required
                      </div>
                    </div>
                    <div className="form-group position-relative">
                      <label>
                        Password <span className="req">*</span>
                      </label>
                      <input
                        type={this.state.pwdText}
                        defaultValue={this.state.password}
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                          this.validateField("password", e.target.value);
                        }}
                        disabled={this.state.loader}
                        className={`form-control ${
                          this.state.submitted && (!this.state.isPasswordValid || this.state.password.length < 8)
                            ? "is-invalid"
                            : ""
                          }`}
                        name="password"
                        id="password"
                      />
                      {
                        this.state.showpwd ?
                          <FaEyeSlash className='position-absolute show-pwd cursor-txt' onClick={this.toggleIcon.bind(this, 'hide')}>hide</FaEyeSlash>
                          :
                          <FaEye className='position-absolute show-pwd cursor-txt' onClick={this.toggleIcon.bind(this, 'show')}>show</FaEye>
                      }
                      <label id="passwordHelpBlock">
                        At least 8 characters, 1 uppercase, and 1 number.
                      </label>
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && this.state.password === ""
                        }
                      >
                        Field is required
                      </div>
                      <div
                        className="error-msg"
                        data-react-if={this.isPasswordValid}
                      >
                        Strong passwords need to have a letter, a number, and be
                        more than 8 characters long.
                      </div>
                      {/* <div className="error-msg" data-react-if="!$v.password.minLength">Password must have at least {{$v.password.$params.minLength.min}} letters.</div> */}
                    </div>
                    <div className="form-group">
                      <label>
                        Email <span className="req">*</span>
                      </label>
                      <input
                        type="email"
                        defaultValue={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                          this.validateField("email", e.target.value);
                        }}
                        disabled={this.state.loader}
                        className={`form-control ${
                          this.state.submitted && !this.state.isEmailValid
                            ? "is-invalid"
                            : ""
                          }`}
                        name="email"
                        id="email"
                      />
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && this.state.email === ""
                        }
                      >
                        Field is required
                      </div>
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && !this.state.isEmailValid
                        }
                      >
                        Please enter a valid email address
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        First Name <span className="req">*</span>
                      </label>
                      <input
                        defaultValue={this.state.firstname}
                        onChange={(e) => {
                          this.setState({ firstname: e.target.value });
                          this.validateField("firstname", e.target.value);
                        }}
                        disabled={this.state.loader}
                        className={`form-control ${
                          this.state.submitted && !this.state.isFirstnameValid
                            ? "is-invalid"
                            : ""
                          }`}
                        type="text"
                        id="firstname"
                        name="firstname"
                      />
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && this.state.firstname === ""
                        }
                      >
                        Field is required
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Last Name <span className="req">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={this.state.lastname}
                        onChange={(e) => {
                          this.setState({ lastname: e.target.value });
                          this.validateField("lastname", e.target.value);
                        }}
                        disabled={this.state.loader}
                        className={`form-control ${
                          this.state.submitted && !this.state.isLastnameValid
                            ? "is-invalid"
                            : ""
                          }`}
                        name="lastname"
                        id="lastname"
                      />
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && this.state.lastname === ""
                        }
                      >
                        Field is required
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Company Name <span className="req">*</span>
                      </label>
                      <input
                        defaultValue={this.state.company}
                        onChange={(e) => {
                          this.setState({ company: e.target.value });
                          this.validateField("company", e.target.value);
                        }}
                        disabled={this.state.loader}
                        className={`form-control ${
                          this.state.submitted && !this.state.isCompanyValid
                            ? "is-invalid"
                            : ""
                          }`}
                        type="text"
                        name="company"
                        id="company"
                      />
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && this.state.company === ""
                        }
                      >
                        Field is required
                      </div>
                    </div>
                    <div className="form-check mb-3">
                      <input
                        type="checkbox"
                        disabled={this.state.loader}
                        defaultChecked={this.state.terms}
                        onChange={(e) => {
                          this.setState({ terms: e.target.value });
                          this.validateField("terms", e.target.checked);
                        }}
                        className={`form-check-input ${
                          this.state.submitted && !this.state.isTermsValid
                            ? "is-invalid"
                            : ""
                          }`}
                        id="terms"
                        name="terms"
                      />
                      <span className="form-check-label">
                        I agree to the Comtech{" "}
                        <a href="" className="txt-primary">
                          terms of Service
                        </a>{" "}
                        and {' '}
                        <a href="" className="txt-primary">
                           Private Policy
                        </a>
                        .
                      </span>
                      <div
                        className="error-msg"
                        data-react-if={
                          this.state.submitted && !this.state.isTermsValid
                        }
                      >
                        Please accept the terms.
                      </div>
                    </div>
                    <button
                      type="button"
                      disabled={this.state.loader}
                      onClick={this.signUp}
                      className="comtech-btn comtech-btn-primary btn-lg"
                    >
                      Submit
                    </button>

                    <div
                      className="p-10 mx-3 spinner-border align-middle text-primary"
                      data-react-if={this.state.loader}
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </form>
                  <p className="toggle pt-3">
                    <span>Already sign up? <span className="txt-primary" onClick={this.signIn}>Sign In</span></span>
                  </p>
                </div>
                <div className="col-md-6 hidden-sm-down"></div>
              </div>
            </div>
            {/* <div
              className="row"
              data-react-if={this.state.formState === "confirmSignUp"}
            >
              <div className="col-6">
                <ConfirmSignUp user={this.state.username}></ConfirmSignUp>
              </div>
              <div className="col-6"></div>
            </div> */}
          </ReactDirective>
        </div>
      </div>
    );
  }
}

export default SignUp;
