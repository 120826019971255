import React from 'react';
import { BsX } from "react-icons/bs";
import SearchServices from '../services/search.services';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';
import '../styles/map.scss';
import { createRoot } from 'react-dom/client';
import Pagination from "react-js-pagination";
import { configTimers, NO_DATA, errorMessages, cancelErrorMessages, clickableLayers, customToastId} from '../constants';
import { connect } from 'react-redux';
import DiscrepancyService from '../services/Discrepancy.service';
import { toggleSearchFlag } from '../redux/actions/searchActions';
import moment from "moment";
import { isPointedToSecondaryRegion } from '../utils/commonUtils';

const toastId = "toast-id";
const featureType = 'Address Point';
class AddressPointSearch extends React.Component {
    constructor(props) {
        super(props);
        this.searchService = new SearchServices();
        this.DiscrepancyService = new DiscrepancyService();
        this.state = {
            AddressPointsList: [],
            renderMarkers: false,
            reportPopupData: null,
            showReportPopup: false
        }
        this.popups = [];
    }

    componentDidMount() {
        if(this.props.pointFeature){
            this.getReport(this.props.pointFeature);
        }
       this.props.map.on('click', this.onMouseClick);
    }

    componentDidUpdate = (prevProps) => {
        const { mapUrl } = this.props;
        if (prevProps.mapUrl && prevProps.mapUrl !== mapUrl) {
            this.onPopupClose();
        }
        if (this.props.searchFlag) {
            this.onPopupClose();
            this.props.toggleSearch(false)
        }
    }

    getReport = (featureID) => {
        let idTokenFeatures = sessionStorage.getItem('idToken');
        if (!isPointedToSecondaryRegion()) {
            this.DiscrepancyService.getReport("PA:" + featureID.properties.APID, featureType, idTokenFeatures).then(response => {
                this.onPopupClose();
                if (response.status === 200 && response.data) {
                    this.getAddessPointsData(this.props.pointFeature);
                    this.setState({
                        reportPopupData: {
                            ...response.data,
                            'receiver': response.data.found ? response.data._source.receiver : '',
                            'sender': response.data.found ? response.data._source.sender : response.data.sender,
                            'notes': response.data.found ? response.data._source.notes : ''
                        }
                    })
                }
            }).catch(error => {
                this.getAddessPointsData(this.props.pointFeature);
                this.setState({
                    reportPopupData: null
                })
                if (error.response) {
                    toast.error(error.response.data.message, { toastId: customToastId });
                }
            })
        } else {
            this.getAddessPointsData(this.props.pointFeature);
        }
    }

    onMouseClick = (e) => {
        var features = this.props.map.queryRenderedFeatures(e.point) || [];
        if (features.length) {
            let pointFeature = this.props.isAPFeatureExists(features);
            if (pointFeature && clickableLayers) {
                if (pointFeature.properties.hasOwnProperty(clickableLayers.addresPointProperty)) {
                    this.getReport(pointFeature);
                }
            }
        }
    }

    handleError = (error, msg) => {
        let errorMessage = msg;
        this.setState({ AddressPointsList: [] });
        if (error && error.response && error.response.status) {
            errorMessage = error.response.data.detail;
        }
        if (errorMessage && errorMessage.length > 0) {
            toast.dismiss();
            toast.error(errorMessage, { toastId: toastId });
        }
    }

    getAddessPointsData = (pointFeature) => {
        this.searchService.getAddressSearchResultsByPoiId(this.props.mapCenter(), pointFeature.properties.APID).then((res) => {
                if (res.status === 200 && res && res.data && res.data.places && res.data.places.length) {
                    res.data.places[0].show = true;
                    this.setState({
                        AddressPointsList: res.data.places,
                        renderMarkers: true
                    }, () => {
                    })
                }
                else {
                    toast.warning(NO_DATA, { toastId: toastId });
                }
            })
            .catch(error => {
                this.setState({ AddressPointsList: [] });
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.addressPointTimeOutMsg, { toastId: toastId });
                } else {
                    this.handleError(error, errorMessages.searchError);
                }
            })
    }

    onPopupClose = () => {
        this.popups.forEach(popup => {
            popup.remove();
        });
        this.setState({
            AddressPointsList: []
        })
    }

    createMarkers = (map) => {
        var lngLat = null;
        if (this.state.renderMarkers) {
            this.state.AddressPointsList.forEach((place, index) => {
                var popupOptions = { offset: [0, -10], closeOnClick: true, closeButton: false };
                let location = place.location;
                if (!lngLat) {
                    lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
                }
                if (this.state.AddressPointsList.length === 1) {
                    if (place.hasOwnProperty('show') && place.show) {
                        const popup = new mapboxgl.Popup(popupOptions);
                        popup.setDOMContent(this.addPopup(place, location, index));
                        popup.setLngLat(lngLat).addTo(map);
                        this.popups.push(popup);
                    }
                }
                else {
                    if (place.hasOwnProperty('show') && place.show) {
                        this.paginatedPopup = new mapboxgl.Popup(popupOptions).setDOMContent(this.addPopupContent(place, location, index));
                        this.paginatedPopup.setLngLat(lngLat).addTo(map);
                        this.popups.push(this.paginatedPopup);
                    }
                }
            });
            this.setState({ renderMarkers: false });
        }
    }

    addPopup(place, location, index) {
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker'>
            <span className="close-popup report-close-popup" >
                <BsX className="hand-cursor" onClick={this.onPopupClose}>close</BsX>
            </span>
            {!isPointedToSecondaryRegion() && this.state.reportPopupData ? <div className="report-menu d-flex align-items-center justify-content-between">
                <div className='report-btn hand-cursor' id='street-report-btn' onClick={this.sendReport.bind(this, place)}>{this.state.reportPopupData && this.state.reportPopupData.found ? 'Reported' : 'Report'}</div>
            </div> : '' }
            {
                place.result_type === 'region' || place.result_type === 'polygon'
                    ? <div className='popup-text'> <div>{place.location.formatted_address}</div> </div>
                    : <div className='popup-text'>
                        <div><b>{place.name}</b></div>
                        <div>{place.location.house_number} {place.location.street}</div>
                        <div>{place.location && place.location.unit && place.location.unit.trim() ? ('Unit ' + place.location.unit) : ''}
                            {place.location && place.location.unit && place.location.unit.trim() &&
                                place.location.floor && place.location.floor.trim() ? ', ' : ''}
                            {place.location && place.location.floor && place.location.floor.trim() ? ('Floor ' + place.location.floor) : ''}</div>
                        <div>{place.location.city}, {place.location.state_code} {place.location.postal}</div>
                    </div>
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    addPopupContent = (place, location, index) => {
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker'>
            <span className="close-popup report-close-popup" >
                <BsX className="hand-cursor" onClick={this.onPopupClose}>close</BsX>
            </span>
            <div>
                {this.state.AddressPointsList.length > 1 && !isPointedToSecondaryRegion() && this.state.reportPopupData ?
                    <div className='report-menu '>
                        <div className="d-flex align-items-center">
                            <div className='report-btn hand-cursor' id='street-report-btn' onClick={this.sendReport.bind(this, place)}>{this.state.reportPopupData && this.state.reportPopupData.found ? 'Reported' : 'Report'}</div>
                            <div className='ms-2 title'><span className='active'>{(index + 1)}</span> of  {this.state.AddressPointsList.length}</div>
                        </div>
                    </div>
                : ''}
            </div>
            {
                place.result_type === 'region' || place.result_type === 'polygon'
                    ? <div className='popup-text'> <div>{place.location.formatted_address}</div> </div>
                    : <div className='popup-text'>
                        <div><b>{place.name}</b></div>
                        <div>{place.location.house_number} {place.location.street}</div>
                        <div>{place.location && place.location.unit && place.location.unit.trim() ? ('Unit ' + place.location.unit) : ''}
                            {place.location && place.location.unit && place.location.unit.trim() &&
                                place.location.floor && place.location.floor.trim() ? ', ' : ''}
                            {place.location && place.location.floor && place.location.floor.trim() ? ('Floor ' + place.location.floor) : ''}</div>
                        <div>{place.location.city}, {place.location.state_code} {place.location.postal}</div>
                    </div>
            }
            {
                this.state.AddressPointsList.length > 1 ? <Pagination
                    activePage={index + 1}
                    itemsCountPerPage={1}
                    totalItemsCount={this.state.AddressPointsList.length}
                    onChange={this.handlePageChange.bind(this, place, index)}
                    firstPageText={''}
                    lastPageText={''}
                    linkClassPrev='arrow-left'
                    linkClassNext='arrow-right'
                /> : ''
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    handlePageChange = (data, id, pageNumber) => {
        this.popups.forEach(popup => {
            popup.remove();
        });
        let addressPointsList = this.state.AddressPointsList;
        addressPointsList.map((list, index) => {
            if ((pageNumber - 1) === index) {
                addressPointsList[index].show = true;
            }
            else {
                addressPointsList[index].show = false;
            }
        })
        this.setState({
            AddressPointsList : addressPointsList,
            renderMarkers: true
        })
    }

    sendReport = (place, e) => {
        let lngLat = new mapboxgl.LngLat(place.location.coordinates[0].longitude, place.location.coordinates[0].latitude);
        this.props.updateReportPopup(true, {
            ...this.state.reportPopupData,
            ...place,
            ...this.props.reportPopupData,
            'type': featureType,
            'lngLat': lngLat,
            'id': place.place_id,
            'time': this.state.reportPopupData && this.state.reportPopupData.found ? this.state.reportPopupData._source.time : moment().format('ddd MMM Do h:mm:ss A YYYY')
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.AddressPointsList.length && this.state.renderMarkers ?
                        this.createMarkers(this.props.map)
                        : ''
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
        searchFlag: state.searchFlag ? state.searchFlag.toggleSearch : state.toggleSearch
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSearch: (val) => dispatch(toggleSearchFlag(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressPointSearch);