import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../styles/intersection.scss';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import mapboxgl from 'mapbox-gl';
import '../styles/map.scss';
import { createRoot } from 'react-dom/client';
import { BsX } from "react-icons/bs";
import { connect } from 'react-redux';
import { setPauseZoom, setSearchZoom } from '../redux/actions/incidentActions';
import { toggleSearchFlag } from '../redux/actions/searchActions';
import { NO_DATA, cancelErrorMessages, errorMessages, configTimers, callDeclarations } from '../constants';

import SearchServices from '../services/search.services';
import { turnonMapDrag, turnoffMapDrag } from '../utils/commonUtils';

const toastId = "toast-id";

class SearchIntersect extends Component {
    markers = [];
    satMarkers = [];
    satBounds = [];
    constructor(props) {
        super(props);
        this.searchService = new SearchServices();
        this.state = {
            showSpinner: false,
            firstStreet: '',
            secondStreet: '',
            city: '',
            state: '',
            zip: '',
            submitted: false
        }
        this.popups = [];
        this.satPopups = [];
    }

    componentWillUnmount() {
        this.removeMarkers();
    }

    handleChange = (e) => {
        let field = e.target;
        if (field.name === 'zip') {
            if (isNaN(Number(field.value))) {
                return;
            } else {
                this.setState({ zip: field.value });
            }
        }
        else {
            this.setState({
                [field.name]: field.value
            })
        }

    }

    clearSearches = () => {
        this.setState({ firstStreet: '', secondStreet: '', city: '', state: '', zip: '' });
        this.removeMarkers();
        this.props.setPauseZoom(false);
        this.props.setSearchZoom(false);
        this.props.handleCancel();
    }

    handleSearch = () => {
        this.props.toggleSearch(true);
        this.removeMarkers();
        this.setState({
            submitted: true
        })
        if (this.state.firstStreet !== '' && this.state.secondStreet !== '' && (this.state.city !== '' || this.state.zip !== '')) {
            this.setState({
                showSpinner: true
            })
            let query = this.state.firstStreet + ' & ' + this.state.secondStreet + ' ' + this.state.city + ' ' +
                this.state.state + ' ' + this.state.zip;

            this.searchService.getAddressSearchResultsByQuery(this.props.mapCenter(), query).then((response) => {
                if (response.status == 200 && response.data.places.length > 0) {
                    this.props.setSearchZoom(true);
                    this.props.setPauseZoom(true);
                    if (response.data.places.length == 1) {
                        response.data.places.map((place, index) => {
                            place.showSinglePopup = (index == 0);
                        })
                    }
                    this.createMarkers(response.data.places);
                } else {
                    toast.warning(NO_DATA, { toastId: toastId });
                }
                this.setState({
                    showSpinner: false,
                    submitted: false
                })

            }, error => {
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.searchTimeOut, { toastId: toastId });
                } else {
                    this.handleError(error, errorMessages.searchError);
                }
                this.setState({
                    showSpinner: false,
                });
            });
        }
    }

    handleError = (error, msg) => {
        let errorMessage = msg;
        this.setState({
            showSpinner: false,
        })
        this.removeMarkers();
        this.setState({ searchPlaces: [] });
        if (error && error.response && error.response.status) {
            errorMessage = error.response.data.detail;
        }
        if (errorMessage && errorMessage.length > 0) {
            toast.dismiss();
            toast.error(errorMessage, { toastId: toastId });
        }
    }

    highlightPOI = (id) => {
        
    }
    onPopupClose = () => {
        this.popups.forEach(popup => {
            popup.remove();
        });
        this.satPopups.forEach(popup => {
            popup.remove();
        });
        let searchPlaces = this.state.searchPlaces;
        searchPlaces.map((list) => {
            list.show = false;
        })
        this.setState({
            searchPlaces
        })
    }

    createMarkers = (response) => {
        this.setState({
            showSpinner: true,
        })
        this.removeMarkers();
        let bounds = [];
        this.satBounds = [];
        response.forEach((place, index) => {
            let el = document.createElement('div');
            // el.addEventListener('click', this.highlightAddress);
            el.addEventListener('click', this.highlightPOI.bind(this, index));
            el.className = 'search-marker';
            let options = { element: el, anchor: 'center', color: '#ff5a1d', scale: 0.8 };
            if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                let location = place.location;
                const lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
                const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                var popupOptions = { offset: 16, closeButton: false };
                const popup = new mapboxgl.Popup(popupOptions);
                popup.setDOMContent(this.addPopup(place, place.location, index));
                marker.setPopup(popup);
                if (place.hasOwnProperty('show') && place.show) {
                    popup.addTo(this.props.map);
                } else if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                    popup.addTo(this.props.map);
                }
                this.markers.push(marker);
                bounds.push(lngLat);
                this.setBounds(bounds, this.props.map, callDeclarations.searchPinZoom);
                this.popups.push(popup);
            } else {
                let location = place.location;
                const lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
                const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                var popupOptions = { offset: [0, -39], closeButton: false };
                const popup = new mapboxgl.Popup(popupOptions);
                popup.setDOMContent(this.addPopup(place, place.location, index));
                marker.setPopup(popup);
                if (place.hasOwnProperty('show') && place.show) {
                    popup.addTo(this.props.map);
                } else if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                    popup.addTo(this.props.map);
                }
                this.markers.push(marker);
                bounds.push(lngLat);
                this.setBounds(bounds, this.props.map, callDeclarations.searchPinZoom);
                this.popups.push(popup);
            }
            if (this.props.isSatelliteMapLoaded && this.props.satelliteMap && this.props.secondaryView !== 'Google-StreetView') {
                this.createSatMarkers(place, index)
            }
        });
        this.setState({
            showSpinner: false,
        })
        turnonMapDrag(this);
    }

    createSatMarkers = (place, index) => {
        let el = document.createElement('div');
        el.addEventListener('click', this.highlightPOI.bind(this, index));
        el.className = 'search-marker';
        let options = { element: el, anchor: 'center', color: '#ff5a1d', scale: 0.8 };
        if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
            let location = place.location;
            const lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
            const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.satelliteMap);
            var popupOptions = { offset: 16, closeButton: false };
            const popup = new mapboxgl.Popup(popupOptions);
            popup.setDOMContent(this.addPopup(place, place.location, index));
            marker.setPopup(popup);
            if (place.hasOwnProperty('show') && place.show) {
                popup.addTo(this.props.satelliteMap);
            } else if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                popup.addTo(this.props.satelliteMap);
            }
            this.satMarkers.push(marker);
            this.satBounds.push(lngLat);
            this.satSetBounds(this.satBounds, this.props.satelliteMap, callDeclarations.satelliteMapZoom);
            this.satPopups.push(popup);
        } else {
            let location = place.location;
            const lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
            const marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.satelliteMap);
            var popupOptions = { offset: [0, -39], closeButton: false };
            const popup = new mapboxgl.Popup(popupOptions);
            popup.setDOMContent(this.addPopup(place, place.location, index));
            marker.setPopup(popup);
            if (place.hasOwnProperty('show') && place.show) {
                popup.addTo(this.props.satelliteMap);
            } else if (place.hasOwnProperty('showSinglePopup') && place.showSinglePopup) {
                popup.addTo(this.props.satelliteMap);
            }
            this.satMarkers.push(marker);
            this.satBounds.push(lngLat);
            this.satSetBounds(this.satBounds, this.props.satelliteMap, callDeclarations.satelliteMapZoom);
            this.satPopups.push(popup);
        }
    }

    setBounds = (bounds, map, zoomLevel) => {
        if (map) {
            var bbox = new mapboxgl.LngLatBounds();
            if (bounds.length === 1) {
                map.flyTo({
                    center: bounds[0],
                    zoom: zoomLevel,
                    bearing: 0,
                    speed: 2, // make the flying slow
                    curve: 4.5, // change the speed at which it zooms out
                    easing: function (t) { return t; }
                });
                if (this.props.secondaryView === 'Google-StreetView') {
                    const panorama = this.props.googleMap.getStreetView()
                    panorama.setPosition({lng: bounds[0].lng, lat: bounds[0].lat});
                    panorama.setPov(
                        /** @type {google.maps.StreetViewPov} */ {
                        heading: 34,
                        pitch: 10,
                        }
                    );
                    panorama.setVisible(true);
                }
            } else if (bounds.length > 1) {
                bounds.forEach((coordinate) => {
                    bbox.extend(coordinate);
                });

                if ((bbox.getSouthWest().lat === bbox.getNorthEast().lat) && (bbox.getSouthWest().lng === bbox.getNorthEast().lng)) {
                    map.flyTo({
                        center: bounds[0],
                        zoom: zoomLevel,
                        bearing: 0,
                        speed: 2, // make the flying slow
                        curve: 4.5, // change the speed at which it zooms out
                        easing: function (t) { return t; }
                    });
                } else {
                    map.fitBounds(bbox, {
                        maxZoom: zoomLevel,
                        padding: { top: 35, bottom: 25, left: 25, right: 25 }
                    });
                }
            }
        }
    }

    satSetBounds = (bounds, map, zoomLevel) => {
        if (map) {
            var bbox = new mapboxgl.LngLatBounds();
            if (bounds.length === 1) {
                map.flyTo({
                    center: bounds[0],
                    zoom: zoomLevel,
                    bearing: 0,
                    screenSpeed: 4, // make the flying slow
                    minZoom: 12,
                    easing: function (t) { return t; }
                });
            } else if (bounds.length > 1) {
                bounds.forEach((coordinate) => {
                    bbox.extend(coordinate);
                });

                if ((bbox.getSouthWest().lat === bbox.getNorthEast().lat) && (bbox.getSouthWest().lng === bbox.getNorthEast().lng)) {
                    map.flyTo({
                        center: bounds[0],
                        zoom: zoomLevel,
                        bearing: 0,
                        screenSpeed: 4, // make the flying slow
                        minZoom: 12,
                        easing: function (t) { return t; }
                    });
                } else {
                    map.fitBounds(bbox, {
                        maxZoom: zoomLevel,
                        padding: { top: 35, bottom: 25, left: 25, right: 25 }
                    });
                }
            }
        }
    }

    addPopup(place, location, index) {
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker'>
            <div>
                <span className="close-popup" >
                    <BsX className="hand-cursor" onClick={this.onPopupClose}>close</BsX>
                </span>
            </div>
            {
                place.result_type === 'region' || place.result_type === 'polygon'
                    ? <div className='popup-text'> <div>{place.location.formatted_address}</div> </div>
                    : <div className='popup-text'>
                        <div><b>{place.name}</b></div>
                        <div>{place.location.house_number} {place.location.street} {location.intersection ? ' & ' + location.intersection : ''}</div>
                        <div>{place.location && place.location.unit && place.location.unit.trim() ? ('Unit ' + place.location.unit) : ''}
                            {place.location && place.location.unit && place.location.unit.trim() &&
                                place.location.floor && place.location.floor.trim() ? ', ' : ''}
                            {place.location && place.location.floor && place.location.floor.trim() ? ('Floor ' + place.location.floor) : ''}</div>
                        <div>{place.location.city}, {place.location.state_code} {place.location.postal}</div>
                    </div>
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    removeMarkers = () => {
        this.markers.forEach(m => m.remove());
        this.markers = [];
        this.satMarkers.forEach(m => m.remove());
        this.satMarkers = [];
        turnoffMapDrag(this);
    }

    render() {
        const { submitted, firstStreet, secondStreet, city, zip } = this.state;
        return (
            <div className='intersection'>

                <Form>
                    <Form.Control type="text" name="firstStreet" placeholder="First Street"
                        onChange={this.handleChange} value={this.state.firstStreet} required />
                    {firstStreet === '' & submitted ?
                        <div className='error-msg'>Enter First Street</div>
                        : ''}
                    <Form.Control type="text" name="secondStreet" placeholder="Second Street"
                        onChange={this.handleChange} value={this.state.secondStreet} required />
                    {secondStreet === '' & submitted ?
                        <div className='error-msg'>Enter Second Street</div>
                        : ''}
                    <Form.Control type="text" name='city' placeholder="City"
                        onChange={this.handleChange} value={this.state.city} required />
                    <Form.Control type="text" name='state' placeholder="State"
                        onChange={this.handleChange} value={this.state.state} required />
                    <Form.Control type="text" name='zip' placeholder="Zip" maxLength="5"
                        value={this.state.zip}
                        onChange={this.handleChange} required />
                    {(city === '' && zip === '' && submitted) ?
                        <div className='error-msg'>Enter City or Zip</div>
                        : ''}
                    <Button variant="outline-primary" className='cancel-btn' onClick={this.clearSearches}
                    >Cancel</Button>
                    <Button className='search-button' variant="outline-primary" onClick={this.handleSearch}
                    >Search</Button>
                    {this.state.showSpinner ?
                        <div className='error-msg'>
                            <Spinner animation="border" variant="primary" size='sm' />
                        </div>
                        : ''
                    }
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        searchString: state.search.searchString,
        isSearchZoom: state.searchPauseZoom ? state.searchPauseZoom.isSearchZoom : false
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPauseZoom: (isPauseZoom) => dispatch(setPauseZoom(isPauseZoom)),
        setSearchZoom: (val) => dispatch(setSearchZoom(val)),
        toggleSearch: (val) => dispatch(toggleSearchFlag(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchIntersect);
