import React, { Component } from "react";
import { Spinner, Form } from "react-bootstrap";
import mapboxgl from 'mapbox-gl';
import { isEqual } from 'lodash';
import { trackingDeviceDeclarations, configTimers, vendor, featureLabels, indoorMapObj, errorMessages } from "../constants.js";
import moment from "moment";
import DateUtils from '../DateUtils.js';
import { connect } from 'react-redux';
import { isFeatureAllowed } from '../utils/groupFeatureUtils';
import { consoleLog, prepareClustersGeojson, createDonutChart, calculateUpdateMarkerBBbox, interpolate, calculateHistoryMarkerBBbox, UpdatePrepareClustersGeojson, getTraxmateVendorFromGP } from "../utils/commonUtils";
import { isIndoorDataAvailable } from "../utils/TrackingUtils";
import '../styles/incident.scss';
import icWph2InActive from '../assets/wph2_completed.svg';
import icWph2Active from '../assets/wph2_active.svg';
import icWph2Selected from '../assets/wph2_selected.svg';

import deviceActive from '../assets/device_active.svg';
import deviceInActive from '../assets/device_inactive.svg';
import deviceSlected from '../assets/device_selected.svg';
import '../styles/deviceTracking.scss';
import { BsChevronRight } from "react-icons/bs";
import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui';
import TrackingServices from '../services/TrackingServices';
import { setDevicesListData, updateFloorChangeData } from '../redux/actions/headerActions';
import turfLength from "@turf/length";
import turfAlong from "@turf/along";
import * as turfHelper from '@turf/helpers';
import turfBearing from "@turf/bearing";
import playIcon from '../assets/play.svg';
import pauseIcon from '../assets/pause.svg';
import icTrash from '../assets/delete_icon.svg';

const unclusteredPoint = 'unclustered-point';
const devicesLayerId = 'device-layer';
const clusterCount = "cluster-count";

const colors = ['#008000', '#99999B', '#99999B'];
const highStatusExp = ['==', ['get', 'status'], ['literal', 'active']];
const mediumsStatusExp = ['==', ['get', 'status'], ['literal', 'inactive']];
const lowStatusExp = ['==', ['get', 'status'], ['literal', '']];
const displayDateFormat = "MM/DD/YYYY";
const displayTimeFormat = "";
const blueColor = '#438CBC'
const orangeColor = '#f99403'
const statusArray = ['indoor', 'outdoor']
let animation; // to store and cancel the animation

let lineId = 'animate-line';
let pointId = 'animate-point';

// objects for caching and keeping track of HTML marker objects (for performance)
var markers = {};
let markersOnScreen = {};
// Used to increment the value of the point measurement against the route.
var counter = 0;
var deviceHistoryGeojson = {}
var historyInterpolateCoordinates = [];
var pointsData = {}
// Number of steps to use in the arc and animation, more steps means a smoother arc and animation, but too many steps will result in a
// low frame rate
var steps = 2000;
  const speedFactor = 10; // number of frames per longitude degree
  let startTime = 0;
  let progress = 0; // progress = timestamp - startTime
  let resetTime = false; // indicator of whether time reset is needed for the animation
var historyRoutePositionStatus = ''
class DeviceTracking extends Component {
  constructor(props) {
    super(props);
    this.dateUtils = new DateUtils();
    this.trackingService = new TrackingServices();
    this.state = {
      clusterData: [],
      showSpinner: false,
      showDeviceList: [],
      deviceFilterList: [],
      startDate: trackingDeviceDeclarations.historyObj.startDate ? trackingDeviceDeclarations.historyObj.startDate : null,
      endDate: trackingDeviceDeclarations.historyObj.endDate ? trackingDeviceDeclarations.historyObj.endDate : null,
      seletedTypeValue: null,
      showNoHistoryMsg: false,
      sliderMin: 0,
      sliderMax: 0,
      sliderTicCount: trackingDeviceDeclarations.historyObj.sliderTickCount ? trackingDeviceDeclarations.historyObj.sliderTickCount : 0,
      animateHistoryData: false,
      showHistorySlider: false,
      displayDateTime: null
    };
    this.isDeviceDataUpdated = false;
    this.donutMarkers = [];
    this.isTrackingAllowed = isFeatureAllowed(featureLabels.device_tracking);
    this.trackingIntervalId = null;
    this.animationTimer = null;
    this.selectedDevice = null;
    this.breadcrumbLayers = [];
    this.animateLayers = [];
    this.activeDevice = null;
    this.animateInterval = null;
    this.deviceHistoryData = trackingDeviceDeclarations.historyObj.histotyData ? trackingDeviceDeclarations.historyObj.histotyData : null;
    this.prevEndDate = null;
    this.endDateCounter = 0;
    this.animationCompleted = false;
    this.autoZoom = true;
    this.zoomToActive = false;
  }

  componentDidMount() {
    this.updateDevicesList();
    this.startGetDeviceIntervalTimer();
    this.getHistoryData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { mapUrl } = this.props;
    if (prevProps.mapUrl && prevProps.mapUrl !== mapUrl) {
      cancelAnimationFrame(animation);
      clearTimeout(this.animationTimer);
      // this.removeDeviceHistoryData();
      clearInterval(this.trackingIntervalId)
      this.startGetDeviceIntervalTimer();
    }
    if (prevProps.loadMap !== this.props.loadMap) {
      this.getHistoryData();
      this.updateDevicesList();
    }
    if (this.isDeviceDataUpdated) {
      this.isDeviceDataUpdated = false
      this.updateDevicesList();
    }
    if (prevState.seletedTypeValue && this.state.seletedTypeValue && prevState.seletedTypeValue !== this.state.seletedTypeValue) {
      trackingDeviceDeclarations.deviceId = null;
      this.selectedDevice = null;
      this.updateDevicesList();
    }
  }

  startGetDeviceIntervalTimer = () => {
    this.trackingIntervalId = setInterval(() => this.getDevicesList(), configTimers.trackingDataReqTime);
  }

  getDevicesList = () => {
    if (this.isTrackingAllowed) {
      let groupVendor = getTraxmateVendorFromGP();
      if(groupVendor && groupVendor == '') {
        return
      }

      this.trackingService.getTrackingData(groupVendor,trackingDeviceDeclarations.deviceId).then(response => {
        if (response.status == 200) {
          if (response && response.data && response.data.length && response.data[0].vehicles && response.data[0].vehicles.length) {
            let positionUpdated = this.isPositionUpdated(response.data[0].vehicles)
            if (positionUpdated) {
              this.props.setDevicesListData(response.data[0].vehicles);
            } else {
              let allDevicesList = response.data[0].vehicles;
                if(allDevicesList.length){
                  allDevicesList.map((device, index) => {
                    device.textField = index+1;
                  })
                }
              var updatedDevicesList = this.getLeftPannelData(allDevicesList);
              this.setState({
                showDeviceList: updatedDevicesList
              })
            }
            this.isDeviceDataUpdated = positionUpdated
          }
        }
      })
        .catch(error => {
          console.log('error: ', error);
        });
    }
  }

  updateDevicesList = () => {
    if (this.props.devicesList && this.props.devicesList.length) {
      var dropDownList = this.props.devicesList.reduce((unique, o) => {
        if (!unique.some(obj => obj.type === o.type)) {
          unique.push(o);
        }
        return unique;
      }, []);
      let allDevicesList = this.props.devicesList;
      if (allDevicesList.length) {
        allDevicesList.map((device, index) => {
          device.textField = index + 1;
        })
      }
      var updatedDevicesList = this.getLeftPannelData(allDevicesList);
      this.setState({
        showDeviceList: updatedDevicesList,
        deviceFilterList: dropDownList
      }, () => {
        if (trackingDeviceDeclarations.historyObj.histotyData && trackingDeviceDeclarations.historyObj.startDate && trackingDeviceDeclarations.historyObj.endDate) {
          if (this.props.loadMap && this.props.map) {
            this.enableDisableLiveMarkers(true)
          }
          return
        }
        this.displayDevicesList();
      })
    }
  }

  getLeftPannelData = (obj) => {
    var updatedDevicesList = obj;
      updatedDevicesList.map(device => {
        if (trackingDeviceDeclarations.deviceId === device.id) {
          device.showAddress = true;
          device.deviceType = 'selected';
        }
        else {
          device.showAddress = false;
          device.deviceType = device.status;
        }
      })
      if (trackingDeviceDeclarations.selectedDeviceType) {
        if (trackingDeviceDeclarations.selectedDeviceType !== 'allDeviceTypes') {
          this.setState({
            seletedTypeValue: trackingDeviceDeclarations.selectedDeviceType
          })
          updatedDevicesList = updatedDevicesList.filter(device => {
            return device.type === trackingDeviceDeclarations.selectedDeviceType;
          });
        }
        else {
          updatedDevicesList = updatedDevicesList;
        }
      }
      return updatedDevicesList;
  }

  handleSelect = (item) => {
    this.setState({
      showNoHistoryMsg: false
    })
    let showDeviceList = this.state.showDeviceList;
    if (showDeviceList && showDeviceList.length) {
      this.autoZoom = true;
      this.removeDeviceHistoryData();
      showDeviceList.map(device => {
        if (device.id === item.id) {
          device.showAddress = !device.showAddress;
          if (device.showAddress) {
            trackingDeviceDeclarations.deviceId = device.id;
            device.deviceType = 'selected';
            this.selectedDevice = device;
	          // this.activeDevice = device;
          }
          else {
            device.deviceType = device.status;
            trackingDeviceDeclarations.deviceId = null;
            trackingDeviceDeclarations.currentFloorNo = 0
            this.selectedDevice = null;
            this.zoomToActive = true; 
          }
        }
        else {
          device.showAddress = false;
        }
      })
    }

    this.startDataPull()
    this.displayDevicesList(true);
  }

  displayDevicesList = (zoomToselected) => {
    let bounds = [];
    this.removeDonuts();
    this.clearDeviceLayers();
    let zoomLevel = trackingDeviceDeclarations.defaultZoomLevel
    if (this.state.showDeviceList && this.props.loadMap) {
      let trackJson = {
        type: "FeatureCollection",
        features: []
      };
      this.removeLines()
      this.state.showDeviceList.forEach((device, index) => {
        if (device.geometry && device.geometry.coordinates && device.geometry.coordinates.length) {
          if (trackingDeviceDeclarations.deviceId && device.id === trackingDeviceDeclarations.deviceId) {
            let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
            if(zoomToselected && isIndoorDataAvailable(device)) {
              bounds.push(lngLat);
              zoomLevel = indoorMapObj.indoorMapZoomLevel
              this.autoZoom = true;
            } else  if(zoomToselected) {
              zoomLevel = trackingDeviceDeclarations.defaultZoomLevel
              bounds.push(lngLat);
              this.autoZoom = true;
            } else {
              var newIndoorObj = isIndoorDataAvailable(device)
              var oldIndoorObj = isIndoorDataAvailable(this.selectedDevice)
              if((newIndoorObj && !oldIndoorObj)) {
                bounds.push(lngLat);
                zoomLevel = indoorMapObj.indoorMapZoomLevel
                this.selectedDevice = device;
                this.autoZoom = true;
              } else if(oldIndoorObj && !newIndoorObj) {
                bounds.push(lngLat);
                zoomLevel = trackingDeviceDeclarations.defaultZoomLevel
                this.selectedDevice = device;
                this.autoZoom = true;
              }
            }

            this.checkFloorChange(device)
            if(device.geometry.coordinates.length > 1) {
              this.addLine(device)
            } else {
              this.removeLines()
            }
          } else if (!trackingDeviceDeclarations.deviceId) {
            if(this.zoomToActive && device.status === trackingDeviceDeclarations.activeStatus) {
              let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
              bounds.push(lngLat);
            } else if(!this.zoomToActive) {
              let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
              bounds.push(lngLat);
            }
            
          }
          trackJson.features.push(prepareClustersGeojson(device));
        }
      });
      if (bounds.length && this.autoZoom) {
        if(this.zoomToActive) {
          this.zoomToActive = false
        }
        this.autoZoom = false;
        this.setBounds(bounds, zoomLevel);
      }
      this.addClusteredResources(trackJson, devicesLayerId);
      if (this.props.map) {
        this.updateMarkers();
        this.props.map.on('render', () => {
          if (!this.props.map.getSource(devicesLayerId) || !this.props.map.isSourceLoaded(devicesLayerId))
            return;
          this.updateMarkers();
        });
      }
    }
  }

  // objects for caching and keeping track of HTML marker objects (for performance)
  updateMarkers = () => {
    if (this.props.map) {
      let that = this;
      const newMarkers = {};
      const features = this.props.map.querySourceFeatures(devicesLayerId);
      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {
        const coords = feature.geometry.coordinates;
        const properties = feature.properties;
        if (!properties.cluster) continue;
        const id = properties.cluster_id;
        let marker = markers[id];
        if (!marker) {
          const el = createDonutChart(properties, colors);
          el.addEventListener('click', (e) => {
            this.props.map.getSource(devicesLayerId).getClusterExpansionZoom(
              id,
              (err, zoom) => {
                if (err) return;

                this.props.map.easeTo({
                  center: coords,
                  zoom: zoom
                });
              }
            );
            el.addEventListener('mouseleave', function (e) {
              e.target.style.cursor = "";
            })
          })
          marker = markers[id] = new mapboxgl.Marker({
            element: el, anchor: 'bottom'
          }).setLngLat(coords);
        }
        this.donutMarkers.push(marker);
        newMarkers[id] = marker;
        if (!markersOnScreen[id]) marker.addTo(this.props.map);
      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].remove();
      }
      markersOnScreen = newMarkers;
    }
  }

  addClusteredResources = (data, layerName) => {
    if (this.props.loadMap && this.props.map) {
      // add a clustered GeoJSON source for a sample set of incidents
      if (!this.props.map.getSource(layerName)) {
        this.props.map.addSource(layerName, {
          'type': 'geojson',
          'data': data,
          'cluster': true,
          'clusterRadius': 40,
          'clusterProperties': {
            // keep separate counts for each magnitude category in a cluster
            'highStatus': ['+', ['case', highStatusExp, 1, 0]],
            'mediumStatus': ['+', ['case', mediumsStatusExp, 1, 0]],
            'lowStatus': ['+', ['case', lowStatusExp, 1, 0]]
          }
        });

        // circle and symbol layers for rendering individual incidents (unclustered points)
        this.props.map.addLayer({
          'id': layerName,
          'type': 'symbol',
          'source': layerName,
          filter: ['!', ['has', 'point_count']],
          layout: {// Make the layer visible by default.
            "text-field": ['get', 'textField'],
            "text-anchor": "center",
            "text-size": 12,
            "text-offset": [-0.1,-2],
            "text-justify": "center",
            'visibility': 'visible',
            "icon-image": [
              "match",
              [
                "get",
                "deviceType"
              ],
              ["active"], 'device_active',
              ["inactive"], 'device_inactive',
              ["selected"], 'device_selected',
              'device_active'
            ],
            "icon-size": 0.8,
            'icon-anchor': 'bottom'
          },
          "paint": {
            "text-color": "#000"
          }

        });
        this.props.map.on("mousemove", layerName, () => {
          this.props.map.getCanvas().style.cursor = "pointer";
        });
        this.props.map.on("mouseleave", layerName, () => {
          if (this.props.isMeasurementOn) {
            this.props.map.getCanvas().style.cursor = 'crosshair';
          } else {
            this.props.map.getCanvas().style.cursor = '';
          }
        });
      } else { // If the layer has already been added then just update it with the new data
        this.props.map.getSource(layerName).setData(data);
      }
    }
  }

  getDeviceLocationHistory = () => {
    if (this.validateStartAndEndDate()) {
      if (this.isTrackingAllowed && trackingDeviceDeclarations.deviceId) {
        const deviceId = trackingDeviceDeclarations.deviceId;
        let startDateInMs = moment(this.state.startDate).valueOf();
        let endDateInMs = moment(this.state.endDate).valueOf();
        this.resetDeviceHistoryValues();
        let groupVendor = getTraxmateVendorFromGP();
        if(groupVendor && groupVendor == '') {
          return
        }
        this.trackingService.getDeviceLocationHistory(groupVendor, deviceId, startDateInMs, endDateInMs).then(response => {
        if (response.status == 200) {
          if (response && response.data && response.data.positions && response.data.positions.length) {
              this.setState({ showHistorySlider: true }, () => { 
              this.enableDisableLiveMarkers(true)
              });
              this.deviceHistoryData = response.data.positions
              trackingDeviceDeclarations.historyObj.histotyData = response.data.positions
              this.prepareRouteData(response.data.positions, true)
            } else {
              this.enableDisableLiveMarkers(false)
              this.setState({ 
                showNoHistoryMsg: true,
                showHistorySlider: false,
              });
          }
        }
      })
        .catch(error => {
          this.enableDisableLiveMarkers(false)
              this.setState({ 
                showNoHistoryMsg: true,
                showHistorySlider: false,
              });
          console.log('error: ', error);
        });
      }
    }
  }

  prepareRouteData = (devicePositionsList, centerOrigin) => {
    if (devicePositionsList && devicePositionsList.length) {
      const newCoordinates = devicePositionsList.map(position => [position.coordinates[1], position.coordinates[0]]);
      var origin = newCoordinates[0];
      var destination = newCoordinates[newCoordinates.length - 1];
      historyRoutePositionStatus = origin.isIndoor ? statusArray[0] : statusArray[1]
      
      historyInterpolateCoordinates = [];
      historyInterpolateCoordinates = newCoordinates

      var markerCurrentPosition = origin
      if(this.state.sliderTicCount === 0) {
        markerCurrentPosition = origin
      } else {
        markerCurrentPosition = historyInterpolateCoordinates[this.state.sliderTicCount]
      }

      deviceHistoryGeojson = {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'geometry': {
              'type': 'LineString',
              'coordinates': [markerCurrentPosition]
            }
          }
        ]
      };

      // A single point that animates along the route.
      // Coordinates are initially set to origin.
      pointsData = {
        'type': 'FeatureCollection',
        'features': [
          {
            'type': 'Feature',
            'properties': {},
            'geometry': {
              'type': 'Point',
              'coordinates': markerCurrentPosition
            }
          }
        ]
      };

      this.setState({
        sliderMin: counter,
        sliderMax: (historyInterpolateCoordinates.length - 1),
      });
      this.addRouteSourcesAndLayers(deviceHistoryGeojson, pointsData, markerCurrentPosition, destination)
      
    }
  } // drawHistoryRoute end

  addRouteSourcesAndLayers = (geojson, pointsData, markerCurrentPosition, destination) => {
    if (this.props.map) {
      let layers = this.animateLayers;
      layers.push(lineId, pointId);
      this.animateLayers = layers;
      if (!this.props.map.getLayer(lineId)) {
        this.props.map.addSource(lineId, {
          'type': 'geojson',
          'data': geojson
        });
        // add the line which will be modified in the animation
        this.props.map.addLayer({
          'id': lineId,
          'type': 'line',
          'source': lineId,
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': blueColor,
            'line-width': 6,
            'line-opacity': 0.8
          }
        });
      } else {
        this.props.map.getSource(lineId).setData(geojson);
      }
      
      if (!this.props.map.getLayer(pointId)) {
        this.props.map.addSource(pointId, {
          'type': 'geojson',
          'data': pointsData
        });

        this.props.map.addLayer({
          'id': pointId,
          'source': pointId,
          'type': 'symbol',
          'layout': {
            'icon-image': 'WPH2S',
            'icon-size': 0.6,
            'icon-rotate': ['get', 'bearing'],
            'icon-rotation-alignment': 'map',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-anchor': 'bottom'
          }
        })
      } else {
        this.props.map.getSource(pointId).setData(pointsData);
      }
      clearInterval(this.trackingIntervalId);
      this.flyToDefaultZoom(markerCurrentPosition, trackingDeviceDeclarations.defaultZoomLevel)
    }
    startTime = performance.now();
  }

  flyToDefaultZoom = (centerPosition, zoomLevel) => {
    if (this.props.loadMap && this.props.map) {
      this.props.map.flyTo({
        center: centerPosition,
        zoom: zoomLevel,
        bearing: 0,
        speed: 1.2, // make the flying slow
        curve: 4, // change the speed at which it zooms out
        easing: function (t) { return t; }
      });
    }
  }

  handleDeviceFilterSelection = (e) => {
    this.autoZoom = true;
    let filterDeviceList = [];
    trackingDeviceDeclarations.selectedDeviceType = e.target.value;
    if (e.target.value !== 'allDeviceTypes') {
      filterDeviceList = this.props.devicesList.filter(device => {
        return device.type === e.target.value
      });
    }
    else {
      filterDeviceList = this.props.devicesList;
    }
    this.removeDeviceHistoryData()
    this.setState({
      showDeviceList: filterDeviceList,
      seletedTypeValue: e.target.value
    }, () => {
      this.displayDevicesList();
    })
  }

  setStartDate = (date) => {
    this.removeDeviceHistoryData();
    this.resetEndDateCounters();
    this.setState({
      startDate: date,
      endDate: null,
      showNoHistoryMsg: false,
    }, () => {
      trackingDeviceDeclarations.historyObj.startDate = date;
      trackingDeviceDeclarations.historyObj.endDate = null;
    });
  }

  setEndDate = (date) => {
    if (this.prevEndDate) {
      (moment(date).format('MM/DD/YYYY') == moment(this.prevEndDate).format('MM/DD/YYYY')) ? this.endDateCounter++ : (this.endDateCounter = 0);
    }
    this.prevEndDate = date;
    this.endDateCounter++;
    
    this.setState({
      endDate: date,
      showNoHistoryMsg: false,
    }, () => {
      if (this.endDateCounter > 1) {
        this.endDateCounter = 0;
        trackingDeviceDeclarations.historyObj.endDate = date;
        this.getDeviceLocationHistory();
      }
    });
  }

  resetEndDateCounters = () => {
    this.prevEndDate = null;
    this.endDateCounter = 0;
  }

  validateStartAndEndDate = () => {
    let validateMsg = this.dateUtils.isValidDateTime(this.state.startDate, this.state.endDate);
    return (validateMsg === 'valid');
  }

  removeLayer = (layerName) => {
    if (this.props.map.getLayer(layerName)) {
      this.props.map.removeLayer(layerName);
    }
  }

  removeSource = (layerName) => {
    if (this.props.map.getSource(layerName)) {
      this.props.map.removeSource(layerName);

    }
  }

  clearDeviceLayers = () => {
    if (this.props.loadMap && this.props.map) {
      this.removeLayer(devicesLayerId);
      this.removeSource(devicesLayerId);
    }
  }

  setBounds = (bounds, zoomLevel) => {
    var bbox = new mapboxgl.LngLatBounds();
    if (this.props.map) {
      if (bounds && bounds.length && bounds.length === 1) {
        this.props.map.flyTo({
          center: bounds[0],
          zoom: zoomLevel,
          bearing: 0,
          speed: 1.2, // make the flying slow
          curve: 4, // change the speed at which it zooms out
          easing: function (t) { return t; }
        });
      } else if (bounds && bounds.length && bounds.length > 1) {
        bounds.forEach((coordinate) => {
          bbox.extend(coordinate);
        });
        this.props.map.fitBounds(bbox, {
          maxZoom: trackingDeviceDeclarations.defaultZoomLevel,
          padding: { top: 120, bottom: 100, left: 50, right: 75 }
        });
      }
    }
  }

  removeDonuts = () => {
    if (this.donutMarkers.length) {
      this.donutMarkers.map(donut => {
        donut.remove();
      })
    }
    markers = {};
  }

  componentWillUnmount() {
    this.clearDeviceLayers();
    this.removeDonuts();
    this.removeLines()
    this.removeAnimateLines()
    clearInterval(this.trackingIntervalId);
    cancelAnimationFrame(animation);
    clearTimeout(this.animationTimer);
  }

  animateLine = (startRecursive) => {
    let markerBounds = [];
    // restart if it finishes a loop
    counter++;
    // append new coordinates to the lineString
    let coord = historyInterpolateCoordinates[counter]
    if (coord && deviceHistoryGeojson && deviceHistoryGeojson.features && deviceHistoryGeojson.features.length) {
      deviceHistoryGeojson.features[0].geometry.coordinates.push(coord);
      if (deviceHistoryGeojson.features[0].geometry.coordinates.length > trackingDeviceDeclarations.historyTickCount) {
        deviceHistoryGeojson.features[0].geometry.coordinates.shift();
      }
      // Update the source layer
      pointsData.features[0].geometry.coordinates = coord;
      this.props.map.getSource('animate-point').setData(pointsData);
      let markerLngLat = new mapboxgl.LngLat(pointsData.features[0].geometry.coordinates[0], pointsData.features[0].geometry.coordinates[1]);
      markerBounds.push(markerLngLat);
      let updateMarkerBbox = calculateHistoryMarkerBBbox(pointsData.features[0], this.props.map);
      if (!updateMarkerBbox && markerBounds.length) {
        this.setBounds(markerBounds, this.props.map.getZoom());
      }
      try {
        let currentObj = this.deviceHistoryData[counter - 1]
        let isIndoor = currentObj.isIndoor
        this.checkFloorChange(currentObj)
        let lngLat = new mapboxgl.LngLat(currentObj.coordinates[1], currentObj.coordinates[0]);
        if (historyRoutePositionStatus === 'indoor' && !isIndoor) {
          let bounds = [];
          bounds.push(lngLat)
          this.setBounds(bounds, trackingDeviceDeclarations.defaultZoomLevel)
          historyRoutePositionStatus = statusArray[1]
        } else if (historyRoutePositionStatus === 'outdoor' && isIndoor) {
          let bounds = [];
          bounds.push(lngLat)
          this.setBounds(bounds, indoorMapObj.indoorMapZoomLevel)
          historyRoutePositionStatus = statusArray[0]
        }
      } catch (error) {
        // DO NOTHING
      }
    }
    // then update the map
    if (deviceHistoryGeojson && deviceHistoryGeojson.features && deviceHistoryGeojson.features.length) {
      this.props.map.getSource('animate-line').setData(deviceHistoryGeojson);
    }
    this.setState({
      sliderTicCount: counter,
      displayDateTime: counter > 0 ? this.deviceHistoryData[counter - 1].timestamp : this.deviceHistoryData[0].timestamp
    });
    trackingDeviceDeclarations.historyObj.sliderTickCount = counter;
    // startRecursive is sent when user slides the slider
    if (startRecursive === false) {
      return
    }
    if (counter >= historyInterpolateCoordinates.length) {
      this.handleAnimationEnd()
      return
    }
    // Request the next frame of the animation.
    // animation = requestAnimationFrame(this.animateLine);
    this.animationTimer = setTimeout(this.animateLine, 500);
  }

  isPositionUpdated = (newDevices) => {
    const { devicesList } = this.props;
    if (!newDevices || !newDevices.length || !devicesList || !devicesList.length) {
      return false;
    }
    if (devicesList.length !== newDevices.length) {
      return true;
    }
    var positionUpdated = false;
    newDevices.forEach((newDevice, index) => {
      let newPosition = newDevice.geometry.coordinates[newDevice.geometry.coordinates.length - 1];

      devicesList.forEach((oldDevice, index) => {
        if (newDevice.id === oldDevice.id) {
          if(oldDevice.geometry.coordinates.length != newDevice.geometry.coordinates.length) {
            positionUpdated = true
            return
          } else {
            let oldPosition = oldDevice.geometry.coordinates[oldDevice.geometry.coordinates.length - 1];
            if (oldPosition && newPosition && oldPosition[0] != newPosition[0] && oldPosition[1] != newPosition[1]) {
              positionUpdated = true
              return
            }
          }
        }
      })
      if (positionUpdated) {
        return positionUpdated
      }
    })
    return positionUpdated
  }

  /* add breadcrumb */
  addLine = (device) => {
    let updateJson = {...device};
    let bounds = [];
    let geometry = [];
    let coordinateArray = [];
    let opacity = [0.3,0.4,0.5,0.6,0.7,0.8,0.9,1];
    let deviceUpdateJson = {
      type: "FeatureCollection",
      features: []
  };
    if (device.geometry.coordinates) {
      if (device.geometry.coordinates.length > 0) {
        if (device.geometry.coordinates.length > 8) {
          coordinateArray = device.geometry.coordinates.slice(-8);
        } else {
          coordinateArray = device.geometry.coordinates;
        }
        coordinateArray.forEach((coordinate, ind) => {
          if (coordinate && coordinate.length > 1) {
            updateJson.opacity = opacity[ind];
            updateJson.coordinates = coordinate;
            deviceUpdateJson.features.push(UpdatePrepareClustersGeojson(updateJson));
            geometry.push([coordinate[1], coordinate[0]]);
            if (geometry.length >= 8) {
              return
            }
          }
        });
      }
    }
    let layers = this.breadcrumbLayers;
    let circleId = 'initCircle' + device.id;
    let lineId = 'circles' + device.id;
    let polylineId = 'polyline' + device.id;
    layers.push(lineId, circleId, polylineId);
    this.breadcrumbLayers = layers;
    // if (this.props.map.getLayer(lineId)) {
    //     this.props.map.removeLayer(lineId);
    //     this.props.map.removeSource(lineId);
    // }
    if (!this.props.map.getSource(lineId)) {
      this.props.map.addSource(lineId, {
        'type': 'geojson',
        'data': deviceUpdateJson
      });
      this.props.map.addLayer({
        'id': lineId,
        "type": "circle",
        "source": lineId,
        "paint": {
          "circle-radius": 5,
          "circle-color": blueColor,
          "circle-opacity": ["get", "opacity"],
        }
      });
    } else { // If the layer has already been added then just update it with the new data
      this.props.map.getSource(lineId).setData(deviceUpdateJson);
    }

    let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
    bounds.push(lngLat);
    let updateMarkerBbox = calculateUpdateMarkerBBbox(device, this.props.map);
    if (!updateMarkerBbox && bounds.length) {
      this.setBounds(bounds, this.props.map.getZoom());
    }
  }

  removeLines = () => {
    let breadCrumbList = this.breadcrumbLayers;
    if(breadCrumbList && breadCrumbList.length){
        for (var i = 0; i < breadCrumbList.length; i++) {
            if (this.props.map.getLayer(breadCrumbList[i])) {
                this.props.map.removeLayer(breadCrumbList[i]);
                this.props.map.removeSource(breadCrumbList[i]);
            }
        }
    }
    this.breadcrumbLayers = [];
  }
  removeAnimateLines = () => {
    let animateLayersList = this.animateLayers;
    if(animateLayersList && animateLayersList.length){
        for (var i = 0; i < animateLayersList.length; i++) {
            if (this.props.map.getLayer(animateLayersList[i])) {
                this.props.map.removeLayer(animateLayersList[i]);
                this.props.map.removeSource(animateLayersList[i]);
            }
        }
    }
    this.animateLayers = [];
  }
  
  enableDisableLiveMarkers = (bool) => {
    if(this.props.map) {
      if (bool) {
        this.props.map.setLayoutProperty(devicesLayerId, 'visibility', 'none');
        this.removeLines()
      } else  {
        this.props.map.setLayoutProperty(devicesLayerId, 'visibility', 'visible');
      }
    }
  }

  playPauseHistoryAnimation = () => {
    this.setState({
      animateHistoryData: !this.state.animateHistoryData
    }, () => {
      if (this.deviceHistoryData && this.deviceHistoryData.length) {
        if (this.state.animateHistoryData) {
          clearInterval(this.trackingIntervalId);
          
          try {
            if (counter == 0) {
              let currentObj = this.deviceHistoryData[counter]
              let lngLat = new mapboxgl.LngLat(currentObj.coordinates[1], currentObj.coordinates[0]);
              let bounds = [];
              bounds.push(lngLat)
              if(!currentObj.isIndoor) {
                this.setBounds(bounds, indoorMapObj.indoorMapZoomLevel)
              } else {
                this.setBounds(bounds, trackingDeviceDeclarations.defaultZoomLevel)
              }
            }
          } catch (error) {

          }
          if (this.animationCompleted) {
            this.animationCompleted = false;
            counter = 0
            this.setState({
              sliderTicCount: counter
            }, () => {
              this.animateLine(true);
              trackingDeviceDeclarations.historyObj.sliderTickCount = counter;
            })
          } else {
            this.animateLine(true);
          }
          this.enableDisableLiveMarkers(true)
        } else {
          cancelAnimationFrame(animation);
          clearTimeout(this.animationTimer);
          // this.startGetDeviceIntervalTimer();
        }
      }
    })
  }

  handleSliderChange = (event, val) => {
    if (event && event.target) {
      counter = event.target.value;
    } else {
      counter = counter;
    }
    if (counter != 0) {
      counter = counter - 1
    } else {
      counter = -1
    }
    this.setState({
      sliderTicCount: counter
    }, () => {
      this.animateLine(false);
      trackingDeviceDeclarations.historyObj.sliderTickCount = counter;
    })
  }
  
  removeDeviceHistoryData = () => {
    this.enableDisableLiveMarkers()
    this.resetDeviceHistoryValues();
    this.setState({
      startDate: null,
      endDate: null
    }, () => {      
      trackingDeviceDeclarations.historyObj.startDate = null;
      trackingDeviceDeclarations.historyObj.endDate = null;
      trackingDeviceDeclarations.historyObj.sliderTickCount = 0;
      trackingDeviceDeclarations.historyObj.histotyData = null;
    })
  }

  onHistoryTrashClick = () => {
    if(this.selectedDevice) {
      var zoomLevel = isIndoorDataAvailable(this.selectedDevice) ? indoorMapObj.indoorMapZoomLevel : trackingDeviceDeclarations.defaultZoomLevel
      let lngLat = new mapboxgl.LngLat(this.selectedDevice.geometry.coordinates[this.selectedDevice.geometry.coordinates.length - 1][1], this.selectedDevice.geometry.coordinates[this.selectedDevice.geometry.coordinates.length - 1][0]);
      this.flyToDefaultZoom(lngLat, zoomLevel)
    }
    this.startDataPull()
  }

  startDataPull = () => {
    this.removeDeviceHistoryData();
    clearInterval(this.trackingIntervalId)
    this.startGetDeviceIntervalTimer();
  }

  resetDeviceHistoryValues = () => {
    this.setState({
      sliderTicCount: 0,
      showHistorySlider: false,
      animateHistoryData: false,
      displayDateTime: null
    })
    clearTimeout(this.animationTimer);
    counter = 0
    historyInterpolateCoordinates = []
    this.deviceHistoryData = null
    deviceHistoryGeojson = {}
    pointsData = {};
    this.removeAnimateLines()
  }

  handleAnimationEnd = () => {
    this.setState({
      animateHistoryData: !this.state.animateHistoryData
    }, () => {    
      counter = 0;
      this.animationCompleted = true;
      // this.startGetDeviceIntervalTimer();
      // this.enableDisableLiveMarkers()
    })
  }

  getHistoryData = () => {
    if(trackingDeviceDeclarations.historyObj.histotyData && trackingDeviceDeclarations.historyObj.startDate && trackingDeviceDeclarations.historyObj.endDate){
      this.setState({ showHistorySlider: true });
      this.prepareRouteData(trackingDeviceDeclarations.historyObj.histotyData, false);
      this.enableDisableLiveMarkers(true)
    }
  }

  checkFloorChange = (device) => {
    if (device && device.hasOwnProperty('indoor') && device.indoor && device.indoor.floorLabel) {
      var currentFloor = trackingDeviceDeclarations.currentFloorNo
      var updatedFloor = Number(device.indoor.floorLabel);
      if (currentFloor != updatedFloor && this.props.map.getZoom() >= indoorMapObj.indoorMapZoomLevel) {
        this.props.updateFloorChangeData(updatedFloor);
        trackingDeviceDeclarations.currentFloorNo = updatedFloor
      }
    } 
  }
  
  render() {
    const { startDate, endDate } = this.state;
    return (
      <>
        <div className='incident-panel'>
          <h4 className='incident-title tracking-title'>Devices</h4>
          <Form.Select aria-label="Select Device" className='filter-device hand-cursor' onChange={this.handleDeviceFilterSelection} value={this.state.seletedTypeValue}>
            <option value={'allDeviceTypes'}>All Types</option>
            {this.state.deviceFilterList && this.state.deviceFilterList.length ?
              this.state.deviceFilterList.map((device) => {
                return (
                  <option key={device.id} value={device.type}>{device.type}</option>
                )
              })
              : ''}
          </Form.Select>
          <div className='incident-list'>
            {
              !this.props.isLoading && this.state.showDeviceList ?
                this.state.showDeviceList.length ?
                  this.state.showDeviceList.map((device, index) => {
                    let diff = device.recorded_time !== '' ? moment(new Date(new Date())).diff(new Date(device.recorded_time)) : null;
                    let dateObj = diff ? moment.duration(diff) : null;
                    let recordedTime;
                    if (dateObj) {
                      if (dateObj._data.years) {
                        recordedTime = dateObj._data.years && dateObj._data.years > 1 ? dateObj._data.years + ' years ago' : dateObj._data.years && dateObj._data.years <= 1 ? dateObj._data.years + ' year ago' : null
                      }
                      else if (dateObj._data.months) {
                        recordedTime = dateObj._data.months && dateObj._data.months > 1 ? dateObj._data.months + ' months ago' : dateObj._data.months && dateObj._data.months <= 1 ? dateObj._data.months + ' month ago' : null
                      }
                      else if (dateObj._data.days) {
                        recordedTime = dateObj._data.days && dateObj._data.days > 1 ? dateObj._data.days + ' days ago' : dateObj._data.days && dateObj._data.days <= 1 ? dateObj._data.days + ' day ago' : null
                      }
                      else if (dateObj._data.hours) {
                        recordedTime = dateObj._data.hours && dateObj._data.hours > 1 ? dateObj._data.hours + ' hours ago' : dateObj._data.hours && dateObj._data.hours <= 1 ? dateObj._data.hours + ' hour ago' : null
                      } else if (dateObj._data.minutes) {
                        recordedTime = dateObj._data.minutes && dateObj._data.minutes > 1 ? dateObj._data.minutes + ' minutes ago' : dateObj._data.minutes && dateObj._data.minutes <= 1 ? dateObj._data.minutes + ' minute ago' : null
                      } else if (dateObj._data.seconds) {
                        recordedTime = dateObj._data.seconds ? 'few seconds ago' : null
                      }
                    }
                    let deviceStatusImage;
                    if (device.status === trackingDeviceDeclarations.activeStatus || device.status === trackingDeviceDeclarations.inActiveStatus) {
                      if (device.showAddress) {
                        deviceStatusImage = deviceSlected;
                      }
                      else {
                        deviceStatusImage = (device.status === trackingDeviceDeclarations.activeStatus) ? deviceActive : deviceInActive;
                      }
                    }
                    return (
                      <div key={device.id} className={`hand-cursor device-list ${device.showAddress ? 'active' : ''}`} >
                        <div onClick={this.handleSelect.bind(this, device)}>
                          <div className="d-flex align-center justify-content-start">
                            <div>
                              <img className="device-icon" src={deviceStatusImage} />
                            </div>
                            <div className="ms-2">
                              <div className="device-name">{device.label}</div>
                              <div className="device-address">{device.type}</div>
                              {recordedTime ? <div>{recordedTime}</div> : ''}
                            </div>
                            <BsChevronRight className={`show-less ${device.showAddress ? 'show-more' : ''}`}></BsChevronRight>
                            <div className="text-count">{device.textField}</div>
                          </div>
                          {device.showAddress ?
                            <div>
                              <div className="device-name mt-2">Complete Address</div>
                              <div>{device.address}</div>
                              {/* <div className="device-name mt-2">Accuracy</div>
                          <div>{device.altitude}</div> */}
                              <div className="device-name mt-2">Location</div>
                              {device.geometry && device.geometry.coordinates && device.geometry.coordinates.length ?
                                <div>[{parseFloat(device.geometry.coordinates[device.geometry.coordinates.length - 1][0]).toFixed(6)}, {parseFloat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1]).toFixed(6)}]</div>
                                : ''
                              }
                            </div>
                            : ''}
                        </div>
                        {device.showAddress ?
                          (<>
                          <EuiDatePickerRange className="mt-2"
                            fullWidth={false}
                            startDateControl={
                              <EuiDatePicker
                                dateFormat={displayDateFormat}
                                timeFormat={displayTimeFormat}
                                selected={startDate}
                                onChange={this.setStartDate}
                                startDate={startDate}
                                endDate={endDate}
                                allowSameDay={true}
                                isInvalid={endDate && startDate >= endDate}
                                aria-label="Start date"
                                timeIntervals={15}
                                showTimeSelect
                              />
                            }
                            endDateControl={
                              <EuiDatePicker
                                dateFormat={displayDateFormat}
                                timeFormat={displayTimeFormat}
                                selected={endDate}
                                onSelect={this.setEndDate}
                                startDate={startDate}
                                endDate={endDate}
                                allowSameDay={true}
                                isInvalid={startDate && endDate && startDate >= endDate}
                                aria-label="End date"
                                timeIntervals={15}
                                showTimeSelect
                              />
                            }
                          />
                          { this.state.showHistorySlider ? (<div className={`d-flex align-items-center play-history mt-2 position-relative ${this.state.showHistorySlider > 0 ? '' : 'disabledbutton'}`}>
                            <div className='animate-slider hand-cursor' onClick={this.playPauseHistoryAnimation}>
                              <img src={playIcon} className={`${this.state.animateHistoryData ? 'd-none' : 'play-icon'}`} />
                              <img src={pauseIcon} className={`${this.state.animateHistoryData ? 'pause-icon' : 'd-none'}`} />
                            </div>
                            <input
                              id="range"
                              type="range"
                              // min={this.state.sliderMin}
                              max={this.state.sliderMax}
                              value={this.state.sliderTicCount}
                              onChange={this.handleSliderChange}
                              step="1"
                            />
                            <img className='file-trash hand-cursor delete-history' title='Delete History' src={icTrash} onClick={this.onHistoryTrashClick} />
                          </div>) : '' }
                          {this.state.displayDateTime ? <div className="display-time text-center">{moment(new Date(this.state.displayDateTime)).format('MM-DD-YYYY hh:mm:ss A')}</div> : ''}
                          {this.state.showNoHistoryMsg ? (<div className='no-history-available'>{errorMessages.noDeviceHistory}</div>) : ''}
                          </>)
                          : ''}
                      </div>
                    )
                  })
                  : <div className='no-data-found'>No Data Found</div>
                :
                <Spinner animation="border" variant="primary">
                  <span className="sr-only"></span>
                </Spinner>
            }

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
    devicesList: state.devicesList.devicesList,
    isLoading: state.analytics.isLoading,
    dates: state.dates.dates,
    render: state.historyRender.render,
    intervals: state.historyIntervals.intervals,
    sliderVal: state.sliderVal.sliderVal,
    error: state.analytics.error,
    loadMap: state.loadMap.mapLoaded
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDevicesListData: (val) => dispatch(setDevicesListData(val)),
    updateFloorChangeData: (val) => dispatch(updateFloorChangeData(val))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTracking);
