import {callDeclarations, groupFeatures} from '../constants'

export function isFeatureAllowed(feature){
    if(!feature || !groupFeatures || !groupFeatures.features){
        return false;
    }

    return groupFeatures.features.includes(feature);
}

export function sitCountFeatureAllowed(feature){
    if(!feature || !groupFeatures || !groupFeatures.situcont){
        return false;
    }

    return groupFeatures.situcont.includes(feature);
}
export function SolacomGroup() {
    var solacomGroup = false;
    if(sessionStorage.getItem('currentUser') !== null && groupFeatures && groupFeatures.call_vendor && groupFeatures.call_vendor.length && (groupFeatures.call_vendor[0]).toLowerCase() == callDeclarations.solacomTopic){
        solacomGroup = true;
    }
    else{
        solacomGroup = false;
    }
    return solacomGroup;
}