import React from 'react';
import '../styles/incident.scss';
import mapboxgl from 'mapbox-gl';
import { Spinner } from "react-bootstrap";
import { connect } from 'react-redux';
import {
    getCallsData, getSimulationCallsData, fetchCallsDataRequested, getCameras, setCamerasReloaded, callsPanelReloaded, setSelectedCamera,
    setCallsData, fetchAED, setAEDReloaded, updateEndCallData, updateRapiSOSAddress, setCallDependencies, setCallAutoZoom
} from '../redux/actions/callActions';
import { serverconfigs, configTimers, featureLabels, customToastId, NO_DATA, callDeclarations, markerImages, groupFeatures, callsConfigs, cancelErrorMessages, tabUrls, solacomMarkerImages } from '../constants';
import { isFeatureAllowed, sitCountFeatureAllowed, SolacomGroup } from '../utils/groupFeatureUtils';
import moment from "moment";
import CameraPanel from './modals/CameraPanel';
import '../assets/Comtech_logo_V2.jpg';
import '../styles/callpanel.scss';
import { BsChevronRight, BsChevronDown, BsX } from "react-icons/bs";
import { toast } from 'react-toastify';
import IdleTimer from 'react-idle-timer';
import { fetchRenderRouting, fetchRouting, setStreamingStatus } from '../redux/actions/headerActions';
import { isEmpty, setSimulationEnabled, getStationIdFromLocalStorage, getEndCallTimeFromLocalStorage, consoleLog } from '../utils/commonUtils';
import turfBbox from '@turf/bbox';
import turfBuffer from '@turf/buffer';
var turfPoint = require("turf-point");
import turfDistance from '@turf/distance';
import turfNearestPointOnLine from "@turf/nearest-point-on-line";
import RapidSOSPanel from './RapidSOSPanel';
import SearchServices from '../services/search.services';
import { setSearchZoom } from '../redux/actions/incidentActions';
import SendSMSPopUp from "./modals/SendSMSPopUp";
import { createRoot } from 'react-dom/client';

var cylinderData;
var groupLoc, groupZoom, renderAED = true, renderCamera = true;

class CallsPanel extends React.Component {
    timer; // Camera update timer
    constructor(props) {
        super(props);
        this.state = {
            displayDate: new Date(),
            cameraImageUrl: '',
            expandModal: false,
            fullImage: '',
            hugeImage: '',
            showVideo: false,
            showCriminalHistory: false,
            showPastResidences: false,
            displayTimer: (callDeclarations.callTimerMinutes < 10 ? ('0' + callDeclarations.callTimerMinutes) : callDeclarations.callTimerMinutes) + ':' + (callDeclarations.callTimerSeconds < 10 ? '0' + callDeclarations.callTimerSeconds : callDeclarations.callTimerSeconds > 59 ? '00' : callDeclarations.callTimerSeconds),
            showMoreText: false,
            selectedEndCallId: '',
            contourLineProperties: 0,
            rapisSOSAddress: null,
            func1: this.createEndCallsMarkers.bind(this),
            popoverOpen: false

        };
        this.circleLayers = [];
        this.markers = [];
        this.endCallMarkers = [];
        this.satEndCallMarkers = [];
        this.onCameraClick = this.onCameraClick.bind(this);
        this.onAedClick = this.onAedClick.bind(this);
        this.interval;
        this.breadcrumbLayers = [];
        this.rapidSOSBreadcrumbLayers = [];
        this.oldCallData;
        this.rapidSOSDottedLineLayers = [];
        this.rapidSOSCircleLayers = [];
        this.aedPopups = []
        this.endCallOverlay = false;
        this.cylinderLayers = [];
        this.SearchServices = new SearchServices();
        this.callGecodeAPI = true;
        this.renderContour = false;
        this.activeMarkers = [];
        this.solacomBounds = [];
        this.solacomPopup;
        this.mobileNumber = "";
        this.satSolacomBounds = [];
        this.solacomOtherBounds = [];
        this.otherMarkers = [];
        this.satOtherSolacomBounds = [];
        this.solacomQueuedCalls = [];
    }

    componentDidMount() {
        this.props.setSearchZoom(false);
        setSimulationEnabled();
        if (callDeclarations.isSimulationEnabled) {
            clearInterval(this.interval);
            callDeclarations.callstartTime = 0;
            callDeclarations.callTimerSeconds = 0;
            callDeclarations.callTimerMinutes = 0;
            this.props.fetchCallsDataRequested();
            if (!SolacomGroup()) {
                this.getSimulationCallsData();
            }
            // this.props.setCallAutoZoom(false);
        } else {
            if (!this.props.callData) {
                this.removeLines();
                this.removeAllLayers();
                // this.getCallsData();
            } else {
                this.props.callsPanelReloaded(1);
            }
        }
        callDeclarations.idleTabName = tabUrls.call;
        var endCallTimer = getEndCallTimeFromLocalStorage();
        if (endCallTimer == 0) {
            this.props.updateEndCallData([]);
        }
        if (sitCountFeatureAllowed(featureLabels.aed)) {
            renderAED = true;
        }
        if (sitCountFeatureAllowed(featureLabels.camera)) {
            renderCamera = true;
        }
    }

    componentDidUpdate = (prevProps) => {
        const { isSearchZoom, solacomData } = this.props;

        if (prevProps.isSearchZoom && prevProps.isSearchZoom != isSearchZoom) {
            this.setState({
                selectedEndCallId: ''
            })
            this.props.callsPanelReloaded(1);
        }
        if (SolacomGroup()) {
            if (prevProps.solacomData && prevProps.solacomData.unplottedCalls && solacomData && solacomData.unplottedCalls) {
                if (prevProps.solacomData.unplottedCalls.length !== solacomData.unplottedCalls.length && solacomData.unplottedCalls.length === 0) {
                    this.setState({
                        popoverOpen: false
                    })
                }
            }
        }
    }

    getSimulationCallsData = () => {
        if (this.props.showMap) {
            this.props.getSimulationCallsData();
        } else {
            setTimeout(() => {
                this.getSimulationCallsData();
            }, 1000);
        }
    }

    /*  first time receiving a new NENAid, compare connectTime UTC with local machine UTC, show the difference
        counter continues when receiving second or more notificatins with same NENAid
        counter reset if there a different NENAid from the notification
        counter reset if current expires 
    */
    startTimer = () => {
        if (this.props.callData) {
            clearInterval(this.interval);
            if (callDeclarations.oldNinaId === '' || (callDeclarations.oldNinaId !== this.props.callData.nenaIncidentId) || (callDeclarations.oldNinaId === this.props.callData.nenaIncidentId && this.props.callData.callState === callsConfigs.endStaus)) {
                let currentDateTime = new Date();
                if (!callDeclarations.isSimulationEnabled) {
                    callDeclarations.oldNinaId = this.props.callData.nenaIncidentId;
                    let diff = moment(new Date(currentDateTime)).diff(new Date(this.props.callData.connectTime));
                    let dateObj = moment.duration(diff);
                    var day = currentDateTime.getUTCDate();
                    var mon = currentDateTime.getUTCMonth();
                    var year = currentDateTime.getUTCFullYear();
                    var hours = currentDateTime.getUTCHours();
                    var min = currentDateTime.getUTCMinutes();
                    var sec = currentDateTime.getUTCSeconds();
                    var milsec = currentDateTime.getUTCMilliseconds();
                    consoleLog('::::::::::::: connectTime :::: ', this.props.callData.connectTime + " ::::::::: Current Time ::: " + year + "-" + mon + "-" + day + " " + hours + ":" + min + ":" + sec + ":" + milsec);
                    callDeclarations.callTimerSeconds = dateObj._data.seconds;
                    callDeclarations.callTimerMinutes = dateObj._data.minutes;
                }
                this.setState({
                    displayDate: currentDateTime,
                })
            }
            if (callDeclarations.notificationFromUrl || callDeclarations.isSimulationEnabled) {
                this.reset911call();
            } else {
                this.interval = setInterval(() => {
                    if (this.props.callData) {
                        this.handleTimer();
                    }
                }, configTimers.counterTime);
            }
        }
    }

    /**
     * Create resizable circle matching the given radius
     * @param {*} center 
     * @param {*} radiusInKm 
     * @param {*} points 
     */
    createGeoJSONCircle(center, radiusInKm, points) {
        if (!points) points = 64;
        const coords = {
            latitude: center[1],
            longitude: center[0]
        };
        const km = radiusInKm;
        const ret = [];
        const distanceX =
            km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
        const distanceY = km / 110.574;
        let theta, x, y;
        for (var i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX * Math.cos(theta);
            y = distanceY * Math.sin(theta);
            ret.push([coords.longitude + x, coords.latitude + y]);
        }
        ret.push(ret[0]);
        return {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [ret]
                        }
                    }
                ]
            }
        };
    }

    prepareProperties(baseHeight, height, color) {
        return {
            name: "3D Cylynder",
            height: height,
            base_height: baseHeight,
            color: color,
        };
    }
    getNearestContourLine(map, coordinates) {
        var point = turfPoint(coordinates);
        var buffered = turfBuffer(point, callDeclarations.contourLineDistance, {
            units: "miles",
        });
        var coordinate1,
            coordinate2,
            closest,
            lineCoords = [];
        if (buffered) {
            var bbox = turfBbox(buffered);

            coordinate1 = map.project(new mapboxgl.LngLat(bbox[0], bbox[1]));
            coordinate2 = map.project(new mapboxgl.LngLat(bbox[2], bbox[3]));
            var features = map.queryRenderedFeatures([coordinate1, coordinate2]);
            if (features) {
                features.forEach((feature, index) => {
                    if (
                        feature &&
                        feature.sourceLayer &&
                        feature.sourceLayer.toLowerCase() === callDeclarations.contourLayer
                    ) {
                        var snapped = turfNearestPointOnLine(feature, point, {
                            units: "miles",
                        });
                        if (!closest) {
                            closest = {};
                            closest.calcData = snapped;
                            closest.feature = feature;
                        } else if (
                            closest.calcData.properties.dist > snapped.properties.dist
                        ) {
                            closest.calcData = snapped;
                            closest.feature = feature;
                        }
                    }
                });
            }
        }
        return closest;
    }
    addRapidSOSZCircle(map) {
        var rapidSOS = cylinderData.rapidSOS;
        try {
            let uncertyCylinder = 'uncertyCylinder';
            let zCylinder = "zCylinder";
            // if (!map.areTilesLoaded() && !this.renderContour) {
            //     setTimeout(() => this.addRapidSOSZCircle(map, rapidSOS), 2000);
            //     return;
            // }

            if (map && rapidSOS && rapidSOS.geometry) {
                if (map.getLayer(uncertyCylinder)) {
                    map.removeLayer(uncertyCylinder);
                    map.removeSource(uncertyCylinder);
                }
                if (map.getLayer(zCylinder)) {
                    map.removeLayer(zCylinder);
                    map.removeSource(zCylinder);
                }
                var allLayers = map.getStyle().layers;
                var geometry = rapidSOS.geometry;
                var contourLayerExist = false;
                for (var i = 0; i < allLayers.length; i++) {
                    if (allLayers[i].id.toLowerCase() === callDeclarations.contourLayer) {
                        contourLayerExist = true;
                        break;
                    }
                }
                if (contourLayerExist) {
                    if (rapidSOS.altitude === null || rapidSOS.altitude === undefined || rapidSOS.altitude === 0) {
                        if (this.props.showUncertainity && this.isRapidSOSDataAvailable()) {
                            this.addRapidSOSCircle(map, geometry, rapidSOS.radius / 1000);
                        }
                    } else {
                        this.cylinderLayers.push(uncertyCylinder, zCylinder);
                        if (map.getSource(uncertyCylinder) == null) {
                            map.addSource(uncertyCylinder, {
                                type: "geojson",
                                data: {
                                    features: [],
                                    type: "FeatureCollection",
                                },
                            });
                            map.addLayer({
                                id: uncertyCylinder,
                                type: "fill-extrusion",
                                source: uncertyCylinder,
                                minzoom: callDeclarations.zAxisZoomLevel,
                                paint: {
                                    // Get the `fill-extrusion-color` from the source `color` property.
                                    "fill-extrusion-color": ["get", "color"],
                                    // Get `fill-extrusion-height` from the source `height` property.
                                    "fill-extrusion-height": ["get", "height"],
                                    // Get `fill-extrusion-base` from the source `base_height` property.
                                    "fill-extrusion-base": ["get", "base_height"],
                                    // Make extrusions slightly opaque to see through indoor walls.
                                    "fill-extrusion-opacity": 0.5,
                                },
                            });
                        }
                        if (map.getSource(zCylinder) == null) {
                            map.addSource(zCylinder, {
                                type: "geojson",
                                data: {
                                    features: [],
                                    type: "FeatureCollection",
                                },
                            });
                            map.addLayer({
                                id: zCylinder,
                                type: "fill-extrusion",
                                source: zCylinder,
                                minzoom: callDeclarations.zAxisZoomLevel,
                                paint: {
                                    // Get the `fill-extrusion-color` from the source `color` property.
                                    "fill-extrusion-color": ["get", "color"],
                                    // Get `fill-extrusion-height` from the source `height` property.
                                    "fill-extrusion-height": ["get", "height"],
                                    // Get `fill-extrusion-base` from the source `base_height` property.
                                    "fill-extrusion-base": ["get", "base_height"],
                                    // Make extrusions slightly opaque to see through indoor walls.
                                    "fill-extrusion-opacity": 0.5,
                                },
                            });
                        }
                        let uncertyData = {
                            features: [],
                            type: "FeatureCollection",
                        };

                        let zData = {
                            features: [],
                            type: "FeatureCollection",
                        };

                        let coordinates =
                            geometry.coordinates[geometry.coordinates.length - 1];
                        let closestContourLine = this.getNearestContourLine(
                            map,
                            coordinates
                        );

                        let circleCoordinates = this.createGeoJSONCircle(
                            coordinates,
                            rapidSOS.radius / 1000
                        );
                        let feature = circleCoordinates.data.features[0];
                        let uncertyFeature = JSON.parse(JSON.stringify(feature));
                        let zFeature = JSON.parse(JSON.stringify(feature));
                        if (closestContourLine && rapidSOS.altitude) {
                            this.removeRapidSOSCircle(map);
                            if (isFeatureAllowed(featureLabels.z_axis)) {
                                consoleLog("closestContourLine");
                                this.setState({
                                    contourLineProperties: closestContourLine
                                })
                                uncertyFeature.properties = this.prepareProperties(
                                    parseFloat(rapidSOS.altitude * callDeclarations.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0),
                                    parseFloat(rapidSOS.altitude * callDeclarations.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0) +
                                    parseFloat((rapidSOS.zuncer ? rapidSOS.zuncer : 0) * callDeclarations.meterToFeet),
                                    "orange"
                                );
                                zFeature.properties = this.prepareProperties(
                                    parseFloat(rapidSOS.altitude * callDeclarations.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0) -
                                    parseFloat((rapidSOS.zuncer ? rapidSOS.zuncer : 0) * callDeclarations.meterToFeet),
                                    parseFloat(rapidSOS.altitude * callDeclarations.meterToFeet) -
                                    parseFloat(closestContourLine.feature.properties.topo_elev) -
                                    parseFloat(closestContourLine.feature.properties.H ? closestContourLine.feature.properties.H : 0),
                                    "grey"
                                );

                                uncertyData.features.push(uncertyFeature);
                                zData.features.push(zFeature);

                                map.getSource(uncertyCylinder).setData(uncertyData);
                                map.getSource(zCylinder).setData(zData);
                            }
                            else {
                                this.setState({
                                    contourLineProperties: 0
                                })
                                if (this.props.showUncertainity && this.isRapidSOSDataAvailable()) {
                                    this.addRapidSOSCircle(map, geometry, rapidSOS.radius / 1000);
                                }
                            }
                            this.renderContour = true;
                        } else {
                            this.renderContour = false;
                            this.setState({
                                contourLineProperties: 0
                            })
                            if (this.props.showUncertainity && this.isRapidSOSDataAvailable()) {
                                this.addRapidSOSCircle(map, geometry, rapidSOS.radius / 1000);
                            }
                        }

                        // map.getSource('Marker').setData(data);
                    }
                }
                else {
                    this.setState({
                        contourLineProperties: 0
                    })
                    if (this.props.showUncertainity && this.isRapidSOSDataAvailable()) {
                        this.addRapidSOSCircle(map, geometry, rapidSOS.radius / 1000);
                    }
                }
            }
        } catch (error) {
            consoleLog("::::::::: error addRapidSOSZCircle ::::::::::: ")
        }
    }

    addRapidSOSCircle(map, geometry, radius) {
        try {
            if (map && geometry.coordinates && geometry.coordinates.length) {
                var allLayers = map.getStyle().layers;
                var cameraLayersId;
                for (var i = 0; i < allLayers.length; i++) {
                    if (allLayers[i].id === "geofence-camera" || allLayers[i].id === "camera" ||
                        allLayers[i].id === "selected_camera" || allLayers[i].id === "dotCircles" || allLayers[i].id === "sosinitCircle" || allLayers[i].id === "soscircles"
                        || allLayers[i].id === "sospolyline") {
                        cameraLayersId = allLayers[i].id;
                        break;
                    }
                }
                let layers = [];
                let circleId = 'sosliveCircle';
                let circleBorder = 'sosliveBorder';
                layers.push(circleId, circleBorder);
                this.rapidSOSCircleLayers = layers;
                let polygonPoints = this.createGeoJSONCircle(
                    [Number(geometry.coordinates[geometry.coordinates.length - 1][0]), Number(geometry.coordinates[geometry.coordinates.length - 1][1])],
                    radius
                );

                if (!map.getLayer(circleBorder)) {
                    map.addSource(circleBorder, polygonPoints);
                    map.addLayer({
                        id: circleBorder,
                        type: "line",
                        source: circleBorder,
                        layout: {},
                        paint: {
                            'line-color': '#5bb0d8',
                            'line-width': 1
                        }
                    }, cameraLayersId);
                } else {
                    map.getSource(circleBorder).setData(polygonPoints.data);
                }
                if (!map.getSource(circleId)) {
                    map.addSource(circleId, polygonPoints);
                    map.addLayer({
                        id: circleId,
                        type: "fill",
                        source: circleId,
                        layout: {},
                        paint: {
                            "fill-color": "#9fcbf9",
                            "fill-opacity": 0.5,
                            'fill-outline-color': '#5bb0d8',
                        }
                    }, cameraLayersId);
                } else {
                    map.getSource(circleId).setData(polygonPoints.data);
                }
            }
        } catch (error) {
            consoleLog("::::::::: error addRapidSOSCircle ::::::::::: ")
        }
    }

    addCircle(map, geometry, radius, prefix) {
        // this.removeCircles(this.props.map);
        // this.removeCircles(this.props.satelliteMap);
        if (map && map.getStyle() && map.getStyle().layers && map.getStyle().layers.length && geometry.coordinates && geometry.coordinates.length) {
            var circlelayers = map.getStyle().layers;
            var cameraLayersId;
            for (var i = 0; i < circlelayers.length; i++) {
                if (circlelayers[i].id === "geofence-camera" || circlelayers[i].id === "camera" ||
                    circlelayers[i].id === "selected_camera" || circlelayers[i].id === "uncertyCylinder" || circlelayers[i].id === "zCylinder") {
                    cameraLayersId = circlelayers[i].id;
                    break;
                }
            }
            let layers = [];
            let circleId = 'liveCircle';
            let circleBorder = 'liveBorder';
            layers.push(circleId, circleBorder);
            this.circleLayers = layers;
            let polygonPoints = this.createGeoJSONCircle(
                [Number(geometry.coordinates[geometry.coordinates.length - 1][0]), Number(geometry.coordinates[geometry.coordinates.length - 1][1])],
                radius
            );

            if (!map.getLayer(circleBorder)) {
                map.addSource(circleBorder, polygonPoints);
                map.addLayer({
                    id: circleBorder,
                    type: "line",
                    source: circleBorder,
                    layout: {},
                    paint: {
                        'line-color': '#5bb0d8',
                        'line-width': 1
                    }
                }, cameraLayersId);
            } else {
                map.getSource(circleBorder).setData(polygonPoints.data);
            }
            if (!map.getSource(circleId)) {
                map.addSource(circleId, polygonPoints);
                map.addLayer({
                    id: circleId,
                    type: "fill",
                    source: circleId,
                    layout: {},
                    paint: {
                        "fill-color": "#9fcbf9",
                        "fill-opacity": 0.5,
                        'fill-outline-color': '#5bb0d8',
                    }
                }, cameraLayersId);
            } else {
                map.getSource(circleId).setData(polygonPoints.data);
            }
        }
    }

    moveToCallMarker = () => {
        if (this.props.map || this.props.satelliteMap) {
            this.renderContour = false;
            // If previous call data is empty then create a marker and zoom to the location.
            // else check for nena id, 
            // if both previous and new call nenaids are same then check for position update. 
            // else consider it as new call and proceed with marker creation
            // if both previous and new call positions are same then consider as same call and continue. 
            let positionUpdated = true;
            if (this.oldCallData) {
                if (this.props.callData.callState === callsConfigs.endStaus) {
                    if (SolacomGroup()) {
                        this.removeActiveMarker();
                    }
                    this.clearCallLayers();
                    clearInterval(this.interval);
                }
                if (this.oldCallData.nenaIncidentId === this.props.callData.nenaIncidentId) {
                    let oldPosition = this.oldCallData.geometry.coordinates[this.oldCallData.geometry.coordinates.length - 1];
                    let newPosition = this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1];
                    if (oldPosition && newPosition && oldPosition[0] === newPosition[0] && oldPosition[1] === newPosition[1]) {
                        positionUpdated = false;
                    }
                    if (this.oldCallData.hasOwnProperty('rapidSOS') && this.oldCallData.rapidSOS && this.oldCallData.rapidSOS.geometry && this.oldCallData.rapidSOS.geometry.coordinates && this.oldCallData.rapidSOS.geometry.coordinates.length
                        && this.isRapidSOSDataAvailable()) {
                        let oldRapidPosition = this.oldCallData.rapidSOS.geometry.coordinates[this.oldCallData.rapidSOS.geometry.coordinates.length - 1];
                        let newRapidPosition = this.props.callData.rapidSOS.geometry.coordinates[this.props.callData.rapidSOS.geometry.coordinates.length - 1];
                        if (oldRapidPosition[0] != newRapidPosition[0] && oldRapidPosition[1] != newRapidPosition[1]) {
                            positionUpdated = true;
                        }
                    }
                }
            }
            if (positionUpdated) {
                if (this.oldCallData) {
                    if (this.oldCallData.nenaIncidentId !== this.props.callData.nenaIncidentId) {
                        // this.props.setCallAutoZoom(false);
                        this.props.setSearchZoom(false);
                        renderAED = true;
                        renderCamera = true;
                    }
                }
                this.oldCallData = this.props.callData;
                this.setState({
                    selectedEndCallId: ''
                }, () => {
                    if (!SolacomGroup()) {
                        this.props.removePoiRouting();
                        this.props.fetchRouting(false);
                        this.props.fetchRenderRouting(1);
                    }
                    if (this.props.showMap && this.props.map) {
                        this.endCallOverlay = true;
                        this.createEndCallsMarkers()
                        if (!SolacomGroup()) {
                            if (this.props.vehicleType && this.props.vehicleType.length && !this.props.callAutoZoom && !this.props.isSearchZoom) {
                                this.setTrackingBounds();
                            }
                            else if (this.props.updateBounds && !this.props.callAutoZoom && !this.props.isSearchZoom) {
                                this.flyToActiveCall(this.props.map, 0, callDeclarations.initMapZoom);
                            }
                        }
                    }
                    if (!SolacomGroup()) {
                        if (this.props.isSatelliteMapLoaded && this.props.satelliteMap && !this.props.isSearchZoom) {
                            this.flyToSatActiveCall(this.props.satelliteMap, serverconfigs.satelliteMapPitch, callDeclarations.satelliteMapZoom);
                        }
                    }
                });
            }
            if (this.isRapidSOSDataAvailable() && this.props.showMap && this.props.map) {
                this.props.map.once('idle', () => {
                    if (callDeclarations.idleTabName === tabUrls.call) {
                        if (!this.renderContour) {
                            this.addRapidSOSZCircle(this.props.map);
                        }
                        this.props.map.on('zoom', () => {
                            if (!this.renderContour && callDeclarations.idleTabName === tabUrls.call) {
                                this.addRapidSOSZCircle(this.props.map);
                            }
                            if (!this.props.rapidSOS) {
                                this.removeAltCylinder(this.props.map);
                            }
                        })
                    }
                })
            }
            else {
                this.removeAltCylinder(this.props.map);
            }
        }
    };

    flyToActiveCall = (map, pitch, zoomLevel) => {
        let bounds = [];
        if (this.props.callData && (this.props.callData.callState === callsConfigs.activeStatus || this.props.callData.callState === callsConfigs.released)) {
            if (this.props.callData && this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length) {
                if (this.solacomQueuedCalls.length) {
                    bounds.push(...this.solacomQueuedCalls);
                    let lngLat = new mapboxgl.LngLat(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1]);
                    bounds.push(lngLat);
                }
                if (bounds.length) {
                    this.setBounds(bounds, map);
                }
                else {
                    map.flyTo({
                        center: this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1],
                        zoom: zoomLevel,
                        bearing: 0,
                        speed: 4, // make the flying slow
                        curve: 4.5, // change the speed at which it zooms out
                        pitch: pitch,
                        easing: function (t) { return t; }
                    });
                }
            }
        }
        else if (!SolacomGroup()) {
            this.handleGroupBounds(map);
        }
    }

    flyToSatActiveCall = (map, pitch, zoomLevel) => {
        let bounds = [];
        if (this.props.callData && (this.props.callData.callState === callsConfigs.activeStatus || this.props.callData.callState === callsConfigs.released)) {
            if (map && this.props.callData && this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length && this.props.callData.callState !== callsConfigs.endStaus) {
                if (SolacomGroup()) {
                    if (this.solacomQueuedCalls.length) {
                        bounds.push(...this.solacomQueuedCalls);
                        let lngLat = new mapboxgl.LngLat(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1]);
                        bounds.push(lngLat);
                    }
                    if (bounds.length) {
                        this.setBounds(bounds, map);
                    }
                    else {
                        map.flyTo({
                            center: this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1],
                            zoom: zoomLevel,
                            bearing: 0,
                            speed: 4, // make the flying slow
                            curve: 4.5, // change the speed at which it zooms out
                            pitch: pitch,
                            easing: function (t) { return t; }
                        });
                    }
                }
                else {
                    map.flyTo({
                        center: this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1],
                        zoom: zoomLevel,
                        bearing: 0,
                        screenSpeed: 4,
                        pitch: pitch,
                        minZoom: 12,
                        easing: function (t) { return t; }
                    });
                }
            }
        }
        else if (!SolacomGroup()) {
            this.handleSatGroupBounds(map);
        }
    }

    activeMarkerClickEvent = (map, pitch, zoomLevel) => {
        if (this.props.callData && this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length) {
            this.setState({
                selectedEndCallId: ''
            });
            this.endCallOverlay = true;
            this.createEndCallsMarkers();
            this.props.map.flyTo({
                center: this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1],
                zoom: callDeclarations.initMapZoom,
                bearing: 0,
                speed: 4, // make the flying slow
                curve: 4.5, // change the speed at which it zooms out
                pitch: 0,
                easing: function (t) { return t; }
            });
            if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
                this.props.satelliteMap.flyTo({
                    center: this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1],
                    zoom: callDeclarations.satelliteMapZoom,
                    bearing: 0,
                    screenSpeed: 4, // make the flying slow
                    pitch: serverconfigs.satelliteMapPitch,
                    minZoom: 12,
                    easing: function (t) { return t; }
                });
            }
        }
    }

    createMarker = (map, classOfService, pitch, zoomLevel) => {
        try {
            if (map && this.props.callData.callState !== callsConfigs.endStaus) {
                var coorLength = this.props.callData.geometry.coordinates.length;
                if (coorLength && this.props.callData.geometry.coordinates[coorLength - 1].length) {
                    let el = document.createElement('div');
                    el.addEventListener('click', this.activeMarkerClickEvent.bind(this, map, pitch, zoomLevel));
                    if (SolacomGroup()) {
                        el.className = 'incident-marker overlay-index solacom-marker';
                        if (this.props.callData.callStateExt === 'transferred' && this.props.callData.callState === callsConfigs.activeStatus) {
                            if (solacomMarkerImages.transferred[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.transferred[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.transferred['default']})`;
                            }
                        }
                        else if (this.props.callData.callState === callsConfigs.released) {
                            if (solacomMarkerImages.released[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.released[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.released['default']})`;
                            }
                        }
                        else {
                            if (solacomMarkerImages.activeMarkers[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers['default']})`;
                            }
                        }
                    }
                    else {
                        el.className = 'incident-marker overlay-index';
                        if (markerImages.activeMarkers[classOfService]) {
                            el.style.backgroundImage = `url(${markerImages.activeMarkers[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${markerImages.activeMarkers['default']})`;
                        }
                    }
                    let options = { element: el, anchor: 'bottom' };
                    // let lngLat = new mapboxgl.LngLat(this.props.callData.geometry.coordinates[0], this.props.callData.geometry.coordinates[1]);
                    let lngLat = new mapboxgl.LngLat(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1]);
                    let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
                    if (SolacomGroup()) {
                        el.addEventListener('mouseenter', this.solacomHoverPopup.bind(this, lngLat, this.props.callData, map));
                        el.addEventListener('mouseleave', this.removeSolacomHoverPopup);
                        this.activeMarkers.push(marker);
                    }
                    else {
                        this.markers.push(marker);
                    }
                    if (this.props.showUncertainity && this.props.callData.radius) {
                        this.addCircle(map, this.props.callData.geometry, this.props.callData.radius / 1000);
                    }
                }
            }
        } catch (error) {
            consoleLog("::::::::: error addmarker ::::::::::: ")
        }
    }

    createSolacomMarker = (map, classOfService, pitch, zoomLevel, ds, event) => {
        this.setState({
            selectedEndCallId: ''
        })
        try {
            if (map) {
                var coorLength = ds.geometry.coordinates && ds.geometry.coordinates.length;
                if (coorLength && ds.geometry.coordinates[coorLength - 1].length) {
                    let el = document.createElement('div');
                    el.className = 'incident-marker overlay-index solacom-marker';
                    if (ds.eventType === 'Position') {
                        if (solacomMarkerImages.waiting[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.waiting[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.waiting['default']})`;
                        }
                    }
                    else if (ds.eventType === 'Route') {
                        if (ds.agents.length > 1) {
                            if (solacomMarkerImages.multiple[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.multiple[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.multiple['default']})`;
                            }
                        }
                        else {
                            if (solacomMarkerImages.ringing[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.ringing[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.ringing['default']})`;
                            }
                        }
                    }
                    let options = { element: el, anchor: 'bottom' };
                    let lngLat = new mapboxgl.LngLat(ds.geometry.coordinates[ds.geometry.coordinates.length - 1][0], ds.geometry.coordinates[ds.geometry.coordinates.length - 1][1]);
                    this.solacomBounds.push(lngLat);
                    this.solacomQueuedCalls.push(lngLat);
                    el.addEventListener('mouseenter', this.solacomHoverPopup.bind(this, lngLat, ds, map));
                    el.addEventListener('mouseleave', this.removeSolacomHoverPopup);
                    let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
                    this.markers.push(marker);
                }
            }
        } catch (error) {
            console.log("::::::::: error addmarker ::::::::::: ", error)
        }
    }
    createSateSolacomMarker = (map, classOfService, pitch, zoomLevel, ds, event) => {
        try {
            if (map) {
                var coorLength = ds.geometry.coordinates && ds.geometry.coordinates.length;
                if (coorLength && ds.geometry.coordinates[coorLength - 1].length) {
                    let el = document.createElement('div');
                    el.className = 'incident-marker overlay-index solacom-marker';
                    if (ds.eventType === 'Position') {
                        if (solacomMarkerImages.waiting[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.waiting[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.waiting['default']})`;
                        }
                    }
                    else if (ds.eventType === 'Route') {
                        if (ds.agents.length > 1) {
                            if (solacomMarkerImages.multiple[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.multiple[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.multiple['default']})`;
                            }
                        }
                        else {
                            if (solacomMarkerImages.ringing[classOfService]) {
                                el.style.backgroundImage = `url(${solacomMarkerImages.ringing[classOfService]})`;
                            }
                            else {
                                el.style.backgroundImage = `url(${solacomMarkerImages.ringing['default']})`;
                            }
                        }
                    }
                    let options = { element: el, anchor: 'bottom' };
                    let lngLat = new mapboxgl.LngLat(ds.geometry.coordinates[ds.geometry.coordinates.length - 1][0], ds.geometry.coordinates[ds.geometry.coordinates.length - 1][1]);
                    this.satSolacomBounds.push(lngLat);
                    el.addEventListener('mouseenter', this.solacomHoverPopup.bind(this, lngLat, ds, map));
                    el.addEventListener('mouseleave', this.removeSolacomHoverPopup);
                    let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
                    this.markers.push(marker);
                }
            }
        } catch (error) {
            console.log("::::::::: error addmarker ::::::::::: ", error)
        }
    }
    createOtherSolacomMarker = (map, classOfService, pitch, zoomLevel, ds, event) => {
        try {
            if (map) {
                var coorLength = ds.geometry.coordinates && ds.geometry.coordinates.length;
                if (coorLength && ds.geometry.coordinates[coorLength - 1].length) {
                    let el = document.createElement('div');
                    el.className = 'incident-marker overlay-index other-agent-markers';
                    if (ds.callStateExt === 'transferred' && ds.callState === callsConfigs.activeStatus) {
                        if (solacomMarkerImages.transferred[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.transferred[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.transferred['default']})`;
                        }
                    }
                    else if (ds.callState === callsConfigs.released) {
                        if (solacomMarkerImages.released[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.released[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.released['default']})`;
                        }
                    }
                    else {
                        if (solacomMarkerImages.activeMarkers[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers['default']})`;
                        }
                    }
                    let options = { element: el, anchor: 'bottom' };
                    let lngLat = new mapboxgl.LngLat(ds.geometry.coordinates[ds.geometry.coordinates.length - 1][0], ds.geometry.coordinates[ds.geometry.coordinates.length - 1][1]);
                    if (ds.eventType !== 'Released') {
                        this.solacomOtherBounds.push(lngLat);
                    }
                    el.addEventListener('mouseenter', this.solacomOtherHoverPopup.bind(this, lngLat, ds, map));
                    el.addEventListener('mouseleave', this.removeSolacomHoverPopup);
                    let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
                    this.otherMarkers.push(marker);
                }
            }
        } catch (error) {
            console.log("::::::::: error addmarker ::::::::::: ", error)
        }
    }
    createSatOtherSolacomMarker = (map, classOfService, pitch, zoomLevel, ds, event) => {
        try {
            if (map) {
                var coorLength = ds.geometry.coordinates && ds.geometry.coordinates.length;
                if (coorLength && ds.geometry.coordinates[coorLength - 1].length) {
                    let el = document.createElement('div');
                    el.className = 'incident-marker overlay-index other-agent-markers';
                    if (ds.callStateExt === 'transferred' && ds.callState === callsConfigs.activeStatus) {
                        if (solacomMarkerImages.transferred[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.transferred[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.transferred['default']})`;
                        }
                    }
                    else if (ds.callState === callsConfigs.released) {
                        if (solacomMarkerImages.released[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.released[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.released['default']})`;
                        }
                    }
                    else {
                        if (solacomMarkerImages.activeMarkers[classOfService]) {
                            el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers[classOfService]})`;
                        }
                        else {
                            el.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers['default']})`;
                        }
                    }
                    let options = { element: el, anchor: 'bottom' };
                    let lngLat = new mapboxgl.LngLat(ds.geometry.coordinates[ds.geometry.coordinates.length - 1][0], ds.geometry.coordinates[ds.geometry.coordinates.length - 1][1]);
                    if (ds.eventType !== 'Released') {
                        this.satOtherSolacomBounds.push(lngLat);
                    }
                    el.addEventListener('mouseenter', this.solacomOtherHoverPopup.bind(this, lngLat, ds, map));
                    el.addEventListener('mouseleave', this.removeSolacomHoverPopup);
                    let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
                    this.otherMarkers.push(marker);
                }
            }
        } catch (error) {
            console.log("::::::::: error addmarker ::::::::::: ", error)
        }
    }
    createRapidSOSMarker = (map) => {
        try {
            if (this.isRapidSOSDataAvailable()) {
                let sosElement = document.createElement('div');
                sosElement.className = 'incident-marker incident-marker-sos call-icon-overlay';
                if (SolacomGroup()) {
                    sosElement.style.backgroundImage = `url(${solacomMarkerImages.activeMarkers['RAPIDSOS']})`;
                }
                else {
                    sosElement.style.backgroundImage = `url(${markerImages.activeMarkers['RAPIDSOS']})`;
                }
                let sosOptions = { element: sosElement, anchor: 'bottom' };
                let rapidSOSlngLat = new mapboxgl.LngLat(this.props.callData.rapidSOS.geometry.coordinates[this.props.callData.rapidSOS.geometry.coordinates.length - 1][0], this.props.callData.rapidSOS.geometry.coordinates[this.props.callData.rapidSOS.geometry.coordinates.length - 1][1]);
                let rapidSOSMarker = new mapboxgl.Marker(sosOptions).setLngLat(rapidSOSlngLat).addTo(map);
                if (SolacomGroup()) {
                    this.activeMarkers.push(rapidSOSMarker);
                }
                else {
                    this.markers.push(rapidSOSMarker);
                }
                // this.addDotedLine(map);
                if (this.callGecodeAPI) {
                    this.getReverseGeocode();
                }
            }
        } catch (error) {
            consoleLog("::::::::: error createRapidSOSMarker ::::::::::: ")
        }
    }

    isRapidSOSDataAvailable = () => {
        if (this.props.rapidSOS && this.props.callData && this.props.callData.hasOwnProperty('rapidSOS') && this.props.callData.rapidSOS && this.props.callData.rapidSOS.geometry && this.props.callData.rapidSOS.geometry.coordinates && this.props.callData.rapidSOS.geometry.coordinates.length) {
            return true;
        } else {
            return false;
        }
    }

    getReverseGeocode = () => {
        this.callGecodeAPI = false;
        this.SearchServices.getReverseGeocode(`${this.props.callData.rapidSOS.geometry.coordinates[this.props.callData.rapidSOS.geometry.coordinates.length - 1][1]},${this.props.callData.rapidSOS.geometry.coordinates[this.props.callData.rapidSOS.geometry.coordinates.length - 1][0]}`)
            .then(res => {
                if (res && res.data && res.data.places && res.data.places.length) {
                    this.setState({
                        rapisSOSAddress: res.data.places[0]
                    }, () => {
                        this.props.updateRapiSOSAddress(this.state.rapisSOSAddress);
                        this.callGecodeAPI = true;
                    })
                }
            })
            .catch(error => {
                this.callGecodeAPI = true;
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.reverseGeocodeTimeOutMsg, { toastId: customToastId });
                } else {
                    toast.error(error.response.data.detail, { toastId: customToastId });
                }
                this.setState({
                    rapisSOSAddress: null
                })
            })
    }

    addDotedLine = (map) => {
        try {
            if (map && this.isRapidSOSDataAvailable() && this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length) {
                let dottedLineId = 'dotCircles';
                let layers = this.rapidSOSDottedLineLayers;
                layers.push(dottedLineId);
                this.rapidSOSDottedLineLayers = layers;
                if (map.getLayer(dottedLineId)) {
                    map.removeLayer(dottedLineId);
                    map.removeSource(dottedLineId);
                }
                map.addLayer({
                    "id": dottedLineId,
                    "type": "line",
                    "source": {
                        "type": "geojson",
                        "data": {
                            "type": "Feature",
                            "properties": {},
                            "geometry": {
                                "type": "LineString",
                                'coordinates': [this.props.callData.rapidSOS.geometry.coordinates[this.props.callData.rapidSOS.geometry.coordinates.length - 1], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1]]
                            }
                        }
                    },
                    "layout": {
                        "line-join": "round",
                        "line-cap": "round"
                    },
                    "paint": {
                        "line-color": "#3d81c3",
                        "line-width": {
                            'stops': [
                                [2, 4],
                                [24, 4],
                            ],
                        },
                        "line-dasharray": [0, 1.5]
                    }
                });
            }
        } catch (error) {
            consoleLog("::::::::: error addDotedLine ::::::::::: ")
        }
    }

    removeRapidSOSCircle = (map) => {
        for (var i = 0; i < this.rapidSOSCircleLayers.length; i++) {
            if (map.getLayer(this.rapidSOSCircleLayers[i])) {
                map.removeLayer(this.rapidSOSCircleLayers[i]);
                map.removeSource(this.rapidSOSCircleLayers[i]);
            }
        }
    };
    removeAltCylinder = (map) => {
        if (this.props.showMap && map) {
            for (var i = 0; i < this.cylinderLayers.length; i++) {
                if (map.getLayer(this.cylinderLayers[i])) {
                    map.removeLayer(this.cylinderLayers[i]);
                    map.removeSource(this.cylinderLayers[i]);
                }
            }
        }
    }

    removeCircles = (map) => {
        for (var i = 0; i < this.circleLayers.length; i++) {
            if (map.getLayer(this.circleLayers[i])) {
                map.removeLayer(this.circleLayers[i]);
                map.removeSource(this.circleLayers[i]);
            }
        }
        this.removeRapidSOSCircle(map);
    }

    removeMarkers = () => {
        this.solacomQueuedCalls = [];
        this.solacomBounds = [];
        this.satSolacomBounds = [];
        this.markers.forEach(marker => {
            marker.remove();
        });
        this.markers = [];
        this.removeEndCallMarkers();
        for (var i = 0; i < this.rapidSOSDottedLineLayers.length; i++) {
            if (this.props.showMap && this.props.map && this.props.map.getLayer(this.rapidSOSDottedLineLayers[i])) {
                this.props.map.removeLayer(this.rapidSOSDottedLineLayers[i]);
                this.props.map.removeSource(this.rapidSOSDottedLineLayers[i]);
            } if (this.props.isSatelliteMapLoaded && this.props.satelliteMap && this.props.satelliteMap.getLayer(this.rapidSOSDottedLineLayers[i])) {
                this.props.satelliteMap.removeLayer(this.rapidSOSDottedLineLayers[i]);
                this.props.satelliteMap.removeSource(this.rapidSOSDottedLineLayers[i]);
            }
        }
        this.rapidSOSDottedLineLayers = [];
    }
    removeOtherMarkers = () => {
        this.solacomQueuedCalls = [];
        this.solacomOtherBounds = [];
        this.satOtherSolacomBounds = [];
        this.otherMarkers.forEach(marker => {
            marker.remove();
        });
        this.otherMarkers = [];
    }

    removeEndCallMarkers = () => {
        this.endCallMarkers.forEach(marker => {
            marker.remove();
        });
        this.endCallMarkers = [];
        this.satEndCallMarkers.forEach(marker => {
            marker.remove();
        });
        this.satEndCallMarkers = [];
    }
    removeAllLayers = () => {
        if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
            this.removeCircles(this.props.satelliteMap);
        }
        if (this.props.showMap && this.props.map) {
            this.removeCircles(this.props.map);
            this.removeAltCylinder(this.props.map);
        }
        if (!SolacomGroup()) {
            this.removeMarkers()
        }
        this.circleLayers = [];
        this.rapidSOSCircleLayers = [];
        this.cylinderLayers = [];
    }

    removeCameras = (map) => {
        ['geofence-camera', 'camera', 'selected_camera'].forEach(layer => {
            if (map.getSource(layer)) {
                this.props.map.off("click", layer, this.onCameraClick);
                map.removeLayer(layer);
                map.removeSource(layer);
            }
        });
    }

    removeAedLayers = (map) => {
        ['aed-layer', 'selected_aed'].forEach(layer => {
            if (map.getSource(layer)) {
                this.props.map.off("click", layer, this.onAedClick);
                map.removeLayer(layer);
                map.removeSource(layer);
            }
        });
    }

    removeClusters = (layer) => {
        this.removeLayer('cluster-count');
        this.removeLayer('unclustered-point');
        this.removeLayer(layer);
        this.removeSource(layer);
    }

    removeLayer = (layerName) => {
        if (this.props.map.getLayer(layerName)) {
            this.props.map.removeLayer(layerName);

        }
    }

    removeSource = (layerName) => {
        if (this.props.map.getSource(layerName)) {
            this.props.map.removeSource(layerName);

        }
    }

    componentWillUnmount = () => {
        this.removeActiveMarker();
        this.clearCallLayers();
        this.props.fetchRouting(false);
        this.props.fetchRenderRouting(1);
        clearInterval(this.interval);
        callDeclarations.idleTabName = ''
        sessionStorage.setItem("routeTo", false);
        if (this.props.showMap) {
            this.props.setCallDependencies({
                'mapZoom': this.props.map.getZoom(),
                'location': this.props.map.getCenter()
            });
        }
    }

    handleTimer = () => {
        this.setState({
            displayTimer: (callDeclarations.callTimerMinutes < 10 ? ('0' + callDeclarations.callTimerMinutes) : callDeclarations.callTimerMinutes) + ':' + (callDeclarations.callTimerSeconds < 10 ? '0' + callDeclarations.callTimerSeconds : callDeclarations.callTimerSeconds > 59 ? '00' : callDeclarations.callTimerSeconds)
        })
    }

    clearAllCameras = () => {
        if (this.props.showMap && this.props.map) {
            this.props.map.off("click", "geofence-camera", this.onClusterClick);
            this.props.map.off('click', 'unclustered-point', this.onCameraClick);
            let geoCameraSource = this.props.map.getSource("geofence-camera");
            let cameraSource = this.props.map.getSource("camera");
            if (typeof geoCameraSource !== "undefined") {
                this.removeClusters('geofence-camera');
            }
            else if (typeof cameraSource !== "undefined") {
                this.removeClusters('camera');
            }
            this.removeCameras(this.props.map);
            if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
                this.removeCameras(this.props.satelliteMap);
            }
            this.setState({
                showVideo: false
            })
        }
    }

    clearCallLayers = () => {
        this.removeLines();
        this.removeAllLayers();
        this.clearAllCameras();
        this.clearAEDLayers();
        this.props.removePoiRouting();
        this.props.fetchRouting(false);
        if (SolacomGroup()) {
            this.removeMarkers();
            // this.removeActiveMarker();
            this.removeOtherMarkers();
        }
    }

    reset911call = () => {
        this.interval = setInterval(() => {
            let initCount = 0;
            callDeclarations.callstartTime = callDeclarations.callstartTime + 1;
            let timerSeconds = parseInt(callDeclarations.callTimerSeconds) + 1;
            callDeclarations.callTimerSeconds = timerSeconds === 60 ? initCount : timerSeconds;
            callDeclarations.callTimerMinutes = timerSeconds === 60 ? (parseInt(callDeclarations.callTimerMinutes) + 1) : parseInt(callDeclarations.callTimerMinutes);
            this.handleTimer();
        }, configTimers.counterTime);
    }

    showAED(AEDList) {
        let aedIds = new Array();
        let aedJson = {
            type: "FeatureCollection",
            features: []
        };
        for (var i in AEDList) {
            aedIds.push(AEDList[i].aed.aed_id);
            aedJson.features.push(this.prepareAEDGeojson(AEDList[i]));
        }
        this.addAedLayer(aedJson, 'aed-layer', 'aed');
        this.showSelectedAed(aedJson, 'selected_aed', 'aed');
    }

    prepareAEDGeojson = (aed) => {
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: this.getAedCoordinates(aed)
            },
            properties: this.getAEDProperties(aed)
        }
    }

    getAedCoordinates = (obj) => {
        return [
            obj.location.coordinates[obj.location.coordinates.length - 1].longitude,
            obj.location.coordinates[obj.location.coordinates.length - 1].latitude
        ]
    }

    getAEDProperties = (obj) => {
        return {
            aedId: obj.aed.aed_id
        }
    }

    resetAedSelectedLayer = (map, aedID) => {
        if (map.getLayer('selected_aed')) {
            map.setFilter('selected_aed', ['==', 'aedId', aedID]);
        }
    }

    removeAEDPpoups = () => {
        if (this.aedPopups.length) {
            this.aedPopups.map(popup => {
                popup.remove();
            });
            this.aedPopups = [];
        }
    }

    onAedClick(event) {
        let properties = event.features[0].properties, that = this;
        this.resetAedSelectedLayer(this.props.map, properties.aedId);
        let data = '';
        if (this.props.AEDList && this.props.AEDList.length) {
            this.props.AEDList.map((aedItem, index) => {
                if (aedItem && aedItem.aed && aedItem.aed.aed_id) {
                    if (aedItem.aed.aed_id === properties.aedId) {
                        if (aedItem.name) {
                            data += '<span class="street-label">Name</span>: ' + aedItem.name + '<br/>';
                        }
                        if (aedItem.aed.description && aedItem.aed.description.length) {
                            data += '<span class="street-label">Description</span>: ' + aedItem.aed.description + '<br/>';
                        }
                        if (aedItem.location) {
                            if (aedItem.location.street && aedItem.location.street.length) {
                                data += '<span class="street-label">Street</span>: ' + aedItem.location.street + '<br/>';
                            }
                            if (aedItem.location.city && aedItem.location.city.length) {
                                data += '<span class="street-label">City</span>: ' + aedItem.location.city + '<br/>';
                            }
                            if (aedItem.location.state_code && aedItem.location.state_code.length) {
                                data += '<span class="street-label">State</span>: ' + aedItem.location.state_code + '<br/>';
                            }
                            if (aedItem.location.postal && aedItem.location.postal.length) {
                                data += '<span class="street-label">PostalCode</span>: ' + aedItem.location.postal + '<br/>';
                            }
                        }
                        if (aedItem.contacts && aedItem.contacts.phone && aedItem.contacts.phone[0] && aedItem.contacts.phone[0].number && aedItem.contacts.phone[0].number.length) {
                            data += '<span class="street-label">BusinessPhone</span>: ' + aedItem.contacts.phone[0].number;
                        }
                    }
                }
            })

            let aedPopup = new mapboxgl.Popup({ offset: [-3, -2], className: "massgis-poi" })
                .setLngLat(event.lngLat)
                .setHTML(`<div class='poi-container aed-container'>${data}</div>`);
            aedPopup.on('close', function (e) {
                that.resetAedSelectedLayer(that.props.map, 0);
            });
            aedPopup.addTo(this.props.map);
            this.aedPopups.push(aedPopup);
        }
    }

    addAedLayer(data, layerName, imageName) {
        if (!this.props.map.getSource(layerName)) {
            this.props.map.addSource(layerName, {
                type: "geojson", data: data
            });
            this.props.map.addLayer({
                id: layerName,
                type: 'symbol',
                source: layerName,
                layout: {
                    "icon-image": imageName
                }
            });
            this.props.map.on("click", layerName, this.onAedClick);
            this.props.map.on("mouseenter", layerName, () => {
                this.props.map.getCanvas().style.cursor = "pointer";
            });
            this.props.map.on("mouseleave", layerName, () => {
                if (this.props.isMeasurementOn) {
                    this.props.map.getCanvas().style.cursor = 'crosshair';
                } else {
                    this.props.map.getCanvas().style.cursor = '';
                }
            });
        } else { // If the layer has already been added then just update it with the new data
            this.props.map.getSource(layerName).setData(data);
        }
    }

    showSelectedAed(data, layerName, imageName1) {
        if (!this.props.map.getSource(layerName)) {
            this.props.map.addSource(layerName, {
                type: "geojson", data: data
            });
            this.props.map.addLayer({
                id: layerName,
                type: 'symbol',
                source: layerName,
                layout: {
                    "icon-image": imageName1,
                    "icon-size": 1.25,
                }, 'filter': ['==', 'aedId', '']
            });

            this.props.map.on('mouseenter', layerName, () => {
                this.props.map.getCanvas().style.cursor = 'pointer';
            });
            this.props.map.on('mouseleave', layerName, () => {
                if (this.props.isMeasurementOn) {
                    this.props.map.getCanvas().style.cursor = 'crosshair';
                } else {
                    this.props.map.getCanvas().style.cursor = '';
                }
            });
        } else {
            this.props.map.setFilter('selected_aed', ['==', 'aedId', '']);
            this.props.map.getSource(layerName).setData(data);
        }
    }

    showCameras(cameras) {
        let radiusCameras = cameras.radiusCameras;
        let bboxCameras = cameras.bboxCameras;
        let radiusCameraIds = new Array();
        let radiusCameraJson = {
            type: "FeatureCollection",
            features: []
        };
        let bboxCameraJson = {
            "type": "FeatureCollection",
            "features": []
        };

        let totalCameraJson = {
            "type": "FeatureCollection",
            "features": []
        };
        for (var i in radiusCameras) {
            radiusCameraIds.push(radiusCameras[i].publicId);
            radiusCameraJson.features.push(this.prepareGeojson(radiusCameras[i]));
        }
        this.addMarkerLayer(radiusCameraJson, 'geofence-camera', 'camera_red_r_0', 'camera red_l_0', true)

        for (var j in bboxCameras) {
            let feature = this.prepareGeojson(bboxCameras[j]);

            totalCameraJson.features.push(feature);

            if (!radiusCameraIds.includes(bboxCameras[j].publicId)) {
                bboxCameraJson.features.push(feature);
            }
        }

        this.addMarkerLayer(bboxCameraJson, 'camera', 'camera_black_r_0', 'camera_black_l_0', false);
        this.showSelectedCamera(this.props.map, totalCameraJson, 'selected_camera', 'camera_red_r_0', 'camera red_l_0');
        if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
            this.showSelectedCamera(this.props.satelliteMap, totalCameraJson, 'selected_camera', 'camera_red_r_0', 'camera red_l_0');
        }

        if (this.props.selectedCamera) {
            this.resetSelectedCameraData(this.props.selectedCamera);
        }
    }

    prepareGeojson = (camera) => {
        return {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: this.getCameraCoordinates(camera)
            },
            properties: this.getCameraProperties(camera)
        }
    }

    getCameraCoordinates = (camera) => {
        return [
            camera.location.longitude,
            camera.location.latitude
        ]
    }

    getCameraProperties = (camera) => {
        return {
            publicId: camera.publicId,
            enabled: !camera.status.isDisabled,
            fullImage: camera.content.fullImage,
            hugeImage: camera.content.hugeImage,
            orientation: camera.orientation
        }
    }

    resetSelectedCameraData = (feature) => {
        this.resetLayerData(this.props.map, feature.properties.publicId);
        if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
            this.resetLayerData(this.props.satelliteMap, feature.properties.publicId);
        }

        let bounds = new mapboxgl.LngLatBounds();
        let callCoordinates = this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1];
        let callLngLat = new mapboxgl.LngLat(callCoordinates[0], callCoordinates[1])
        let featureLngLat = new mapboxgl.LngLat(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
        bounds.extend(callLngLat);
        bounds.extend(featureLngLat)
        if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
            this.props.satelliteMap.fitBounds(bounds, {
                padding: { top: 50, bottom: 50, left: 50, right: 50 }
            });
        }
    }

    showSelectedCamera(map, data, layerName, imageName, imageName1, layerBefore) {
        if (map) {
            if (!map.getSource(layerName)) {
                map.addSource(layerName, { type: 'geojson', data: data });
                map.addLayer({
                    'id': layerName,
                    'type': 'symbol',
                    'source': layerName,
                    'layout': {
                        "icon-image": [
                            "match",
                            [
                                "get",
                                "orientation"
                            ],
                            ["NORTHEAST"],
                            imageName,
                            ["NORTHWEST"], imageName1,
                            ["SOUTHWEST"], imageName1,
                            ["SOUTHEAST"], imageName,
                            ["SOUTH"], imageName,
                            ["NORTH"], imageName,
                            ["WEST"], imageName1,
                            ["EAST"], imageName,
                            imageName
                        ],
                        "icon-rotate": [
                            "match",
                            [
                                "get",
                                "orientation"
                            ],
                            ["NORTHEAST"], 45,
                            ["NORTHWEST"], -45,
                            ["SOUTHWEST"], -135,
                            ["SOUTHEAST"], 135,
                            ["SOUTH"], 180,
                            ["NORTH"], 0,
                            ["WEST"], -90,
                            ["EAST"], 90,
                            90
                        ],
                        "icon-size": 1.25
                    }, 'filter': ['==', 'publicId', '']
                }, layerBefore);

                map.on('mouseenter', layerName, () => {
                    map.getCanvas().style.cursor = 'pointer';
                });
                map.on('mouseleave', layerName, () => {
                    if (this.props.isMeasurementOn) {
                        map.getCanvas().style.cursor = 'crosshair';
                    } else {
                        map.getCanvas().style.cursor = '';
                    }
                });
            } else {
                map.setFilter('selected_camera', ['==', 'publicId', '']);
                map.getSource(layerName).setData(data);
            }
        }
    }

    addMarkerLayer(data, layerName, imageName, imageName1, isClusterLayer) {
        if (!this.props.map.getSource(layerName)) {

            this.props.map.addSource(layerName, {
                type: "geojson", data: data,
                cluster: isClusterLayer
            });

            if (isClusterLayer) {
                this.addClusterLayers(this.props.map, layerName);

                if (!this.props.map.getLayer('unclustered-point')) {
                    this.addCameraLayer(this.props.map, 'unclustered-point', layerName, imageName, imageName1);
                }

                this.props.map.on('click', 'unclustered-point', this.onCameraClick);
                this.props.map.on('touchstart', 'unclustered-point', this.onCameraClick);
                this.props.map.on("mouseenter", 'unclustered-point', () => {
                    this.props.map.getCanvas().style.cursor = "pointer";
                });
                this.props.map.on("mouseleave", 'unclustered-point', () => {
                    if (this.props.isMeasurementOn) {
                        this.props.map.getCanvas().style.cursor = 'crosshair';
                    } else {
                        this.props.map.getCanvas().style.cursor = '';
                    }
                });
            }
            else {
                this.addCameraLayer(this.props.map, layerName, layerName, imageName, imageName1);
                this.props.map.on("click", layerName, this.onCameraClick);
                this.props.map.on('touchstart', layerName, this.onCameraClick);
                this.props.map.on("mouseenter", layerName, () => {
                    this.props.map.getCanvas().style.cursor = "pointer";
                });
                this.props.map.on("mouseleave", layerName, () => {
                    if (this.props.isMeasurementOn) {
                        this.props.map.getCanvas().style.cursor = 'crosshair';
                    } else {
                        this.props.map.getCanvas().style.cursor = '';
                    }
                });
            }
        } else { // If the layer has already been added then just update it with the new data
            this.props.map.getSource(layerName).setData(data);
        }
    }

    addClusterLayers = (map, layerName) => {
        map.addLayer({
            id: layerName,
            type: "circle",
            source: layerName,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': 'rgba(0,0,0,.6)',
                'circle-radius': [
                    'step',
                    ['get', 'point_count'], 20, 20, 30, 50, 40
                ],
                'circle-stroke-color': '#ffffff',
                'circle-stroke-width': 1.5
            }
        }
        );
        if (!map.getLayer('cluster-count')) {
            map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: layerName,
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    "text-font": ["Arial Unicode MS Bold"],
                    'text-size': 16,
                },
                paint: {
                    "text-color": "#ffffff"
                }
            });
            this.props.map.on("mouseenter", layerName, () => {
                this.props.map.getCanvas().style.cursor = "pointer";
            });
            this.props.map.on("mouseleave", layerName, () => {
                if (this.props.isMeasurementOn) {
                    this.props.map.getCanvas().style.cursor = 'crosshair';
                } else {
                    this.props.map.getCanvas().style.cursor = '';
                }
            });
        }

        map.on("click", layerName, this.onClusterClick);
        map.on('touchstart', layerName, this.onClusterClick);
    }

    onClusterClick = (e) => {
        var features = this.props.map.queryRenderedFeatures(e.point, {
            layers: ["geofence-camera"],
        });
        var clusterId = features[0].properties.cluster_id
        let map = this.props.map;

        map.getSource("geofence-camera")
            .getClusterExpansionZoom(clusterId, function (error, zoom) {
                map.easeTo(
                    {
                        center: features[0].geometry.coordinates,
                        zoom: zoom,
                    },
                    { data: 'custom' }
                );
            });
    }

    addCameraLayer = (map, layerId, layerName, imageName, imageName1, layerBefore) => {
        map.addLayer({
            id: layerId,
            type: 'symbol',
            source: layerName,
            filter: ['!', ['has', 'point_count']],
            layout: {
                "icon-image": [
                    "match",
                    [
                        "get",
                        "orientation"
                    ],
                    ["NORTHEAST"], imageName,
                    ["NORTHWEST"], imageName1,
                    ["SOUTHWEST"], imageName1,
                    ["SOUTHEAST"], imageName,
                    ["SOUTH"], imageName,
                    ["NORTH"], imageName,
                    ["WEST"], imageName1,
                    ["EAST"], imageName,
                    imageName
                ],
                "icon-rotate": [
                    "match",
                    [
                        "get",
                        "orientation"
                    ],
                    ["NORTHEAST"], 45,
                    ["NORTHWEST"], -45,
                    ["SOUTHWEST"], -135,
                    ["SOUTHEAST"], 135,
                    ["SOUTH"], 180,
                    ["NORTH"], 0,
                    ["WEST"], -90,
                    ["EAST"], 90,
                    90
                ]
            }
        });
    }


    onCameraClick(event) {
        this.setState({ cameraImageUrl: '' });  // Reset on change
        clearInterval(this.timer);
        const properties = event.features[0].properties;
        this.resetSelectedCameraData(event.features[0]);
        //this.resetLayerData(this.props.map, properties.publicId);
        //this.resetLayerData(this.props.satelliteMap, properties.publicId)
        this.setState({
            fullImage: properties.fullImage,
            hugeImage: properties.hugeImage,
            showVideo: true
        });
        this.props.setSelectedCamera(event.features[0]);
        this.updateImgURL();
        this.timer = setInterval(this.updateImgURL.bind(this), 2000);
    }

    resetLayerData(map, publicId) {
        if (map && map.getLayer('selected_camera')) {
            map.setFilter('selected_camera', ['==', 'publicId', publicId]);
        }
    }

    updateImgURL() {
        const imageUrl = (this.state.expandModal === true ? this.state.hugeImage : this.state.fullImage)
            + "?api_key=" + serverconfigs.apiKey
            + "&t=" + new Date().getTime()
        this.setState({
            cameraImageUrl: imageUrl
        });
    }

    getCameras = () => {
        if (this.props.callData.geometry && this.props.callData.geometry.coordinates.length) {
            consoleLog("::::::::::: get Cameras ::::::")
            var bbox;
            var bounds = this.props.map.getBounds();
            var sw = bounds.getSouthWest().wrap();
            var distance = turfDistance([sw.lng, sw.lat], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1], { units: 'kilometers' });
            if (distance > callDeclarations.bboxDistance) {
                var point = turfPoint(this.props.callData.geometry.coordinates[[this.props.callData.geometry.coordinates.length - 1]]);
                var buffered = turfBuffer(point, callDeclarations.bboxDistance, { units: 'kilometers' });
                var turfBounds = turfBbox(buffered);
                var southWest = [turfBounds[2], turfBounds[3]];
                var northEast = [turfBounds[0], turfBounds[1]];
                bbox = new mapboxgl.LngLatBounds(southWest, northEast);
            }
            else {
                bbox = bounds;
            }
            this.props.getCameras(bbox,
                this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1],
                this.props.callData.radius ? this.props.callData.radius : callDeclarations.defaultRadius);
        }
    }

    getAED = () => {
        if (this.props.callData.geometry && this.props.callData.geometry.coordinates.length) {
            this.props.fetchAED(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1], 'AED');
        }
    }

    handleCameraClose = () => {
        this.setState({
            showVideo: false,
            expandModal: false
        })
        clearInterval(this.timer);
        this.props.setSelectedCamera('');
        if (this.props.showMap) {
            this.resetLayerData(this.props.map, 0);
        }// Reset the selected camera
        if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
            this.resetLayerData(this.props.satelliteMap, 0);
        }
    }

    handleSMSPopupClose = () => {
        // this.setState({
        //     showSMSPopUp: false
        // })
        this.props.setStreamingStatus(false)
    }

    handleCameraExpand = () => {
        this.setState({
            expandModal: !this.state.expandModal
        })
    }
    handleCriminalHistory = () => {
        this.setState({
            showCriminalHistory: !this.state.showCriminalHistory
        })
    }

    handlePastResidences = () => {
        this.setState({
            showPastResidences: !this.state.showPastResidences
        })
    }

    /* add breadcrumb */
    addLine = (list, map, type) => {
        try {
            let layers = this.breadcrumbLayers, circleId, lineId, polylineId;
            if (SolacomGroup()) {
                circleId = 'initCircle' + type;
                lineId = 'circles' + type;
                polylineId = 'polyline' + type;
            }
            else {
                circleId = 'initCircle';
                lineId = 'circles';
                polylineId = 'polyline';
            }
            layers.push(lineId, circleId, polylineId);
            this.breadcrumbLayers = layers;
            if (map.getLayer(lineId)) {
                map.removeLayer(lineId);
                map.removeSource(lineId);
            }
            map.addSource(lineId, {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': list.geometry.coordinates
                    }
                }
            });
            map.addLayer({
                'id': lineId,
                "type": "circle",
                "source": lineId,
                "paint": {
                    "circle-radius": 5,
                    "circle-color": '#888'

                }
            });
            if (map.getLayer(polylineId)) {
                map.removeLayer(polylineId);
                map.removeSource(polylineId);

            }
            map.addSource(polylineId, {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': list.geometry.coordinates
                    }
                }
            });
            map.addLayer({
                'id': polylineId,
                "type": "line",
                "source": polylineId,
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#888',
                    'line-width': 2
                }
            });
            if (map.getLayer(circleId)) {
                map.removeLayer(circleId);
                map.removeSource(circleId);
            }
            map.addSource(circleId, {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: list.geometry.coordinates[0]
                            }
                        }
                    ]
                }
            });
            map.addLayer({
                "id": circleId,
                "type": "circle",
                "source": circleId,
                "paint": {
                    "circle-radius": 8,
                    "circle-color": '#888'

                }
            });
        } catch (error) {
            consoleLog("::::::::: error Add Line ::::::::::: ")
        }
    }

    /* draw RapidSOS line */
    drawRapidSOSPolyline = (callsData, map) => {
        try {
            if (this.isRapidSOSDataAvailable()) {
                let layers = this.rapidSOSBreadcrumbLayers;
                let circleId = 'sosinitCircle';
                let lineId = 'soscircles';
                let polylineId = 'sospolyline';
                layers.push(lineId, circleId, polylineId);
                this.rapidSOSBreadcrumbLayers = layers;
                if (map.getLayer(lineId)) {
                    map.removeLayer(lineId);
                    map.removeSource(lineId);
                }
                map.addSource(lineId, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': callsData.rapidSOS.geometry.coordinates
                        }
                    }
                });
                map.addLayer({
                    'id': lineId,
                    "type": "circle",
                    "source": lineId,
                    "paint": {
                        "circle-radius": 5,
                        "circle-color": '#ea0e0e'

                    }
                });
                if (map.getLayer(polylineId)) {
                    map.removeLayer(polylineId);
                    map.removeSource(polylineId);

                }
                map.addSource(polylineId, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': callsData.rapidSOS.geometry.coordinates
                        }
                    }
                });
                map.addLayer({
                    'id': polylineId,
                    "type": "line",
                    "source": polylineId,
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': '#888',
                        'line-width': 2
                    }
                }, lineId);
                if (map.getLayer(circleId)) {
                    map.removeLayer(circleId);
                    map.removeSource(circleId);
                }
                map.addSource(circleId, {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                geometry: {
                                    type: "Point",
                                    coordinates: callsData.rapidSOS.geometry.coordinates[0]
                                }
                            }
                        ]
                    }
                });
                map.addLayer({
                    "id": circleId,
                    "type": "circle",
                    "source": circleId,
                    "paint": {
                        "circle-radius": 8,
                        "circle-color": '#ea0e0e'
                    }
                });
            }
        } catch (error) {
            consoleLog("::::::::: error drawRapidSOSPolyline ::::::::::: ")
        }
    }

    removeLines = () => {
        for (var i = 0; i < this.breadcrumbLayers.length; i++) {
            if (this.props.showMap && this.props.map && this.props.map.getLayer(this.breadcrumbLayers[i])) {
                this.props.map.removeLayer(this.breadcrumbLayers[i]);
                this.props.map.removeSource(this.breadcrumbLayers[i]);
            }
            if (this.props.isSatelliteMapLoaded && this.props.satelliteMap && this.props.satelliteMap.getLayer(this.breadcrumbLayers[i])) {
                this.props.satelliteMap.removeLayer(this.breadcrumbLayers[i]);
                this.props.satelliteMap.removeSource(this.breadcrumbLayers[i]);
            }
        }
        this.breadcrumbLayers = [];
        this.removeRapidSOSLines();
    }

    removeRapidSOSLines = () => {
        for (var i = 0; i < this.rapidSOSBreadcrumbLayers.length; i++) {
            if (this.props.showMap && this.props.map && this.props.map.getLayer(this.rapidSOSBreadcrumbLayers[i])) {
                this.props.map.removeLayer(this.rapidSOSBreadcrumbLayers[i]);
                this.props.map.removeSource(this.rapidSOSBreadcrumbLayers[i]);
            }
            if (this.props.isSatelliteMapLoaded && this.props.satelliteMap && this.props.satelliteMap.getLayer(this.rapidSOSBreadcrumbLayers[i])) {
                this.props.satelliteMap.removeLayer(this.rapidSOSBreadcrumbLayers[i]);
                this.props.satelliteMap.removeSource(this.rapidSOSBreadcrumbLayers[i]);
            }
        }
        this.rapidSOSBreadcrumbLayers = [];
    }

    clearAEDLayers = () => {
        if (this.props.showMap && this.props.map) {
            this.removeAEDPpoups();
            this.props.map.off('click', 'aed-layer', this.onAedClick);
            let aedSource = this.props.map.getSource("aed-layer");
            if (typeof aedSource !== "undefined") {
                this.removeLayer('aed-layer');
                this.removeSource('aed-layer');
            }
            this.removeAedLayers(this.props.map);
        }
    }

    handleCallsData = () => {
        if (callDeclarations.isSimulationEnabled) {
            renderAED = true;
            renderCamera = true;
            if (!SolacomGroup()) {
                this.props.getSimulationCallsData();
            }
            callDeclarations.callstartTime = 0;
            callDeclarations.callTimerSeconds = 0;
            callDeclarations.callTimerMinutes = 0;
            this.setState({
                displayDate: new Date(),
                displayTimer: '00:00'
            })
            // this.props.setCallAutoZoom(false);
            this.props.setSearchZoom(false);
            this.props.setUpdateBounds(true);
        }
    }

    handleDisabilities = () => {
        this.setState({
            showMoreText: !this.state.showMoreText
        })
    }

    /* select markers */
    handleEndCallClick = (place) => {
        this.removeEndCallMarkers();
        this.props.endCallsData.map((endCall, ind) => {
            if (endCall.nenaIncidentId === place.nenaIncidentId) {
                this.setState({
                    selectedEndCallId: ((endCall.nenaIncidentId === place.nenaIncidentId) && (this.state.selectedEndCallId !== place.nenaIncidentId)) ? place.nenaIncidentId : ''
                }, () => {
                    this.endCallOverlay = false
                    this.createEndCallsMarkers()
                    if (this.state.selectedEndCallId === '') {
                        if (this.props.callData && (this.props.callData.callState === callsConfigs.activeStatus || this.props.callData.callState === callsConfigs.released)) {
                            this.flyToActiveCall(this.props.map, 0, callDeclarations.initMapZoom);
                            this.flyToSatActiveCall(this.props.satelliteMap, serverconfigs.satelliteMapPitch, callDeclarations.satelliteMapZoom);
                        }
                        else {
                            if ((this.props.vehicleType && this.props.vehicleType.length === 0) || !this.props.vehicleType) {
                                if (this.solacomBounds.length && (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released))) {
                                    this.setBounds(this.solacomBounds, this.props.map);
                                }
                            }
                            else if (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released)) {
                                if (this.props.vehicleType && this.props.vehicleType.length) {
                                    this.setTrackingBounds();
                                }
                                else {
                                    this.handleGroupBounds(this.props.map);
                                }
                            }
                            if (this.satSolacomBounds.length && (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released))) {
                                this.setBounds(this.satSolacomBounds, this.props.satelliteMap);
                            }
                            else if (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released)) {
                                this.handleSatGroupBounds(this.props.satelliteMap);
                            }
                        }
                    }
                })
            }
        });
    }

    createEndCallsMarkers = () => {
        if (this.props.endCallsData && this.props.endCallsData.length) {
            this.removeEndCallMarkers();
            this.props.endCallsData.forEach((place, ind) => {
                if (place && place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
                    if (this.props.showMap && this.props.map) {
                        let el = document.createElement('div');
                        el.addEventListener('click', this.handleEndCallClick.bind(this, place))
                        if (SolacomGroup()) {
                            el.className = 'incident-marker solacom-marker';
                        }
                        else {
                            el.className = 'incident-marker';
                        }
                        if (this.state.selectedEndCallId === place.nenaIncidentId) {
                            if (!this.endCallOverlay) {
                                if (SolacomGroup()) {
                                    el.className = 'incident-marker active-icon-overlay solacom-marker';
                                }
                                else {
                                    el.className = 'incident-marker active-icon-overlay';
                                }
                            }
                            if (SolacomGroup()) {
                                if (solacomMarkerImages.selectMarkers[place.classOfService]) {
                                    el.style.backgroundImage = `url(${solacomMarkerImages.selectMarkers[place.classOfService]})`;
                                }
                                else {
                                    el.style.backgroundImage = `url(${solacomMarkerImages.selectMarkers['default']})`;
                                }
                            }
                            else {
                                if (markerImages.selectMarkers[place.classOfService]) {
                                    el.style.backgroundImage = `url(${markerImages.selectMarkers[place.classOfService]})`;
                                }
                                else {
                                    el.style.backgroundImage = `url(${markerImages.selectMarkers['default']})`;
                                }
                            }
                            this.props.map.flyTo({
                                center: place.geometry.coordinates[place.geometry.coordinates.length - 1],
                                zoom: callDeclarations.initMapZoom,
                                bearing: 0,
                                speed: 4, // make the flying slow
                                curve: 4.5, // change the speed at which it zooms out
                                pitch: 0,
                                easing: function (t) { return t; }
                            });
                        }
                        else if (SolacomGroup()) {
                            if (solacomMarkerImages.activeMarkers[place.classOfService]) {
                                if (place.callState === callsConfigs.endStaus) {
                                    el.style.backgroundImage = `url(${solacomMarkerImages.endedMarkers[place.classOfService]})`;
                                }
                            } else {
                                if (place.callState === callsConfigs.endStaus) {
                                    el.style.backgroundImage = `url(${solacomMarkerImages.endedMarkers['default']})`;
                                }
                            }
                        } else {
                            if (markerImages.activeMarkers[place.classOfService]) {
                                if (place.callState === callsConfigs.endStaus) {
                                    el.style.backgroundImage = `url(${markerImages.endedMarkers[place.classOfService]})`;
                                }
                            } else {
                                if (place.callState === callsConfigs.endStaus) {
                                    el.style.backgroundImage = `url(${markerImages.endedMarkers['default']})`;
                                }
                            }
                        }
                        let options = { element: el, anchor: 'bottom' };
                        let lngLat = new mapboxgl.LngLat(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1]);
                        let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                        // new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.satelliteMap);
                        if (SolacomGroup()) {
                            el.addEventListener('mouseenter', this.solacomHoverPopup.bind(this, lngLat, place, this.props.map));
                            el.addEventListener('mouseleave', this.removeSolacomHoverPopup);
                        }
                        this.endCallMarkers.push(marker);
                    }
                    if (this.props.isSatelliteMapLoaded && this.props.satelliteMap) {
                        this.createEndCallMarkerInSatMap(place)
                    }
                }
            });
        }
    }

    createEndCallMarkerInSatMap = (place) => {
        if (place && place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
            let el = document.createElement('div');
            el.addEventListener('click', this.handleEndCallClick.bind(this, place))
            if (SolacomGroup()) {
                el.className = 'incident-marker solacom-marker';
            }
            else {
                el.className = 'incident-marker';
            }
            if (this.state.selectedEndCallId === place.nenaIncidentId) {
                if (!this.endCallOverlay) {
                    if (SolacomGroup()) {
                        el.className = 'incident-marker active-icon-overlay solacom-marker';
                    }
                    else {
                        el.className = 'incident-marker active-icon-overlay';
                    }
                }
                if (SolacomGroup()) {
                    if (solacomMarkerImages.selectMarkers[place.classOfService]) {
                        el.style.backgroundImage = `url(${solacomMarkerImages.selectMarkers[place.classOfService]})`;
                    }
                    else {
                        el.style.backgroundImage = `url(${solacomMarkerImages.selectMarkers['default']})`;
                    }
                }
                else {
                    if (markerImages.selectMarkers[place.classOfService]) {
                        el.style.backgroundImage = `url(${markerImages.selectMarkers[place.classOfService]})`;
                    }
                    else {
                        el.style.backgroundImage = `url(${markerImages.selectMarkers['default']})`;
                    }
                }
                this.props.satelliteMap.flyTo({
                    center: place.geometry.coordinates[place.geometry.coordinates.length - 1],
                    zoom: callDeclarations.satelliteMapZoom,
                    bearing: 0,
                    screenSpeed: 4, // make the flying slow
                    pitch: serverconfigs.satelliteMapPitch,
                    minZoom: 12,
                    easing: function (t) { return t; }
                });
            }
            else if (SolacomGroup()) {
                if (solacomMarkerImages.activeMarkers[place.classOfService]) {
                    if (place.callState === callsConfigs.endStaus) {
                        el.style.backgroundImage = `url(${solacomMarkerImages.endedMarkers[place.classOfService]})`;
                    }
                } else {
                    if (place.callState === callsConfigs.endStaus) {
                        el.style.backgroundImage = `url(${solacomMarkerImages.endedMarkers['default']})`;
                    }
                }
            }
            else {
                if (markerImages.activeMarkers[place.classOfService]) {
                    if (place.callState === callsConfigs.endStaus) {
                        el.style.backgroundImage = `url(${markerImages.endedMarkers[place.classOfService]})`;
                    }
                } else {
                    if (place.callState === callsConfigs.endStaus) {
                        el.style.backgroundImage = `url(${markerImages.endedMarkers['default']})`;
                    }
                }
            }
            let options = { element: el, anchor: 'bottom' };
            let lngLat = new mapboxgl.LngLat(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1]);
            let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.satelliteMap);
            if (SolacomGroup()) {
                el.addEventListener('mouseenter', this.solacomHoverPopup.bind(this, lngLat, place, this.props.satelliteMap));
                el.addEventListener('mouseleave', this.removeSolacomHoverPopup);
            }
            // new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.satelliteMap);
            this.satEndCallMarkers.push(marker);
        }
    }


    static getDerivedStateFromProps(props, state) {
        if (props.resetCallsEndCall) {
            state.selectedEndCallId = '';
            state.func1()
            props.removeEndCallResetValue()
        }
        return state
    }

    setBounds = (bounds, map) => {
        var bbox = new mapboxgl.LngLatBounds();
        if (bounds.length === 1) {
            map.flyTo({
                zoom: 15,
                center: bounds[0],
                bearing: 0,
                speed: 4, // make the flying slow
                curve: 4.5, // change the speed at which it zooms out
                easing: function (t) { return t; }
            });
        } else if (bounds.length > 1) {
            bounds.forEach((coordinate) => {
                bbox.extend(coordinate);
            });
            map.fitBounds(bbox, {
                padding: { top: 120, bottom: 75, left: 50, right: 75 }
            });
        }
    }

    setTrackingBounds = () => {
        let bounds = [];
        if (this.props.vehicleType && this.props.vehicleType.length) {
            this.props.vehicleType.map(list => {
                if (list.geometry && list.geometry.coordinates && list.geometry.coordinates.length) {
                    let location = list.geometry.coordinates;
                    var lngLat = new mapboxgl.LngLat(location[location.length - 1][1], location[location.length - 1][0]);
                    bounds.push(lngLat);
                }
            })
        }
        if (this.props.callData && this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length && this.props.callData.callState !== callsConfigs.endStaus) {
            bounds.push(new mapboxgl.LngLat(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0], this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1]))
        }
        if (bounds.length && this.props.updateBounds) {
            this.setBounds(bounds, this.props.map);
        }
    }

    getDefaultGroups = () => {
        if (groupFeatures) {
            if (groupFeatures.map) {
                groupLoc = groupFeatures.map.center && groupFeatures.map.center.length ? groupFeatures.map.center : groupLocations.default.center;
                groupZoom = groupFeatures.map.zoom ? groupFeatures.map.zoom : groupLocations.default.zoom;
            }
            else {
                groupLoc = groupLocations.default.center;
                groupZoom = groupLocations.default.zoom;
            }
        }
        else {
            groupLoc = groupLocations.default.center;
            groupZoom = groupLocations.default.zoom;
        }
    }

    handleGroupBounds = (map) => {
        this.getDefaultGroups();
        if (map) {
            map.flyTo({
                center: groupLoc,
                zoom: groupZoom,
                bearing: 0,
                speed: 4, // make the flying slow
                curve: 4.5, // change the speed at which it zooms out
                easing: function (t) { return t; }
            });
        }
    }

    handleSatGroupBounds = (map) => {
        this.getDefaultGroups();
        if (map) {
            map.flyTo({
                center: groupLoc,
                zoom: groupZoom,
                bearing: 0,
                screenSpeed: 4, // make the flying slow
                minZoom: 12,
                pitch: serverconfigs.satelliteMapPitch,
                easing: function (t) { return t; }
            });
        }
    }
    removeActiveMarker = () => {
        this.activeMarkers.forEach(marker => {
            marker.remove();
        });
        this.activeMarkers = [];
    }
    solacomHoverPopup = (lngLat, data, map) => {
        var content = this.createStLayerContent(data);
        var offsetHeight;
        if (data.eventType === 'Route' || data.eventType === 'TransferCall') {
            offsetHeight = [0, -69];
        }
        else {
            offsetHeight = [0, -50];
        }
        this.solacomPopup = new mapboxgl.Popup({ offset: offsetHeight, className: "massgis-poi solacom-popup" })
            .setLngLat(lngLat)
            .setDOMContent(content);
        this.solacomPopup.addTo(map);
    }
    solacomOtherHoverPopup = (lngLat, data, map) => {
        var content = this.createStLayerContent(data);
        var offsetHeight;
        if (data.eventType === 'TransferCall') {
            offsetHeight = [0, -43];
        }
        else {
            offsetHeight = [0, -30];
        }
        this.solacomPopup = new mapboxgl.Popup({ offset: offsetHeight, className: "massgis-poi solacom-popup" })
            .setLngLat(lngLat)
            .setDOMContent(content);
        this.solacomPopup.addTo(map);
    }
    createStLayerContent(data) {
        const placeholder = document.createElement('div');
        const jsx = <div className='poi-container'>
            {
                <div>
                    <div><span className="street-label">Ani: </span>{data.ani}</div>
                    <div><span className="street-label">Caller Name: </span>{data.callerName}</div>
                    {data.agents && data.agents.length ?
                        <div className='d-flex'>
                            <div className="street-label">Agent(s): </div>
                            {data.agents && data.agents.length ?
                                data.agents.map((obj, i) => {
                                    return (
                                        <span key={obj.agent} className='pl-1'>{i + 1 !== data.agents.length ? obj.agent + `, ` : obj.agent}</span>
                                    )
                                }) : ''}
                        </div>
                        : ''}
                    {data.routesList && data.routesList.length ?
                        <div className='d-flex'>
                            <div className="street-label">Routings: </div>
                            {data.routesList.map((route, i) => {
                                return (
                                    <span key={route.rule} className='pl-1'>{i + 1 !== data.routesList.length ? route.rule + `, ` : route.rule}</span>
                                )
                            })}
                        </div>
                        : ''}
                    {data.transfer && data.transfer.target ?
                        <div className='d-flex'>
                            <div className="street-label">Transfer Target: </div>
                            <span className='pl-1'>{data.transfer.target}</span>
                        </div>
                        : ''}
                </div>
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }
    removeSolacomHoverPopup = () => {
        if (this.solacomPopup) {
            this.solacomPopup.remove();
        }
    }
    onPopupHover = () => {
        this.setState({
            popoverOpen: true,
        })
    }
    onPopupHoverLeave = () => {
        this.setState({
            popoverOpen: false,
        })
    }

    render() {
        if (this.props.renderCalls === 5) {
            this.clearAllCameras();
            this.clearAEDLayers();
            renderAED = true;
            renderCamera = true;
            this.props.callsPanelReloaded(2);
        }

        if (this.props.showMap && this.props.map && this.props.renderCalls === 1) {
            this.removeSolacomHoverPopup();
            if (SolacomGroup() && this.props.solacomData && this.props.solacomData !== '' && Object.keys(this.props.solacomData).length) {
                // this.markers =[]
                this.removeLines();
                this.removeMarkers();
                this.removeOtherMarkers();
                if (this.props.solacomData.queuedCalls && this.props.solacomData.queuedCalls.length) {
                    this.props.solacomData.queuedCalls.map((ds, ind) => {
                        if (ds.geometry && ds.geometry.coordinates) {
                            this.createSolacomMarker(this.props.map, ds.classOfService, 0, callDeclarations.initMapZoom, ds);
                            if (ds.geometry.coordinates.length > 1) {
                                this.addLine(ds, this.props.map, 'queued' + ind);
                            }
                        }
                    });
                }
                if (this.props.solacomData.answeredCalls && this.props.solacomData.answeredCalls.length) {
                    this.props.solacomData.answeredCalls.map((ds, ind) => {
                        if (ds.geometry && ds.geometry.coordinates) {
                            this.createOtherSolacomMarker(this.props.map, ds.classOfService, 0, callDeclarations.initMapZoom, ds);
                            if (ds.geometry.coordinates.length > 1) {
                                this.addLine(ds, this.props.map, 'otherCalls' + ind);
                            }
                        }
                    });
                }
            }
            if (!this.props.callAutoZoom && !this.props.isSearchZoom) {
                if (SolacomGroup()) {
                    if (this.solacomOtherBounds.length) {
                        this.solacomBounds.push(...this.solacomOtherBounds);
                    }
                    if ((this.props.vehicleType && this.props.vehicleType.length === 0) || !this.props.vehicleType) {
                        if (this.solacomBounds.length && (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released))) {
                            this.setBounds(this.solacomBounds, this.props.map);
                        }
                    }
                    else if (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released)) {
                        if (this.props.vehicleType && this.props.vehicleType.length) {
                            this.setTrackingBounds();
                        }
                        else {
                            this.handleGroupBounds(this.props.map);
                        }
                    }
                }
                else if (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released)) {
                    this.handleGroupBounds(this.props.map);
                }
            }
            if (this.props.callData && this.props.callData !== '' && Object.keys(this.props.callData).length) {
                if (!SolacomGroup()) {
                    this.removeLines();
                }
                this.removeAllLayers();
                this.removeActiveMarker();
                cylinderData = this.props.callData;
                if (this.props.callData.geometry && this.props.callData.geometry.coordinates) {
                    this.createMarker(this.props.map, this.props.callData.classOfService, 0, callDeclarations.initMapZoom);
                    this.createRapidSOSMarker(this.props.map);
                    this.addDotedLine(this.props.map);
                    if (this.props.callData.geometry.coordinates.length > 1) {
                        this.addLine(this.props.callData, this.props.map, 'activeCall');
                    }
                    this.drawRapidSOSPolyline(this.props.callData, this.props.map);
                    this.startTimer();
                    // this.moveToCallMarker(); // TODO handle sat
                    if (!this.props.callAutoZoom && !this.props.isSearchZoom) {
                        if (this.props.vehicleType && this.props.vehicleType.length) {
                            this.setTrackingBounds();
                        }
                        else if (this.props.updateBounds) {
                            this.flyToActiveCall(this.props.map, 0, callDeclarations.initMapZoom);
                        }
                    }
                }
                this.mobileNumber = this.props.callData.ani
                // Let the map settle down to get bounds
                if (isFeatureAllowed(featureLabels.sitCount) && this.props.showCamera) {
                    if (sitCountFeatureAllowed(featureLabels.camera) && renderCamera) {
                        setTimeout(() => {
                            this.getCameras();
                            renderCamera = false;
                        }, 1500);
                    }
                    if (sitCountFeatureAllowed(featureLabels.aed) && renderAED) {
                        this.getAED();
                        renderAED = false;
                    }
                }
                else {
                    this.clearAllCameras();
                    this.clearAEDLayers();
                }
            } else {
                if (SolacomGroup()) {
                    this.removeAllLayers();
                    this.removeActiveMarker();
                }
                else {
                    this.clearCallLayers();
                    if (!this.props.callAutoZoom && !this.props.isSearchZoom) {
                        if (this.props.vehicleType && this.props.vehicleType.length) {
                            this.setTrackingBounds();
                        }
                        else if (this.props.updateBounds) {
                            this.handleGroupBounds(this.props.map);
                        }
                    }
                }
                this.setState({
                    displayTimer: '00:00'
                });
                clearInterval(this.interval);
            }
            this.props.callsPanelReloaded(2);
        }
        else if (this.props.error) {
            toast.error(this.props.error, { toastId: customToastId });
        }

        if (this.props.isSatelliteMapLoaded && this.props.satelliteMap && this.props.renderCalls === 1) {
            if (SolacomGroup() && this.props.solacomData && this.props.solacomData !== '' && Object.keys(this.props.solacomData).length) {
                this.satSolacomBounds = [];
                if (this.props.solacomData.queuedCalls && this.props.solacomData.queuedCalls.length) {
                    this.props.solacomData.queuedCalls.map((ds, ind) => {
                        if (ds.geometry && ds.geometry.coordinates) {
                            this.createSateSolacomMarker(this.props.satelliteMap, ds.classOfService, serverconfigs.satelliteMapPitch, callDeclarations.satelliteMapZoom, ds);
                            if (ds.geometry.coordinates.length > 1) {
                                this.addLine(ds, this.props.satelliteMap, 'queued' + ind);
                            }
                        }
                    });
                }
                if (this.props.solacomData.answeredCalls && this.props.solacomData.answeredCalls.length) {
                    this.satOtherSolacomBounds = [];
                    this.props.solacomData.answeredCalls.map((ds, ind) => {
                        if (ds.geometry && ds.geometry.coordinates) {
                            this.createSatOtherSolacomMarker(this.props.satelliteMap, ds.classOfService, 0, callDeclarations.initMapZoom, ds);
                            if (ds.geometry.coordinates.length > 1) {
                                this.addLine(ds, this.props.satelliteMap, 'otherCalls' + ind);
                            }
                        }
                    });
                }
            }
            if (!this.props.isSearchZoom) {
                if (SolacomGroup()) {
                    if (this.satOtherSolacomBounds.length) {
                        this.satSolacomBounds.push(...this.satOtherSolacomBounds);
                    }
                    if (this.satSolacomBounds.length && (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released))) {
                        this.setBounds(this.satSolacomBounds, this.props.satelliteMap);
                    }
                    else if (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released)) {
                        this.handleSatGroupBounds(this.props.satelliteMap);
                    }
                }
                else if (!this.props.callData || (this.props.callData && this.props.callData.callState !== callsConfigs.activeStatus) || (this.props.callData && this.props.callData.callState !== callsConfigs.released)) {
                    this.handleSatGroupBounds(this.props.satelliteMap);
                }
            }
            if (this.props.callData && this.props.callData !== '' && Object.keys(this.props.callData).length) {
                if (this.props.callData.geometry && this.props.callData.geometry.coordinates) {
                    this.createMarker(this.props.satelliteMap, this.props.callData.classOfService, serverconfigs.satelliteMapPitch, callDeclarations.satelliteMapZoom);
                    this.createRapidSOSMarker(this.props.satelliteMap);
                    this.addDotedLine(this.props.satelliteMap);
                    if (this.props.callData.geometry.coordinates.length > 1) {
                        this.addLine(this.props.callData, this.props.satelliteMap, 'activeCall');
                    }
                    this.drawRapidSOSPolyline(this.props.callData, this.props.satelliteMap);
                }
                if (this.props.showUncertainity && this.isRapidSOSDataAvailable()) {
                    this.addRapidSOSCircle(this.props.satelliteMap, this.props.callData.rapidSOS.geometry, this.props.callData.rapidSOS.radius / 1000);
                }
                if (!this.props.isSearchZoom) {
                    this.flyToSatActiveCall(this.props.satelliteMap, serverconfigs.satelliteMapPitch, callDeclarations.satelliteMapZoom);
                }
            }
        }
        else if (this.props.error) {
            toast.error(this.props.error, { toastId: customToastId });
        }

        if (this.props.secondaryView === 'Google-StreetView' && this.props.googleMap && this.props.callData && this.props.callData.geometry && this.props.renderCalls === 1 && !this.props.isSearchZoom) {
            if (this.props.callData && this.props.callData.geometry && this.props.callData.geometry.coordinates.length > 0) {
                const panorama = this.props.googleMap.getStreetView()
                panorama.setPosition({ lat: this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1], lng: this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0] });
                panorama.setPov(
                    /** @type {google.maps.StreetViewPov} */ {
                        heading: 34,
                        pitch: 10,
                    }
                );
                panorama.setVisible(true);
            }
        }

        if ((/* this.props.isSatelliteMapLoaded ||  */this.props.showMap) && this.props.renderCalls === 1) {
            if (this.props.callData && this.props.callData !== '' && Object.keys(this.props.callData).length) {
                this.moveToCallMarker();
            }
            if (this.props.endCallsData && this.props.endCallsData.length) {
                this.createEndCallsMarkers();
            }
        }

        if (this.props.showMap && this.props.callData !== '') {
            if (this.props.cameras && this.props.camerasReloaded) {
                this.showCameras(this.props.cameras);
                this.props.setCamerasReloaded(false);
            }
            if (this.props.AEDList && this.props.AEDList.length && this.props.AEDReloaded) {
                this.removeAEDPpoups();
                this.showAED(this.props.AEDList);
                this.props.setAEDReloaded(false);
            } else if (this.props.AEDList && this.props.AEDList.length === 0 && this.props.AEDReloaded) {
                this.removeAEDPpoups();
                this.clearAEDLayers();
            }

            setTimeout(() => {
                if (this.props.renderCalls === 1)
                    this.props.callsPanelReloaded(2);
            }, 1500);
            if (this.props.callAutoZoom) {
                this.props.map.once('idle', () => {
                    if (this.props.callDependencies && this.props.callDependencies.mapZoom && this.props.callDependencies.location && (callDeclarations.idleTabName === tabUrls.call)) {
                        this.props.map.flyTo({
                            center: this.props.callDependencies.location,
                            zoom: this.props.callDependencies.mapZoom
                        });
                        this.props.setCallDependencies({
                            'mapZoom': undefined,
                            'location': null
                        });
                    }
                })
            }
        }

        return (
            <>
                <div className='incident-panel call-panel'>
                    <div className='call-details'>
                        <span>Call Details</span>
                        <div className='d-flex align-items-center'>
                            <span className='station-label'>Position ID:</span> <span className='station-id'>{getStationIdFromLocalStorage()}</span>
                        </div>
                    </div>
                    {!this.props.isLoading ?
                        <div className='incident-list'>
                            {!this.props.isLoading && this.props.callData && Object.keys(this.props.callData).length && (this.props.callData.callState == callsConfigs.activeStatus || this.props.callData.callState == callsConfigs.released) ?
                                <>
                                    <div className='py-2 mb-2'>{moment(this.state.displayDate).format('MM-DD-YYYY hh:mm:ss A')}</div>
                                    <div className='d-flex align-items-center'>
                                        {SolacomGroup() ?
                                            this.props.callData.eventType === 'Released' ?
                                                this.props.callData.eventType === 'Released' && callDeclarations.releasedCallIcons[this.props.callData.classOfService] ?
                                                    <img src={callDeclarations.releasedCallIcons[this.props.callData.classOfService]} className='call-icon' />
                                                    : <img src={callDeclarations.releasedCallIcons['default']} className='call-icon' />
                                                :
                                                this.props.callData.callStateExt === 'transferred' ?
                                                    this.props.callData.callStateExt === 'transferred' && callDeclarations.transferOut[this.props.callData.classOfService] ?
                                                        <img src={callDeclarations.transferOut[this.props.callData.classOfService]} className='call-icon' />
                                                        : <img src={callDeclarations.transferOut['default']} className='call-icon' />
                                                    : callDeclarations.SolacomCallIcons[this.props.callData.classOfService] ?
                                                        <img src={callDeclarations.SolacomCallIcons[this.props.callData.classOfService]} className='call-icon' />
                                                        : <img src={callDeclarations.SolacomCallIcons['default']} className='call-icon' />
                                            : callDeclarations.isSimulationEnabled ? <img src={callDeclarations.callIcons[this.props.callData.classOfService]} className='call-icon hand-cursor' onClick={this.handleCallsData} /> : callDeclarations.callIcons[this.props.callData.classOfService] ?
                                                <img src={callDeclarations.callIcons[this.props.callData.classOfService]} className='call-icon' />
                                                : <img src={callDeclarations.callIcons['default']} className='call-icon' />}
                                        <div>
                                            <div>{this.props.callData.classOfService}</div>
                                            <div className='call-mdn'><b>{this.props.callData.ani ?
                                                this.props.callData.ani.includes(';') ? this.props.callData.ani.split(';')[0].includes('+') ? <><div>{this.props.callData.ani.split(';')[0].slice(0, 2)} {this.props.callData.ani.split(';')[0].slice(2, this.props.callData.ani.split(';')[0].length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</div><div>{this.props.callData.ani.split(';')[1]}</div></> :
                                                    this.props.callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') :
                                                    this.props.callData.ani.includes('+') ? <span>{this.props.callData.ani.slice(0, 2)} {this.props.callData.ani.slice(2, this.props.callData.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                                        this.props.callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''
                                            }</b></div>
                                        </div>
                                    </div>
                                    {SolacomGroup() ?
                                        <div className='mt-2'>
                                            <span className='display-counter'>{this.state.displayTimer}</span>
                                            <span className='call-btn'>{this.props.callData.eventType === 'Released' ? 'Released' : this.props.callData.callStateExt === 'transferred' ? 'Transferred' : 'In Progress'}</span>
                                        </div>
                                        : <div className='mt-2'>
                                            <span className='display-counter'>{this.state.displayTimer}</span>
                                            <span className='call-btn'>In Progress</span>
                                        </div>
                                    }
                                    <div className='main-info my-3'>Main Information</div>
                                    {SolacomGroup() ? <>
                                        {this.props.callData.callerName ? <div className='mb-1'>
                                            <div className="label">Caller Name: </div> {this.props.callData.callerName}
                                        </div> : ''}
                                    </> : ''}
                                    {this.props.callData.address ? <div className='mb-3'>
                                        <div className='label'>Call Address:</div>
                                        {this.props.callData.cityRadius == callDeclarations.citySearchRadius ?
                                            <div>{this.props.callData.address.city}{isEmpty(this.props.callData.address.city) && isEmpty(this.props.callData.address.state || this.props.callData.address.zip) ? ',' : ''} {this.props.callData.address.state}{isEmpty(this.props.callData.address.state && this.props.callData.address.zip) ? ',' : ''} {this.props.callData.address.zip}</div>
                                            :
                                            <>
                                                <div>{this.props.callData.address.address1}{isEmpty(this.props.callData.address.address1) && isEmpty(this.props.callData.address.address2) && isEmpty(this.props.callData.address.city || this.props.callData.address.state || this.props.callData.address.zip) ? ',' : ''}</div>
                                                <div>{this.props.callData.address.address2}{isEmpty(this.props.callData.address.city && this.props.callData.address.address2) || isEmpty(this.props.callData.address.state && this.props.callData.address.address2) || isEmpty(this.props.callData.address.zip && this.props.callData.address.address2) ? ',' : ''}</div>
                                                <div>{this.props.callData.address.city}{isEmpty(this.props.callData.address.city) && isEmpty(this.props.callData.address.state || this.props.callData.address.zip) ? ',' : ''} {this.props.callData.address.state}{isEmpty(this.props.callData.address.state && this.props.callData.address.zip) ? ',' : ''} {this.props.callData.address.zip}</div>
                                            </>
                                        }
                                    </div> : ''}

                                    <div className='py-2'>
                                        {this.props.callData.geometry && this.props.callData.geometry.coordinates && this.props.callData.geometry.coordinates.length ?
                                            <span className='coordinates'>[{parseFloat(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][1]).toFixed(6)}, {parseFloat(this.props.callData.geometry.coordinates[this.props.callData.geometry.coordinates.length - 1][0]).toFixed(6)}]</span>
                                            : <div className='unknown-loc'>Location Unknown</div>}
                                        {this.props.callData.radius ?
                                            <span className='radius'> UNC: {this.props.callData.radius ? parseFloat(this.props.callData.radius * callDeclarations.meterToFeet).toFixed() + ' ft' : ""}</span> : ""}
                                    </div>

                                    {this.props.callData.disabilities ?
                                        <div>
                                            <div className='label'>Disabilities: </div>
                                            <div className='disability'>{this.props.callData.disabilities.length > 60 ?
                                                <span>{this.state.showMoreText ? this.props.callData.disabilities : this.props.callData.disabilities.slice(0, 60)} <span className='hand-cursor more-btn' onClick={this.handleDisabilities}>See {this.state.showMoreText ? 'Less' : 'More'}</span></span>
                                                : this.props.callData.disabilities}</div>
                                        </div>
                                        : ''}

                                    <div className='my-3'>
                                        <div>{this.props.callData.classOfService}</div>
                                        <div className='text-left'><span className='label'>Provider ID:</span> <span color={'black'}>{this.props.callData.serviceProvider}</span></div>

                                    </div>
                                    {SolacomGroup() ?
                                        <>
                                            {this.props.callData.agents && this.props.callData.agents.length ?
                                                <div className='mb-1'>
                                                    <div><div className="label">Agent(s): </div> {this.props.callData.agents[0].agent}</div>
                                                </div>
                                                : ''}
                                            {this.props.callData.routesList && this.props.callData.routesList.length ?
                                                <div className='mb-1'>
                                                    <div>Routing Policies: </div>
                                                    {this.props.callData.routesList.map((route, i) => {
                                                        return (
                                                            <div key={route.rule} className='d-flex justify-content-between'>
                                                                <div><span className="label">Route:</span> {route.rule}</div> <div><span className="label">Reason:</span> {route.reason}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                : ''}
                                            {this.props.callData.transfer && this.props.callData.transfer.target ?
                                                <div className='mb-1 mt-2'>
                                                    <div><div className="label">Transfer Target: </div> {this.props.callData.transfer.target}</div>
                                                </div>
                                                : ''}
                                        </>
                                        : ''}
                                    <div className='main-info add-info my-3'>Additional Information</div>
                                    <div className='history-details'>
                                        {this.props.rapidSOS ?
                                            <RapidSOSPanel rapidSOSInfo={this.props.callData.rapidSOS} contourLineProperties={this.state.contourLineProperties} rapisSOSAddress={this.state.rapisSOSAddress} />
                                            : ''}
                                        {isFeatureAllowed(featureLabels.person) ?
                                            <>
                                                <div className='history-list'>
                                                    <div className='title'>
                                                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${this.state.showPastResidences ? 'active' : ''}`}
                                                            onClick={this.handlePastResidences}>
                                                            <span >Past Residences</span>
                                                            {this.state.showPastResidences ?
                                                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                                                :
                                                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                                            }
                                                        </div>
                                                    </div>
                                                    {this.state.showPastResidences && this.props.identity && this.props.identity.length ?
                                                        <div className='border-bottom pb-3'>
                                                            {this.props.identity.map((list, ind) => {
                                                                return (
                                                                    <div key={ind}>
                                                                        {list.addresses.map((residence, key) => {
                                                                            return (
                                                                                <div key={key} className='address-details'>
                                                                                    <div className='label'>
                                                                                        From {residence.firstDate.month + '/' + residence.firstDate.year} To {residence.lastDate.month + '/' + residence.lastDate.year}
                                                                                    </div>
                                                                                    <div>
                                                                                        {residence.line1},
                                                                                    </div>
                                                                                    <div>{residence.city}, {residence.state} {residence.zip}</div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                        : ''
                                                    }
                                                </div>
                                                <div className='history-list'>
                                                    <div className='title'>
                                                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${this.state.showCriminalHistory ? 'active' : ''}`}
                                                            onClick={this.handleCriminalHistory}>
                                                            <span>Criminal History</span>
                                                            {this.state.showCriminalHistory ?
                                                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                                                :
                                                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                                            }
                                                        </div>
                                                    </div>
                                                    {this.state.showCriminalHistory && this.props.criminalData && this.props.criminalData.length ?
                                                        <div className='border-bottom pb-3'>
                                                            {this.props.criminalData.map((list, ind) => {
                                                                return (
                                                                    <div key={ind}>
                                                                        {list.offenses.offense.map((history, key) => {
                                                                            return (
                                                                                <div key={key} className='address-details'>
                                                                                    <div><span className='label'>Offence Description:</span> {history.offensedescription}</div>
                                                                                    <div><span className='label'>Offence Type:</span> {history.casetype}</div>
                                                                                    <div><span className='label'>Disposition Date :</span> {history.dispositiondate}</div>
                                                                                    <div><span className='label'>Court :</span> {history.court}</div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                        : ''
                                                    }
                                                </div>
                                            </>
                                            : ''
                                        }
                                    </div>
                                </>
                                : ''}
                            {
                                this.props.endCallsData && this.props.endCallsData.length ?
                                    <>
                                        <div className='main-info end-calls-info my-3'>Ended Calls</div>
                                        <div className="listView position-relative h-auto">
                                            {this.props.endCallsData
                                                .sort((a, b) => b.disconnectTime - a.disconnectTime)
                                                .map((callData, index) => (
                                                    <div key={index} className={`containerBox m-0 mb-2 hand-cursor ${this.state.selectedEndCallId === callData.nenaIncidentId ? 'active' : ''}`} onClick={this.handleEndCallClick.bind(this, callData)} >
                                                        <p className="noMargin">
                                                            {/* <span>MDN:</span>{" "} */}
                                                            <span className="grey-font">
                                                                {moment(callData.disconnectTime).format('MM-DD-YYYY hh:mm:ss A')}
                                                            </span>
                                                        </p>

                                                        {this.state.selectedEndCallId === callData.nenaIncidentId ?
                                                            <>
                                                                <div className='my-3'>
                                                                    <div className='label'>Call Back:</div>
                                                                    <div>{callData.ani ?
                                                                        callData.ani.includes('+') ? <span>{callData.ani.slice(0, 2)} {callData.ani.slice(2, callData.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                                                            callData.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''}</div>
                                                                </div>
                                                                {callData.address ? <div className='my-3'>
                                                                    <div className='label'>Call Address:</div>
                                                                    <div>{callData.address.address1}{isEmpty(callData.address.address1) && isEmpty(callData.address.address2) && isEmpty(callData.address.city || callData.address.state || callData.address.zip) ? ',' : ''}</div>
                                                                    <div>{callData.address.address2}{isEmpty(callData.address.city && callData.address.address2) || isEmpty(callData.address.state && callData.address.address2) || isEmpty(callData.address.zip && callData.address.address2) ? ',' : ''}</div>
                                                                    <div>{callData.address.city}{isEmpty(callData.address.city) && isEmpty(callData.address.state || callData.address.zip) ? ',' : ''} {callData.address.state}{isEmpty(callData.address.state && callData.address.zip) ? ',' : ''} {callData.address.zip}</div>
                                                                </div> : ''}
                                                            </>
                                                            : ''}
                                                    </div>
                                                ))}
                                        </div>
                                    </> : ''
                            }
                            {(
                                (this.props.callData && (this.props.callData.callState === callsConfigs.activeStatus || this.props.callData.callState === callsConfigs.released)) || (this.props.endCallsData && this.props.endCallsData.length)) ?
                                ''
                                : <div className='no-data-found'>No Active Call Found</div>}
                        </div>
                        : this.props.isLoading ?
                            <Spinner animation="border" variant="primary">
                                <span className="sr-only"></span>
                            </Spinner> : <div className='no-data-found'>No Active Call Found</div>
                    }
                </div>
                {SolacomGroup() && this.props.solacomData && this.props.solacomData.unplottedCalls && this.props.solacomData.unplottedCalls.length ?
                    <>
                        <div className='uplotted-calls hand-cursor' onMouseEnter={this.onPopupHover} onMouseLeave={this.onPopupHoverLeave}><span className='count'>{this.props.solacomData.unplottedCalls.length}</span></div>
                        {this.state.popoverOpen ?
                            <div className="mapboxgl-popup mapboxgl-popup-anchor-bottom unplotted-popup">
                                <div className="mapboxgl-popup-tip"></div>
                                <div className="mapboxgl-popup-content">
                                    <div className="marker_popup address-marker">
                                        <div className="popup-text">
                                            {this.props.solacomData.unplottedCalls.map(unplottedCall => {
                                                return (
                                                    <div className='call-list' key={unplottedCall.ani}>
                                                        <div className='d-flex'>
                                                            <div className="street-label">Ani: </div>
                                                            <span className='pl-1'>{unplottedCall.ani}</span>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className="street-label">Caller Name: </div>
                                                            <span className='pl-1'>{unplottedCall.callerName}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                    </>
                    : ''}
                <CameraPanel cameraImageUrl={this.state.cameraImageUrl} show={this.state.showVideo} hide={this.handleCameraClose}
                    expandModal={this.state.expandModal} handleExpand={this.handleCameraExpand} />
                {this.props.callData && Object.keys(this.props.callData).length ?
                    <SendSMSPopUp mobileNumber={this.mobileNumber} show={this.props.isStreaming} hide={this.handleSMSPopupClose} />
                    : ''}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        callData: state.calls ? state.calls.data : state.calls,
        solacomData: state.solacomCallEvents,
        endCallsData: state.calls ? state.calls.endCallsData : state.calls,
        isLoading: state.calls ? state.calls.isLoading : state.calls,
        cameras: state.calls ? state.calls.cameras : state.calls,
        error: state.calls.error,
        invokeCameras: state.calls ? state.calls.invokeCameras : false,
        camerasReloaded: state.calls ? state.calls.camerasReloaded : false,
        mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
        loadMap: state.loadMap.mapLoaded,
        renderCalls: state.renderCalls ? state.renderCalls.renderCalls : state.renderCalls,
        identity: state.identity ? state.identity.identity : state.identity,
        criminalData: state.criminalData ? state.criminalData.criminalData : state.criminalData,
        selectedCamera: state.calls ? state.calls.selectedCamera : '',
        error: state.calls ? state.calls.error : state.calls,
        routing: state.routing ? state.routing.routing : state.routing,
        renderRouting: state.renderRouting ? state.renderRouting.renderRouting : state.renderRouting,
        showCamera: state.showCamera ? state.showCamera.showCamera : state.showCamera,
        AEDList: state.AEDList ? state.AEDList.AEDList : state.AEDList,
        AEDReloaded: state.AEDList ? state.AEDList.AEDReloaded : false,
        rapidSOS: state.rapidSOS ? state.rapidSOS.rapidSOS : state.rapidSOS,
        vehicleType: state.showTracking ? state.showTracking.vehicleType : state.showTracking,
        showTracking: state.showTracking ? state.showTracking.showTracking : state.showTracking,
        tabName: state.tab.tabName,
        callAutoZoom: state.callAutoZoom ? state.callAutoZoom.callAutoZoom : state.callAutoZoom,
        callDependencies: state.callDependencies.dependencies,
        isSearchZoom: state.searchPauseZoom ? state.searchPauseZoom.isSearchZoom : false,
        isStreaming: state.isStreaming.isStreaming

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCallsDataRequested: () => dispatch(fetchCallsDataRequested()),
        getCallsData: () => dispatch(getCallsData()),
        getSimulationCallsData: () => dispatch(getSimulationCallsData()),
        getCameras: (bbox, position, radius) => dispatch(getCameras(bbox, position, radius)),
        setCamerasReloaded: (isReloaded) => dispatch(setCamerasReloaded(isReloaded)),
        callsPanelReloaded: (val) => dispatch(callsPanelReloaded(val)),
        setSelectedCamera: (val) => dispatch(setSelectedCamera(val)),
        fetchRenderRouting: (val) => dispatch(fetchRenderRouting(val)),
        setCallsData: (val) => dispatch(setCallsData(val)),
        fetchRouting: (val) => dispatch(fetchRouting(val)),
        fetchAED: (center, field) => dispatch(fetchAED(center, field)),
        setAEDReloaded: (val) => dispatch(setAEDReloaded(val)),
        updateEndCallData: (val) => dispatch(updateEndCallData(val)),
        updateRapiSOSAddress: (val) => dispatch(updateRapiSOSAddress(val)),
        setCallDependencies: (val) => dispatch(setCallDependencies(val)),
        setCallAutoZoom: (val) => dispatch(setCallAutoZoom(val)),
        setSearchZoom: (val) => dispatch(setSearchZoom(val)),
        setStreamingStatus: (val) => dispatch(setStreamingStatus(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CallsPanel);
