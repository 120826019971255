import moment from "moment";

export default class DateUtils {
  
  isValidDateTime(startDate, endDate) {
    if (startDate && (startDate < endDate)) {
      return 'valid';      
    } else if(startDate > endDate){
      return 'Invalid Start Time and End Time.';
    } else {
      return 'Invalid Start Time and End Time.';
    }
    
  }

  getUtcTimeStamp = (dateVal) =>{
    let formatedDate = moment(dateVal).format('YYYY-MM-DD hh:mm:ss A');
    return moment.utc(formatedDate, 'YYYY-MM-DD hh:mm:ss A').unix();
  }
}