import React from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import '../../styles/modal.scss';
import '../../styles/uploadIndoorMap.scss';
import { isJsonString } from '../../utils/commonUtils';
import { toast } from 'react-toastify';
import { customToastId } from '../../constants';
import icTrash from '../../assets/delete_icon.svg';
import fileTrash from '../../assets/delete_file_icon.svg';
import IndoorMapService from '../../services/IndoorMap.services';
class UploadIndoorMap extends React.Component {
    constructor(props) {
        super(props);
        this.IndoorMapService = new IndoorMapService();
        this.state = {
            fileContent: null,
            formData: [],
            uploadJson: null,
            submitted: false,
            showSpinner: false
        };
        this.refImageUpload = [];
    }

    handleClose = () => {
        this.props.hide();
        this.handlleDeleteFile();
    }

    uploadFile = (e) => {
        const fileReader = new FileReader();
        const { files } = e.target;
        if (files[0]) {
            this.setState({
                uploadJson: files[0]
            })
            fileReader.readAsText(files[0], "UTF-8");
            fileReader.onload = e => {
                const content = e.target.result;
                if (isJsonString(content)) {
                    this.setState({
                        fileContent: JSON.parse(content)
                    })
                }
                else {
                    toast.error('Invalid JSON Format', { toastId: customToastId });
                }
            };
        }
    }

    uploadImage = (key) => {
            this.refImageUpload[key].click();
    }

    uploadImageFile = (obj, e) => {
        let paramsObj = this.state.formData;
        let file = e.target.files[0];
        let fileContent = this.state.fileContent;
        if (fileContent && fileContent.floors && fileContent.floors.length) {
            fileContent.floors.map(list => {
                if (list.flNo === obj.flNo) {
                    // let fileName = file.name.replace(/.png|.svg|.jpeg|.jpg/g, '');
                    list.uploadImage = file.name;
                    paramsObj.push({'key': [list.imageFileName], 'file':file});
                    this.setState({
                        submitted: true
                    })
                }
                else{
                    this.setState({
                        submitted: false
                    })
                }
            })
            this.setState({
                fileContent: fileContent,
                formData: paramsObj
            })
        }
    }

    handleDelete = (image) => {
        let fileContent = this.state.fileContent;
        if (fileContent && fileContent.floors && fileContent.floors.length) {
            fileContent.floors.map(list => {
                if (image.imageFileName === list.imageFileName) {
                    list.uploadImage = null;
                    this.setState({
                        submitted: false
                    })
                }
            })
            this.setState({
                fileContent: fileContent
            })
        }
    }

    handleUpload = () => {
        let formData = this.state.formData;
        var object = formData.reduce(
            (obj, item) => Object.assign(obj, { [item.key]: item.file }), {});
        let params = {
            'metadata': this.state.uploadJson,
            ...object
        }
        if(formData.length && this.state.uploadJson ){
            this.setState({
                showSpinner: true
            })
            this.IndoorMapService.uploadIndoorMapd(params).then((response) => {
                if(response && response.status === 200){
                    this.setState({
                        showSpinner: false
                    })
                    this.handleClose();
                    toast.success(response.data, { toastId: customToastId });
                    this.props.onExit();
                }
            }).catch(error => {
                if(error.response){
                    this.setState({
                        showSpinner: false
                    })
                    if(error.response.data && error.response.data !== ''){
                        toast.error(error.response.data, { toastId: customToastId });
                    }
                    else if (error) {
                        toast.error(error.message, { toastId: customToastId });
                    }
                }
            })
        }
    }

    handlleDeleteFile = () => {
        this.setState({
            formData: [],
            uploadJson: null,
            fileContent: null,
            submitted: false
        })
    }

    render() {
        return (
            <>
                <Modal show={this.props.show} onHide={this.props.hide} backdrop={false} className='upload-modal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Indoor Maps</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            {this.state.fileContent ?
                                <><div className="mb-3 d-flex align-items justify-content-between"><div>
                                    <span className='form-label'>File:</span><span className='image-name'> {this.state.uploadJson.name}</span></div>
                                <div><img className='file-trash hand-cursor ms-3' title='Delete File' src={fileTrash} onClick={this.handlleDeleteFile} /></div></div></>
                                : <Form.Group controlId="formFileSm" className="mb-3">
                                    <Form.Label className='me-2'>Select File:</Form.Label>
                                    <Form.Control type="file" onChange={this.uploadFile} accept=".json,application/json,application/JSON" />
                                </Form.Group>
                            }
                            {this.state.fileContent && this.state.fileContent.floors && this.state.fileContent.floors.length ?
                                <>
                                    <div className='image-header'>
                                        <div>Name</div>
                                        <div>Status</div>
                                    </div>
                                    <div className='image-container'>
                                        {this.state.fileContent.floors.map((list, ind) => {
                                            return (
                                                <div key={list.imageFileName} className="upload-pad mb-3 d-flex align-items justify-content-between">
                                                    <div className='image-name'>{list.imageFileName}</div>
                                                    {list.uploadImage ? <div className='ms-3'>
                                                        <span className='uploaded'>Uploaded</span>
                                                        <img className='file-trash hand-cursor ms-3' title='Delete Image' src={icTrash} onClick={this.handleDelete.bind(this, list)} /></div> :
                                                        <>
                                                            <input type="file" className='upload-image' ref={refImageUpload => this.refImageUpload[ind] = refImageUpload}
                                                            name={list.imageFileName} accept="image/png"
                                                                onChange={this.uploadImageFile.bind(this,list)} />
                                                            <div className='add-image hand-cursor' onClick={this.uploadImage.bind(this, ind)}>Select</div>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                    </>
                                : ''}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={this.handleClose}>Cancel</Button>
                        <Button variant="primary" onClick={this.handleUpload} disabled={this.state.submitted ? false : true}>Upload</Button>
                        {this.state.showSpinner ?
                            <Spinner animation="border" variant="primary" className='ms-3'>
                                <span className="sr-only"></span>
                            </Spinner>
                            : ''}
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default UploadIndoorMap;