import React from "react";
import { Auth } from "aws-amplify";
import { serverconfigs, featureLabels, callDeclarations, groupFeatures, factoryTracking } from "../constants";
import "../styles/account.scss";
import { Tabs, Tab, Form } from "react-bootstrap";
import { isFeatureAllowed, SolacomGroup } from "../utils/groupFeatureUtils";
import { getStationIdFromLocalStorage, getEndCallTimeFromLocalStorage, setEndCallTimeInLocalStorage, setStationIdInLocalStorage, consoleLog } from "../utils/commonUtils";
import withRouter from "../withRouter";
import { connect } from 'react-redux';
import { fetchLoginStatus } from '../redux/actions/headerActions';
import { setSecondMapPath, setStationID } from '../redux/actions/incidentActions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStyleView: "",
      username: "",
      groupId: "",
      unSubscribe:
        sessionStorage.getItem("showNotifications") == "true" ? true : false,
      simulateSim911:
        sessionStorage.getItem("isSimulationEnabled") == "true" ? true : false,
      endCallTime: this.getSecondsToMin(),
      enableApplyBtn: true,
      stationList: sessionStorage.getItem("groupProfile"),
      showStationList: false,
      slectStationId: null,
      selectedSimulation: factoryTracking.selectedSimulation
    };
  }

  componentDidMount() {
    this.setState({ username: sessionStorage.getItem("currentUser") });
    this.setState({ groupId: sessionStorage.getItem("groupName") });
    const sessionSecondMap = JSON.parse(sessionStorage.getItem('groupProfile'));
    const initMapViewPath = Array.isArray(sessionSecondMap.secondMap) ?
      (sessionSecondMap.secondMap)[0] : sessionSecondMap.secondMap;

    const secondaryMapView = this.props.secondaryMapView;

    if (groupFeatures != null) {
      this.setState({
        stationList: groupFeatures
          ? JSON.parse(sessionStorage.getItem("groupProfile")).stationID
          : [],
      });
    }

    this.setState({
      selectedStyleView: (!!secondaryMapView && secondaryMapView.secondMapPath !== '') ? secondaryMapView.secondMapPath : (!!initMapViewPath ? initMapViewPath : 'No-Map')
    });
  }

  changePassword = () => {
    Auth.signOut()
      .then(() => {
        this.props.fetchLoginStatus(false);
        window.location.href = serverconfigs.passwordResetUrl;
        // sessionStorage.currentUser = undefined;
        // sessionStorage.notAdmin = undefined;
        // sessionStorage.removeItem("createUser");
        sessionStorage.clear();
      })
      .catch((e) => {
        consoleLog(e);
      });
  };
  resetStationId = () => {
    this.setState({
      showStationList: true
    })
    // window.location.href = "/auth"
  };

  handleUnsubscribe = (e) => {
    if (!this.props.isNetworkAvailable) {
      return;
    }
    sessionStorage.setItem("showNotifications", e.target.checked);
    this.setState({
      unSubscribe: e.target.checked,
    });
    this.props.sendProps();
  };

  handleViewChange = (e) => {
    e.persist();
    this.setState({
      selectedStyleView: e.target.name
    })
  };

  handleSecondaryMapview = (e) => {
    e.preventDefault();
    this.props.setSecondMapPath(this.state.selectedStyleView);
    this.props.navigate('/calls');
  };

  handleSim911Simulation = (e) => {
    if (!this.props.isNetworkAvailable) {
      return;
    }
    sessionStorage.setItem("isSimulationEnabled", e.target.checked);
    callDeclarations.isSimulationEnabled = e.target.checked;
    this.setState({
      simulateSim911: e.target.checked,
    });
    this.props.handleSimulation();
  };

  updateEndCallTime(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({ endCallTime: e.target.value })
    }
    if (e.target.value.length) {
      this.setState({ enableApplyBtn: false })
    }
    else {
      this.setState({ enableApplyBtn: true })
    }
  }

  resetEndCallToDefault = () => {
    var time = groupFeatures.endCallTimer ? groupFeatures.endCallTimer : 0;
    setEndCallTimeInLocalStorage(sessionStorage.getItem("currentUser"), time);
    this.setState({
      endCallTime: this.convertToMin(groupFeatures.endCallTimer ? groupFeatures.endCallTimer : 0)
    });
    this.props.navigate('/calls');
  };

  getSecondsToMin = () => {
    var localVal = groupFeatures.endCallTimer;
    var storedEndCallTime = getEndCallTimeFromLocalStorage();
    if (storedEndCallTime != null) {
      localVal = storedEndCallTime
    }
    return this.convertToMin(localVal)
  }

  convertToMin = (minutes) => {
    let endTime = 0
    if (minutes && minutes != null && minutes != '' && minutes > 0) {
      endTime = minutes / 60
    }
    return endTime
  }
  applyEndCallTime = () => {
    setEndCallTimeInLocalStorage(sessionStorage.getItem("currentUser"), this.state.endCallTime * 60);
    this.props.navigate('/calls');
  };

  cancelStationSelection = () => {
    this.setState({
      showStationList: false,
      slectStationId: null
    })
  }

  handleStationId = (e) => {
    this.setState({
      slectStationId: e.target.value
    })
  }

  submitStation = () => {
    sessionStorage.setItem("showNotifications", false);
    this.props.sendProps();
    setStationIdInLocalStorage(sessionStorage.getItem("currentUser"), this.state.slectStationId);
    this.cancelStationSelection();
    sessionStorage.setItem("showNotifications", true);
    this.props.setStationID(this.state.slectStationId);
    this.props.navigate('/calls');
  }

  handleOptionChange = (event) => {
    this.setState({
      selectedSimulation: event.target.value,
      slectStationId: null
    }, () => {
      factoryTracking.selectedSimulation = this.state.selectedSimulation;
    })
  };

  handleFactorySimulation = (e) => {
    e.preventDefault();
    // `${serverUrl.serverBaseUrl}/geo/maps/v3/weather/v1.0`
    let useCase = 'Lab'
    if (this.state.selectedSimulation === factoryTracking.useCase1) {
      useCase = 'Lab'
    } else if (this.state.selectedSimulation === factoryTracking.useCase2) {
      useCase = 'Factory'
    }
    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://api.qa.smartresponse.net/geo/psap/tracking/traxmate/config?isActive=true&useCase=${useCase}&api_key=${serverconfigs.apiKey}`,
      headers: {}
    };

    axios.request(config)
      .then((response) => {
        this.props.navigate('/calls');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const sessionSecondMap = JSON.parse(sessionStorage.getItem('groupProfile'));
    return (
      <div className="account about-us">
        <div className="container">
          <h3 className="py-3 title">Account</h3>
          <Tabs defaultActiveKey="profile" className="mb-3">
            <Tab eventKey="profile" title="Profile">
              {/* <h4 className='py-2'>Profile</h4> */}
              <div className="account-form">
                <div className="d-flex align-items-center py-2">
                  <div className="col-form-label">Username:</div>
                  <input
                    type="text"
                    className="input"
                    value={this.state.username}
                    disabled
                  />
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="col-form-label">Password:</div>
                  <input
                    type="password"
                    className="input"
                    ref={this.password}
                    placeholder="********"
                    disabled
                  />
                  <button
                    type="button"
                    className="change-btn ml-3"
                    onClick={() => this.changePassword()}
                  >
                    {" "}
                    Change{" "}
                  </button>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="col-form-label">Group ID:</div>
                  <input
                    type="text"
                    className="input"
                    value={this.state.groupId}
                    disabled
                  />
                </div>
              </div>
            </Tab>


            <Tab eventKey="settings" title="Settings">
              <div className="account-form">
                {isFeatureAllowed(featureLabels.call) ?
                  <>
                    <div className="card">
                      <div className="d-flex align-items-center py-2">
                        <div className="col-form-label">Notifications</div>
                        <div className="unsubscribe-text">
                          Subscribe Position ID notification
                        </div>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={this.state.unSubscribe}
                          onChange={this.handleUnsubscribe}
                        />
                      </div>


                      <div className="d-flex align-items-center py-2">
                        <div className="col-form-label">Station ID</div>
                        <div className="unsubscribe-text">
                          {getStationIdFromLocalStorage()}
                        </div>
                        <button
                          type="button"
                          className={this.state.showStationList || !this.state.unSubscribe ? 'change-btn-disable ' : "change-btn"}
                          onClick={this.resetStationId}
                          disabled={this.state.showStationList || !this.state.unSubscribe ? true : false}
                        >
                          {" "}
                          Reset{" "}
                        </button>
                      </div>
                      {this.state.showStationList && this.state.unSubscribe ?
                        <div className="choose-station d-flex align-items-end mb-5">
                          <div className="form-group position-relative">
                            <label className="mb-1">Choose MCW Position</label>
                            <select className="form-control form-select" onChange={this.handleStationId}>
                              <option hidden>Position ID</option>
                              {Array.isArray(this.state.stationList) &&
                                this.state.stationList.length
                                ? this.state.stationList.map((stationId) => {
                                  return (
                                    <option value={stationId} key={stationId}>
                                      {stationId}
                                    </option>
                                  );
                                })
                                : ""}
                            </select>
                          </div>
                          <div className="form-group d-flex">
                            <button className="mt-0 comtech-btn comtech-btn-primary btn-lg"
                              onClick={this.submitStation} type="submit" disabled={this.state.slectStationId !== null ? false : true} >
                              <span>Select</span>
                            </button>
                            <button className='change-btn-disable ms-2 me-2'
                              onClick={this.cancelStationSelection}
                            >Cancel</button>
                          </div>
                        </div>
                        : ''}
                    </div>
                  </>
                  : ''}

                {isFeatureAllowed(featureLabels.call) ?
                <div className="card mt-4">
                  {!SolacomGroup() && isFeatureAllowed(featureLabels.call) ?
                    callDeclarations.enableSimulation ?
                      <div className="d-flex align-items-center py-2">
                        <div className="col-form-label">Sim911</div>
                        <div className="unsubscribe-text">
                          Simulate 911 Calls{" "}
                        </div>
                        <Form.Check
                          type="switch"
                          id="sim911-switch"
                          checked={this.state.simulateSim911}
                          onChange={this.handleSim911Simulation}
                        />
                      </div>
                      : ''
                    : ''}
                  {isFeatureAllowed(featureLabels.call) || isFeatureAllowed(featureLabels.incident) ?
                    <div className="d-flex align-items-center py-2">
                      <div className="col-form-label">End Calls</div>
                      <div className="unsubscribe-text">
                        Time to keep ended calls in minutes
                      </div>
                      <input className='form-control endcall-interval w-auto' min={1} step={1} max={99}
                        value={this.state.endCallTime} maxLength={4}
                        onChange={this.updateEndCallTime.bind(this)} />
                      <button
                        type="button"
                        className={this.state.enableApplyBtn ? "change-btn-disable ms-2 me-2" : "change-btn ms-2 me-2"}
                        onClick={this.applyEndCallTime}
                        disabled={this.state.enableApplyBtn || this.state.endCallTime === '' ? true : false}
                      >
                        {" "}
                        Apply{" "}
                      </button>
                      <button
                        type="button"
                        className="change-btn"
                        onClick={this.resetEndCallToDefault}
                      >
                        {" "}
                        Reset{" "}
                      </button>
                    </div>
                    : ''}
                </div>
                :''}
                {isFeatureAllowed(featureLabels.call) && sessionSecondMap && sessionSecondMap.secondMap && (<div className="card mt-4">
                  <Row>
                    <Col>
                      <div className="col-form-label">Secondary Map View</div>
                    </Col>
                  </Row>
                  <Form onSubmit={this.handleSecondaryMapview}>
                    <Row className='account gmapcard'>
                      <Col md={6}>
                        {Array.isArray(sessionSecondMap.secondMap) ? (sessionSecondMap.secondMap.map((ds, i) =>
                          <Form.Group controlId="map-view">
                            <div className="mb-3">
                              <Form.Check
                                label={<div className='account gmapstyle'>{ds.match(/(\/\w+)?\/(.*)\.json/)[2]}</div>}
                                name={ds}
                                type={'radio'}
                                id={`default-radio-${i}`}
                                onChange={this.handleViewChange}
                                checked={this.state.selectedStyleView === ds}
                              />
                            </div>
                          </Form.Group>)) :
                          <Form.Group controlId="map-view">
                            <div className="mb-3">
                              <Form.Check
                                label={<div className='account gmapstyle'>{sessionSecondMap.secondMap.match(/(\/\w+)?\/(.*)\.json/)[2]}</div>}
                                name={sessionSecondMap.secondMap}
                                type={'radio'}
                                id={`default-radio-1`}
                                onChange={this.handleViewChange}
                                checked={this.state.selectedStyleView === sessionSecondMap.secondMap}
                              />
                            </div>
                          </Form.Group>
                        }
                      </Col>
                      <Col md={{ span: 4 }}>
                        <button
                          className={"change-btn ms-2 me-2"}
                          type="submit"
                        >Apply</button>
                      </Col>
                    </Row>
                  </Form>
                </div>)}
                {isFeatureAllowed(featureLabels.tracking_factory) && (<div className="card mt-4">
                  <Row>
                    <Col>
                      <div className="col-form-label">Tracking Simulation</div>
                    </Col>
                  </Row>
                  <Form onSubmit={this.handleFactorySimulation}>
                    <Row className='account gmapcard'>
                      <Col md={6}>
                        <label className="radio-label mb-2">
                          <input
                            type="radio"
                            value={factoryTracking.useCase1}
                            checked={this.state.selectedSimulation === factoryTracking.useCase1}
                            onChange={this.handleOptionChange}
                          />
                          <span className="ms-2">{factoryTracking.useCase1}</span>
                        </label>
                        <label className="radio-label">
                          <input
                            type="radio"
                            value={factoryTracking.useCase2}
                            checked={this.state.selectedSimulation === factoryTracking.useCase2}
                            onChange={this.handleOptionChange}
                          />
                          <span className="ms-2">{factoryTracking.useCase2}</span>
                        </label>
                      </Col>
                      <Col md={{ span: 4 }}>
                        <button
                          className={"change-btn ms-2 me-2"}
                          type="submit"
                        >Apply</button>
                      </Col>
                    </Row>
                  </Form>
                </div>)}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isNetworkAvailable: state.isNetworkAvailable ? state.isNetworkAvailable.isOnline : state.isNetworkAvailable,
    mapThemes: state.mapThemes ? state.mapThemes.data : state.mapThemes,
    secondaryMapView: state.secondMapPath ? state.secondMapPath : state.secondMapPath.secondMapPath
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLoginStatus: (val) => dispatch(fetchLoginStatus(val)),
    setSecondMapPath: (val) => dispatch(setSecondMapPath(val)),
    setStationID: (val) => dispatch(setStationID(val))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
