import React from "react";
import { BsChevronRight, BsChevronDown } from "react-icons/bs";
import RapidSOSLogo from '../assets/rapid.svg';
import { callDeclarations, featureLabels } from '../constants';
import RapidSOSLogo2 from '../assets/RapidSOS-logo.png';
import { isFeatureAllowed } from '../utils/groupFeatureUtils';
import { isEmpty} from '../utils/commonUtils';

class RapidSOSPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showUberList: false,
            showSiriusXMList: false,
            showVehicleInfo: false,
            showImpactInfo: false,
            showAirBagsInfo: false,
            showSeatsInfo: false,
            showContactInfo: false,
            showRapidSOSLocation: false
        }
    }

    handleUber = () => {
        this.setState({
            showUberList: !this.state.showUberList
        })
    }

    handleSiriusXM = () => {
        this.setState({
            showSiriusXMList: !this.state.showSiriusXMList
        })
    }

    handleVehicleInfo = () => {
        this.setState({
            showVehicleInfo: !this.state.showVehicleInfo
        })
    }

    handleImpactInfo = () => {
        this.setState({
            showImpactInfo: !this.state.showImpactInfo
        })
    }

    handleAirbags = () => {
        this.setState({
            showAirBagsInfo: !this.state.showAirBagsInfo
        })
    }

    handleSeats = () => {
        this.setState({
            showSeatsInfo: !this.state.showSeatsInfo
        })
    }

    handleContactInfo = () => {
        this.setState({
            showContactInfo: !this.state.showContactInfo
        })
    }

    handleRapidLocation = () => {
        this.setState({
            showRapidSOSLocation: !this.state.showRapidSOSLocation
        })
    }

    componentDidMount() {  
        if (this.props.rapidSOSInfo && 
            ((this.props.rapidSOSInfo.geometry && 
                this.props.rapidSOSInfo.geometry.coordinates && 
                this.props.rapidSOSInfo.geometry.coordinates.length) || 
                this.props.rapidSOSInfo.altitude || this.props.rapidSOSInfo.radius || 
                this.props.rapidSOSInfo.zuncer)) {
                    if (!this.state.showRapidSOSLocation) {
                        this.setState({
                            showRapidSOSLocation: true
                        });
                    }
        } 
    }

    componentDidUpdate(prevProps) {  
        if (this.props.rapidSOSInfo && prevProps.rapidSOSInfo !== this.props.rapidSOSInfo && 
            ((this.props.rapidSOSInfo.geometry && 
                this.props.rapidSOSInfo.geometry.coordinates && 
                this.props.rapidSOSInfo.geometry.coordinates.length) || 
                this.props.rapidSOSInfo.altitude || this.props.rapidSOSInfo.radius || 
                this.props.rapidSOSInfo.zuncer)) {
                    if (!this.state.showRapidSOSLocation) {
                        this.setState({
                            showRapidSOSLocation: true
                        });
                    }
        } else if (prevProps.rapidSOSInfo && prevProps.rapidSOSInfo !== this.props.rapidSOSInfo && 
            ((prevProps.rapidSOSInfo.geometry && 
                prevProps.rapidSOSInfo.geometry.coordinates && 
                prevProps.rapidSOSInfo.geometry.coordinates.length) || 
                prevProps.rapidSOSInfo.altitude || prevProps.rapidSOSInfo.radius || 
                prevProps.rapidSOSInfo.zuncer)) {
            this.handleRapidLocation();
        }
    }
 
    render() {
        return (
            <>
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${this.state.showRapidSOSLocation ? 'active' : ''}`}
                            onClick={this.handleRapidLocation}>
                            <span >RapidSOS Location <img className='rapisos-logo ml-1' src={RapidSOSLogo} /></span>
                            {this.state.showRapidSOSLocation ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                    </div>
                    {this.props.rapidSOSInfo && this.state.showRapidSOSLocation && ((this.props.rapidSOSInfo.geometry && this.props.rapidSOSInfo.geometry.coordinates && this.props.rapidSOSInfo.geometry.coordinates.length) || this.props.rapidSOSInfo.altitude || this.props.rapidSOSInfo.radius || this.props.rapidSOSInfo.zuncer) ?
                        <div className='border-bottom pb-3'>
                            <div className='address-details'>
                                {this.props.rapisSOSAddress && this.props.rapisSOSAddress.location ? <div className='mb-3'>
                                    <div className='label'>Alternate Location:</div>
                                    <div>{this.props.rapisSOSAddress.location.house_number} {this.props.rapisSOSAddress.location.street}</div>
                                    <div>{this.props.rapisSOSAddress.location.city}{isEmpty(this.props.rapisSOSAddress.location.city) && isEmpty(this.props.rapisSOSAddress.location.state_code || this.props.rapisSOSAddress.location.postal) ? ', ' : ''}
                                    {this.props.rapisSOSAddress.location.state_code}{isEmpty(this.props.rapisSOSAddress.location.state_code && this.props.rapisSOSAddress.location.postal) ? ', ' : ''} 
                                    {this.props.rapisSOSAddress.location.postal}</div>
                                </div> : ''}
                                <div className='d-flex align-items-center justify-content-between'>
                                    {this.props.rapidSOSInfo.geometry && this.props.rapidSOSInfo.geometry.coordinates && this.props.rapidSOSInfo.geometry.coordinates.length ?
                                        <span className='coordinates'>[{parseFloat(this.props.rapidSOSInfo.geometry.coordinates[this.props.rapidSOSInfo.geometry.coordinates.length - 1][1]).toFixed(6)}, {parseFloat(this.props.rapidSOSInfo.geometry.coordinates[this.props.rapidSOSInfo.geometry.coordinates.length - 1][0]).toFixed(6)}]</span>
                                        : ''}
                                    {this.props.rapidSOSInfo.radius ?
                                        <span className='radius'> UNC: {this.props.rapidSOSInfo.radius ? parseFloat(this.props.rapidSOSInfo.radius * callDeclarations.meterToFeet).toFixed() + ' ft': ""}</span> : ""}
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    {this.props.rapidSOSInfo.altitude && this.props.contourLineProperties && isFeatureAllowed(featureLabels.z_axis) ?
                                        <span className='radius'> Height: {parseFloat(this.props.rapidSOSInfo.altitude * callDeclarations.meterToFeet - this.props.contourLineProperties.feature.properties.topo_elev - (this.props.contourLineProperties.feature.properties.H ? this.props.contourLineProperties.feature.properties.H : 0)).toFixed() + ' ft'}</span>
                                        : <span className='radius'> Altitude: {(parseFloat(this.props.rapidSOSInfo.altitude * callDeclarations.meterToFeet).toFixed()) + ' ft'}</span>}
                                    {this.props.rapidSOSInfo.zuncer ?
                                        <span className='radius'> Zuncer: {this.props.rapidSOSInfo.zuncer ? parseFloat(this.props.rapidSOSInfo.zuncer * callDeclarations.meterToFeet).toFixed() + ' ft' : ""}</span> : ""}
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${this.state.showUberList ? 'active' : ''}`}
                            onClick={this.handleUber}>
                            <span >Uber <img className='rapisos-logo ml-1' src={RapidSOSLogo2} /></span>
                            {this.state.showUberList ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                    </div>
                    {this.props.rapidSOSInfo && this.props.rapidSOSInfo.uber && this.props.rapidSOSInfo.uber.panic_button && this.state.showUberList ?
                        <div className='border-bottom pb-3'>
                            <div className='address-details'>
                                <div><span className='label'>Driver Name:</span> {this.props.rapidSOSInfo.uber.panic_button.user_info ? this.props.rapidSOSInfo.uber.panic_button.user_info.user_name : ''}</div>
                                <div><span className='label'>Contact No:</span> {this.props.rapidSOSInfo.uber.panic_button.user_info ? this.props.rapidSOSInfo.uber.panic_button.user_info.user_phone : ''}</div>
                                <div><span className='label'>License Plate:</span> {this.props.rapidSOSInfo.uber.panic_button.trip_info ? this.props.rapidSOSInfo.uber.panic_button.trip_info.license_plate : ''}</div>
                                <div><span className='label'>License Plate State:</span> {this.props.rapidSOSInfo.uber.panic_button.trip_info ? this.props.rapidSOSInfo.uber.panic_button.trip_info.license_plate_state : ''}</div>
                                <div><span className='label'>Vehicle Maker:</span> {this.props.rapidSOSInfo.uber.panic_button.trip_info ? this.props.rapidSOSInfo.uber.panic_button.trip_info.vehicle_make : ''}</div>
                                <div><span className='label'>Vehicle Model:</span> {this.props.rapidSOSInfo.uber.panic_button.trip_info ? this.props.rapidSOSInfo.uber.panic_button.trip_info.vehicle_model : ''}</div>
                                <div><span className='label'>Vehicle Color:</span> {this.props.rapidSOSInfo.uber.panic_button.trip_info ? this.props.rapidSOSInfo.uber.panic_button.trip_info.vehicle_color : ''}</div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                <div className='history-list'>
                    <div className='title'>
                        <div className={`hand-cursor d-flex align-items-center justify-content-between ${this.state.showSiriusXMList ? 'active' : ''}`}
                            onClick={this.handleSiriusXM}>
                            <span >SiriusXM <img className='rapisos-logo ml-1' src={RapidSOSLogo2} /></span>
                            {this.state.showSiriusXMList ?
                                <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                :
                                <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                            }
                        </div>
                        {this.state.showSiriusXMList && this.props.rapidSOSInfo && this.props.rapidSOSInfo.sxm ?
                            <div className='border-top mt-3'>
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${this.state.showVehicleInfo ? 'active' : ''}`}
                                    onClick={this.handleVehicleInfo}>Vehicle Info
                                    {this.state.showVehicleInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>
                                {this.props.rapidSOSInfo && this.props.rapidSOSInfo.sxm && this.props.rapidSOSInfo.sxm.crash_data && this.state.showVehicleInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {this.props.rapidSOSInfo.sxm.crash_data.vehicle ?
                                                Object.keys(this.props.rapidSOSInfo.sxm.crash_data.vehicle).map(keyName => {
                                                    return (
                                                        <div key={keyName}><span className={`label ${(keyName === 'vin' || keyName === 'oem') ? 'text-uppercase' : 'text-capitalize'}`}>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {this.props.rapidSOSInfo.sxm.crash_data.vehicle[keyName]}</div>
                                                    )
                                                })

                                                : ''}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${this.state.showImpactInfo ? 'active' : ''}`}
                                    onClick={this.handleImpactInfo}>Impact
                                    {this.state.showImpactInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>
                                {this.props.rapidSOSInfo && this.props.rapidSOSInfo.sxm && this.props.rapidSOSInfo.sxm.crash_data && this.state.showImpactInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {this.props.rapidSOSInfo.sxm.crash_data.impacts ?
                                                Object.keys(this.props.rapidSOSInfo.sxm.crash_data.impacts).map(keyName => {
                                                    return (
                                                        <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {this.props.rapidSOSInfo.sxm.crash_data.impacts[keyName]}</div>
                                                    )
                                                })

                                                : ''}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${this.state.showAirBagsInfo ? 'active' : ''}`}
                                    onClick={this.handleAirbags}>Airbags
                                    {this.state.showAirBagsInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>
                                {this.props.rapidSOSInfo && this.props.rapidSOSInfo.sxm && this.props.rapidSOSInfo.sxm.crash_data && this.state.showAirBagsInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {this.props.rapidSOSInfo.sxm.crash_data.airbags.length && this.props.rapidSOSInfo.sxm.crash_data.airbags.map((list, ind) => {
                                                return (
                                                    <div key={ind} className='child-list'>
                                                        {Object.keys(list).map(keyName => {
                                                            return (
                                                                <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {list[keyName]}</div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${this.state.showSeatsInfo ? 'active' : ''}`}
                                    onClick={this.handleSeats}>Seats
                                    {this.state.showSeatsInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>

                                {this.props.rapidSOSInfo && this.props.rapidSOSInfo.sxm && this.props.rapidSOSInfo.sxm.crash_data && this.state.showSeatsInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {this.props.rapidSOSInfo.sxm.crash_data.seats.length && this.props.rapidSOSInfo.sxm.crash_data.seats.map((list, ind) => {
                                                return (
                                                    <div key={ind} className='child-list'>
                                                        {Object.keys(list).map(keyName => {
                                                            return (
                                                                <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {list[keyName]}</div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ''
                                }
                                <div className={`hand-cursor d-flex align-items-center justify-content-between rapid-list ${this.state.showContactInfo ? 'active' : ''}`}
                                    onClick={this.handleContactInfo}>Contacts
                                    {this.state.showContactInfo ?
                                        <BsChevronDown className='hand-cursor'>down</BsChevronDown>
                                        :
                                        <BsChevronRight className='hand-cursor'>right</BsChevronRight>
                                    }
                                </div>

                                {this.props.rapidSOSInfo && this.props.rapidSOSInfo.sxm && this.props.rapidSOSInfo.sxm.crash_data && this.state.showContactInfo ?
                                    <div className='pb-3 ml-3'>
                                        <div className='address-details p-0'>
                                            {this.props.rapidSOSInfo.sxm.crash_data.contacts.length && this.props.rapidSOSInfo.sxm.crash_data.contacts.map((list, ind) => {
                                                return (
                                                    <div key={ind} className='child-list'>
                                                        {Object.keys(list).map(keyName => {
                                                            return (
                                                                <div key={keyName}><span className='label text-capitalize'>{keyName.includes('_') ? keyName.replaceAll('_', ' ') : keyName}:</span> {list[keyName]}</div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                            : ''
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default RapidSOSPanel;
