import {
    FETCH_SOLACOM_CALL_EVENTS
} from '../actions/actionTypes';

const initialState = {
}

export const SolacomReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SOLACOM_CALL_EVENTS:
            return { queuedCalls: action.queuedCalls, unplottedCalls: action.unplottedCalls, answeredCalls: action.answeredCalls}
        default:
            return state;
    }
}

// const initialState = {
//     data: {
//         queued: [],
//         inProgress: null
//     }
// }

// export const SolacomReducer = (state = initialState, action) => {    
//     switch (action.type) {
//         case FETCH_SOLACOM_CALL_EVENTS:
//             let collArr = state.data.queued && state.data.queued.length > 0 && action.data.callIdentifier ? state.data.queued : [];
//             if (action.data.callIdentifier && action.data.callState !== 'inProgress') {
//                 if (state.data.queued.length === 0) {
//                     collArr.push(action.data)
//                 } else if (state.data.queued.find(ds => ds.callIdentifier === action.data.callIdentifier)) {
//                     collArr = collArr.map(ds => ds.callIdentifier === action.data.callIdentifier ? action.data : ds)
//                 } else {
//                     collArr.push(action.data)
//                 }
//             } else if (action.data.callIdentifier && action.data.callState === 'inProgress') {
//                 collArr = collArr.filter(ds => ds.callIdentifier !== action.data.callIdentifier)
//             }
//             return {
//                 ...state, 
//                 data: {
//                     queued: collArr,
//                     inProgress: action.data.callState === 'inProgress' ? action.data : null
//                 }
//             }
//         default:
//             return state;
//     }
// }