import { FETCH_INCIDENTS_HISTORY_REQUEST, FETCH_INCIDENTS_HISTORY_SUCCESS, FETCH_INCIDENTS_HISTORY_FAIL,
    FETCH_HISTORY_DATES, FETCH_RENDER_HISTORY, FETCH_HISTORY_INTERVALs, FETCH_HISTORY_SLIDER_VALUE} from '../actions/actionTypes';


const initialState = {
    // incidentsHistory: [],
    error: '',
    isLoading: false,
    lastFetchSuccess: false
}

export const HistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_INCIDENTS_HISTORY_REQUEST:
            return { ...state, isLoading: false ,error: ''}
        case FETCH_INCIDENTS_HISTORY_SUCCESS:
            return { incidentsHistory: action.data, error: '', isLoading: true, lastFetchSuccess: true}
        case FETCH_INCIDENTS_HISTORY_FAIL:
            return { incidentsHistory: [], error: action.data, isLoading: false }
        default:
            return state;
    }
}

const dateObj = {}

export const DatesReducer = (state = dateObj, action) => {
    switch (action.type) {
        case FETCH_HISTORY_DATES:
            return {
                ...state,
                dates: action.data
             }
        default:
            return state;
    }
}

const render = '';

export const HistoryRenderReducer = (state = render, action) => {
    switch (action.type) {
        case FETCH_RENDER_HISTORY:
            return {
                ...state,
                render: action.data
             }
        default:
            return state;
    }
}

const intervals = {};
export const HistoryIntervalReducer = (state = intervals, action) => {
    switch (action.type) {
        case FETCH_HISTORY_INTERVALs:
            return {
                ...state,
                intervals: action.data
             }
        default:
            return state;
    }
}

const sliderVal = '';

export const HistorySliderReducer = (state = sliderVal, action) => {
    switch (action.type) {
        case FETCH_HISTORY_SLIDER_VALUE:
            return {
                ...state,
                sliderVal: action.data
             }
        default:
            return state;
    }
}
