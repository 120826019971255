import { IDENTITY_SEARCH_REQUEST, IDENTITY_SEARCH_SUCCESS, IDENTITY_SEARCH_FAIL, CRIMINAL_SEARCH_REQUEST, CRIMINAL_SEARCH_SUCCESS,
    CRIMINAL_SEARCH_FAIL} from '../actions/actionTypes';


const initialState = {
    error: '',
    isLoading: false,
}

export const IdentityReducer = (state = initialState, action) => {
    switch (action.type) {
        case IDENTITY_SEARCH_REQUEST:
            return { ...state, isLoading: false ,error: ''}
        case IDENTITY_SEARCH_SUCCESS:
            return { identity: action.data, error: '', isLoading: true, }
        case IDENTITY_SEARCH_FAIL:
            return { identity: [], error: action.data, isLoading: false }
        default:
            return state;
    }
}

const criminalState = {
    error: '',
    isLoading: false,
}

export const CriminalDataReducer = (state = criminalState, action) => {
    switch (action.type) {
        case CRIMINAL_SEARCH_REQUEST:
            return { ...state, isLoading: false ,error: ''}
        case CRIMINAL_SEARCH_SUCCESS:
            return { criminalData: action.data, error: '', isLoading: true, }
        case CRIMINAL_SEARCH_FAIL:
            return { criminalData: [], error: action.data, isLoading: false }
        default:
            return state;
    }
}