import {
    FETCH_INCIDENTS_HISTORY_REQUEST, FETCH_INCIDENTS_HISTORY_SUCCESS, FETCH_INCIDENTS_HISTORY_FAIL,
    FETCH_FEATURE_COLLECTION, FETCH_HISTORY_DATES, FETCH_RENDER_HISTORY, FETCH_HISTORY_INTERVALs, FETCH_HISTORY_SLIDER_VALUE
} from '../actions/actionTypes';
import axios from 'axios';
import { serverconfigs, configTimers, cancelErrorMessages } from '../../constants';
import moment from "moment";
let cancelToken; 

const fetchIncidentHistoryRequest = () => {
    return {
        type: FETCH_INCIDENTS_HISTORY_REQUEST
    }
}

const fetchIncidentHistorySuccess = (data) => {
    return {
        type: FETCH_INCIDENTS_HISTORY_SUCCESS,
        data: data,
    }
}

const fetchIncidentHistoryFail = (error) => {
    return {
        type: FETCH_INCIDENTS_HISTORY_FAIL,
        data: error
    }
}

const formatData = (results) => {
    let timeStamp, time, seconds;
    results.map(list => {
        list.properties = {}
        timeStamp = list.connectTime.split(' ');
        time = timeStamp[1].split(':');
        seconds = (+time[0]) * 60 * 60 + (+time[1]) * 60 + (+time[2]);
        list.Date = timeStamp[0];
        list.TimeSec = parseFloat(seconds);
        list.properties.TimeSec = parseFloat(seconds);
        list.properties.Date = timeStamp[0];
        list.properties.ani = list.ani;
        list.properties.connectTime = list.connectTime;
        list.connectTimeStamp = getUtcTimeStamp(list.connectTime) * 1000;
    })

    return results;
}

export const getIncidentsHistory = (startTs, endTs) => {
    return function (dispatch) {
        dispatch(fetchIncidentHistoryRequest());
        dispatch(fetchIncidentHistorySuccess([]));
        if(!navigator.onLine){
            dispatch(fetchIncidentHistoryFail('No Internet Connection'));
        }
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
          }
        cancelToken = axios.CancelToken.source();
        axios.get(serverconfigs.saBaseUrl + '&start_ts=' + startTs + '&end_ts=' + endTs, {cancelToken: cancelToken.token ,timeout: configTimers.historyCallsTimeOut})
        .then(response => {
                let historyData;
                if (response.status == 200) {
                    let data = {
                        "type": "FeatureCollection",
                        "name": "Calls",
                        "features": response.data.results
                    }
                    let paginationToken = response.data.nextToken;
                    if (response.data.results.length > 0) {
                        data.features = formatData(response.data.results);
                    }
                    if (paginationToken) {
                        function callAPI(nextToken) {
                            if (nextToken) {
                                axios.get(serverconfigs.saBaseUrl + '&next_token=' + nextToken, {cancelToken: cancelToken.token ,timeout: configTimers.historyCallsTimeOut})
                                    .then(res => {
                                        if (res.status == 200) {
                                            let paginationToken = res.data.nextToken;
                                            if (res.data.results.length > 0) {
                                                historyData = data.features.concat(formatData(res.data.results));
                                                data.features = historyData;
                                            }
                                            callAPI(paginationToken);
                                        }
                                    })
                                    .catch(error => {
                                        if(error.code ==  configTimers.cancelStatusCode){
                                            dispatch(fetchIncidentHistoryFail(cancelErrorMessages.AnalyticsTimeOutMsg))
                                        }
                                        else if(error.response){
                                            dispatch(fetchIncidentHistoryFail(error.response.data.message));
                                        }
                                        dispatch(fetchRenderHistory(3));
                                    })
                            }
                            else {
                                if (data.features.length) {
                                    dispatch(fetchIncidentHistorySuccess(data));
                                    dispatch(fetchRenderHistory(1));
                                } else {
                                    dispatch(fetchIncidentHistoryFail('No data found'));
                                    dispatch(fetchRenderHistory(4));
                                }
                            }
                        }
                        callAPI(paginationToken);
                    }
                    else {
                        if (data.features.length) {
                            dispatch(fetchIncidentHistorySuccess(data));
                            dispatch(fetchRenderHistory(1));
                        } else {
                            dispatch(fetchIncidentHistoryFail('No data found'));
                            dispatch(fetchRenderHistory(4));
                        }
                    }
                }
            })
            .catch(error => {
                if(error.code ==  configTimers.cancelStatusCode){
                    dispatch(fetchIncidentHistoryFail(cancelErrorMessages.AnalyticsTimeOutMsg))
                }
                else if(error.response){
                    dispatch(fetchIncidentHistoryFail(error.response.data.message));
                }
                dispatch(fetchRenderHistory(3));
            })
        // }
    }
}

const getUtcTimeStamp = (dateVal) => {
    return moment.utc(dateVal, 'YYYY-MM-DD hh:mm:ss').unix();
}

export const fetchHistoryDates = (obj) => {
    return {
        type: FETCH_HISTORY_DATES,
        data: obj
    }
}

export const fetchRenderHistory = (val) => {
    return {
        type: FETCH_RENDER_HISTORY,
        data: val
    }
}

export const fetchHistoryIntervals = (obj) => {
    return {
        type: FETCH_HISTORY_INTERVALs,
        data: obj
    }
}

export const fetchHistorySliderValue = (val) => {
    return {
        type: FETCH_HISTORY_SLIDER_VALUE,
        data: val
    }
}