import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BsX } from "react-icons/bs";
import '../../styles/modal.scss';
import '../../styles/drawPolygon.scss';

export default class DrawPolygon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Modal className='draw-polygon-dialog' backdrop={false} show={this.props.show} onHide={this.props.hide}>
                    <BsX className='close-panel hand-cursor' onClick={this.props.hide}>close</BsX>
                    <Modal.Body>
                        <h3>Drawing Polygon</h3>
                        <div>Click on the map to start a path.</div>
                        <div> Double-click or draw end point on the start point to close the path.</div>
                        <div>ESC key or click trash can to drop drawing tool.</div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}
