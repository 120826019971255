import { FETCH_INCIDENTS_REQUEST, FETCH_INCIDENTS_SUCCESS, FETCH_INCIDENTS_FAIL, FETCH_NENA, FETCH_UBER, 
    FETCH_ADDINFO, SELECT_INCIDENT, FETCH_MAP_LOADED, FETCH_MAP_BOUND, SET_PAUSE_ZOOM, SET_SEARCH_PAUSE_ZOOM, SET_INCIDENT_DEPENDENCIES,
    SET_AUTO_ZOOM, FETCH_REGION_NAME, SET_PEERS_DATA, SET_PEERS_API_RESPONSE, SET_FILTERED_PEERS, SET_SEOND_MAP_PATH,
    SET_STATION_ID, TOGGLE_STATION_ID } from '../actions/actionTypes';

const initialSate = {
    // incidents: [],
    error: '',
    isLoading: false
}

export const incidentReducer = (state = initialSate, action) => {
    switch (action.type) {
        case FETCH_INCIDENTS_REQUEST:
            return { ...state, isLoading: true }
        case FETCH_INCIDENTS_SUCCESS:
            return { incidents: action.data, error: '', isLoading: false }
        case FETCH_INCIDENTS_FAIL:
            return { incidents: [], error: action.data, isLoading: false }
        default:
            return state;
    }
}

const NENA = '';

export const NENAReducer = (state = NENA, action) => {
    switch (action.type) {
        case FETCH_NENA:
            return {
                ...state,
                NENA: action.payload
             }
        default:
            return state;
    }
}

const addInfo = false;

export const AddInfoReducer = (state = addInfo, action) => {
    switch (action.type) {
        case FETCH_ADDINFO:
            return {
                ...state,
                addInfo: action.payload
             }
        default:
            return state;
    }
}

const uber = false;

export const UberReducer = (state = uber, action) => {
    switch (action.type) {
        case FETCH_UBER:
            return {
                ...state,
                uber: action.payload
             }
        default:
            return state;
    }
}

const incidentData = {};

export const SelectIncidentReducer = (state = incidentData, action) => {
    switch (action.type) {
        case SELECT_INCIDENT:
            return {
                ...state,
                incidentData: action.data
             }
        default:
            return state;
    }
}

const mapLoaded = '';

export const MapLoadedReducer = (state = mapLoaded, action) => {
    switch (action.type) {
        case FETCH_MAP_LOADED:
            return {
                ...state,
                mapLoaded: action.data
             }
        default:
            return state;
    }
}

const mapBound = true;

export const MapBoundReducer = (state = mapBound, action) => {
    switch (action.type) {
        case FETCH_MAP_BOUND:
            return {
                ...state,
                mapBound: action.data
             }
        default:
            return state;
    }
}

const isPauseZoom = false;

export const PauseZoomReducer = (state = isPauseZoom, action) => {
    switch(action.type){
        case SET_PAUSE_ZOOM:
            return {
                ...state,
                isPauseZoom: action.data
            }
        default: 
        return state;
    }
}

const isSearchZoom = false;

export const SearchZoomReducer = (state = isSearchZoom, action) => {
    switch(action.type){
        case SET_SEARCH_PAUSE_ZOOM:
            return {
                ...state,
                isSearchZoom: action.data
            }
        default: 
        return state;
    }
}

const dependencies = {};
export const IncidentDependenciesReducer = (state = dependencies, action) => {
    switch (action.type) {
        case SET_INCIDENT_DEPENDENCIES:
            return {
                ...state,
                dependencies: action.data
             }
        default:
            return state;
    }
}

const incidentAutoZoom = {incidentAutoZoom: false};
export const IncidentAutoZoomdReducer = (state = incidentAutoZoom, action) => {
    switch (action.type) {
        case SET_AUTO_ZOOM:
            return {
                ...state,
                incidentAutoZoom: action.data
             }
        default:
            return state;
    }
}

const regionName = "";
export const RegionNameReducer = (state = regionName, action) => {
    switch (action.type) {
        case FETCH_REGION_NAME:
            return {
                ...state,
                regionName: action.data
            }
        default:
            return state;
    }
}
const peersData = [];
export const PeersDataReducer = (state = peersData, action) => {
    switch (action.type){
        case SET_PEERS_DATA:
            return {...state, peersData:action.data}
        default:
            return state;
    }
}

const peersApiResp = [];
export const PeersApiRespReducer = (state = peersApiResp, action) => {
    switch(action.type){
        case SET_PEERS_API_RESPONSE:
            return{
                ...state,
                peersApiResp: action.data
            }
        default:
            return state;
    }
}

const filteredPeers = [];
export const FilteredPeersRespReducer = (state = filteredPeers, action) => {
    switch(action.type){
        case SET_FILTERED_PEERS:
            return{
                ...state,
                filteredPeers: action.data
            }
        default:
            return state;
    }
}

const secondMapPath = '';
export const SecondMapPathReducer = (state = secondMapPath, action) => {
    switch(action.type) {
        case SET_SEOND_MAP_PATH:
            return {
                ...state,
                secondMapPath: action.data
            }
    default:
        return state;
    }
}

const stationIDState = {
    stationID: '',
    toggleNotifications: false
};
export const StationIDReducer = (state = stationIDState, action) => {
    switch(action.type) {
        case SET_STATION_ID:
            return {
                ...state,
                stationID: action.data,
                toggleNotifications: true
            }
        case TOGGLE_STATION_ID:
            return {
                ...state,
                toggleNotifications: false
            }
    default:
        return state;
    }
}
