// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.moreinfo-panel {
  position: fixed;
  left: 318px;
  top: 63px;
  width: 230px;
  border: 3px solid #f99403;
  background-color: #e6eef4;
  transition: left 0.3s ease-in-out;
  z-index: 3;
  padding: 10px 10px 30px 10px;
  font-size: 12px;
  line-height: 24px;
  display: none;
}
.moreinfo-panel.open-panel {
  display: block;
}
.moreinfo-panel .close-panel {
  font-size: 14px;
}
.moreinfo-panel .info-list {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.moreinfo-panel .info-list:last-child {
  border-bottom: none;
}
.moreinfo-panel .info-list .text {
  padding-left: 14px;
}
.moreinfo-panel .info-list h4 {
  font-size: 13px;
  padding-left: 5px;
}
.moreinfo-panel .info-list svg {
  font-size: 9px;
}
.moreinfo-panel .info-list svg path {
  stroke-width: 1;
}
.moreinfo-panel .uber-info .uber-icon {
  width: 35px;
  margin-left: -4px;
}
.moreinfo-panel .uber-info h6 {
  margin: 5px 0 0 0;
  font-size: 13px;
}
.moreinfo-panel .uber-info .list {
  padding: 5px 0;
}
.moreinfo-panel .uber-info .list .text {
  padding-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/moreInfo.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,SAAA;EACA,YAAA;EACA,yBAAA;EACA,yBAAA;EACA,iCAAA;EACA,UAAA;EACA,4BAAA;EACA,eAAA;EACA,iBAAA;EAEA,aAAA;AAAJ;AACI;EACI,cAAA;AACR;AACI;EACI,eAAA;AACR;AACI;EACI,6BAAA;EACA,eAAA;AACR;AAAQ;EACI,mBAAA;AAEZ;AAAQ;EACI,kBAAA;AAEZ;AAAQ;EACI,eAAA;EACA,iBAAA;AAEZ;AAAQ;EACI,cAAA;AAEZ;AADY;EACI,eAAA;AAGhB;AAEQ;EACI,WAAA;EACA,iBAAA;AAAZ;AAEQ;EACI,iBAAA;EACA,eAAA;AAAZ;AAEQ;EACI,cAAA;AAAZ;AACY;EACI,eAAA;AAChB","sourcesContent":[".moreinfo-panel{\n    position: fixed;\n    left: 318px;\n    top: 63px;\n    width: 230px;\n    border: 3px solid #f99403;\n    background-color: #e6eef4;\n    transition: left .3s ease-in-out;\n    z-index: 3;\n    padding: 10px 10px 30px 10px;\n    font-size: 12px;\n    line-height: 24px;\n    // box-shadow: 0 0 4px rgba(0,0,0,0.8);\n    display: none;\n    &.open-panel{\n        display: block;\n    }\n    .close-panel{\n        font-size: 14px;\n    }\n    .info-list{\n        border-bottom: 1px solid #ccc;\n        padding: 10px 0;\n        &:last-child{\n            border-bottom: none;\n        }\n        .text{\n            padding-left:  14px;\n        }\n        h4{\n            font-size: 13px;\n            padding-left: 5px;\n        }\n        svg{\n            font-size: 9px;\n            path{\n                stroke-width: 1;\n            }\n        }\n    }\n    .uber-info{\n        .uber-icon{\n            width: 35px;\n            margin-left: -4px;\n        }\n        h6{\n            margin: 5px 0 0 0;\n            font-size: 13px;\n        }\n        .list{\n            padding: 5px 0;\n            .text{\n                padding-left: 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
