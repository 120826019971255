import React from "react";
import { createBrowserHistory } from "history";
import { groupFeatures } from "../constants";

class Logo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const history = createBrowserHistory();
    return (<div className="logo px-3">
      {sessionStorage.getItem('currentUser') !== null && sessionStorage.getItem('groupName') !== null  ?
        <>
          <div className='logo-text'><span className='upper-text'>{sessionStorage.getItem('groupName').slice(0,1)}</span>{sessionStorage.getItem('groupName').slice(1,sessionStorage.getItem('groupName').length)} {groupFeatures && groupFeatures.gis_id && groupFeatures.gis_id !== '' ? ',' : ''}</div>
          {groupFeatures && groupFeatures.gis_id && groupFeatures.gis_id !== '' ? 
          <div className='logo-text'><span className='upper-text'>{groupFeatures.gis_id.substring(0,2)}</span></div>
          : ''}
        </>
        :
        ''
      }
    </div>
    );
  }
}

export default Logo;
