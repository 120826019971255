import React from "react";
import withRouter from "../withRouter";
import {getStationIdFromLocalStorage} from "../utils/commonUtils"

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: false,
      showFooter: false
    };

  }

  renderer() {
    return (
      <footer id='footer' className='footer footer-fix'>
        <div className={`navbar-header position-relative ${sessionStorage.getItem('currentUser') !== null && getStationIdFromLocalStorage() ? 'dashboard-footer' : 'login-footer container'}`}>
          <div className="d-flex">
              <div className={`${sessionStorage.getItem('currentUser') !== null && getStationIdFromLocalStorage() ? 'footer-copyright' : ''}`}>
                @ 2023 Comtech Telecommunications Corp. All Rights Reserved.
                  </div>
              <div className="footer-links">
                <span className="link">
                  <a
                    href="http://www.comtechtel.com/privacy-policies"
                    target="_blank"
                  >
                    Privacy Policy
                      </a>
                </span>
                <span className="link">
                  <a
                    href="http://www.comtechtel.com/terms-use"
                    target="_blank"
                  >
                    Terms of Use
                      </a>
                </span>
                <span className="last-link">
                  <a
                    href="https://www.comtechlocation.com/contact-us"
                    target="_blank"
                  >
                    Contact Us
                      </a>
                </span>
              </div>
          </div>
        </div>
      </footer>
    )
  }
  render() {
    return (
      this.renderer()
    );
  }
}
export default withRouter(Footer);
