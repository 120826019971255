import {
    FETCH_INCIDENTS_REQUEST, FETCH_INCIDENTS_SUCCESS, FETCH_INCIDENTS_FAIL, SELECT_INCIDENT, FETCH_NENA, FETCH_UBER, FETCH_ADDINFO,
    FETCH_MAP_LOADED, FETCH_MAP_BOUND, SET_PAUSE_ZOOM, SET_SEARCH_PAUSE_ZOOM, SET_INCIDENT_DEPENDENCIES, SET_AUTO_ZOOM, SET_PEERS_DATA,
    SET_PEERS_API_RESPONSE, FETCH_PEERS_INCIDENTS_FAIL, SET_FILTERED_PEERS, FETCH_REGION_NAME, SET_SEOND_MAP_PATH, SET_STATION_ID, TOGGLE_STATION_ID
} from './actionTypes';
import axios from 'axios';
import {primaryRegion, secondaryRegion} from "../../awsexport-client";
import { serverconfigs, maxPositionUpdateCounter, incidentConfigs, groupFeatures, configTimers, cancelErrorMessages, pingConstants, displayLogs } from '../../constants';

import {getEndCallTimeFromLocalStorage, consoleLog } from "../../utils/commonUtils";

const fetchIncidentRequest = () => {
    return {
        type: FETCH_INCIDENTS_REQUEST
    }
}

const fetchIncidentSuccess = (incidents) => {
    return {
        type: FETCH_INCIDENTS_SUCCESS,
        data: incidents
    }
}

const fetchIncidentFail = (error) => {
    return {
        type: FETCH_INCIDENTS_FAIL,
        data: error
    }
}

var counter = 0, updateMapCounter = 0, incidents = [], filterIncidents = [], mapLoaded = false, OldIncidents = [],  updatePeerCounter = 0,
 cancelledCallCounter = 0,  cancelledPeerCallCounter = 0, peersIncidents = [], OldPeersIncidents = [], filterPeersUsers = [], filteredPeers = [];
var endCallsArray = [];
var apiFailureCounter = 0;
var secondMapPath = '';
function areIncidentsChanged(newIncidents, previousIncidents, status) {
    let newIncidentIds = [], previousIncidentIds = [];

    newIncidents.forEach(newIncident => {
        if (newIncident.callState == status) {
            newIncidentIds.push(newIncident.nenaIncidentId);
        }
    })

    previousIncidents.forEach(previousIncident => {
        if (previousIncident.callState == status) {
            previousIncidentIds.push(previousIncident.nenaIncidentId);
        }
    })

    if (newIncidentIds.length !== previousIncidentIds.length) {
        return true
    }
    let intersectionArray = previousIncidentIds.filter(x => newIncidentIds.includes(x));
    var isNenaIdChanged = !(intersectionArray.length == newIncidentIds.length && intersectionArray.length == previousIncidentIds.length);
    if(isNenaIdChanged){
       return true 
    }
    else{
        // checking for location update
        for(var i=0; i<newIncidents.length; i++){
            let newIncident = newIncidents[i];
            if (newIncident.callState === status) {                
                var myIndex = previousIncidents.findIndex(p => p.nenaIncidentId === newIncident.nenaIncidentId);
                if(myIndex === -1){
                    return true
                }
                if(myIndex >= 0 && isPositionUpdated( previousIncidents[myIndex], newIncident)){
                    return true;
                }
            }
        }
    }
    return false;
}

function isRapidSOSDataAvailable(place) {
    if (place.rapidSOS && place.rapidSOS.geometry && place.rapidSOS.geometry.coordinates && place.rapidSOS.geometry.coordinates.length) {
        return true;
    } else {
        return false;
    }
}
function isGeometryAvailable(place) {
    if (place && place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
        return true;
    } else {
        return false;
    }
}

function isPositionUpdated(prevIncident, newIncident){
    if(isRapidSOSDataAvailable(newIncident) && isRapidSOSDataAvailable(prevIncident)){
        if(newIncident.rapidSOS.geometry.coordinates.length !== prevIncident.rapidSOS.geometry.coordinates.length){
             return true;
        }
    }
    else if(isRapidSOSDataAvailable(prevIncident) && !isRapidSOSDataAvailable(newIncident)){
        return true;
    }
    else if(!isRapidSOSDataAvailable(prevIncident) && isRapidSOSDataAvailable(newIncident)){
        return true;
    }
    if(isGeometryAvailable(newIncident) && isGeometryAvailable(prevIncident)){
        if(newIncident.geometry.coordinates.length !== prevIncident.geometry.coordinates.length){
            return true;
        }
    }
    else if(isGeometryAvailable(prevIncident) && !isGeometryAvailable(newIncident)){
        return true;
    }
    else if(!isGeometryAvailable(prevIncident) && isGeometryAvailable(newIncident)){
        return true;
    };
    return false;
}

function arePeersIncidentsChanged(newIncidents, previousIncidents, status) {
    let newIncidentIds = [], previousIncidentIds = [];

    newIncidents.forEach(newIncident => {
        newIncident.results.forEach(peersData => {
            if (peersData.callState == status) {
                newIncidentIds.push(peersData.nenaIncidentId);
            }
        })
    })

    previousIncidents.forEach(previousIncident => {
        previousIncident.results.forEach(peersData => {
            if (peersData.callState == status) {
                previousIncidentIds.push(peersData.nenaIncidentId);
            }
        })
    })

    if (newIncidentIds.length !== previousIncidentIds.length) {
        return true
    }
    let intersectionArray = previousIncidentIds.filter(x => newIncidentIds.includes(x));
    var isNenaIdChanged = !(intersectionArray.length == newIncidentIds.length && intersectionArray.length == previousIncidentIds.length);
    if(isNenaIdChanged){
       return true 
    }
    else{
        // checking for location update
        for(var i=0; i<newIncidents.length; i++){
            for(var j =0; j<newIncidents[i].results.length; j++){
                for(var k = 0; k<previousIncidents.length; k++){
                    let newIncident = newIncidents[i].results[j];
                    let prevPeer = previousIncidents[k].results;
                    if (newIncidents[i].results[j].callState === status) {                
                        var myIndex = prevPeer.findIndex(p => p.nenaIncidentId === newIncident.nenaIncidentId);
                        if(myIndex === -1){
                            return true;
                        }
                        if(myIndex >= 0 && isPositionUpdated( prevPeer[myIndex], newIncident)){
                            return true;
                        }
                    }
                }
            }
        }
    }
    return false;
}


function swapCoordinates(arr) {
    let geometry = [];
    if (arr) {        
        if(arr.length > 0 ){
            if(arr[0] instanceof Array){
                arr.forEach(coordinate => {
                    if(coordinate && coordinate.length > 1){
                        geometry.push([coordinate[1], coordinate[0]]);
                    }
                });    
            } else {
                geometry.push([arr[1], arr[0]]);
            }
        }
    }
    return geometry;
}

export const getPeerIncidents = (peersList) => {
    return function (dispatch) {

      let peerString = '';
      let groupName = sessionStorage.getItem('groupName');
      for(let i=0; i<peersList.length; i++){
          if(peerString === ''){
            peerString += peersList[i];
          } else {
              peerString += ','+peersList[i];
          }
      }
      const peerServerURL = `${serverconfigs.peersBaseUrl}&peers=${peerString}`;

      const waitTime = configTimers.getIncidentsReqTime;
      let cancelToken;
      cancelToken = axios.CancelToken.source();
      let incidentTimeout = setTimeout(() => {
        cancelledPeerCallCounter++;
        if(cancelledPeerCallCounter === maxPositionUpdateCounter){
            dispatch(fetchIncidentFail(cancelErrorMessages.incidentTimeOutMsg))
            cancelledPeerCallCounter = 0;
            clearTimeout(incidentTimeout);
        }
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
          }
    },waitTime);
    try {
        const result = axios({
            url: peerServerURL,
            cancelToken: cancelToken.token
        });
        result.then(response => {
            cancelledPeerCallCounter = 0;
            clearTimeout(incidentTimeout);
            updatePeerCounter++;
            var activeCallsChanged = arePeersIncidentsChanged(response.data.results, peersIncidents, incidentConfigs.activeStatus);
            var queuedCallsChanged = arePeersIncidentsChanged(response.data.results, peersIncidents, incidentConfigs.waitingStaus);
            if (updatePeerCounter === maxPositionUpdateCounter || peersIncidents.length == 0 || activeCallsChanged || queuedCallsChanged) {
                    OldPeersIncidents = peersIncidents;
                    peersIncidents = response.data.results;
                    let activeUsers = [], waitUsers = [], endUsers = [];
                    updatePeerCounter = 0;
                    peersIncidents.map(peersList => {
                        if(peersList && peersList.results && peersList.results.length){
                            peersList.results.map(item => {
                                item.show = false;
                                item.id = Math.random();
                                item.radius = item.radius >=0 ? item.radius : 0;
                                item.geometry.coordinates = swapCoordinates(item.geometry.coordinates);
                                if (item.rapidSOS && item.rapidSOS.geometry && item.rapidSOS.geometry.coordinates && item.rapidSOS.geometry.coordinates.length) {
                                    item.rapidSOS.geometry.coordinates = swapCoordinates(item.rapidSOS.geometry.coordinates);
                                }
                                // ES-1905 - Added the below logic to handle the Queued call time format. For Queued calls the connect time is not appending the 'Z',
                                // which is causing problem when converting to local time. So appends 'Z' if it is not their. 
                                if(item.connectTime.trim().slice(-1) !== 'Z'){
                                    item.connectTime = item.connectTime.trim() + 'Z'
                                }
                            })
                            endUsers = filterPeersEndCalls(OldPeersIncidents, peersList);

                            peersList.results.filter(peerResult => {
                                if(peerResult.callState === incidentConfigs.activeStatus){
                                    activeUsers.push(peerResult);
                                }
                            });

                            peersList.results.filter(peerResult => {
                                if(peerResult.callState === incidentConfigs.waitingStaus){
                                    waitUsers.push(peerResult);
                                }
                            });
                        } else
                            endUsers = filterPeersEndCalls(OldPeersIncidents, peersList);

                        filterPeersUsers = [...activeUsers, ...waitUsers, ...endUsers];
                        peersList.results = filterPeersUsers;
                        activeUsers = [];
                        waitUsers = [];
                        endUsers = [];
                    });

                    if(filterIncidents && filterIncidents.length && peersIncidents.length) {
                        filterIncidents.map(incidentElement => {
                            peersIncidents.map(peerData => {
                                if(peerData.results && peerData.results.length ) {
                                    filteredPeers = peerData.results.filter(peersElement => {
                                        return incidentElement.nenaIncidentId !== peersElement.nenaIncidentId
                                    });
                                    peerData.results = filteredPeers;
                                }
                                filteredPeers = [];
                            });
                        })
                    }
                    dispatch(setPeersApiResp(peersIncidents));
                    dispatch(fetchMapBound(true));
                }
        })
        .catch(error => {
          if(error.response){
              dispatch(fetchPeersIncidentFail(error.response.data.message));
              cancelledPeerCallCounter = 0;
              clearTimeout(incidentTimeout);
          }
      })
    } catch(error) {
      clearTimeout(incidentTimeout);
    }
  }
}

export const getIncidents = () => {
    return function (dispatch) {
        // dispatch(fetchIncidentRequest());
        const waitTime = configTimers.getIncidentsReqTime;
        var endCallTimer = getEndCallTimeFromLocalStorage();
        let cancelToken; 
        cancelToken = axios.CancelToken.source();
        let incidentTimeout = setTimeout(() => {
            cancelledCallCounter++;
            apiFailureCounter++
            if(cancelledCallCounter === maxPositionUpdateCounter){
                dispatch(fetchIncidentFail(cancelErrorMessages.incidentTimeOutMsg))
                cancelledCallCounter = 0;
                clearTimeout(incidentTimeout);
            }
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
            }
        },waitTime);
        try {
            const result = axios({
                url: serverconfigs.saBaseUrl,
                cancelToken: cancelToken.token 
            });
            result.then(response => {
                if(response.headers['region']) {
                    consoleLog(":::::::::::: current Region :::: " + localStorage.getItem('currentRegion'))
                    consoleLog(":::::::::::: getIncidents Server header region :::: " + response.headers['region'])
                    dispatch(setRegionName(response.headers['region']));
                }
                apiFailureCounter = 0;
                cancelledCallCounter = 0;
                clearTimeout(incidentTimeout);
                updateMapCounter++;
                // if (mapLoaded) {
                    var activeCallsChanged  = areIncidentsChanged(response.data.results, incidents, incidentConfigs.activeStatus);
                    var queuedCallsChanged  = areIncidentsChanged(response.data.results, incidents, incidentConfigs.waitingStaus);
                   if (updateMapCounter === maxPositionUpdateCounter || incidents.length == 0 || activeCallsChanged || queuedCallsChanged) {
                    OldIncidents = incidents;
                    incidents = response.data.results;
                    let activeUsers = [], waitUsers = [];
                    updateMapCounter = 0;
                    counter++;
                    incidents.map(item => {
                        item.show = false;
                        item.counter = counter;
                        item.radius = item.radius >=0 ? item.radius : 0;
                        item.geometry.coordinates = swapCoordinates(item.geometry.coordinates);
                        if (item.rapidSOS && item.rapidSOS.geometry && item.rapidSOS.geometry.coordinates && item.rapidSOS.geometry.coordinates.length) {
                            item.rapidSOS.geometry.coordinates = swapCoordinates(item.rapidSOS.geometry.coordinates);
                        }
                        // ES-1905 - Added the below logic to handle the Queued call time format. For Queued calls the connect time is not appending the 'Z',
                        // which is causing problem when converting to local time. So appends 'Z' if it is not their. 
                        if(item.connectTime.trim().slice(-1) !== 'Z'){
                            item.connectTime = item.connectTime.trim() + 'Z'
                        }
                    })
                    if(endCallTimer && endCallTimer != null && endCallTimer != '' && endCallTimer > 0 && endCallsArray.length){  
                        if(Math.floor((incidentConfigs.backgroundTimer / 1000 / 60) % 60) >= endCallTimer){
                            endCallsArray =  endCallsArray.filter(function (el) {
                                return el.disconnectTime < incidentConfigs.backgroundTimer
                            });
                        }
                    }
                    let tempEndCallList = filterEndCalls(OldIncidents, incidents);
                    activeUsers = incidents.filter(function (el) {
                        return el.callState === incidentConfigs.activeStatus
                    });
                    waitUsers = incidents.filter(function (el) {
                        return el.callState === incidentConfigs.waitingStaus
                    });
                    filterIncidents = [...activeUsers, ...waitUsers, ...tempEndCallList];
                    
                    if(peersIncidents && peersIncidents.length && filterIncidents.length){
                        filterIncidents.map(incidentElement => {
                            peersIncidents.map(peerData => {
                                if(peerData.results && peerData.results.length ) {
                                    filteredPeers = peerData.results.filter(peersElement => {
                                        return incidentElement.nenaIncidentId !== peersElement.nenaIncidentId
                                    });
                                    peerData.results = filteredPeers;
                                }
                                filteredPeers = [];
                            });
                        })
                        dispatch(setPeersApiResp(peersIncidents));
                        updatePeerCounter = 0;
                    }
                    dispatch(fetchIncidentSuccess(filterIncidents));
                    dispatch(fetchMapBound(true));
                }
                // }
            })
                .catch(error => {
                    if (error.response) {
                        if (error.response.headers['region']) {
                            consoleLog(":::::::::::: current Region :::: " + localStorage.getItem('currentRegion'))
                            consoleLog(":::::::::::: Server error header region :::: " + error.response.headers['region'])
                            dispatch(setRegionName(error.response.headers['region']));
                        }
                        dispatch(fetchIncidentFail(error.response.data.message));
                        cancelledCallCounter = 0;
                        apiFailureCounter++;
                        clearTimeout(incidentTimeout);
                    }
                    consoleLog(":::::::::::: apiFailureCounter :::: " + apiFailureCounter)
                    if (apiFailureCounter >= configTimers.incidentsApiMaxFailureCount) {
                        apiFailureCounter = 0;
                        if (localStorage.getItem('currentRegion') === primaryRegion.aws_project_region) {
                            dispatch(setRegionName(secondaryRegion.aws_project_region));
                        } else {
                            dispatch(setRegionName(primaryRegion.aws_project_region));
                        }
                    }
                })
        } catch(error) {
            clearTimeout(incidentTimeout);
        }
    }
}

// filter the end calls from old and new incidents based on Nena id. 
// if call ended then assign the disconnect time and change the call state to ended.
// check all ended calls disconnect time with current time. if difference is more that the configured time 
//then remove that call from endcalls list. 
function filterEndCalls(val1, val2) {
    var endCallTimer = getEndCallTimeFromLocalStorage();
    if (endCallTimer && endCallTimer != null && endCallTimer != '' && endCallTimer > 0) {
        let endCallList = val1.filter(({ nenaIncidentId: id1 }) => !val2.some(({ nenaIncidentId: id2 }) => id2 === id1));
        if (endCallList && endCallList.length) {
            endCallList.map(endCall => {
                endCall.show = false;
                endCall.disconnectTime = new Date().getTime();
                endCall.callState = incidentConfigs.endStaus
                endCallsArray.unshift(endCall);
            })
        }
    }
    var tempArray = [];
    if (endCallsArray && endCallsArray.length) {
        endCallsArray.map(callData => {
            var difference = new Date().getTime() - callData.disconnectTime;
            var diffInMin = Math.round(difference / 1000);
            if (diffInMin < endCallTimer) {
                tempArray.push(callData);
            }
        })
    }
    endCallsArray = tempArray;
    return endCallsArray;
}

function filterPeersEndCalls(prevPeersVal, currentPeersValue) {
    var endCallTimer = getEndCallTimeFromLocalStorage();
    let peersEndCallArray = [];
    if (endCallTimer && endCallTimer != null && endCallTimer != '' && endCallTimer > 0) {
        let peersEndCallList;

        prevPeersVal.map(prevPeers => {
            if(prevPeers.peerName === currentPeersValue.peerName){
                if(currentPeersValue.results.length === 0){
                    peersEndCallList = prevPeers.results;
                }
                else if(prevPeers.results.length === 0 && currentPeersValue.results.length){
                    // do nothing
                }
                else{
                    peersEndCallList = prevPeers.results.filter(({ nenaIncidentId: id1 }) => !currentPeersValue.results.some(({ nenaIncidentId: id2 }) => id2 === id1));
                }

                if (peersEndCallList && peersEndCallList.length) {
                    peersEndCallList.map(endCall => {
                        endCall.show = false;
                        endCall.disconnectTime = endCall.disconnectTime ? endCall.disconnectTime : new Date().getTime();
                        endCall.callState = incidentConfigs.endStaus
                        peersEndCallArray.unshift(endCall);
                    });
                } 
            }
        });
    }

    // Remove Duplicates From PeersEndCallArray
    if(peersEndCallArray && peersEndCallArray.length){
        peersEndCallArray = peersEndCallArray.reduce((finalList, currentList) => {
            let obj = finalList.find((item) => item.nenaIncidentId === currentList.nenaIncidentId);

            if(obj)
                return finalList;
             
            return finalList.concat([currentList]);
        }, []);
    }

    var tempArray = [];
    if (peersEndCallArray && peersEndCallArray.length) {
        peersEndCallArray.map(callData => {
            var difference = new Date().getTime() - callData.disconnectTime;
            var diffInMin = Math.round(difference / 1000);
            if (diffInMin < endCallTimer) {
                tempArray.push(callData);
            }
        })
    }
    peersEndCallArray = tempArray;
    return peersEndCallArray;
}

export function fetchNENA(val) {
    // NENAID = val;
    return {
        type: FETCH_NENA,
        payload: val
    }
}

export function fetchAddInfo(val) {
    return {
        type: FETCH_ADDINFO,
        payload: val
    }
}

export function fetchUber(val) {
    return {
        type: FETCH_UBER,
        payload: val
    }
}


export function fetchIncident(list) {
    return {
        type: SELECT_INCIDENT,
        data: list
    }
}

export function fetchMapLoaded(val) {
    mapLoaded = val;
    return {
        type: FETCH_MAP_LOADED,
        data: val
    }
}

export function setSecondMapPath(val) {
    secondMapPath = val;
    return {
        type: SET_SEOND_MAP_PATH,
        data: val 
    }
}

export function setStationID(val) {
    return {
        type: SET_STATION_ID,
        data: val
    }
}

export function toggleNotifications() {
    return {
        type: TOGGLE_STATION_ID
    }
}

export function fetchMapBound(val) {
    return {
        type: FETCH_MAP_BOUND,
        data: val
    }
}

export function setPauseZoom(isPauseZoom){
    return {
        type: SET_PAUSE_ZOOM,
        data: isPauseZoom
    }
}

export function setSearchZoom(zoom){
    return {
        type: SET_SEARCH_PAUSE_ZOOM,
        data: zoom
    }
}

export const setIncidentDependencies = (obj) => {
    return {
        type: SET_INCIDENT_DEPENDENCIES,
        data: obj
    }
}

export const setIncidentAutoZoom = (val) => {
    return {
        type: SET_AUTO_ZOOM,
        data: val
    }
}

export const setPeerSelectedValue = (val) => {
    return {
        type: SET_PEERS_DATA,
        data: val
    }
}

export const setPeersApiResp = (val) => {
    return {
        type: SET_PEERS_API_RESPONSE,
        data: val
    }
}

const fetchPeersIncidentFail = (error) => {
    return {
        type: FETCH_PEERS_INCIDENTS_FAIL,
        data: error
    }
}

export const setFilteredPeers = (val) => {
    return {
        type: SET_FILTERED_PEERS,
        data: val
    }
}

export const setRegionName = (val) => {
    return{
        type: FETCH_REGION_NAME,
        data: val
    }
}

export const checkRegionStatus = () => {
    return function (dispatch) {
        const waitTime = configTimers.multiRegionReqTime;
        let cancelToken; 
        cancelToken = axios.CancelToken.source();
        let statusTimeout = setTimeout(() => {
            cancelledCallCounter++;
            apiFailureCounter++
            if(cancelledCallCounter === maxPositionUpdateCounter){
                dispatch(fetchIncidentFail(cancelErrorMessages.statusReportTimeOutMsg))
                cancelledCallCounter = 0;
                clearTimeout(statusTimeout);
            }
            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
              }
        },waitTime);
        try {
            const result = axios({
                url: pingConstants.url,
                cancelToken: cancelToken.token 
            });
            result.then(response => {
                if(response.headers['region'] && displayLogs) {
                    consoleLog(":::::::::::: checkRegionStatus current Region :::: " + localStorage.getItem('currentRegion'))
                    consoleLog(":::::::::::: checkRegionStatus Server header region :::: " + response.headers['region'])
                }
                cancelledCallCounter = 0;
                clearTimeout(statusTimeout);
                if(response.headers['region']) {
                    dispatch(setRegionName(response.headers['region']));
                } else {
                    dispatch(setRegionName(""));
                }
            })
                .catch(error => {
                    if (error.response) {
                        if (error.response.headers['region']) {
                            consoleLog(":::::::::::: current Region :::: " + localStorage.getItem('currentRegion'))
                            consoleLog(":::::::::::: Server error header region :::: " + error.response.headers['region'])
                            dispatch(setRegionName(error.response.headers['region']));
                        }
                        cancelledCallCounter = 0;
                        apiFailureCounter++;
                        clearTimeout(statusTimeout);
                    }
                    consoleLog(":::::::::::: apiFailureCounter :::: " + apiFailureCounter)
                    if (apiFailureCounter >= configTimers.incidentsApiMaxFailureCount) {
                        apiFailureCounter = 0;
                        if (localStorage.getItem('currentRegion') === primaryRegion.aws_project_region) {
                            dispatch(setRegionName(secondaryRegion.aws_project_region));
                        } else {
                            dispatch(setRegionName(primaryRegion.aws_project_region));
                        }
                    }
                })
        } catch(error) {
            clearTimeout(statusTimeout);
        }
    }
}