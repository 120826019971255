import {urls} from './urls';

import icWph1 from './assets/wph1.svg';
import icWph2 from './assets/wph2.svg';
import icLandline from './assets/landline.svg';
import icOthers from './assets/others.svg';
import icPayPhone from './assets/payphone.svg';
import icTelematics from './assets/telematics.svg';
import icWph2Active from './assets/wph2_active.svg';
import icWph2Selected from './assets/wph2_selected.svg';
import icWph2Waiting from './assets/wph2_waiting.svg';
import icLandlineActive from './assets/landline_active.svg';
import icLandlineWaiting from './assets/landline_waiting.svg';
import icLandlineSelected from './assets/landline_selected.svg';
import icWph1Selected from './assets/wph1_selected.svg';
import icWph1Active from './assets/wph1_active.svg';
import icWph1Waiting from './assets/wph1_waiting.svg';
import icOthersActive from './assets/others_active.svg';
import icOthersSelected from './assets/others_selected.svg';
import icOthersWaiting from './assets/others_waiting.svg';
import icPayPhoneActive from './assets/payphone_active.svg';
import icPayPhoneSelected from './assets/payphone_selected.svg';
import icPayPhoneWaiting from './assets/payphone_waiting.svg';
import icTelematicsActive from './assets/telematics_active.svg';
import icTelematicsSelected from './assets/telematics_selected.svg';
import icTelematicsWaiting from './assets/telematics_waiting.svg';
import imgMassGisBaseMap from './assets/MassGIS-BaseMap.png';
import imgMassGisEmergency from './assets/MassGIS-Emergency.png';
import imgMassGisPsap from './assets/MassGIS-PSAP.png';
import imgMassGisSatellite from './assets/MassGIS-Satellite-Hybrid.png';
import imgMassGisPd from './assets/MassGIS-pd.png';
import imgMassGisFd from './assets/MassGIS-fd.png';
import imgUsDark from './assets/US-Dark.png';
import imgUsDay from './assets/US-Day.png';
import imgUsLight from './assets/US-Light.png';
import imgUsNight from './assets/US-Night.png';
import imgUsSatellite from './assets/US-Satellite.png';
import imgMapStyle from './assets/mapstyle_default.png';
import icVoip from './assets/voip.svg';
import icVoipActive from './assets/voip_active.svg';
import icVoipSelected from './assets/voip_selected.svg';
import icVoipWaiting from './assets/voip_waiting.svg';
import icCallWph1Active from './assets/call_wph1_active.png';
import icCallWph2Active from './assets/call_wph2_active.png';
import icCallLandlineActive from './assets/call_landline_active.png';
import icCallTelematicsActive from './assets/call_telematics_active.png';
import icCallPayPhoneActive from './assets/call_payphone_active.png';
import icCallOtherActive from './assets/call_other_active.png';
import icRapidActive from './assets/rapid_active.svg';
import icRapidSelected from './assets/rapid_selected.svg';
import icRapidWaiting from './assets/rapid_waiting.svg';
import icVoipCompleted from './assets/voip_completed.svg';
import icTelematicsCompleted from './assets/telematics_completed.svg';
import icPayPhoneCompleted from './assets/payphone_completed.svg';
import icOthersCompleted from './assets/others_completed.svg';
import icWph1Completed from './assets/wph1_completed.svg';
import icLandlineCompleted from './assets/landline_completed.svg';
import icWph2Completed from './assets/wph2_completed.svg';
import icRapidCompleted from './assets/rapid_completed.svg';
import icon_t_queued_ringing from './assets/icon_t_queued_ringing.gif';
import icon_m_queued_ringing from './assets/icon_m_queued_ringing.gif';
import icon_l_queued_ringing from './assets/icon_l_queued_ringing.gif';
import icon_v_queued_ringing from './assets/icon_v_queued_ringing.gif';
import icon_te_queued_ringing from './assets/icon_te_queued_ringing.gif';
import icon_p_queued_ringing from './assets/icon_p_queued_ringing.gif';
import icon_o_queued_ringing from './assets/icon_o_queued_ringing.gif';
import icon_t_queued_waiting from './assets/icon_t_queued_waiting.svg';
import icon_m_queued_waiting from './assets/icon_m_queued_waiting.svg';
import icon_l_queued_waiting from './assets/icon_l_queued_waiting.svg';
import icon_v_queued_waiting from './assets/icon_v_queued_waiting.svg';
import icon_te_queued_waiting from './assets/icon_te_queued_waiting.svg';
import icon_p_queued_waiting from './assets/icon_p_queued_waiting.svg';
import icon_o_queued_waiting from './assets/icon_o_queued_waiting.svg';
import icon_t_inprogress from './assets/icon_t_inprogress.svg';
import icon_m_inprogress from './assets/icon_m_inprogress.svg';
import icon_l_inprogress from './assets/icon_l_inprogress.svg';
import icon_v_inprogress from './assets/icon_v_inprogress.svg';
import icon_p_inprogress from './assets/icon_p_inprogress.svg';
import icon_o_inprogress from './assets/icon_o_inprogress.svg';
import icon_t_ended from './assets/icon_t_ended.svg';
import icon_m_ended from './assets/icon_m_ended.svg';
import icon_l_ended from './assets/icon_l_ended.svg';
import icon_v_ended from './assets/icon_v_ended.svg';
import icon_te_inprogress from './assets/icon_te_inprogress.svg';
import icon_te_ended from './assets/icon_te_ended.svg';
import icon_p_ended from './assets/icon_p_ended.svg';
import icon_o_ended from './assets/icon_o_ended.svg';
import icon_t_selected from './assets/icon_t_selected.svg';
import icon_m_selected from './assets/icon_m_selected.svg';
import icon_l_selected from './assets/icon_l_selected.svg';
import icon_v_selected from './assets/icon_v_selected.svg';
import icon_te_selected from './assets/icon_te_selected.svg';
import icon_p_selected from './assets/icon_p_selected.svg';
import icon_o_selected from './assets/icon_o_selected.svg';
import icon_t_transfer_t_out from './assets/icon_t_transfer_t_out.svg';
import icon_m_transfer_t_out from './assets/icon_m_transfer_t_out.svg';
import icon_l_transfer_t_out from './assets/icon_l_transfer_t_out.svg';
import icon_v_transfer_t_out from './assets/icon_v_transfer_t_out.svg';
import icon_te_transfer_t_out from './assets/icon_te_transfer_t_out.svg';
import icon_p_transfer_t_out from './assets/icon_p_transfer_t_out.svg';
import icon_o_transfer_t_out from './assets/icon_o_transfer_t_out.svg';
import sidebar_st_t from './assets/sidebar_st_t.svg';
import sidebar_st_m from './assets/sidebar_st_m.svg';
import sidebar_st_l from './assets/sidebar_st_l.svg';
import sidebar_st_v from './assets/sidebar_st_v.svg';
import sidebar_st_te from './assets/sidebar_st_te.svg';
import sidebar_st_pp from './assets/sidebar_st_pp.svg';
import sidebar_st_o from './assets/sidebar_st_o.svg';
import sidebar_st_t_tout from './assets/sidebar_st_t_tout.svg';
import sidebar_st_m_tout from './assets/sidebar_st_m_tout.svg';
import sidebar_st_l_tout from './assets/sidebar_st_l_tout.svg';
import sidebar_st_v_tout from './assets/sidebar_st_v_tout.svg';
import sidebar_st_te_tout from './assets/sidebar_st_te_tout.svg';
import sidebar_st_pp_tout from './assets/sidebar_st_pp_tout.svg';
import sidebar_st_o_tout from './assets/sidebar_st_o_tout.svg';
import icon_l_released from './assets/icon_l_released.svg';
import icon_m_released from './assets/icon_m_released.svg';
import icon_o_released from './assets/icon_o_released.svg';
import icon_p_released from './assets/icon_p_released.svg';
import icon_t_released from './assets/icon_t_released.svg';
import icon_te_released from './assets/icon_te_released.svg';
import icon_v_released from './assets/icon_v_released.svg';
import icon_t_multi_ringing from './assets/icon_t_multi_ringing.gif';
import icon_m_multi_ringing from './assets/icon_m_multi_ringing.gif';
import icon_l_multi_ringing from './assets/icon_l_multi_ringing.gif';
import icon_v_multi_ringing from './assets/icon_v_multi_ringing.gif';
import icon_te_multi_ringing from './assets/icon_te_multi_ringing.gif';
import icon_p_multi_ringing from './assets/icon_p_multi_ringing.gif';
import icon_o_multi_ringing from './assets/icon_o_multi_ringing.gif';
import icon_t_sidebar_released from './assets/icon_t_sidebar_released.svg';
import icon_m_sidebar_released from './assets/icon_m_sidebar_released.svg';
import icon_l_sidebar_released from './assets/icon_l_sidebar_released.svg';
import icon_te_sidebar_released from './assets/icon_te_sidebar_released.svg';
import icon_p_sidebar_released from './assets/icon_p_sidebar_released.svg';
import icon_o_sidebar_released from './assets/icon_o_sidebar_released.svg';
import icon_v_sidebar_released from './assets/icon_v_sidebar_released.svg';
import logo from './assets/comtech_corp_logo.svg';

var pwdResetBaseApi = 'editor';
var seBaseApi = 'api-se';
var replaceBaseApi = /apis|api/g;

var sessionConfigs = {
  apiKey: sessionStorage.getItem('api_key'),
  username: sessionStorage.getItem('currentUser'),
  groupName: sessionStorage.getItem('groupName'),
  idToken: sessionStorage.getItem('idToken')
}

export const serverUrl = {
  serverBaseUrl : sessionStorage.getItem('base')
}

export const serverconfigs = {
    serviceBaseUrl: `${serverUrl.serverBaseUrl}/geo/maps/v2/mb/`,
    psapProxyUrl: `${serverUrl.serverBaseUrl}/geo/maps/v2/psap`,
    apiKey : sessionConfigs.apiKey,
    username: sessionConfigs.username,
    searchKey: `${urls.searchKey}` !== 'undefined'? `${urls.searchKey}` :  sessionConfigs.apiKey,
    searchBaseUrl: `${urls.searchBaseUrl}`!== 'undefined'? `${urls.searchBaseUrl}` :  serverUrl.serverBaseUrl,
    suggestionUrl: '/geo/v3/suggestions',
    poiSearchUrl: '/geo/v3/select',    
    addressSearchUrl: '/geo/v3/search',
    w3wSearchUrl: '/geo/v3/searchW3W',
    w3wSuggestionsUrl: '/geo/v3/suggestionsW3W',
    passwordResetUrl: `${urls.passwordResetUrl}` !== 'undefined'? `${urls.passwordResetUrl}/auth` :  serverUrl.serverBaseUrl ? serverUrl.serverBaseUrl.replace(replaceBaseApi, pwdResetBaseApi)+'/auth' : serverUrl.serverBaseUrl+'/auth',
    reversegeocodeUrl: '/geo/v3/reverseGeocode',
    personIdentityPath: '/person/v1.0/identity/search',
    personCriminalPath: '/person/v1.0/criminal/search',
    apiKey: sessionConfigs.apiKey,
    groupName: sessionConfigs.groupName,
    layerUrl:'sources/',
    filterField:"TimeSec",
    lat: 42.350340,
    lon:-71.145985,
    zoom:10,
    interval:15,  // minutes and mutiple of 5,
    satelliteMapPitch: 45,
    weatherUrl: `${serverUrl.serverBaseUrl}/geo/maps/v3/weather/v1.0`,
    liveFeedUrl: `${serverUrl.serverBaseUrl}/sa/${sessionConfigs.groupName}/calls/notification?api_key=${sessionConfigs.apiKey}&pos_id=`,
    simulatedLiveFeedUrl: `${urls.enableSimulationData}` === 'true'?`${urls.simulated911FeedUrl}`:'',    
    simulatedLiveFeedApiKey: `${urls.enableSimulationData}` === 'true'? `${urls.simulated911FeedApiKey}` !== 'undefined'? `${urls.simulated911FeedApiKey}` :  sessionConfigs.apiKey :'',
    secondaryMapBaseUrl: `${serverUrl.serverBaseUrl}/geo/maps/v2/mb/styles/default`,
    initialCount: 1,
    saBaseUrl: `${serverUrl.serverBaseUrl}/sa/${sessionConfigs.groupName}/v2/calls?api_key=${sessionConfigs.apiKey}`,
    peersBaseUrl: `${serverUrl.serverBaseUrl}/sa/${sessionConfigs.groupName}/v2/peer-calls?api_key=${sessionConfigs.apiKey}`,
    cameraPoiPath: '/camera/v1.0/search/poi',
    cameraBboxPath: '/camera/v1.0/search/bbox',
    routeUrl: (`${urls.routeBaseUrl}`!== 'undefined'? `${urls.routeBaseUrl}` :  serverUrl.serverBaseUrl) + "/geo/v3/route",
    routeApikey : `${urls.routeApiKey}` !== 'undefined'? `${urls.routeApiKey}` :  sessionConfigs.apiKey,
    title: 'SmartResponse',
    notificationBody: 'Notification from SmartResponse',
    notificationClientIdSuffix: 'webapp:',
    notificationReconnectTime: 5000,
    notificationPolicy: 'IotNotificationPolicy',
    trackingUrl: `${serverUrl.serverBaseUrl}/sa/${sessionConfigs.groupName}/v2/vehicles?api_key=${sessionConfigs.apiKey}`,
    deviceDetails: `${serverUrl.serverBaseUrl}/sa/${sessionConfigs.groupName}/v2/vehicles`,
    devicesAnalyticsUrl: `${serverUrl.serverBaseUrl}/sa/${sessionConfigs.groupName}/v2/vehicles`,
    sendSmsUrl: `${urls.seUrl}` !== 'undefined'? `${urls.seUrl}/video?mdn=` :  serverUrl.serverBaseUrl ? serverUrl.serverBaseUrl.replace(replaceBaseApi, seBaseApi)+'/video?mdn=' : serverUrl.serverBaseUrl+'/video?mdn=',
    streamingUrl: (`${urls.streamingUrl}`!== 'undefined'? `${urls.streamingUrl}` :  serverUrl.serverBaseUrl) + '/kvs/kinesis-video-url/',
    discrepancyUrl:`${urls.seUrl}` !== 'undefined'? `${urls.seUrl}` :  serverUrl.serverBaseUrl ? serverUrl.serverBaseUrl.replace(replaceBaseApi, seBaseApi) : serverUrl.serverBaseUrl,
    idToken: sessionConfigs.idToken,
    indoorMapUrl: `${serverUrl.serverBaseUrl}/geo/maps/v2/mb/indoor/${sessionConfigs.username}?api_key=${sessionConfigs.apiKey}`,
    uploadIndoorMapUrl: `${serverUrl.serverBaseUrl}/geo/maps/v2/mb/upload/indoor/${sessionConfigs.username}?api_key=${sessionConfigs.apiKey}`,
    tableauDashobardUrl: urls.tableauDashobardUrl,
}

export const displayLogs = (`${urls.debuggLogs}`!== 'undefined'? `${urls.debuggLogs}` : false);

export const maxPositionUpdateCounter = 3 // i.e. 30 seconds

export const splitterConfigs = {
  secondaryPaneSize: '37%',
  dividerPosition: '37%',
  primaryPaneSize: '62.4%'
}

export var callback = {
  callback: false,
  displayDate: '',
  setBounds: false

}

export const markerImages = {
  'incidentIcons':{
    'WPH1': icWph1,
    'WPH2': icWph2,
    'RESD': icLandline,
    'BUSN': icLandline,
    'VOIP': icVoip,
    'TELE': icTelematics,
    'CENT': icLandline,
    'COIN': icPayPhone,
    'WRLS': icOthers,
    'UNK': icOthers,
    'default': icOthers
  },
  'serviceList':{
    'WPH1': 'Wireless Phase 1',
    'WPH2': 'Wireless Phase 2',
    'RESD' : 'Residential Line',
    'BUSN' : 'Business Line',
    'VOIP': 'Voice over Internet',
    'TELE': 'Telematics',
    'CENT': 'Centrex Line',
    'COIN': 'Payphone',
    'WRLS': 'Mobile',
    'UNK': 'Unknown Source',
    'default': 'Others'
  },
  'rapidSOSMarkers' : {
    'active': icRapidActive,
    'selected': icRapidSelected,
    'waiting': icRapidWaiting,
    'ended': icRapidCompleted
  },
  'selectMarkers': {
    'WPH1': icWph1Selected,
    'WPH2': icWph2Selected,
    'RESD': icLandlineSelected,
    'BUSN': icLandlineSelected,
    'VOIP': icVoipSelected,
    'TELE': icTelematicsSelected,
    'CENT': icLandlineSelected,
    'COIN': icPayPhoneSelected,
    'WRLS': icOthersSelected,
    'UNK': icOthersSelected,
    'default': icOthersSelected
  },
  'activeMarkers':{
    'WPH1': icWph1Active,
    'WPH2': icWph2Active,
    'RESD': icLandlineActive,
    'BUSN': icLandlineActive,
    'VOIP': icVoipActive,
    'TELE': icTelematicsActive,
    'CENT': icLandlineActive,
    'COIN': icPayPhoneActive,
    'WRLS': icOthersActive,
    'UNK': icOthersActive,
    'RAPIDSOS': icRapidActive,
    'default': icOthersActive
  },
  'queuedMarkers':{
    'WPH1': icWph1Waiting,
    'WPH2': icWph2Waiting,
    'RESD': icLandlineWaiting,
    'BUSN': icLandlineWaiting,
    'VOIP': icVoipWaiting,
    'TELE': icTelematicsWaiting,
    'CENT': icLandlineWaiting,
    'COIN': icPayPhoneWaiting,
    'WRLS': icOthersWaiting,
    'UNK': icOthersWaiting,
    'default': icOthersWaiting
  },
  'endedMarkers':{
    'WPH1': icWph1Completed,
    'WPH2': icWph2Completed,
    'RESD': icLandlineCompleted,
    'BUSN': icLandlineCompleted,
    'VOIP': icVoipCompleted,
    'TELE': icTelematicsCompleted,
    'CENT': icLandlineCompleted,
    'COIN': icPayPhoneCompleted,
    'WRLS': icOthersCompleted,
    'UNK': icOthersCompleted,
    'default': icOthersCompleted
  }
}

export const solacomMarkerImages = {
  'waiting':{
    'WPH1': icon_t_queued_waiting,
    'WPH2': icon_m_queued_waiting,
    'RESD': icon_l_queued_waiting,
    'BUSN': icon_l_queued_waiting,
    'VOIP': icon_v_queued_waiting,
    'TELE': icon_te_queued_waiting,
    'CENT': icon_l_queued_waiting,
    'COIN': icon_p_queued_waiting,
    'WRLS': icon_o_queued_waiting,
    'UNK': icon_o_queued_waiting,
    'default': icon_o_queued_waiting
  },
  'ringing':{
    'WPH1': icon_t_queued_ringing,
    'WPH2': icon_m_queued_ringing,
    'RESD': icon_l_queued_ringing,
    'BUSN': icon_l_queued_ringing,
    'VOIP': icon_v_queued_ringing,
    'TELE': icon_te_queued_ringing,
    'CENT': icon_l_queued_ringing,
    'COIN': icon_p_queued_ringing,
    'WRLS': icon_o_queued_ringing,
    'UNK': icon_o_queued_ringing,
    'default': icon_o_queued_ringing
  },
  'transferred':{
    'WPH1': icon_t_transfer_t_out,
    'WPH2': icon_m_transfer_t_out,
    'RESD': icon_l_transfer_t_out,
    'BUSN': icon_l_transfer_t_out,
    'VOIP': icon_v_transfer_t_out,
    'TELE': icon_te_transfer_t_out,
    'CENT': icon_l_transfer_t_out,
    'COIN': icon_p_transfer_t_out,
    'WRLS': icon_o_transfer_t_out,
    'UNK': icon_o_transfer_t_out,
    'default': icon_o_transfer_t_out
  },
  'activeMarkers':{
    'WPH1': icon_t_inprogress,
    'WPH2': icon_m_inprogress,
    'RESD': icon_l_inprogress,
    'BUSN': icon_l_inprogress,
    'VOIP': icon_v_inprogress,
    'TELE': icon_te_inprogress,
    'CENT': icon_l_inprogress,
    'COIN': icon_p_inprogress,
    'WRLS': icon_o_inprogress,
    'UNK': icon_o_inprogress,
    'RAPIDSOS': icRapidActive,
    'default': icon_o_inprogress
  },
  'selectMarkers': {
    'WPH1': icon_t_selected,
    'WPH2': icon_m_selected,
    'RESD': icon_l_selected,
    'BUSN': icon_l_selected,
    'VOIP': icon_v_selected,
    'TELE': icon_te_selected,
    'CENT': icon_l_selected,
    'COIN': icon_p_selected,
    'WRLS': icon_o_selected,
    'UNK': icon_o_selected,
    'default': icon_o_selected
  },
  'endedMarkers':{
    'WPH1': icon_t_ended,
    'WPH2': icon_m_ended,
    'RESD': icon_l_ended,
    'BUSN': icon_l_ended,
    'VOIP': icon_v_ended,
    'TELE': icon_te_ended,
    'CENT': icon_l_ended,
    'COIN': icon_p_ended,
    'WRLS': icon_o_ended,
    'UNK': icon_o_ended,
    'default': icon_o_ended
  },
  'rapidSOSMarkers' : {
    'active': icRapidActive,
    'selected': icRapidSelected,
    'waiting': icRapidWaiting,
    'ended': icRapidCompleted
  },
  'released' : {
    'WPH1': icon_t_released,
    'WPH2': icon_m_released,
    'RESD': icon_l_released,
    'BUSN': icon_l_released,
    'VOIP': icon_v_released,
    'TELE': icon_te_released,
    'CENT': icon_l_released,
    'COIN': icon_p_released,
    'WRLS': icon_o_released,
    'UNK': icon_o_released,
    'default': icon_o_released
  },
  'multiple' : {
    'WPH1': icon_t_multi_ringing,
    'WPH2': icon_m_multi_ringing,
    'RESD': icon_l_multi_ringing,
    'BUSN': icon_l_multi_ringing,
    'VOIP': icon_v_multi_ringing,
    'TELE': icon_te_multi_ringing,
    'CENT': icon_l_multi_ringing,
    'COIN': icon_p_multi_ringing,
    'WRLS': icon_o_multi_ringing,
    'UNK': icon_o_multi_ringing,
    'default': icon_o_multi_ringing
  }
}
export const incidentConfigs = {
  activeStatus: 'inProgress',
  waitingStaus: 'queued',
  endStaus: 'ended',
  incidentTileHeight: 217,
  backgroundTimer: null
}

export const configTimers = {
  // When access token is expired, allow Amplify for 20 seconds to refresh for a new token.
  refreshTokenExpiryTime: 20000,
  // incident API timeout interval
  getIncidentsReqTime: 1000*10, // 10 seconds
  idelTimeOutTime:1000 * 60 * 15, // 15 minutes,
  counterTime: 1000,
  cancelStatusCode : 'ECONNABORTED',
  mapDataTimeout: 1000*30, // 30 seconds
  routeCallTimeOut: 1000*60, // 60 seconds
  searchCallTimeOut: 1000*60, // 60 seconds
  polygonUploadCallTimeOut: 1000*60, // 60 seconds,
  weatherTimeOut: 1000*5,  // 5 seconds
  historyCallsTimeOut: 1000*60, // 60 seconds
  searchAEDTimeOut: 1000*60, // 60 seconds,
  sessionTokenRetry: 1000*60 ,// 60 seconds
  reverseGeocodeTimeOut: 1000*60, // 60 seconds
  // This counter variable is used to check the Incidents max failure count.
  incidentsApiMaxFailureCount: 6,
  discrepencyReportTimeOut: 1000*60,
  multiRegionReqTime: 1000*10, // 10 seconds
  IndoorMapDataTimeout: 1000*30, // 30 seconds
  trackingDataReqTime: 1000*3, // 5 seconds
}

export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const NO_DATA = 'No data found';

export const customToastId = "custom-id";

export const mapStyle = {
  'images': {
    'MassGIS-BaseMap': imgMassGisBaseMap,
    'MassGIS-Emergency': imgMassGisEmergency,
    'MassGIS-PSAP': imgMassGisPsap,
    'MassGIS-Satellite-Hybrid': imgMassGisSatellite,
    'MassGIS-Satellite': imgMassGisSatellite,
    'MassGIS-Fire': imgMassGisFd,
    'MassGIS-Police': imgMassGisPd,
    'US-Traffic-Satellite': imgUsSatellite,
    'US-Satellite': imgUsSatellite,
    'dark': imgUsDark,
    'day': imgUsDay,
    'light': imgUsLight,
    'night': imgUsNight,
    'default': imgMapStyle
  },
  'themes': {
    'dark' : 'dark',
    'day' : 'day',
    'light' : 'light',
    'night' : 'night'
  }
}

export const groupLocations = {
  'default' : {
    'center' : [-71.0875, 42.299],
    'zoom': 11.45
  }
}

export const tabUrls = {
  'incident' : 'All Calls',
  'call': 'call',
  'analytics': 'analytics',
  'tracking': 'Tracking',
  'tracking_factory': 'Tracking',
}

export const featureLabels = {
  'map' : 'FC_MAP',
  'traffic' :'FC_TRAFFIC',
  'weather' : 'FC_WEATHER',
  'search': 'FC_SEARCH',
  'person': 'FC_PERSON',
  'call' : 'FC_CALL',
  'incident' : 'FC_INCIDENT',
  'analytics' : 'FC_ANALYTICS',
  'routing' : 'FC_ROUTING',
  'sitCount' : 'FC_SITUCONT',
  'camera': 'CAMERA',
  'aed' : 'AED',
  'z_axis' : 'FC_ZAXIS',
  'tracking' : 'FC_AVL',
  'video': 'FC_VIDEO',
  'indoorMap': 'FC_MAP_INDOOR',
  'device_tracking': 'FC_TRACK',
  'device_analytics': 'FC_ANALYTICS_TRACK',
  'tracking_factory': 'FC_TRACK_LAB_FACTORY',
}

export const mapStyleLayers = {
  'GoogleStreetViewLayer': 'Google-StreetView',
  'NoMapLayer': 'No-Map',
  'GoogleStreetViewPath': '/Google-StreetView.json'
}

export const groupFeatures = JSON.parse(sessionStorage.getItem('groupProfile'));

export const callDeclarations = {
  enableSimulation : `${urls.enableSimulationData}` === 'true'? true :  false,
  isSimulationEnabled : sessionStorage.getItem('isSimulationEnabled') === 'true'? true :  false,
  callExpireTime : 30,
  callstartTime : 0,
  callTimerSeconds: 0,
  callTimerMinutes: 0,
  oldNinaId : '',
  notificationFromUrl: false,
  defaultRadius: 500,   
  bboxDistance: 1.5, // to calculate bounding box
  'callIcons':{
    'WPH1': icCallWph1Active,
    'WPH2': icCallWph2Active,
    'RESD': icCallLandlineActive,
    'BUSN': icCallLandlineActive,
    'VOIP': icCallOtherActive,
    'TELE': icCallTelematicsActive,
    'CENT': icCallLandlineActive,
    'COIN': icCallPayPhoneActive,
    'WRLS': icCallOtherActive,
    'UNK': icCallOtherActive,
    'default': icCallOtherActive
  },
  'SolacomCallIcons':{
    'WPH1': sidebar_st_t,
    'WPH2': sidebar_st_m,
    'RESD': sidebar_st_l,
    'BUSN': sidebar_st_l,
    'VOIP': sidebar_st_v,
    'TELE': sidebar_st_te,
    'CENT': sidebar_st_l,
    'COIN': sidebar_st_pp,
    'WRLS': sidebar_st_o,
    'UNK': sidebar_st_o,
    'default': sidebar_st_o
  },
  'releasedCallIcons':{
    'WPH1': icon_t_sidebar_released,
    'WPH2': icon_m_sidebar_released,
    'RESD': icon_l_sidebar_released,
    'BUSN': icon_l_sidebar_released,
    'VOIP': icon_v_sidebar_released,
    'TELE': icon_te_sidebar_released,
    'CENT': icon_l_sidebar_released,
    'COIN': icon_p_sidebar_released,
    'WRLS': icon_o_sidebar_released,
    'UNK': icon_o_sidebar_released,
    'default': icon_o_sidebar_released
  },
  'transferOut':{
    'WPH1': sidebar_st_t_tout,
    'WPH2': sidebar_st_m_tout,
    'RESD': sidebar_st_l_tout,
    'BUSN': sidebar_st_l_tout,
    'VOIP': sidebar_st_v_tout,
    'TELE': sidebar_st_te_tout,
    'CENT': sidebar_st_l_tout,
    'COIN': sidebar_st_pp_tout,
    'WRLS': sidebar_st_o_tout,
    'UNK': sidebar_st_o_tout,
    'default': sidebar_st_o_tout
  },
  defaultGisId : 'MassGIS',
  contourLineDistance: 0.12,
  contourLayer: 'contour',
  initMapZoom: 15,
  searchPinZoom: 16,
  satelliteMapZoom: 18,
  meterToFeet: 3.28,
  zAxisZoomLevel: 15,
  sim911Topic: 'call911/',
  vehicleTopic: 'vehicles/',
  allVehicles: 'all vehicles',
  vehicleTypes:{
    'engine': 'firestation',
    'rescue': 'firestation',
    'breaker': 'firestation',
    'car': 'policestation',
    'default': 'policestation'
  },
  customSourceLayersList: [],
  citySearchRadius: '-9999',
  idleTabName: '',
  solacomTopic: 'solacom'
}

export const psapLayerBounds = [
  {
    Name: 'Counties', value: 'counties', active: false, id: 'Counties', displayName: 'County Bounds'
  },
  {
    Name: 'Incorporated Municipalities', value: 'incorporatedMunicipalities', active: false, id: 'Incorporated Municipalities', displayName: 'City Bounds',
  },
  {
    Name: 'PSAP Bounds', value: 'psapBounds', active: false, id: 'PSAP Bounds', displayName: 'PSAP Bounds',
    data: [
      {Name: 'PSAP Large Scale Label', value: 'psapLarge'},
      {Name: 'PSAP Mid Scale Label', value: 'psapMid'}
    ]
  },
  {
    Name: 'Police Service Bounds', value: 'policeBounds', active: false, id: 'Police Service Bounds', displayName: 'Police Service Bounds',
    data: [
      {Name: 'Police Large Scale Label', value: 'policeLarge'},
      {Name: 'Police Mid Scale Label', value: 'policeMid'}
    ]
  },
  {
    Name: 'Fire Service Bounds', value: 'fireBounds', active: false, id: 'Fire Service Bounds', displayName: 'Fire Service Bounds',
    data: [
      {Name: 'Fire Large Scale Label', value: 'fireLarge'},
      {Name: 'Fire Mid Scale Label', value: 'firepMid'}
    ]
  }
]

// address point layers ID
var addressPointLayerID = 'Alt AddressPoint';

export const massGISAPLayerBound = {
  massGISAPLayer: {Name: addressPointLayerID, value: addressPointLayerID, active: false, id: addressPointLayerID, displayName: 'Address Points'}
}

export const streetLayerIds = ['Local','Normal', 'Regional','National']

export const clickableLayers = {
  layerIds: [addressPointLayerID, ...streetLayerIds],
  addresPointLayerIds: addressPointLayerID, 
  addresPointProperty: 'APID',  // address point ID
  poiSources: 'GIS_pois', // police and fire stations source
  cursorPointerZoom: 16
}


export const routingRadius = 25;

export const callsConfigs = {
  activeStatus: 'inProgress',
  waitingStaus: 'queued',
  endStaus: 'ended',
  released: 'released',
  endCallLoopTime:1000 * 60 * 1, // 1 minutes,
}

export const vendor = {
  gpsinsight: 'GPSInsight',
  traxmate: 'TRAXMATE',
}

export const cancelErrorMessages = {
  mapUserMsg: 'Map styles request is timing out.  Please try again.',
  polygonDatasetTimeOutMsg: 'Geometry request is timing out.  Please try again.',
  routeTimeOutMsg: 'Route request is timing out.  Please try again.',
  searchTimeOut: 'Search request is timing out.  Please try again.',
  reverseGeocodeTimeOutMsg: 'Reverse GeoCode request is timing out.  Please try again.',
  incidentTimeOutMsg: 'All Calls request is timing out.  Please try again.',
  AnalyticsTimeOutMsg: 'Upstream server is timing out.  Please try again.',
  weatherTimeOutMsg: 'Weather request is timing out.  Please try again.',
  addressPointTimeOutMsg: 'Address Point request is timing out.  Please try again.',
  discrepencyReportTimeOutMsg: 'Discrepency Report request is timing out.  Please try again.',
  statusReportTimeOutMsg: 'Region check request is timing out.  Please try again.'
}

export const errorMessages = {
  suggestionError: 'Failed to get suggestions.  Please try again.',
  searchEmptyError: 'Please enter Address, POI or Lat lng to search.',
  searchError: 'Failed to get results.  Please try again.',
  nofireStations: 'No Fire Stations or Police Stations are available on Map.',
  noActiveCalls: 'No Active Call is available.',
  iDTokenExpireMessage: 'Current login session expired. Please sign out and sign in again',
  invalidW3WError: 'Please enter a valid 3 word address',
  networkError: 'No Network Connection',
  regionDownMsg:'Current service is unavailable. Please sign out and sign in to another available service.',
  sameRegionSelectedMsg:'Current service is unavailable. Please select another available service',
  browserNotifPermMsg: 'Browser Notifications popup permissions are blocked',
  noDeviceHistory: 'History data not found'
}

export const successMessages = {
  polygonUploadMsg: 'Geometry is uploaded.',
  polygonDeletedMsg: 'Geometry is deleted.'
}

export var pingConstants = {
  url:  `${serverUrl.serverBaseUrl}/sa/status?api_key=${sessionConfigs.apiKey}`,
  timeout: 5000,
  interval: 10000
}

export const peerDeclarations = {
  allPeers: 'all peers',
}

export const TrackingRouteColors = ['Steel Blue','orange','green', 'yellow','brown', 'teal', 'maroon', 'navy','DeepPink','MediumVioletRed','Tomato', 'DarkKhaki', 
'Orchid', 'Chocolate', 'CadetBlue', 'Gold', 'lime',  'Turquoise',];

export const GoeFenceRouteColors = ['#438CBC','#d180b7', 'green', "#8A2BE2", 'yellow', 'lime', 'Turquoise','MediumVioletRed','Tomato', 'Chocolate', 'CadetBlue']
// export const GoeFenceRouteColors = ["#438CBC", // Blue
// "#00FF00", // Lime green
// "#FF5733", // Orange
// "#8A2BE2", // Blue violet
// 'maroon',
// "#FF00FF", // Magenta
// "#1E8449", // Emerald green
// "#00CED1", // Dark turquoise
// "#7FFF00", // Chartreuse
// "#FF1493", // Deep pink
// "#6A5ACD", // Slate blue
// "#9AECDB", // Turquoise
// "#00FF7F", // Spring green
// "#9400D3"  // Dark violet
// ];

export const trackingMessages = {
  noVehicleData: 'No Vehicle found',
  noVendorList: 'No Vendor is available'
}

export const googleApiKey = {
  wrapperKey: urls.googleAPiKey,
  nonceValue: "bXlzZWNyZXRub25jZTIx"
}

export const trackingDeviceDeclarations = {
  'activeStatus': 'active',
  'inActiveStatus': 'inactive',
  'restrictedStatus': 'restricted',
  'restrictSelectedStatus': 'restrictSelected',
  'selectedStatus': 'selected',
  'idleStatus': 'idle',
  'idleSelectedStatus': 'idleSelected',
  'fireman':'fireman',
  'medic': 'medic',
  'police': 'police',
  'deviceId': null,
  'selectedDeviceType': null,
  defaultZoomLevel: 14,
  factoryDefaultZoomLevel: 17,
  currentFloorNo: 0,
  historyTickCount: 16,
  historyObj: {
    startDate: null,
    endDate: null,
    histotyData: null,
    sliderTickCount: 0
  },
  geofenceTopic: 'vehicles/geofence/',
  allDevicesHistoryObj: {
    startDate: null,
    endDate: null,
    isGeofence: true,
    deviceData: [],
    geofenceScan: false,
    defaultInterval: 60,
    interval: 0,
    rangeValue: 0,
    sliderTotalTimeInMIn: 0
  },
}

export const factoryTracking = {
  useCase1: 'Lab simulation',
  useCase2: 'Factory simulation',
  selectedSimulation: 'Lab simulation'
}

export const indoorMapObj = {
  indoorMapsList: [],
  indoorMapZoomLevel: 17,
}