const apiKeys = require('./vaultEnvKeys.json');

export const urls = {
    // baseUrl: '',
    // passwordResetUrl: '',
	enableSimulationData: true,
	simulated911FeedUrl: 'https://api.dev.smartresponse.net/geo/psap/sim911',
	simulated911FeedApiKey: 'YDeuWJpOT3WQN2jdF93z1UfTVhp0oQfTUwZKpMIL',
	debuggLogs: true,
	// seUrl: ''
	discrepancyUrl: 'https://apisls.dev.smartresponse.net',
	smsUrl: 'https://apisls.dev.smartresponse.net',
	tableauDashobardUrl: 'http://public.tableau.com/views/RegionalSampleWorkbook/Flights',
	googleAPiKey: apiKeys ? apiKeys.googleAPiKey : ''
}