// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle {
  cursor: pointer;
  font-size: 14px;
  color: #000;
}

.txt-primary {
  color: #0068a6;
}

.sign-up {
  padding-bottom: 60px;
}
.sign-up .form-group {
  max-width: 329px;
}

.show-pwd {
  right: 7px;
  top: 31px;
}`, "",{"version":3,"sources":["webpack://./src/styles/auth.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;EACA,WAAA;AACJ;;AAEE;EACE,cAAA;AACJ;;AACE;EACE,oBAAA;AAEJ;AADI;EACE,gBAAA;AAGN;;AAAE;EACE,UAAA;EACA,SAAA;AAGJ","sourcesContent":[".toggle {\n    cursor: pointer;\n    font-size: 14px;\n    color:#000;\n  }\n  \n  .txt-primary{\n    color: #0068a6;\n  }\n  .sign-up{\n    padding-bottom: 60px;\n    .form-group{\n      max-width: 329px;\n    }\n  }\n  .show-pwd{\n    right: 7px;\n    top: 31px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
