import moment from "moment";
import {serverconfigs} from '../constants';
import timetzzone from "moment-timezone";
import icPlay from '../assets/play.svg';
import icPause from '../assets/pause.svg';
import icRefresh from '../assets/refresh_weather.svg';

var date = new Date();
var playDoppler;
var currentIndex = 0;
var previousIndex = currentIndex;
var sone = moment.tz.guess();
var timezone = moment.tz(sone).zoneAbbr();
const frameCount = 5; // total intervals
var startMinutes = Math.round(new Date().getTime() / 1000);
const endMinutes = 0;
var timer = '';
var gifIndex = 0;
var gifLayers = [];
var map;
var isWeatherDisplayed = false;
var frames = [];
var disableSlider = true;
var maxZoomThreshold = 16;

export default class GifControl {
    onAdd(obj) {
        frames = [];
        this.map = obj;
        map = obj;
        isWeatherDisplayed = true;
        // map.showTileBoundaries = true;
        this.container = document.createElement('div');
        this.container.className = 'my-custom-control';
        this.container.id = 'doppler-control';
        var parentDiv = document.createElement("div");
        parentDiv.className = 'doppler';
        this.container.appendChild(parentDiv);
        var refreshWeather = document.createElement("span");
        refreshWeather.className = 'refresh-weather';
        var refreshImg = document.createElement("img");
        refreshImg.src = icRefresh;
        refreshImg.addEventListener('click', function(){
            resetWeather();
        });
        refreshWeather.appendChild(refreshImg);
        parentDiv.appendChild(refreshWeather);
        var playWeather = document.createElement("span");
        playWeather.className = 'play-option';
        var playImg = document.createElement("img");
        playImg.id = 'play-icon';
        playImg.src = icPlay;
        playImg.addEventListener('click', function(){
            playAnimation();
        });
        playWeather.appendChild(playImg);
        var stopImg = document.createElement("img");
        stopImg.id = 'pause-icon';
        stopImg.src = icPause;
        stopImg.addEventListener('click', function(){
            stopAnimation();
        });
        playWeather.appendChild(stopImg);
        parentDiv.appendChild(playWeather);
        var displayTime = document.createElement("span");
        displayTime.id = 'current-timer';
        displayTime.className = 'display-time';
        displayTime.innerHTML = moment().format(" hh:mm A") + ' ' + timezone;
        parentDiv.appendChild(displayTime);
        var slider = document.createElement("input");
        slider.id = 'timer';
        slider.className = 'doppler-slider';
        slider.type = 'range';
        slider.min = 0;
        slider.max = 4;
        slider.disabled = true;
        slider.value = 0;
        slider.step = 1;
        slider.addEventListener('click', function(){
            sliderChange(this.value, 'input');
        });
        parentDiv.appendChild(slider);
        var timeRange = document.createElement("div");
        timeRange.className = 'time-zone';
        var currentText = document.createElement("span");
        currentText.className = 'current-time';
        currentText.innerHTML = 'Current';
        timeRange.appendChild(currentText);
        var endTime = document.createElement("span");
        endTime.className = 'end-time';
        endTime.innerHTML = '+2h';
        timeRange.appendChild(endTime);
        parentDiv.appendChild(timeRange);
        
     //LARD-1071 - Limited weather to max zoom 16
     map.on('zoom', function () {
         if (isWeatherDisplayed) {
            if (map.getZoom() > maxZoomThreshold) {
                document.getElementById('doppler-control').style.display = 'none';
            } else {
                document.getElementById('doppler-control').style.display = 'block';
            }
         }        
    });
        return this.container;
    }
    onRemove() {
        if (this.container !== undefined && this.container.parentNode) {
            stopAnimation()
            isWeatherDisplayed = false;
            this.container.parentNode.removeChild(this.container);
            this.map = undefined;
        }
    }
}

function getForeCastTilePath(interval) {
    return serverconfigs.weatherUrl+`/doppler/{z}/{x}/{y}/radarFcst?ts=${interval}&api_key=`+serverconfigs.apiKey; // Dev
}
function getRadarTilePath(interval) {
    return serverconfigs.weatherUrl+`/doppler/{z}/{x}/{y}/radar?ts=${interval}&api_key=`+serverconfigs.apiKey; // Dev
}

export function addRasterLayer(interval, opacity = 0, ind) {
    const id = `amp-${interval}`;
    frames.push(id);
    gifLayers.push(id);
    gifLayers = gifLayers.filter((layer, index) => {
        return gifLayers.indexOf(layer) === index;
    });
    map.addSource(id, {
        type: 'raster',
        tiles: [ind == 0 ? getRadarTilePath(interval) : getForeCastTilePath(interval)]
    });
    map.addLayer({
        id,
        type: 'raster',
        source: id,
        minzoom: 0,
        maxzoom: maxZoomThreshold, //LARD-1071 - Limited weather to max zoom 16
        paint: {
            'raster-opacity': opacity,
            'raster-opacity-transition': {
                duration: 0,
                delay: 0
            }
        }
    });
    return id;
}
function setRasterLayerOpacity(map, id, opacity) {
    map.setPaintProperty(id, 'raster-opacity', opacity);
}
function showGifLayers(map) {
    // step time determines the time in milliseconds each frame holds before advancing
    const stepTime = 1000;

    let currentOffset = 0;
    let previousOffset = currentOffset;

    timer = setInterval(() => {
        previousOffset = currentOffset;
        currentOffset += 1;
        if (currentOffset === frameCount) {
            currentOffset = 0;
        }

        setRasterLayerOpacity(map, frames[previousOffset], 0);
        setRasterLayerOpacity(map, frames[currentOffset], 1);
    }, stepTime);
}

function playAnimation (){
    showGifLayers(map);
    gifIndex = gifIndex + 1;
    document.getElementById('timer').disabled = false;
    document.getElementById('pause-icon').style.display = 'block';
    document.getElementById('play-icon').style.display = 'none';
    // set up the animation frames and layers
    if (gifIndex == 1 || localStorage.getItem("gifIndex") == 1) {
        localStorage.setItem("gifIndex", 0);
        for (let i = 1; i < frameCount; i += 1) {
            const timeOffset = startMinutes + i * 1800;
            addRasterLayer(timeOffset, 1, i);
            frames = frames.filter((layer, index) => {
                return frames.indexOf(layer) === index;
            });
        }
    }

    previousIndex = currentIndex;
    playDoppler = setInterval(() => {
        previousIndex = currentIndex;
        currentIndex += 1;
        if (currentIndex === frameCount) {
            currentIndex = 0;
        }
        sliderChange(currentIndex);
    }, 1000);

}

window.stopAnimation = function() {
    clearInterval(timer);
    clearInterval(playDoppler);
    document.getElementById('pause-icon').style.display = 'none';
    document.getElementById('play-icon').style.display = 'block';
}

window.sliderChange = function (rangeValue, val) {
    document.getElementById('timer').disabled = false;
    document.getElementById('timer').value = rangeValue;
    if (val == 'input' && parseInt(rangeValue) == 0) {
        currentIndex = parseInt(rangeValue);
        for (let i = 0; i < frameCount; i += 1) {
            setRasterLayerOpacity(map, frames[i], 0);
            if (i === 0) {
                setRasterLayerOpacity(map, frames[parseInt(rangeValue)], 1);
            }
        }
    }
    else if (val == 'input') {
        currentIndex = parseInt(rangeValue);
        for (let i = 0; i < frameCount; i += 1) {
            setRasterLayerOpacity(map, frames[i], 0);
        }
        setRasterLayerOpacity(map, frames[parseInt(rangeValue)], 1);
    }
    else if (rangeValue == 0) {
        for (let i = 0; i < frameCount; i += 1) {
            setRasterLayerOpacity(map, frames[i], 0);
            if (i === 0) {
                setRasterLayerOpacity(map, frames[parseInt(rangeValue)], 1);
            }
        }
    }
    else if (rangeValue > 0) {
        for (let i = 0; i < frameCount; i += 1) {
            setRasterLayerOpacity(map, frames[i], 0);
            setRasterLayerOpacity(map, frames[parseInt(rangeValue)], 1);
        }
    }
    var hours = moment().hours();
    var minutes = moment().minutes();
    var displayTime;
    var totalMinutes = parseInt(minutes) + parseInt(rangeValue * 30);
    var displayMinutes = '';
    if (totalMinutes >= 60 && totalMinutes < 120) {
        hours = hours + 1;
        displayMinutes = (totalMinutes - 60) < 10 ? '0' + (totalMinutes - 60) : totalMinutes - 60;
        displayTime = (hours > 12 ? (hours - 12) < 10 ? '0' + (hours - 12) : hours - 12 : hours < 10 ? '0' + hours : hours) + ':' + displayMinutes;
    }
    else if (totalMinutes >= 120 && totalMinutes < 180) {
        hours = hours + 2;
        displayMinutes = (totalMinutes - 120) < 10 ? '0' + (totalMinutes - 120) : totalMinutes - 120;
        displayTime = (hours > 12 ? (hours - 12) < 10 ? '0' + (hours - 12) : hours - 12 : hours < 10 ? '0' + hours : hours) + ':' + displayMinutes;
    }
    else if (totalMinutes >= 180 && totalMinutes < 240) {
        hours = hours + 3;
        displayMinutes = (totalMinutes - 120) < 10 ? '0' + (totalMinutes - 180) : totalMinutes - 180;
        displayTime = (hours > 12 ? (hours - 12) < 10 ? '0' + (hours - 12) : hours - 12 : hours < 10 ? '0' + hours : hours) + ':' + displayMinutes;
    }
    else if (totalMinutes >= 240 && totalMinutes < 300) {
        hours = hours + 4;
        displayMinutes = (totalMinutes - 120) < 10 ? '0' + (totalMinutes - 240) : totalMinutes - 240;
        displayTime = (hours > 12 ? (hours - 12) < 10 ? '0' + (hours - 12) : hours - 12 : hours < 10 ? '0' + hours : hours) + ':' + displayMinutes;
    }
    else if (totalMinutes >= 300) {
        hours = hours + 5;
        displayMinutes = (totalMinutes - 120) < 10 ? '0' + (totalMinutes - 300) : totalMinutes - 300;
        displayTime = (hours > 12 ? (hours - 12) < 10 ? '0' + (hours - 12) : hours - 12 : hours < 10 ? '0' + hours : hours) + ':' + displayMinutes;
    }
    else {
        displayMinutes = totalMinutes < 10 ? '0' + totalMinutes : totalMinutes;
        displayTime = (hours > 12 ? (hours - 12) < 10 ? '0' + (hours - 12) : hours - 12 : hours < 10 ? '0' + hours : hours) + ':' + displayMinutes;
    }
    document.getElementById('current-timer').innerHTML = displayTime + ' ' + moment(hours, 'HH:mm').format('A') + ' ' + timezone;


}

function resetWeather (){
    var currentTime = moment().format('LT');
    document.getElementById('timer').disabled = true;
    document.getElementById('timer').value = 0;
    document.getElementById('current-timer').innerHTML = (parseInt(currentTime.charAt(0)) < 10 ? '0'+moment().format('LT') : moment().format('LT')) + ' ' + timezone;
    removeGifLayers();
    stopAnimation();
    currentIndex = 0;
    startMinutes = Math.round(new Date().getTime() / 1000);
    addRasterLayer(startMinutes, 1, 0);
}

export function removeGifLayers() {
    for (var i = 0; i < frames.length; i++) {
        if (map.getLayer(frames[i])) {
            map.removeLayer(frames[i]);
            map.removeSource(frames[i]);
        }
    }
    frames = [];
    gifIndex = 0;
    localStorage.setItem("gifIndex", 1);
    
}

export function clearFrames() {
    var currentTime = moment().format('LT');
    clearInterval(timer);
    clearInterval(playDoppler);

    frames = [];
    gifIndex = 0;
    localStorage.setItem("gifIndex", 1);
    if(document.getElementById('pause-icon')){
        document.getElementById('pause-icon').style.display = 'none';
    }
    if(document.getElementById('play-icon')){
        document.getElementById('play-icon').style.display = 'block';
    }
    if(document.getElementById('timer')){
        document.getElementById('timer').disabled = true;
        document.getElementById('timer').value = 0; 
    }
    if(document.getElementById('current-timer')){
        document.getElementById('current-timer').innerHTML = (parseInt(currentTime.charAt(0)) < 10 ? '0'+moment().format('LT') : moment().format('LT')) + ' ' + timezone;
    }
}
