import React from 'react';
import '../styles/moreInfo.scss';
import { BsChevronDown, BsX } from "react-icons/bs";
import uberSvg from '../assets/uber.svg';

class MoreInfoPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props === nextProps) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        return (
            <>
                <div className={`moreinfo-panel ${this.props.className}`}>
                    <BsX className='close-panel hand-cursor' onClick={this.props.close}>close</BsX>
                    {this.props.addInfo && !this.props.uberInfo && this.props.moreInfo !== '' ?
                        <div className='moreinfo-content'>
                            <h6>Additional Info</h6>
                            <div className='info-list'>
                                <div className='d-flex align-items-baseline'><BsChevronDown /><h4>Postal Address</h4></div>
                                <div className='text'>
                                    <div>House Number: {this.props.moreInfo.properties.postalAddress.houseNo}</div>
                                    <div>Street Name: {this.props.moreInfo.properties.postalAddress.streetName}</div>
                                    <div>Street Type: {this.props.moreInfo.properties.postalAddress.streetType}</div>
                                    <div>Community: {this.props.moreInfo.properties.postalAddress.community}</div>
                                    <div>State: {this.props.moreInfo.properties.postalAddress.state}</div>
                                    <div>Residence/Business Name: {this.props.moreInfo.properties.postalAddress.residence}</div>
                                    <div>Country Code: {this.props.moreInfo.properties.postalAddress.country}</div>
                                </div>
                            </div>
                            <div className='info-list'>
                                <div className='d-flex align-items-baseline'><BsChevronDown /><h4>Geocode Service Address</h4></div>
                                <div className='text'>{this.props.moreInfo.properties.address}</div>
                            </div>
                            <div className='info-list'>
                                <div className='d-flex align-items-baseline'><BsChevronDown /> <h4>Latitude and Longitude</h4></div>
                                {this.props.moreInfo.geometry.coordinates.length ?
                                    <div className='text'>{this.props.moreInfo.geometry.coordinates[this.props.moreInfo.geometry.coordinates.length - 1][0].toFixed(6)}, {this.props.moreInfo.geometry.coordinates[this.props.moreInfo.geometry.coordinates.length - 1][1].toFixed(6)}</div>
                                    : ''}
                            </div>
                            <div className='info-list'>
                                <div className='d-flex align-items-baseline'><BsChevronDown /> <h4>Class of Service</h4></div>
                                <div className='text'></div>
                            </div>
                            <div className='info-list'>
                                <div className='d-flex align-items-baseline'><BsChevronDown /> <h4>Incident Service Provider</h4></div>
                                <div className='text'>
                                    <div>Provide ID: {this.props.moreInfo.properties.incident.provider.provideId}</div>
                                    <div>Contact URL: {this.props.moreInfo.properties.incident.provider.url}</div>
                                    <div>Language: {this.props.moreInfo.properties.incident.provider.language}</div>
                                    <div>Data Provider Reference: {this.props.moreInfo.properties.incident.provider.reference}</div>
                                </div>
                            </div>
                        </div>
                        : this.props.uberInfo && !this.props.addInfo && this.props.moreInfo !== '' ?
                            <div className='moreinfo-content uber-info'>
                                <img className='uber-icon' src={uberSvg} />
                                <h6>Uber Info</h6>
                                <div className='info-list'>
                                    <div className='text p-0'>
                                        <div>Name: {this.props.moreInfo.properties.uber.name}</div>
                                        <div>Make: {this.props.moreInfo.properties.uber.make}</div>
                                        <div>Model: {this.props.moreInfo.properties.uber.model}</div>
                                        <div>Color: {this.props.moreInfo.properties.uber.color}</div>
                                        <div>License: {this.props.moreInfo.properties.uber.license}</div>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                    }
                </div>
            </>
        );
    }
}


export default MoreInfoPanel;