import mapboxgl from 'mapbox-gl';
import distance from '@turf/distance';
import './Ruler.scss';

const LAYER_LINE = 'controls-layer-line';
const LAYER_SYMBOL = 'controls-layer-symbol';
const SOURCE_LINE = 'controls-source-line';
const SOURCE_SYMBOL = 'controls-source-symbol';
const MAIN_COLOR = '#263238';
const HALO_COLOR = '#fff';

function geoLineString(coordinates = []) {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates,
    },
  };
}

function geoPoint(coordinates = [], labels = []) {
  return {
    type: 'FeatureCollection',
    features: coordinates.map((coordinate, i) => ({
      type: 'Feature',
      properties: {
        text: labels[i],
      },
      geometry: {
        type: 'Point',
        coordinates: coordinate,
      },
    })),
  };
}

function defaultLabelFormat(number) {
  if (number < 1) {
    return `${(number * 1000).toFixed()} m`;
  }
  return `${number.toFixed(2)} km`;
}

function safeDefault(value, defaultValue) {
  if (typeof value === 'undefined') {
    return defaultValue;
  }
  return value;
}

export default class RulerControl {
  constructor(options = {}) {
    this.isMeasuring = false;
    this.markers = [];
    this.coordinates = [];
    this.labels = [];
    this.units = options.units || 'kilometers';
    this.font = options.font || ['Roboto Medium'];
    this.fontSize = safeDefault(options.fontSize, 12);
    this.fontHalo = safeDefault(options.fontHalo, 1);
    this.textVariableAnchor = options.textVariableAnchor || ['bottom'];
    this.textAllowOverlap = options.textAllowOverlap || true;
    this.markerNodeSize = `${safeDefault(options.markerNodeSize, 12)}px`;
    this.markerNodeBorderWidth = `${safeDefault(options.markerNodeBorderWidth, 2)}px`;
    this.labelFormat = options.labelFormat || defaultLabelFormat;
    this.mainColor = options.mainColor || MAIN_COLOR;
    this.secondaryColor = options.secondaryColor || HALO_COLOR;
    this.mapClickListener = this.mapClickListener.bind(this);
    this.styleLoadListener = this.styleLoadListener.bind(this);
  }

  draw() {

    this.map.addSource(SOURCE_LINE, {
      type: 'geojson',
      data: geoLineString(this.coordinates),
    });

    this.map.addSource(SOURCE_SYMBOL, {
      type: 'geojson',
      data: geoPoint(this.coordinates, this.labels),
    });

    this.map.addLayer({
      id: LAYER_LINE,
      type: 'line',
      source: SOURCE_LINE,
      paint: {
        'line-color': this.mainColor,
        'line-width': 2,
      },
    });

    this.map.addLayer({
      id: LAYER_SYMBOL,
      type: 'symbol',
      source: SOURCE_SYMBOL,
      layout: {
        'text-field': '{text}',
        'text-font': this.font,
        'text-allow-overlap': this.textAllowOverlap,
        'text-variable-anchor': this.textVariableAnchor,
        'text-size': this.fontSize,
        'text-offset': [0, 0.8],
      },
      paint: {
        'text-color': this.mainColor,
        'text-halo-color': this.secondaryColor,
        'text-halo-width': this.fontHalo,
      },
    });
  }

  measuringOn() {
    this.isMeasuring = true;
    this.markers = [];
    this.coordinates = [];
    this.labels = [];
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw();
    this.map.on('click', this.mapClickListener);
    this.map.on('style.load', this.styleLoadListener);
    this.map.on('dblclick', e => {
      if (this.isMeasuring) {
        this.markers[(this.markers.length - 1)].remove();
        this.markers.pop();
        this.labels.pop();
        this.coordinates.pop();
        this.labels = this.coordinatesToLabels();
        this.map.getSource(SOURCE_LINE)
          .setData(geoLineString(this.coordinates));
        this.map.getSource(SOURCE_SYMBOL)
          .setData(geoPoint(this.coordinates, this.labels));
        this.measuringOff();
      }
    })
  }

  measuringOff() {
    this.isMeasuring = false;
    this.map.getCanvas().style.cursor = '';
    this.markers.forEach(marker => {
      marker.setDraggable(false);
    });
    this.map.off('click', this.mapClickListener);
    // this.map.off('style.load', this.styleLoadListener);
  }

  resetMeasurements() {
    if (this.map && this.map.getSource(SOURCE_LINE)) {
      this.map.removeLayer(LAYER_LINE);
      this.map.removeLayer(LAYER_SYMBOL);
      this.map.removeSource(SOURCE_LINE);
      this.map.removeSource(SOURCE_SYMBOL);
      this.markers.forEach(m => m.remove());
      this.markers = [];
    }
  }

  mapClickListener(event) {
    const markerNode = document.createElement('div');
    markerNode.className = 'ruler-marker';
    const marker = new mapboxgl.Marker({
      element: markerNode,
      draggable: true,
    })
      .setLngLat(event.lngLat)
      .addTo(this.map);
    this.coordinates.push([event.lngLat.lng, event.lngLat.lat]);
    this.labels = this.coordinatesToLabels();
    this.map.getSource(SOURCE_LINE)
      .setData(geoLineString(this.coordinates));
    this.map.getSource(SOURCE_SYMBOL)
      .setData(geoPoint(this.coordinates, this.labels));
    this.markers.push(marker);
    marker.on('drag', () => {
      const index = this.markers.indexOf(marker);
      const lngLat = marker.getLngLat();
      this.coordinates[index] = [lngLat.lng, lngLat.lat];
      this.labels = this.coordinatesToLabels();
      this.map.getSource(SOURCE_LINE)
        .setData(geoLineString(this.coordinates));
      this.map.getSource(SOURCE_SYMBOL)
        .setData(geoPoint(this.coordinates, this.labels));
      // UI indicator for clicking/hovering a point on the map
      this.map.getCanvas().style.cursor = this.coordinates.length
        ? 'crosshair'
        : 'crosshair';
    });
  }

  coordinatesToLabels() {
    const { coordinates, units, labelFormat } = this;
    let sum = 0;
    return coordinates.map((coordinate, index) => {
      if (index === 0) return labelFormat(0);
      sum += distance(coordinates[index - 1], coordinates[index], { units });
      return labelFormat(sum);
    });
  }

  styleLoadListener() {
    this.draw();
  }

  addMeasuring(map) {
    this.resetMeasurements();
    this.map = map;
    if (this.isMeasuring) {
      this.measuringOff();
    } else {
      this.measuringOn();
    }
    return this.container;
  }

  removeMeasuring() {
    if (this.isMeasuring) {
      this.measuringOff();
    }
  }
}
