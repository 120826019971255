import {
    FETCH_CALL_DATA_REQUESTED, FETCH_CALL_DATA_SUCCESS, FETCH_CAMERA_DATA_SUCCESS, FETCH_CALL_DATA_FAIL, SET_CAMERAS_RELOADED,
    RENDER_CALLS_PANEL, SET_SELECTED_CAMERA, FETCH_AED_SUCCESS, FETCH_AED_FAIL, FETCH_AED_REQUEST, SET_AED_RELOADED, FETCH_RAPID_SOS_ADDRESS,
    SET_CALL_AUTO_ZOOM, SET_CALL_DEPENDENCIES, FETCH_SOLACOM_CALL_EVENTS
} from './actionTypes';
import axios from 'axios';
import { serverconfigs, featureLabels, callDeclarations, groupFeatures, callsConfigs, configTimers, cancelErrorMessages } from '../../constants';
import { getIdentities } from './whoosterActions';
import { isFeatureAllowed, SolacomGroup } from '../../utils/groupFeatureUtils';
import {getStationIdFromLocalStorage, getEndCallTimeFromLocalStorage, consoleLog } from "../../utils/commonUtils";

var oldCallResponse = null;
var endCallsArray = [];
var interval;
var radpidSOSAddress = null;
var unplottedCalls = [], queuedCalls = [], answeredCalls = [];
export const setCallsData = (payload) => {
    let callsResponse = payload;
    if(!SolacomGroup()){
    if (callsResponse && callsResponse.rapidSOS && callsResponse.rapidSOS.geometry && callsResponse.rapidSOS.geometry.coordinates.length) {   
        callsResponse.rapidSOS.geometry.coordinates = swapCoordinates(callsResponse.rapidSOS.geometry.coordinates);
    }
    if (callsResponse && callsResponse.geometry && callsResponse.geometry.coordinates) {
        callsResponse.geometry.coordinates = swapCoordinates(callsResponse.geometry.coordinates);
    } else {
        callsResponse = "";
    }
    }
    var endCallTimer = getEndCallTimeFromLocalStorage()
    if (payload != null && endCallTimer && endCallTimer != null && endCallTimer != '') {
        if(SolacomGroup()){
            if(callsResponse.eventType === 'EndCall'){
                callsResponse.disconnectTime = new Date().getTime();
                callsResponse.callState = callsConfigs.endStaus;
                endCallsArray.unshift(callsResponse);
                callsResponse = '';
                endCallsArray = [...new Map(endCallsArray.map(item => [item['nenaIncidentId'], item])).values()];
            }
        }
        else{
        if (didCallChanged(callsResponse, oldCallResponse)) {
            oldCallResponse.disconnectTime = new Date().getTime();
            oldCallResponse.callState = callsConfigs.endStaus;
            endCallsArray.unshift(oldCallResponse)
        }
        endCallsArray = [...new Map(endCallsArray.map(item => [item['nenaIncidentId'], item])).values()];
        }
        filterEndCalls()
    } else if (payload === null) {
        endCallsArray = []
    }
    if(SolacomGroup()){
        oldCallResponse = callsResponse
    }
    else{
    if(oldCallResponse && (oldCallResponse.nenaIncidentId === callsResponse.nenaIncidentId && callsResponse.callState === callsConfigs.endStaus)) {
        callsResponse = null
    } else {
        oldCallResponse = callsResponse
    }
    }
    return {
        type: FETCH_CALL_DATA_SUCCESS,
        data: callsResponse,
        endCallsData: endCallsArray,
        invokeCameras: true,
        isLoading: false
    }
}

export const updateRapiSOSAddress = (obj) => {
    if(oldCallResponse && oldCallResponse.rapidSOS){
        oldCallResponse.rapidSOS.address = obj;
    }
    return {
        type: FETCH_RAPID_SOS_ADDRESS,
        data: oldCallResponse
    }
}

export function updateEndCallData(updatedEndCalls) {
    endCallsArray = updatedEndCalls
    return {
        type: FETCH_CALL_DATA_SUCCESS,
        data: oldCallResponse,
        endCallsData: updatedEndCalls,
        invokeCameras: true,
        isLoading: false
    }
}

function filterEndCalls() {
    var tempArray = [];    
    if (endCallsArray && endCallsArray.length) {
        endCallsArray.map(callData => {
            var difference = new Date().getTime() - callData.disconnectTime;
            var diffInMin = Math.round(difference / 1000);
            if (diffInMin < getEndCallTimeFromLocalStorage()) {
                tempArray.push(callData);
            }
        })
    }
    endCallsArray = tempArray;
    return endCallsArray
}

function didCallChanged(newCallData, oldCallData) {
    // if old call data or new data is not available then consider it as first call.
    if(!oldCallData || oldCallData === null || !newCallData || newCallData === "") {
        return false
    } 
    if ((oldCallData.nenaIncidentId !== newCallData.nenaIncidentId && newCallData.callState !== callsConfigs.endStaus) || (oldCallData.nenaIncidentId === newCallData.nenaIncidentId && newCallData.callState === callsConfigs.endStaus)) {
        return true
    }
}

export const getCallsData = () => {
    return function (dispatch) {
        axios.get(serverconfigs.liveFeedUrl + getStationIdFromLocalStorage())
            .then(response => {
                let callsResponse = (response.data);
                callsResponse.cityRadius = response.data.radius;  
                callsResponse.radius = response.data.radius >= 0 ? response.data.radius : 0;
                if(callsResponse.rapidSOS){
                    callsResponse.rapidSOS.radius = callsResponse.rapidSOS.radius >= 0 ? callsResponse.rapidSOS.radius : 0
                }
                callsResponse.callState = callsConfigs.activeStatus;
                setCallsData(callsResponse)
                dispatch(fetchCallsData(callsResponse));
                dispatch(callsPanelReloaded(1));
                if (callsResponse && callsResponse != "" && isFeatureAllowed(featureLabels.person) && response.data.ani && response.data.ani.length > 0) {
                    dispatch(getIdentities(response.data.ani));
                }
            })
            .catch(error => {
                dispatch(fetchCallsData(""));
            })
    }
}

export const getSimulationCallsData = () => {   
    return function (dispatch) {
        var gisId = callDeclarations.defaultGisId
        if (groupFeatures && groupFeatures != null && groupFeatures.gis_id != null && groupFeatures.gis_id != "") {
            gisId = groupFeatures.gis_id
        }
        axios.get(serverconfigs.simulatedLiveFeedUrl + "?api_key=" + serverconfigs.simulatedLiveFeedApiKey + "&gis_id=" + gisId)
                .then(response => {
                    if (response && response.data && response.status === 200) {
                        let newResponse = {};
                        let geometry = {
                            "type": "LineString",
                            "coordinates": [[]]
                        };
                        newResponse.type = response.data.type;
                        newResponse.connectTime = new Date();
                        newResponse.address = response.data.properties.address;
                        newResponse.nenaIncidentId = '';
                        newResponse.ani = response.data.properties.MDN;
                        newResponse.callState = callsConfigs.activeStatus;
                        newResponse.active = true;
                        geometry.coordinates[0] = response.data.geometry.coordinates;
                        newResponse.geometry = geometry;
                        newResponse.radius = response.data.properties.Radius >= 0 ? response.data.properties.Radius : 0;
                        newResponse.cityRadius = response.data.properties.Radius;
                        newResponse.serviceProvider = response.data.properties.provider;
                        newResponse.classOfService = 'WPH2';
                        newResponse.rapidSOS = response.data.rapidSOS;
                        if(response.data.rapidSOS){
                            newResponse.rapidSOS.radius = newResponse.rapidSOS.radius >= 0 ? newResponse.rapidSOS.radius : 0;
                        }
                        newResponse.stationId = null;
                        dispatch(fetchCallsData(newResponse));
                        dispatch(callsPanelReloaded(1));
                        if (isFeatureAllowed(featureLabels.person) && newResponse.ani && newResponse.ani.length > 0) {
                            dispatch(getIdentities(newResponse.ani));
                        }
                    }
                })
                .catch(error => {
                    dispatch(fetchCallsDataFail(error.response.data.message));
                })
    }
}

function swapCoordinates(arr) {
    let geometry = [];
    if (arr) {        
        if(arr.length > 0 ){
            if(arr[0] instanceof Array){
                arr.forEach(coordinate => {
                    if(coordinate && coordinate.length > 1){
                        geometry.push([coordinate[1], coordinate[0]]);
                    }
                });    
            }
        }
    }
    return geometry;
}

export function getCameras(bbox, position, radius) {
    return function (dispatch) {
        let sw = bbox.getSouthWest().wrap();
        let ne = bbox.getNorthEast().wrap();
        const radiusSearch = axios.get(serverconfigs.psapProxyUrl + serverconfigs.cameraPoiPath, {
            params: {
                lat: position[1],
                lon: position[0],
                radius: radius,
                uom: 'm',
                api_key: serverconfigs.apiKey
            }
        }, {
            headers: {}
        });

        const bboxSearchParams = {
            nelat: ne.lat,
            nelon: ne.lng,
            swlat: sw.lat,
            swlon: sw.lng,
            api_key: serverconfigs.apiKey
        };

        const bboxSearch = axios.get(serverconfigs.psapProxyUrl + serverconfigs.cameraBboxPath, {
            params: bboxSearchParams
        }, {
            headers: {}
        });

        axios.all([radiusSearch, bboxSearch]).then(axios.spread((radiusSearch, bboxSearch) => {
            dispatch(fetchCamerasData(radiusSearch.data, bboxSearch.data));
        })).catch(error => {
            consoleLog("Error: ", error);
            dispatch(fetchCamerasData({}, {}));
        });
    }
}

export function fetchAED(searchCenter, searchFeild) {
    return function (dispatch) {
        axios.get(serverconfigs.searchBaseUrl + serverconfigs.addressSearchUrl + '?api_key=' + serverconfigs.searchKey + '&search_center='+ searchCenter[1] +',' + searchCenter[0] +'&query='+searchFeild, {timeout: configTimers.searchAEDTimeOut})
            .then(response => {
                dispatch(fetchAEDData(response.data.places));
            })
            .catch(error => {
                if (error.code == configTimers.cancelStatusCode) {
                    dispatch(fetchAEDFail(cancelErrorMessages.searchTimeOut));
                } else {
                    dispatch(fetchAEDFail(error.response.data.message));
                }                
            })
    }
}

export function fetchCamerasData(radiusSearch, bboxSearch) {
    return {
        type: FETCH_CAMERA_DATA_SUCCESS,
        cameras: { radiusCameras: radiusSearch, bboxCameras: bboxSearch },
        invokeCameras: false,
        camerasReloaded: true
    }
}

export function fetchAEDRequested() {
    return {
        type: FETCH_AED_REQUEST,
        isLoading: false
    }
}

export function fetchAEDData(data) {
    return {
        type: FETCH_AED_SUCCESS,
        data: data,
        AEDReloaded: true
    }
}

export function fetchAEDFail(err) {
    return {
        type: FETCH_AED_FAIL,
        data: err
    }
}

export function fetchCallsData(data) {
    return {
        type: FETCH_CALL_DATA_SUCCESS,
        data: data,
        endCallsData: endCallsArray,
        invokeCameras: true,
        isLoading: false
    }
}

export function fetchCallsDataFail(error) {
    return {
        type: FETCH_CALL_DATA_FAIL,
        data: error,
        isLoading: false
    }
}

export function fetchCallsDataRequested() {
    return {
        type: FETCH_CALL_DATA_REQUESTED,
        isLoading: false
    }
}

export function setCamerasReloaded(isReloaded) {
    return {
        type: SET_CAMERAS_RELOADED,
        camerasReloaded: isReloaded
    }
}

export function setAEDReloaded(isReloaded) {
    return {
        type: SET_AED_RELOADED,
        AEDReloaded: isReloaded
    }
}

export function callsPanelReloaded(val) {
    return {
        type: RENDER_CALLS_PANEL,
        data: val
    }
}

export function setSelectedCamera(val) {
    return {
        type: SET_SELECTED_CAMERA,
        data: val
    }
}

export const setCallAutoZoom = (val) => {
    return {
        type: SET_CALL_AUTO_ZOOM,
        data: val
    }
}

export const setCallDependencies = (obj) => {
    return {
        type: SET_CALL_DEPENDENCIES,
        data: obj
    }
}

export const setSolacomCallsData = (payload) => {
    if(payload.eventType === 'StartCall'){
        unplottedCalls.push(payload);
    }
    if(payload.eventType === 'Position' || payload.eventType === 'Route'){
        queuedCalls.push(payload);
        if(queuedCalls.length){
            queuedCalls.map(queued => {
                if(payload.eventType === 'Route'){
                    queuedCalls = [...new Map(queuedCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }

                unplottedCalls = unplottedCalls.filter(el => {
                    return el.nenaIncidentId !== queued.nenaIncidentId;
                })
                if(payload.eventType === 'Position' && payload.geometry && payload.geometry.coordinates && payload.geometry.coordinates.length > 1){
                    if(queued.nenaIncidentId === payload.nenaIncidentId){
                        queued.geometry.coordinates = payload.geometry.coordinates;
                    }                    
                }
            })
        }
    }
    if(payload.eventType === 'Answer' || payload.eventType === 'TransferCall' || payload.eventType === 'Released' || (payload.eventType === 'Position' && payload.geometry && payload.geometry.coordinates && payload.geometry.coordinates.length > 1)){
        queuedCalls = queuedCalls.filter(el => {
            return payload.nenaIncidentId !== el.nenaIncidentId;
        })
        if(payload.agents && payload.agents.length && getStationIdFromLocalStorage() !== payload.agents[0].agent){
            answeredCalls.push(payload);
            if(answeredCalls.length){
                if(payload.eventType === 'Answer'){
                    answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }
                if(payload.eventType === 'TransferCall'){
                    answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }
                if(payload.eventType === 'Released'){
                    answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
                }
                answeredCalls.map(answer => {
                    if(payload.eventType === 'Position' && answer.nenaIncidentId === payload.nenaIncidentId){
                        if(payload.geometry && payload.geometry.coordinates && payload.geometry.coordinates.length > 1){
                            answer.geometry.coordinates = payload.geometry.coordinates;
                        }
                    }
                })
                answeredCalls = [...new Map(answeredCalls.map(item => [item['nenaIncidentId'], item])).values()];
            }
        }
    }
    if(payload.eventType === 'EndCall'){
        answeredCalls = answeredCalls.filter(el => {
            return payload.nenaIncidentId !== el.nenaIncidentId;
        })
    }
    return {
        type: FETCH_SOLACOM_CALL_EVENTS,
        queuedCalls: queuedCalls,
        unplottedCalls: unplottedCalls,
        answeredCalls: answeredCalls
    }
}
