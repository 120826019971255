import axios from 'axios';
import {serverconfigs, configTimers} from '../constants';

export default class IndoorMapService {
    loadIndoorMaps(){
        return axios.get(serverconfigs.indoorMapUrl, {timeout: configTimers.IndoorMapDataTimeout});
     }

     uploadIndoorMapd(params){
        return axios.post(serverconfigs.uploadIndoorMapUrl,
            params,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }, {timeout: configTimers.IndoorMapDataTimeout})
     }
}