/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


export const secondaryRegion  = {
    "baseUrl":'https://api.dev.smartresponse.net',
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3ee34845-c720-428a-a1cb-f8210db8eccc",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vzljgEkO7",
    "aws_user_pools_web_client_id": "3lu46qfp51r8mqj2lp70802mrq",
    "aws_notification_host": "a13mxjnpgsczwk-ats.iot.us-east-1.amazonaws.com",
    "aws_notification_region": "us-east-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "geoservices-devva",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d37fxq3lxpytwe.cloudfront.net",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://gjl56w2enb.execute-api.us-east-1.amazonaws.com/devva",
            "region": "us-east-1"
        }
    ]
};

export const primaryRegion = {
    "baseUrl":'https://api.dev.smartresponse.net',
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:bf31b0de-7c1a-455d-9ae7-5a8e2bc8db00",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_RDFd7EtFI",
    "aws_user_pools_web_client_id": "1g16q210e1skfcsvakpg2rojo3",
    "oauth": {},
    "aws_content_delivery_bucket": "geoservices-devnew",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d1k8cxr3n2p90v.cloudfront.net",
    "aws_notification_host": "a13mxjnpgsczwk-ats.iot.us-west-2.amazonaws.com",
    "aws_notification_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://kc45b672s9.execute-api.us-west-2.amazonaws.com/devnew",
            "region": "us-west-2"
        }
    ]
};