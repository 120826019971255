import {
    FETCH_CALL_DATA_REQUESTED, FETCH_CALL_DATA_SUCCESS, FETCH_CAMERA_DATA_SUCCESS, FETCH_CALL_DATA_FAIL, SET_CAMERAS_RELOADED,
    RENDER_CALLS_PANEL, SET_SELECTED_CAMERA, FETCH_AED_SUCCESS, FETCH_AED_FAIL, FETCH_AED_REQUEST, SET_AED_RELOADED, SET_CALL_AUTO_ZOOM,
    SET_CALL_DEPENDENCIES
} from '../actions/actionTypes';


const initialState = {
    error: '',
    isLoading: false
}

export const CallsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALL_DATA_REQUESTED:
            return { ...state, isLoading: action.isLoading }
        case FETCH_CALL_DATA_SUCCESS:
            return { isLoading: action.isLoading, data: action.data, endCallsData: action.endCallsData, invokeCameras: action.invokeCameras, error: '' }
        case FETCH_CALL_DATA_FAIL:
            return { error: action.data, isLoading: action.isLoading }
        case FETCH_CAMERA_DATA_SUCCESS:
            return { ...state, cameras: action.cameras, invokeCameras: action.invokeCameras, camerasReloaded: action.camerasReloaded }
        case SET_CAMERAS_RELOADED:
            return { ...state, camerasReloaded: action.camerasReloaded }
        case SET_SELECTED_CAMERA:
            return { ...state, selectedCamera: action.data }
        default:
            return state;
    }
}

const AEDState = {
    error: '',
    isLoading: false
}

export const AEDReducer = (state = AEDState, action) => {
    switch (action.type) {
        case FETCH_AED_REQUEST:
            return { ...state, isLoading: action.isLoading }
        case FETCH_AED_SUCCESS:
            return { isLoading: action.isLoading, AEDList: action.data, AEDReloaded: action.AEDReloaded, error: '' }
        case FETCH_AED_FAIL:
            return { error: action.data, isLoading: action.isLoading }
        case SET_AED_RELOADED:
            return { ...state, AEDReloaded: action.AEDReloaded }
        default:
            return state;
    }
}


const renderCalls = 0;

export const RenderCallsReducer = (state = renderCalls, action) => {
    switch (action.type) {
        case RENDER_CALLS_PANEL:
            return {
                ...state,
                renderCalls: action.data
            }
        default:
            return state;
    }
}

const callAutoZoom = {callAutoZoom: false};
export const CallAutoZoomdReducer = (state = callAutoZoom, action) => {
    switch (action.type) {
        case SET_CALL_AUTO_ZOOM:
            return {
                ...state,
                callAutoZoom: action.data
             }
        default:
            return state;
    }
}

const dependencies = {};
export const CallDependenciesReducer = (state = dependencies, action) => {
    switch (action.type) {
        case SET_CALL_DEPENDENCIES:
            return {
                ...state,
                dependencies: action.data
             }
        default:
            return state;
    }
}