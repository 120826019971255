import React, { Component } from 'react';
import Draggable, { DraggableCore } from 'react-draggable';
import Modal from 'react-bootstrap/Modal';
import '../../styles/camerapanel.scss';
// import { MdVideocam } from "react-icons/md";
import { FaExpand } from "react-icons/fa";
import { BsX } from "react-icons/bs";

export default class CameraPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandModal: false,
            activeDrags: 0
        };
        this.windowWidth = window.innerWidth/2 - 256;
    }

    static getDerivedStateFromProps(props, state) {
        state.expandModal = props.expandModal
        return state
    }

    onStart = () => {
        this.setState({ activeDrags: ++this.state.activeDrags });
    };

    onStop = () => {
        this.setState({ activeDrags: --this.state.activeDrags });
    };

    handleClose = () => {
        this.props.hide();
    }

    render() {
        const dragHandlers = { onStart: this.onStart, onStop: this.onStop, enableUserSelectHack: false};
        
        return (
            <>
                {this.props.show ?
                    <Draggable bounds="body" defaultPosition={{x: this.windowWidth, y: -25}} {...dragHandlers}>
                        <div className={`camera-panel ${this.state.expandModal ? 'expand-modal' : ''}`} >
                            <div role="document" className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" onClick={this.handleClose} onTouchStart={this.handleClose}>
                                            <span className="close-popup" ><BsX className="hand-cursor">close</BsX></span>
                                        </button>
                                    </div>
                                    <Modal.Body>
                                        <img className="h-100" src={this.props.cameraImageUrl} />
                                    </Modal.Body>
                                    <Modal.Footer className='justify-content-end'>
                                        {/* <MdVideocam className='video-icon'>camera</MdVideocam> */}
                                        <FaExpand className='hand-cursor' onClick={this.props.handleExpand} onTouchStart={this.props.handleExpand}>expand</FaExpand>
                                    </Modal.Footer>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                    : ''
                }
            </>
        )
    }
}
