import {
  callDeclarations, 
  displayLogs,
  serverconfigs, indoorMapObj, vendor, groupFeatures
} from "../constants";
import {primaryRegion, secondaryRegion} from "../awsexport-client";
import * as turfHelper from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import turfDistance from '@turf/distance';
var turfPoint = require("turf-point");

export function adjustPopup() {
  var newAnchor = "";
  if (this.markers && this.markers.length > 0) {
      this.markers.map((pin, index) => {
          const pinPopup = pin.getPopup();
          if(pinPopup.isOpen()) {
              // Get right limit from one of the icons
              const drawToolsBounds = this.props.map.getContainer().getElementsByClassName('icon-measurement')[0].getBoundingClientRect();
              // Figure out bounding box for search box
              const searchBoxBounds = this.props.map.getContainer().getElementsByClassName('searchLayout')[0].getBoundingClientRect();
              // Figure out bounding box for search results box)
              var searchResults;
              var searchResultsBounds;
              searchResults = this.props.map.getContainer().getElementsByClassName('intersection');
              if (searchResults.length) {
                  searchResultsBounds = this.props.map.getContainer().getElementsByClassName('intersection')[0].getElementsByTagName('form')[0].getBoundingClientRect();
              }
              else {
                  searchResults = this.props.map.getContainer().getElementsByClassName('list-container');
                  if (searchResults.length) {
                      searchResultsBounds = this.props.map.getContainer().getElementsByClassName('list-container')[0].getBoundingClientRect();
                  }
              }
              // Get point location for popup anchor
              const popupLocation = this.props.map.project(pinPopup.getLngLat());
              // Figure out popup bounds
              const popupBounds = pinPopup.getElement().getBoundingClientRect();
              // Get map bounds
              const mapBounds = this.props.map.getContainer().getBoundingClientRect();
              const oldAnchor = pinPopup.options.hasOwnProperty('anchor') ? pinPopup.options.anchor : "";
              // Calculate popup bounds to check against
              const popupBottom = popupLocation.y;
              const popupTop = popupLocation.y - popupBounds.height;
              const popupLeft = popupLocation.x + mapBounds.left - (popupBounds.width / 2);
              const popupRight = popupLocation.x + mapBounds.left + (popupBounds.width / 2);
              // Check search box overlap
              if (popupTop < searchBoxBounds.bottom) {
                  if (popupLeft < searchBoxBounds.right) {
                      newAnchor = "top-left";
                  }
                  if (popupRight < searchBoxBounds.right) {
                      newAnchor = "top";
                  }
              }
              if (searchResults.length) {
                  // Check search results box
                  if ((popupTop < searchResultsBounds.bottom) && (popupLeft < searchResultsBounds.right)) {
                      newAnchor = "top-left";
                  }
                  if ((popupTop < searchResultsBounds.bottom) && (popupRight < searchResultsBounds.right)) {
                      newAnchor = "top";
                  }
                  if ((popupBottom < searchResultsBounds.bottom) && (popupLeft < searchResultsBounds.right)) {
                      newAnchor = "left";
                  }
                  if ((popupBottom < searchResultsBounds.bottom) && (popupRight < searchResultsBounds.right)) {
                      newAnchor = "top-left";
                  }
              }
              // Check tools overlap
              if (popupRight > drawToolsBounds.left) {
                  switch (newAnchor) {
                      case "left":
                          newAnchor = "top";
                          break;
                      case "top-left":
                          newAnchor = "top";
                          break;
                      case "top":
                          newAnchor = "top-right";
                          break;
                      default:
                          newAnchor = "right";
                          break;
                  }
              }
              if ( newAnchor != oldAnchor) {
                  if (newAnchor == "") {
                      delete(pinPopup.options.anchor);
                  }
                  else {
                      pinPopup.options.anchor = newAnchor;
                  }
                  pinPopup.remove();
                  pin.setPopup(pinPopup);
                  pinPopup.addTo(this.props.map);
              }
          }
      })
  }
}

export function turnonMapDrag(item) {
  if(item.props.map)
    item.props.map.on('drag', adjustPopup.bind(item));
}

export function turnoffMapDrag(item) {
  if(item.props.map)
    item.props.map.off('drag', adjustPopup.bind(item));
}


export function isEmpty(str) {
  if (!str || str.trim().length === 0) {
    return false;
  }
  return true;
}

export function setSimulationEnabled() {
  if (callDeclarations.enableSimulation) {
    let simulationDisabled = sessionStorage.getItem("isSimulationEnabled");
    if (simulationDisabled === null) {
      sessionStorage.setItem("isSimulationEnabled", true);
      callDeclarations.isSimulationEnabled = true;
    }
  }
}

export const setStationIdInLocalStorage = (name, stationId) => {
  /* setting the station Id pref for multiple users in ocal storage */
  var temp = [];
  let stationIdArray = localStorage.getItem("stationIdPref") // Get Previous station Id lists to temp = []
  if (stationIdArray !== null) {
    temp = [...JSON.parse(stationIdArray)]
    // const people = this.state.people.slice();
    const index = temp.findIndex(item => item.userName === name);
    if(index >= 0) {
      temp[index].stationId = stationId;
    } else {
      temp.push({
        userName: name,
        stationId: stationId,
      })
    }
  } else {
    temp.push({
      userName: name,
      stationId: stationId,
    })
  }
  localStorage.setItem("stationIdPref", JSON.stringify(temp))
};

export const getStationIdFromLocalStorage = () => {
  var stationId = null;
  var stationIdList = JSON.parse(localStorage.getItem("stationIdPref"));
  var currentUser = sessionStorage.getItem("currentUser") || sessionStorage.getItem("user");
  if(stationIdList && stationIdList != null && currentUser && currentUser != null) {
    stationIdList.map(station => {
      if(station.userName === currentUser) {
        stationId = station.stationId;
        return stationId;
      }
    }) 
    return stationId;
  }
  return stationId;
}

export const setEndCallTimeInLocalStorage = (userName, endCallTime) => {
  var temp = [];
  let endCallTimerArray = localStorage.getItem("endCallTime") // Get Previous endCallTimer lists to temp = []
  if (endCallTimerArray !== null) {
    temp = [...JSON.parse(endCallTimerArray)]
    const index = temp.findIndex(item => item.userName === userName);
    if(index >= 0) {
      temp[index].endCallTimer = endCallTime;
    } else {
      temp.push({
        userName: userName,
        endCallTimer: endCallTime,
      })
    }
  } else {
    temp.push({
      userName: userName,
      endCallTimer: endCallTime,
    })
  }
  localStorage.setItem("endCallTime", JSON.stringify(temp))
};

export const getEndCallTimeFromLocalStorage = () => {
  var endCallTime = null;
  var endCallTimeList = JSON.parse(localStorage.getItem("endCallTime"));
  var currentUser = sessionStorage.getItem("user");
  if(endCallTimeList && endCallTimeList != null && currentUser && currentUser != null) {
    endCallTimeList.map(endCall => {
      if(endCall.userName === currentUser) {
        endCallTime = endCall.endCallTimer;
        return endCallTime;
      }
    }) 
    return endCallTime;
  }
  return endCallTime;
}

export const isPointedToSecondaryRegion = () => {
  var pointedToSecondaryRegion = false
  var currentRegion = localStorage.getItem('currentRegion')
  if ((currentRegion != null || currentRegion) && currentRegion === secondaryRegion.aws_project_region) {
    pointedToSecondaryRegion = true
  }
  return pointedToSecondaryRegion;
}

export const consoleLog = (consoleMessage, info) => {
  if (displayLogs === true || displayLogs === 'true') {
    if (info) console.log(consoleMessage, info);
    else console.log(consoleMessage);
  }
}

export const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const calculateIndoorBBbox = (list, map) => {
  let isInside = false;
  if (map && list && list.coordinates && list.coordinates.length && map.getZoom() >= indoorMapObj.indoorMapZoomLevel) {
      // Get the map's current bounds
      const bounds = map.getBounds();
      // Convert the bounds to a Turf.js polygon feature
      const mapPolygonObj = turfHelper.polygon([[
          [bounds.getWest(), bounds.getSouth()],
          [bounds.getEast(), bounds.getSouth()],
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()]
      ]]);
      
      const indoorPolygonObj = turfHelper.polygon([[
          ...list.coordinates,list.coordinates[0]
      ]]);
      isInside =  booleanIntersects(mapPolygonObj, indoorPolygonObj);
      // if (isInside) {
      //     consoleLog(':::: The coordinates are within the bounding box');
      // } else {
      //     consoleLog(':::: The coordinates are not within the bounding box');
      // }
  }
  return isInside;
}

export const  prepareClustersGeojson = (device) => {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: device.geometry && device.geometry.coordinates && device.geometry.coordinates.length ? swipeCoordinates(device) : []
    },
    properties: getPropertiesObj(device)
  }
} 

const swipeCoordinates = (obj) => {
  return [
    obj.geometry.coordinates[obj.geometry.coordinates.length - 1][1],
    obj.geometry.coordinates[obj.geometry.coordinates.length - 1][0]
  ]
}

export const  UpdatePrepareClustersGeojson = (device) => {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: device && device.coordinates && device.coordinates.length ? swipeUpdateCoordinates(device) : []
    },
    properties: getPropertiesObj(device)
  }
} 

const swipeUpdateCoordinates = (obj) => {
  return [
    obj.coordinates[1],
    obj.coordinates[0]
  ]
}


const getPropertiesObj = (obj) => {
  return {
    trackId: obj.id,
    deviceType: obj.deviceType,
    vehicleType: obj.type,
    address: obj.address,
    ignition: obj.ignition,
    status: obj.status,
    label: obj.label,
    odometer: obj.odometer,
    recorded_time: obj.recorded_time,
    runtime_minutes: obj.runtime_minutes,
    speed: obj.speed,
    speed_limit: obj.speed_limit,
    verified_time: obj.verified_time,
    heading: obj.heading,
    speed_label: obj.speed_label,
    textField: obj.textField,
    opacity: obj.opacity
  }
}

export const createDonutChart = (props, colors) => {
  const offsets = [];
  const counts = [
      props.highStatus,
      props.mediumStatus,
      props.lowStatus
  ];
  let total = 0;
  for (const count of counts) {
      offsets.push(total);
      total += count;
  }
  const fontSize =
      total >= 50 ? 12 : total >= 20 ? 11 : total >= 10 ? 11 : 10;
  const r =
      total >= 50 ? 32 : total >= 20 ? 24 : total >= 10 ? 18 : 14;
  const r0 = Math.round(r * 0.6);
  const w = r * 2;

  let html = `<div>
  <svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle"; font: ${fontSize}px sans-serif;>`;

  for (let i = 0; i < counts.length; i++) {
      html += donutSegment(
          offsets[i] / total,
          (offsets[i] + counts[i]) / total,
          r,
          r0,
          colors[i]
      );
  }
  html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
  <text dominant-baseline="central" transform="translate(${r}, ${r})">
      ${total.toLocaleString()}
  </text>
  </svg>
  </div>`;

  var el = document.createElement('div');
  el.innerHTML = html;
  return el.firstChild;
}

const donutSegment = (start, end, r, r0, color) =>{
  if (end - start === 1) end -= 0.00001;
  const a0 = 2 * Math.PI * (start - 0.25);
  const a1 = 2 * Math.PI * (end - 0.25);
  const x0 = Math.cos(a0),
      y0 = Math.sin(a0);
  const x1 = Math.cos(a1),
      y1 = Math.sin(a1);
  const largeArc = end - start > 0.5 ? 1 : 0;

  // draw an SVG path
  return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
      } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
      } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
      }" fill="${color}" />`;
}

export const calculateUpdateMarkerBBbox = (list, map) => {
  let isInside = false;
  if (map && list && list.geometry.coordinates && list.geometry.coordinates.length) {
      // Get the map's current bounds
      const bounds = map.getBounds();
      // Convert the bounds to a Turf.js polygon feature
      const mapPolygonObj = turfHelper.polygon([[
          [bounds.getWest(), bounds.getSouth()],
          [bounds.getEast(), bounds.getSouth()],
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()]
      ]]);
      
      var point = turfPoint([list.geometry.coordinates[list.geometry.coordinates.length - 1][1], list.geometry.coordinates[list.geometry.coordinates.length - 1][0]]);
      isInside =  booleanIntersects(mapPolygonObj, point);
      // if (isInside) {
      //     consoleLog(':::: marker update within the bounding box');
      // } else {
      //     consoleLog(':::: marker update is not within the bounding box');
      // }
  }
  return isInside;
}
export const interpolate = (a, b, frac)  => { // points A and B, frac between 0 and 1
  let points = []
  for (let index = 0; index < frac; index++) {
      var nx = a[0] + (b[0] - a[0]) * index * (1 / frac);
      var ny = a[1] + (b[1] - a[1]) * index * (1 / frac);
      points.push([nx, ny])
  }
  return points;
}
export const getTraxmateVendorFromGP = ()  => { // points A and B, frac between 0 and 1
  let groupVendor = '', vendorList = '';
      if (groupFeatures && groupFeatures.avl_vendor) {
        vendorList = groupFeatures.avl_vendor;
        if(Array.isArray(vendorList)){
          for (let i = 0; i < vendorList.length; i++) {
            if (vendorList[i].includes(vendor.traxmate)) {
              groupVendor = vendorList[i];
              return groupVendor;
            }
          }
        }
        else{
          groupVendor = groupFeatures.avl_vendor;
        }
      }
      return groupVendor;
}
export const calculateHistoryMarkerBBbox = (list, map) => {
  let isInside = false;
  if (map && list && list.geometry.coordinates && list.geometry.coordinates.length) {
      // Get the map's current bounds
      const bounds = map.getBounds();
      // Convert the bounds to a Turf.js polygon feature
      const mapPolygonObj = turfHelper.polygon([[
          [bounds.getWest(), bounds.getSouth()],
          [bounds.getEast(), bounds.getSouth()],
          [bounds.getEast(), bounds.getNorth()],
          [bounds.getWest(), bounds.getNorth()],
          [bounds.getWest(), bounds.getSouth()]
      ]]);
      
      var point = turfPoint(list.geometry.coordinates);
      isInside =  booleanIntersects(mapPolygonObj, point);
      // if (isInside) {
      //     consoleLog(':::: history marker update within the bounding box');
      // } else {
      //     consoleLog(':::: history marker update is not within the bounding box');
      // }
  }
  return isInside;
}