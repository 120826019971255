import React, { Component } from 'react';
import Draggable, { DraggableCore } from 'react-draggable';
import Modal from 'react-bootstrap/Modal';
import '../../styles/camerapanel.scss';
// import { MdVideocam } from "react-icons/md";
import { FaExpand } from "react-icons/fa";

function Iframe(props) {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

export default class StreamingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDrags: 0
        };
        this.windowWidth = window.innerWidth / 2 - 256;
    }

    static getDerivedStateFromProps(props, state) {
        return state
    }

    onStart = () => {
        this.setState({ activeDrags: ++this.state.activeDrags });
    };

    onStop = () => {
        this.setState({ activeDrags: --this.state.activeDrags });
    };

    handleClose = () => {
        this.props.hide();
    }

    render() {
        const dragHandlers = { onStart: this.onStart, onStop: this.onStop, enableUserSelectHack: false };
        const iframeDiv = {
            streamUrl: `<iframe scrolling="no" frameborder="no" allow="autoplay" style="height:260px; width:100%" 
            src=${this.props.videoUrl}></iframe>`,
        };

        return (
            <>
                {this.props.show ?
                    <Draggable bounds="body" defaultPosition={{ x: this.windowWidth, y: -25 }} {...dragHandlers}>
                        <div className={`camera-panel ${this.state.expandModal ? 'expand-modal' : ''}`} >
                            <div role="document" className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" onClick={this.handleClose} onTouchStart={this.handleClose}>
                                            <span aria-hidden="true">×</span><span className="sr-only"></span>
                                        </button>
                                    </div>
                                    <Modal.Body>
                                        <div className='iframe-video'>
                                            <Iframe iframe={iframeDiv["streamUrl"]} allow="autoplay" />
                                        </div>
                                    </Modal.Body>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                    : ''
                }
            </>
        )
    }
}
