import {trackingDeviceDeclarations, configTimers, vendor, featureLabels, indoorMapObj, errorMessages, groupFeatures} from '../constants'

export function isIndoorDataAvailable(device) {
    if (device && device.hasOwnProperty('indoor') && device.indoor && device.indoor.isIndoor) {
        if (indoorMapObj.indoorMapsList && indoorMapObj.indoorMapsList.length) {
            indoorMapObj.indoorMapsList.forEach(indoorObj => {
                if(indoorObj.name.toLowerCase().includes(device.indoor.building.toLowerCase())){
                // if(indoorObj.name === device.indoor.building) {
                    indoorMapObj.indoorMapZoomLevel = indoorObj.zoom
                    return true;
                }
            });
        }
        return true;
    } else {
        return false;
    }
  }