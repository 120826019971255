import axios from 'axios';
import {serverconfigs, configTimers} from '../constants';

export default class SearchServices {

    getSuggestions(searchCenter, searchTerm, source){

        const suggestionUrl = serverconfigs.searchBaseUrl + serverconfigs.suggestionUrl;
        return axios.get(suggestionUrl, {cancelToken: source.token,
            params: {
                api_key: serverconfigs.searchKey,
                query: searchTerm,
                search_center: searchCenter.lat + ',' + searchCenter.lng
            },
            timeout: configTimers.searchCallTimeOut
        });
    }

    getSearchResultsBySuggestionFilter(searchCenter, searchTerm){
        const poiUrl = serverconfigs.searchBaseUrl + serverconfigs.poiSearchUrl;
        return axios.get(poiUrl, {
            params: {
                api_key: serverconfigs.searchKey,
                search_id: searchTerm,
                search_center: searchCenter.lat + ',' + searchCenter.lng
            }, timeout: configTimers.searchCallTimeOut
        });
    }

    getAddressSearchResultsByQuery(searchCenter, searchTerm) {
        const addressSearchUrl = serverconfigs.searchBaseUrl + serverconfigs.addressSearchUrl;

        return axios.get(addressSearchUrl, {
            params: {
                api_key: serverconfigs.searchKey,
                query: searchTerm,
                search_center: searchCenter.lat + ',' + searchCenter.lng,
                uid: serverconfigs.uid
            }, timeout: configTimers.searchCallTimeOut
        });
    }

    getReverseGeocode(lngLat) {
        let searchUrl = serverconfigs.searchBaseUrl + serverconfigs.reversegeocodeUrl;
        return axios.get(searchUrl, {params:{
            api_key: serverconfigs.searchKey,
                search_center: lngLat,
                named_road: 'True'
         }, timeout: configTimers.reverseGeocodeTimeOut
        });
    }

    getAddressSearchResultsByPoiId(searchCenter, poiId) {
        const addressSearchUrl = serverconfigs.searchBaseUrl + serverconfigs.addressSearchUrl;

        if (addressSearchUrl.includes('v2')) {
            return axios.get(addressSearchUrl, {
                params: {
                    api_key: serverconfigs.searchKey,
                    point_address_id: poiId,
                    uid: serverconfigs.uid,
                    search_center: `${searchCenter.lat}, ${searchCenter.lng}`,
                }
            });
        } else {
            return axios.get(addressSearchUrl, {
                params: {
                    api_key: serverconfigs.searchKey,
                    place_id: "PA:" + poiId,
                    uid: serverconfigs.uid,
                    search_center: searchCenter.lat + ',' + searchCenter.lng
                }
            });
        }
    }

    getAddressSearchResultsByW3W(w3wSearchText) {
        const w3wSearchUrl = serverconfigs.searchBaseUrl + serverconfigs.w3wSearchUrl;

        return axios.get(w3wSearchUrl, {
            params: {
                api_key: serverconfigs.searchKey,
                words: w3wSearchText,
            }, timeout: configTimers.searchCallTimeOut
        });
    }

    getW3WSuggestions(searchCenter, w3wSearchText, source){
        const w3wSuggestionUrl = serverconfigs.searchBaseUrl + serverconfigs.w3wSuggestionsUrl;

        return axios.get(w3wSuggestionUrl, {cancelToken: source.token,
            params: {
                api_key: serverconfigs.searchKey,
                words: w3wSearchText,
                search_center: searchCenter.lat + ',' + searchCenter.lng
            },
            timeout: configTimers.searchCallTimeOut
        });
    }
}