import React from 'react';
import '../styles/aboutus.scss';
import { Nav, Tab, Table } from 'react-bootstrap';
import compass from '../assets/compass_dark.png';
import locate from '../assets/btn_locate_off.png';
import zoomIn from '../assets/zoom_in.png';
import zoomOut from '../assets/zoom_out.png';
import mapStyle from '../assets/map-header-icon.png';
import activePin from '../assets/active-pin.svg';
import waitingPin from '../assets/waiting-pin.svg';
import selectPin from '../assets/selected-pin.svg';
import splitIcon from '../assets/split-icon.png';
import routing from '../assets/routing-icon.png';
import traffic from '../assets/traffic-header.png';
import weather from '../assets/weather-header.png';
import uncIcon from '../assets/unc-header.png';
import customLayers from '../assets/custom_layers.png';
import rapidSOS from '../assets/icon-rapidsos.png';
import drawIcon from '../assets/tool_measurement_drawarea.png';
import measurement from '../assets/tool_measurement.png';
import intersection from '../assets/icon_intersection_off.png';
import poiIcon from '../assets/icon_poi_off.png';
import addressIcon from '../assets/icon_address_off.png';
import { BsDownload } from "react-icons/bs";
import { markerImages, serverconfigs, tabUrls } from '../constants';
import completedPin from '../assets/completed-pin.svg';

class Documentation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <div className='about-us documentaion-page'>
                <div className='glossary-header'>
                    <div className='container'>
                        <h2 className='mb-3'>Glossary</h2>
                        <p>Find {serverconfigs.title} related terms, icons and their definitions.</p>
                    </div>
                </div>
                <div className='container'>
                    <div className='d-flex'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="icons">

                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="icons">Icons</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="color">Color</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="terms">Terms</Nav.Link>
                                </Nav.Item> */}
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="icons">
                                    <h4>Icons</h4>
                                    <p>Icons available on Comtech Maps and applications.</p>
                                    <h4>Call Icons:</h4>
                                    <Table bordered responsive size="sm" className='mt-2 call-icons'>
                                        <thead><tr><th>Icon</th>
                                        <th>Code</th>
                                        <th>Description</th>
                                        </tr></thead>
                                        <tbody>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.WPH2} /></td>
                                                <td className='icon-name'>WPH2</td>
                                                <td>{markerImages.serviceList.WPH2}</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.COIN} /></td>
                                                <td className='icon-name'>COIN</td>
                                                <td>Payphone</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.VOIP} /></td>
                                                <td className='icon-name'>VoIP</td>
                                                <td>Voice Over Internet</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.RESD} /></td>
                                                <td className='icon-name'>RESD</td>
                                                <td>Residential Line</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.RESD} /></td>
                                                <td className='icon-name'>BUSN</td>
                                                <td>Business Line</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.WPH1} /></td>
                                                <td className='icon-name'>WPH1</td>
                                                <td>{markerImages.serviceList.WPH1}</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.TELE} /></td>
                                                <td className='icon-name'>TELE</td>
                                                <td>Telematics</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.RESD} /></td>
                                                <td className='icon-name'>CENT</td>
                                                <td>Centrex Line</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={markerImages.incidentIcons.default} /></td>
                                                <td className='icon-name'>Others</td>
                                                <td>Others</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <h4 className='mt-3'>Header Icons:</h4>
                                    <Table bordered responsive size="sm" className='mt-2 call-icons header-icons'>
                                        <thead><tr>
                                            <th>Icon</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                        </tr></thead>
                                        <tbody>
                                            <tr>
                                                <td className='icon-img split-icon'><img src={splitIcon} /></td>
                                                <td className='icon-name'>Split Screen</td>
                                                <td>Add/Remove a second map view to/from current page</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img map-icon'><img src={mapStyle} /></td>
                                                <td className='icon-name'>Map Style</td>
                                                <td>Select a map style</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img route-icon'><img src={routing} /></td>
                                                <td className='icon-name'>Directions/Routing</td>
                                                <td>Generate navigation route(s)</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img route-icon'><img src={traffic} /></td>
                                                <td className='icon-name'>Traffic</td>
                                                <td>Enable/Disable realtime traffic layer</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img route-icon'><img src={weather} /></td>
                                                <td className='icon-name'>Weather</td>
                                                <td>Enable/Disable weather layer</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img map-icon'><img src={uncIcon} /></td>
                                                <td className='icon-name'>Uncertainty</td>
                                                <td>Enable/Disable uncertainty radius</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img route-icon'><img src={customLayers} /></td>
                                                <td className='icon-name'>Custom layers</td>
                                                <td>Enable/Disable custom layer</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img route-icon'><img src={rapidSOS} /></td>
                                                <td className='icon-name'>RapidSOS</td>
                                                <td>RapidSOS positions</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <h4 className='mt-3'>Tool Icons:</h4>
                                    <Table bordered responsive size="sm" className='mt-2 call-icons'>
                                        <thead><tr>
                                            <th>Icon</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                        </tr></thead>
                                        <tbody>
                                            <tr>
                                                <td className='icon-img compass-icon'><img src={measurement} /></td>
                                                <td className='icon-name'>Measure</td>
                                                <td>Measure distance</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img compass-icon'><img src={drawIcon} /></td>
                                                <td className='icon-name'>Draw</td>
                                                <td>Draw and share a polygon </td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img compass-icon'>
                                                    <div className='icon-download'>
                                                        <BsDownload>download</BsDownload>
                                                    </div>
                                                </td>
                                                <td className='icon-name'>Download/Clear Map</td>
                                                <td>Download shared polygons</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <h4 className='mt-3'>Map Icons:</h4>
                                    <Table bordered responsive size="sm" className='mt-2 call-icons'>
                                        <thead><tr>
                                            <th>Icon</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                        </tr></thead>
                                        <tbody>
                                            <tr>
                                                <td className='icon-img compass-icon'><img src={compass} /></td>
                                                <td className='icon-name'>Compass</td>
                                                <td>Compass</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img compass-icon'><img src={locate} /></td>
                                                <td className='icon-name'>Locate</td>
                                                <td>Locate</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img compass-icon'><img src={zoomIn} /></td>
                                                <td className='icon-name'>Zoom in</td>
                                                <td>Zoom in</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img compass-icon'><img src={zoomOut} /></td>
                                                <td className='icon-name'>Zoom out</td>
                                                <td>Zoom out</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <h4 className='mt-3'>Search Icons:</h4>
                                    <Table bordered responsive size="sm" className='mt-2 call-icons'>
                                        <thead><tr>
                                            <th>Icon</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                        </tr></thead>
                                        <tbody>
                                            {/* <tr>
                                                <td className='icon-name'>Address</td>
                                                <td className='icon-img search-icon'><img src={addressIcon} /></td>
                                            </tr> */}
                                            <tr>
                                                <td className='icon-img search-icon'><img src={poiIcon} /></td>
                                                <td className='icon-name'>POI</td>
                                                <td>Search POI or address in free form</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img search-icon'><img src={intersection} /></td>
                                                <td className='icon-name'>Intersection</td>
                                                <td>Find intersection</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab.Pane>
                                <Tab.Pane eventKey="color">
                                    <h4>Color</h4>
                                    <p>On the {tabUrls.incident} tab, for the calls and tiles, the following color legend applies.</p>
                                    <Table bordered responsive size="sm" className='mt-2 tile-color'>
                                        <thead><tr><th colSpan='2'>Tile Color</th></tr></thead>
                                        <tbody>
                                            <tr>
                                                <td className='icon-img'><span className='status-color active'></span></td>
                                                <td className='icon-name'>Active</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><span className='status-color waiting'></span></td>
                                                <td className='icon-name'>Waiting</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><span className='status-color ended'></span></td>
                                                <td className='icon-name'>Ended</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table bordered responsive size="sm" className='mt-3 tile-color'>
                                        <thead><tr><th colSpan='2'>Pin Color</th></tr></thead>
                                        <tbody>
                                            <tr>
                                                <td className='icon-img'><img src={activePin} /></td>
                                                <td className='icon-name'>Active</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={waitingPin} /></td>
                                                <td className='icon-name'>Waiting</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={completedPin}  /></td>
                                                <td className='icon-name'>Ended</td>
                                            </tr>
                                            <tr>
                                                <td className='icon-img'><img src={selectPin} /></td>
                                                <td className='icon-name'>Selected</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab.Pane>
                                <Tab.Pane eventKey="terms">
                                    <h4>Terms</h4>

                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        )
    }
}
export default Documentation;
