
import './nonce.js';
import { IconContext } from "react-icons";
import React from 'react';
import { createRoot } from 'react-dom/client';

import './favicon.ico';
import './styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css'
// import 'animate.css';
import '../src/styles/index.scss'
import App from './App';
import { Provider } from 'react-redux';
import Store from '../src/redux/store';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache'; 

const nonceValue = 'bXlzZWNyZXRub25jZTIx';
const cache = createCache({
    key:"sr-nonce-key",
    nonce: nonceValue,
 });
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<IconContext.Provider value={{ className: 'react-icons' }}>
    <Provider store={Store}>
<CacheProvider value={cache}>
      <App />
  </CacheProvider>
    </Provider>
</IconContext.Provider>);