import React, { Component } from 'react'
import { BsX } from "react-icons/bs";
import SearchServices from '../services/search.services';
import { toast } from 'react-toastify';
import mapboxgl from 'mapbox-gl';
import '../styles/map.scss';
import { createRoot } from 'react-dom/client';
import Pagination from "react-js-pagination";
import { configTimers, NO_DATA, errorMessages, cancelErrorMessages, groupFeatures } from '../constants';
import { turnonMapDrag, turnoffMapDrag } from '../utils/commonUtils';

const toastId = "toast-id";
export class AddressMarkers extends Component {
    markers = [];
    constructor(props) {
        super(props);
        this.searchService = new SearchServices();
        this.state = {
            searchPlaces: [],
            addressCounter: 1,
            renderMarkers: false,
            currentMap: {}
        }
        this.popups = [];
        this.callGecodeAPI = true;
    }

    componentDidMount() {
        this.props.map.on("contextmenu", (e) => {
            if (this.callGecodeAPI) {
                this.setState({
                    currentMap: this.props.map
                })
                this.getReverseGeocode(e.lngLat, e.point);
            }
        });
        if (groupFeatures && groupFeatures.secondMap && this.props.isSatelliteMapLoaded) {
            this.props.satelliteMap.on("contextmenu", (e) => {
                if (this.callGecodeAPI) {
                    this.setState({
                        currentMap: this.props.satelliteMap
                    })
                    this.getReverseGeocode(e.lngLat, e.point);
                }
            });
        }
    }

    handleError = (error, msg) => {
        let errorMessage = msg;
        this.removeMarkers();
        this.setState({ searchPlaces: [] });
        if (error && error.response && error.response.status) {
            errorMessage = error.response.data.detail;
        }
        if (errorMessage && errorMessage.length > 0) {
            toast.dismiss();
            toast.error(errorMessage, { toastId: toastId });
        }
    }

    getReverseGeocode = (lngLat, point) => {
        this.callGecodeAPI = false;
        this.removeMarkers();
        this.searchService.getReverseGeocode(`${lngLat.lat},${lngLat.lng}`)
            .then(res => {
                if (res.status === 200 && res && res.data && res.data.places && res.data.places.length) {
                    if((point.y < 60 && point.x < 345) || point.y < 30 && this.props.map){
                        let lat = this.props.map.getCenter().lat+0.001;
                        let lng = this.props.map.getCenter().lng+0.001;
                        this.props.map.flyTo({
                            center: [lng, lat],
                            bearing: 0,
                            speed: 4, // make the flying slow
                            curve: 4.5, // change the speed at which it zooms out
                            easing: function (t) { return t; }
                        });
                    }
                    res.data.places[0].show = true;
                    this.setState({
                        searchPlaces: res.data.places,
                        renderMarkers: true
                    }, () => {
                        this.callGecodeAPI = true;
                    })
                }
                else {
                    this.callGecodeAPI = true;
                    toast.warning(NO_DATA, { toastId: toastId });
                }
            })
            .catch(error => {
                this.callGecodeAPI = true;
                this.setState({ searchPlaces: [] });
                if (error.code == configTimers.cancelStatusCode) {
                    toast.error(cancelErrorMessages.reverseGeocodeTimeOutMsg, { toastId: toastId });
                } else {
                    this.handleError(error, errorMessages.searchError);
                }
            })
    }

    onPopupClose = () => {
        this.popups.forEach(popup => {
            popup.remove();
        });
        this.setState({
            searchPlaces: []
        })
        this.removeMarkers();
    }

    createMarkers = (map) => {
        var lngLat = null, marker = null;
        if (this.state.renderMarkers) {
            this.removeMarkers();
            this.state.searchPlaces.forEach((place, index) => {
                let el = document.createElement('div');
                el.className = 'search-marker';
                let options = { element: el, anchor: 'center', color: '#ff5a1d', scale: 0.8 };
                var popupOptions = { offset: 16, closeOnClick: true, closeButton: false };
                let location = place.location;
                if (!lngLat || !marker) {
                    lngLat = new mapboxgl.LngLat(location.coordinates[0].longitude, location.coordinates[0].latitude);
                    marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(map);
                }
                if (this.state.searchPlaces.length === 1) {
                    if (place.hasOwnProperty('show') && place.show) {
                        const popup = new mapboxgl.Popup(popupOptions);
                        popup.setDOMContent(this.addPopup(place, location, index));
                        marker.setPopup(popup);
                        popup.addTo(map);
                        this.markers.push(marker);
                        this.popups.push(popup);
                    }
                }
                else {
                    this.paginatedPopup = new mapboxgl.Popup(popupOptions).setDOMContent(this.addPopupContent(place, location, index));
                    if (place.hasOwnProperty('show') && place.show) {
                        this.paginatedPopup.addTo(map);
                        marker.setPopup(this.paginatedPopup);
                    }
                    this.markers.push(marker);
                }
            });
            this.setState({ renderMarkers: false });
            turnonMapDrag(this);
        }
    }

    addPopup(place, location, index) {
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker'>
            <div>
                <span className="close-popup" >
                    <BsX className="hand-cursor" onClick={this.onPopupClose}>close</BsX>
                </span>
            </div>
            {
                place.result_type === 'region' || place.result_type === 'polygon'
                    ? <div className='popup-text'> <div>{place.location.formatted_address}</div> </div>
                    : <div className='popup-text'>
                        <div><b>{place.name}</b></div>
                        <div>{place.location.house_number} {place.location.street}</div>
                        <div>{place.location && place.location.unit && place.location.unit.trim() ? ('Unit ' + place.location.unit) : ''}
                            {place.location && place.location.unit && place.location.unit.trim() &&
                                place.location.floor && place.location.floor.trim() ? ', ' : ''}
                            {place.location && place.location.floor && place.location.floor.trim() ? ('Floor ' + place.location.floor) : ''}</div>
                        <div>{place.location.city}, {place.location.state_code} {place.location.postal}</div>
                    </div>
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    addPopupContent = (place, location, index) => {
        const placeholder = document.createElement('div');
        const jsx = <div className='marker_popup address-marker'>
            <div className='close-popup'>
                <BsX className="hand-cursor" onClick={this.onPopupClose}>close</BsX>
            </div>
            <div className="report-menu d-flex align-items-center justify-content-between">
                {this.state.searchPlaces.length > 1 ?
                <div className='title'><span className='active'>{(index + 1)}</span> of  {this.state.searchPlaces.length}</div>
                : ''}
            </div>
            {
                place.result_type === 'region' || place.result_type === 'polygon'
                    ? <div className='popup-text'> <div>{place.location.formatted_address}</div> </div>
                    : <div className='popup-text'>
                        <div><b>{place.name}</b></div>
                        <div>{place.location.house_number} {place.location.street}</div>
                        <div>{place.location && place.location.unit && place.location.unit.trim() ? ('Unit ' + place.location.unit) : ''}
                            {place.location && place.location.unit && place.location.unit.trim() &&
                                place.location.floor && place.location.floor.trim() ? ', ' : ''}
                            {place.location && place.location.floor && place.location.floor.trim() ? ('Floor ' + place.location.floor) : ''}</div>
                        <div>{place.location.city}, {place.location.state_code} {place.location.postal}</div>
                    </div>
            }
            {
                this.state.searchPlaces.length > 1 ? <Pagination
                    activePage={index + 1}
                    itemsCountPerPage={1}
                    totalItemsCount={this.state.searchPlaces.length}
                    onChange={this.handlePageChange.bind(this, place, index)}
                    firstPageText={''}
                    lastPageText={''}
                    linkClassPrev='arrow-left'
                    linkClassNext='arrow-right'
                /> : ''
            }
        </div>
        var root = createRoot(placeholder);
        root.render(jsx);
        return placeholder
    }

    handlePageChange = (data, id, pageNumber) => {
        let searchPlaces = this.state.searchPlaces;
        searchPlaces.map((list, index) => {
            if ((pageNumber - 1) === index) {
                searchPlaces[index].show = true;
            }
            else {
                searchPlaces[index].show = false;
            }
        })
        this.setState({
            searchPlaces,
            renderMarkers: true
        })
    }

    removeMarkers = () => {
        this.markers.forEach(m => m.remove());
        this.markers = [];
        turnoffMapDrag(this);
    }

    render() {
        return (
            <>
                {
                    this.state.searchPlaces.length && this.state.renderMarkers ?
                        this.createMarkers(this.state.currentMap)
                        : ''
                }
            </>
        )
    }
}

export default AddressMarkers;
