import axios from 'axios';
import {serverconfigs, configTimers, serverUrl} from '../constants';

export default class DatasetService {
    uploadPolygon(geojson, uploadGroup, isGeofence){
        let fileName = geojson.id + '.geojson'; 
        let data = new FormData();
        data.append("file", new Blob([JSON.stringify(geojson)], { type: 'application/json' }), fileName);
        data.append('isGeofence', isGeofence);
        data.append('geofenceId', geojson.id);
        return axios.post(serverUrl.serverBaseUrl + '/sa/' + (uploadGroup ? uploadGroup : serverconfigs.username)+'/v2/geofences/'+ serverconfigs.username +'?api_key=' + serverconfigs.apiKey,
        data,
        {
          headers: {            
            'Content-Type': 'multipart/form-data'
          }, timeout: configTimers.polygonUploadCallTimeOut
        })
    }

    deletePolygon(polygonId, uploadGroup){
        return axios.delete(serverconfigs.serviceBaseUrl + 'datasets/' + (uploadGroup ? uploadGroup : serverconfigs.username) + '/' + polygonId + '.geojson' +'?api_key=' + serverconfigs.apiKey, {timeout: configTimers.polygonUploadCallTimeOut});
    }

    listPolygon(uploadGroup){
       return axios.get(serverUrl.serverBaseUrl + '/sa/' + (uploadGroup ? uploadGroup : serverconfigs.username)+'/v2/geofences/'+ serverconfigs.username +'?api_key=' + serverconfigs.apiKey, {timeout: configTimers.polygonUploadCallTimeOut});
    }

    getPolygon(polygonUrl){
        return axios.get(polygonUrl + '?api_key=' + serverconfigs.apiKey, {timeout: configTimers.polygonUploadCallTimeOut} );
    }

    deleteGeofencePolygon(polygonId, uploadGroup){
        return axios.delete(serverUrl.serverBaseUrl + '/sa/' + (uploadGroup ? uploadGroup : serverconfigs.username)+'/v2/geofences/'+ serverconfigs.username + '/'+polygonId+'?api_key=' + serverconfigs.apiKey, {timeout: configTimers.polygonUploadCallTimeOut});
    }

    updatePolygonGeofenceStatus = (params) => {
        const apiUrl = serverconfigs.serviceBaseUrl + 'upload/datasets/' + serverconfigs.username + '?api_key=' + serverconfigs.apiKey;
        return axios.put(
            apiUrl,
            params,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }, {timeout: configTimers.polygonUploadCallTimeOut})
    }
}