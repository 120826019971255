import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import '../../styles/deleteModal.scss';

export class DeleteModal extends React.Component {
    constructor(props) {
        super(props);
    }
    render(){
        return (
            <>
                <Modal className='delete-polygon' backdrop='static' show={this.props.show}  onHide={this.props.hide}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='delete-content'>Are you sure you want to delete: <b>{this.props.title}</b>?</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="light" onClick={this.props.hide}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.props.delete}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}