import React, { Component } from "react";
import { Spinner, Form } from "react-bootstrap";
import mapboxgl from 'mapbox-gl';
import { isEqual } from 'lodash';
import { trackingDeviceDeclarations, configTimers, vendor, featureLabels, indoorMapObj, errorMessages } from "../constants";
import DateUtils from '../DateUtils';
import { connect } from 'react-redux';
import { isFeatureAllowed } from '../utils/groupFeatureUtils';
import { consoleLog, prepareClustersGeojson, createDonutChart, calculateUpdateMarkerBBbox, UpdatePrepareClustersGeojson, getTraxmateVendorFromGP } from "../utils/commonUtils";
import { isIndoorDataAvailable } from "../utils/TrackingUtils";
import '../styles/incident.scss';
import deviceActive from '../assets/device_active.svg';
import deviceInActive from '../assets/device_inactive.svg';
import deviceSlected from '../assets/device_selected.svg';
import icRestrictSelected from '../assets/icon_geo_restrict_marker_selected.svg';
import icRestricted from '../assets/icon_geo_restrict_marker.svg';
import icRestrictFoot from '../assets/restrict_icon.svg';
import icIdle from '../assets/icon_idle_time_marker.svg';
import icIdleSelected from '../assets/icon_idle_timer_marker_selected.svg';
import icIdleClock from '../assets/idletime_icon.svg';
import '../styles/FactoryTracking.scss';
import TrackingServices from '../services/TrackingServices';
import { setDevicesListData, updateFloorChangeData } from '../redux/actions/headerActions';
import moment from "moment";

const unclusteredPoint = 'unclustered-point';
const devicesLayerId = 'device-layer';
const clusterCount = "cluster-count";

const colors = ['#008000', '#99999B', '#99999B'];
const highStatusExp = ['==', ['get', 'status'], ['literal', 'active']];
const mediumsStatusExp = ['==', ['get', 'status'], ['literal', 'inactive']];
const lowStatusExp = ['==', ['get', 'status'], ['literal', '']];
const blueColor = '#438CBC'
const orangeColor = '#f99403'
const idleTimeLimit = 120000 // 2 min
const idleCountLimit = 2 // 2 times

// objects for caching and keeping track of HTML marker objects (for performance)
var markers = {};
let markersOnScreen = {};
class FactoryTracking extends Component {
  constructor(props) {
    super(props);
    this.dateUtils = new DateUtils();
    this.trackingService = new TrackingServices();
    this.state = {
      clusterData: [],
      showSpinner: false,
      showDeviceList: [],
      deviceFilterList: [],
      seletedTypeValue: null,
      showRestructedText: '',
      showIdleText: '',
    };
    this.isDeviceDataUpdated = false;
    this.donutMarkers = [];
    this.isTrackingAllowed = isFeatureAllowed(featureLabels.tracking_factory);
    this.trackingIntervalId = null;
    this.selectedDevice = null;
    this.breadcrumbLayers = [];
    this.activeDevice = null;
    this.autoZoom = true;
    this.zoomToActive = false;
  }

  componentDidMount() {
    this.updateDevicesList();
    this.startGetDeviceIntervalTimer();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { mapUrl } = this.props;
    if (prevProps.mapUrl && prevProps.mapUrl !== mapUrl) {
      clearInterval(this.trackingIntervalId)
      this.startGetDeviceIntervalTimer();
    }
    if (prevProps.loadMap !== this.props.loadMap) {
      this.updateDevicesList();
    }
    if (this.isDeviceDataUpdated) {
      this.isDeviceDataUpdated = false
      this.updateDevicesList();
    }
    if (prevState.seletedTypeValue && this.state.seletedTypeValue && prevState.seletedTypeValue !== this.state.seletedTypeValue) {
      trackingDeviceDeclarations.deviceId = null;
      this.selectedDevice = null;
      this.updateDevicesList();
    }
  }

  startGetDeviceIntervalTimer = () => {
    this.trackingIntervalId = setInterval(() => this.getDevicesList(), configTimers.trackingDataReqTime);
  }

  getDevicesList = () => {
    if (this.isTrackingAllowed) {
      let groupVendor = getTraxmateVendorFromGP();
      if (groupVendor && groupVendor == '') {
        return
      }

      this.trackingService.getTrackingData(groupVendor, trackingDeviceDeclarations.deviceId).then(response => {
        if (response.status == 200) {
          if (response && response.data && response.data.length && response.data[0].vehicles && response.data[0].vehicles.length) {
            let activeDevicesList = response.data[0].vehicles.filter(device => {
              return device.status === trackingDeviceDeclarations.activeStatus;
            });
            activeDevicesList.reverse();
            let positionUpdated = this.isPositionUpdated(activeDevicesList)
            if (positionUpdated) {
              this.props.setDevicesListData(activeDevicesList);
            } else {
              let allDevicesList = activeDevicesList;
              if (allDevicesList.length) {
                allDevicesList.map((device, index) => {
                  device.textField = index + 1;
                })
              }
              var updatedDevicesList = this.getLeftPannelData(allDevicesList);
              this.setState({
                showDeviceList: updatedDevicesList
              })
            }
            this.isDeviceDataUpdated = positionUpdated
          }
        }
      })
        .catch(error => {
          console.log('error: ', error);
        });
    }
  }

  updateDevicesList = () => {
    if (this.props.devicesList && this.props.devicesList.length) {
      let allDevicesList = this.props.devicesList;
      if (allDevicesList.length) {
        allDevicesList = allDevicesList.filter(device => {
          return device.status === trackingDeviceDeclarations.activeStatus;
        });
        allDevicesList.map((device, index) => {
          device.textField = index + 1;
        })
      }
      var dropDownList = allDevicesList.reduce((unique, o) => {
        if (!unique.some(obj => obj.type === o.type)) {
          unique.push(o);
        }
        return unique;
      }, []);
      var updatedDevicesList = this.getLeftPannelData(allDevicesList);
      this.setState({
        showDeviceList: updatedDevicesList,
        deviceFilterList: dropDownList
      }, () => {
        this.displayDevicesList();
      })
    }
  }

  getLeftPannelData = (obj) => {
    var updatedDevicesList = obj;
    updatedDevicesList.map(device => {
      var deviceStatus = this.getDeviceIconStatus(device)
      if (trackingDeviceDeclarations.deviceId === device.id) {
        device.showAddress = true;
      }
      else {
        device.showAddress = false;
      }
      device.deviceType = deviceStatus;
    })
    if (trackingDeviceDeclarations.selectedDeviceType) {
      if (trackingDeviceDeclarations.selectedDeviceType !== 'allDeviceTypes') {
        this.setState({
          seletedTypeValue: trackingDeviceDeclarations.selectedDeviceType
        })
        updatedDevicesList = updatedDevicesList.filter(device => {
          return device.type === trackingDeviceDeclarations.selectedDeviceType;
        });
      }
      else {
        updatedDevicesList = updatedDevicesList;
      }
    }
    return updatedDevicesList;
  }

  getDeviceIconStatus = (device) => {
    if (device && device.hasOwnProperty('restricted') && device.restricted && device.restricted.status === 'in') {
      if (trackingDeviceDeclarations.deviceId === device.id) {
        return trackingDeviceDeclarations.restrictSelectedStatus;
      } else {
        return trackingDeviceDeclarations.restrictedStatus;
      }
    } else if (device && device.hasOwnProperty('idle') && device.idle && this.checkUserIdleStatus(device)) {
        if (trackingDeviceDeclarations.deviceId === device.id) {
          return trackingDeviceDeclarations.idleSelectedStatus;
        } else {
          return trackingDeviceDeclarations.idleStatus;
        }
    } else {
      if (trackingDeviceDeclarations.deviceId === device.id) {
        return trackingDeviceDeclarations.selectedStatus;
      }
      else {
        return device.status;
      }
    }
  }

  getDeviceIconType = (device) => {
    if (device && device.hasOwnProperty('restricted') && device.restricted && device.restricted.status === 'in') {
      if (trackingDeviceDeclarations.deviceId === device.id) {
        return icRestrictSelected;
      } else {
        return icRestricted;
      }
    } else if (device && device.hasOwnProperty('idle') && device.idle && this.checkUserIdleStatus(device)) {
        if (trackingDeviceDeclarations.deviceId === device.id) {
          return icIdleSelected;
        } else {
          return icIdle;
        }
    } else {
      if (trackingDeviceDeclarations.deviceId === device.id) {
        return deviceSlected;
      }
      else {
        return deviceActive;
      }
    }
  }

  checkUserIdleStatus = (device) => {
    if (device && device.hasOwnProperty('idle') && device.idle && device.idle.count){
      let diff = ''
      if (device.idle.status === 'out') {
        if(device.idle.outTimestamp) {
         diff = moment(new Date()).diff(device.idle.outTimestamp);
          diff = diff + device.idle.idleTime
        } 
      } else {
        diff = device.idle.idleTime
      }
      if (device.idle.count && device.idle.count > idleCountLimit  || diff > idleTimeLimit) {
       return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  handleSelect = (item) => {
    let showDeviceList = this.state.showDeviceList;
    if (showDeviceList && showDeviceList.length) {
      this.autoZoom = true;
      showDeviceList.map(device => {
        if (device.id === item.id) {
          device.showAddress = !device.showAddress;
          if (device.showAddress) {
            trackingDeviceDeclarations.deviceId = device.id;
            device.deviceType = trackingDeviceDeclarations.selectedStatus;
            this.selectedDevice = device;
            // this.activeDevice = device;
          }
          else {
            device.deviceType = device.status;
            trackingDeviceDeclarations.deviceId = null;
            trackingDeviceDeclarations.currentFloorNo = 0
            this.selectedDevice = null;
            this.zoomToActive = true;
          }
        }
        else {
          device.showAddress = false;
        }
      })
    }
    this.setState({
      showDeviceList: showDeviceList
    })
    this.displayDevicesList(true);
  }

  displayDevicesList = (zoomToselected) => {
    let bounds = [];
    this.removeDonuts();
    this.clearDeviceLayers();
    let zoomLevel = trackingDeviceDeclarations.factoryDefaultZoomLevel
    if (this.state.showDeviceList && this.props.loadMap) {
      let trackJson = {
        type: "FeatureCollection",
        features: []
      };
      this.removeLines()
      this.state.showDeviceList.forEach((device, index) => {
        if (device.geometry && device.geometry.coordinates && device.geometry.coordinates.length && device.status === trackingDeviceDeclarations.activeStatus) {
          if (trackingDeviceDeclarations.deviceId && device.id === trackingDeviceDeclarations.deviceId) {
            let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
            if (zoomToselected && isIndoorDataAvailable(device)) {
              bounds.push(lngLat);
              zoomLevel = indoorMapObj.indoorMapZoomLevel
              this.autoZoom = true;
            } else if (zoomToselected) {
              zoomLevel = trackingDeviceDeclarations.factoryDefaultZoomLevel
              bounds.push(lngLat);
              this.autoZoom = true;
            } else {
              var newIndoorObj = isIndoorDataAvailable(device)
              var oldIndoorObj = isIndoorDataAvailable(this.selectedDevice)
              if ((newIndoorObj && !oldIndoorObj)) {
                bounds.push(lngLat);
                zoomLevel = indoorMapObj.indoorMapZoomLevel
                this.selectedDevice = device;
                this.autoZoom = true;
              } else if (oldIndoorObj && !newIndoorObj) {
                bounds.push(lngLat);
                zoomLevel = trackingDeviceDeclarations.factoryDefaultZoomLevel
                this.selectedDevice = device;
                this.autoZoom = true;
              }
            }
            this.checkFloorChange(device)
            if (device.geometry.coordinates.length > 1) {
              this.addLine(device)
            } else {
              this.removeLines()
            }
          } else if (!trackingDeviceDeclarations.deviceId) {
            if (this.zoomToActive && device.status === trackingDeviceDeclarations.activeStatus) {
              let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
              bounds.push(lngLat);
            } else if (!this.zoomToActive) {
              let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
              bounds.push(lngLat);
            }

          }
          trackJson.features.push(prepareClustersGeojson(device));
        }
      });
      if (bounds.length && this.autoZoom) {
        if (this.zoomToActive) {
          this.zoomToActive = false
        }
        this.autoZoom = false;
        this.setBounds(bounds, zoomLevel);
      }
      this.addClusteredResources(trackJson, devicesLayerId);
      if (this.props.map) {
        this.updateMarkers();
        this.props.map.on('render', () => {
          if (!this.props.map.getSource(devicesLayerId) || !this.props.map.isSourceLoaded(devicesLayerId))
            return;
          this.updateMarkers();
        });
      }
    }
  }

checkFloorChange = (device) => {
    if (device && device.hasOwnProperty('indoor') && device.indoor && device.indoor.floorLabel) {
      var currentFloor = trackingDeviceDeclarations.currentFloorNo
      var updatedFloor = Number(device.indoor.floorLabel);
      if (currentFloor != updatedFloor && this.props.map.getZoom() >= indoorMapObj.indoorMapZoomLevel) {
        this.props.updateFloorChangeData(updatedFloor);
        trackingDeviceDeclarations.currentFloorNo = updatedFloor
      }
    } 
  }

  // objects for caching and keeping track of HTML marker objects (for performance)
  updateMarkers = () => {
    if (this.props.map) {
      let that = this;
      const newMarkers = {};
      const features = this.props.map.querySourceFeatures(devicesLayerId);
      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {
        const coords = feature.geometry.coordinates;
        const properties = feature.properties;
        if (!properties.cluster) continue;
        const id = properties.cluster_id;
        let marker = markers[id];
        if (!marker) {
          const el = createDonutChart(properties, colors);
          el.addEventListener('click', (e) => {
            this.props.map.getSource(devicesLayerId).getClusterExpansionZoom(
              id,
              (err, zoom) => {
                if (err) return;

                this.props.map.easeTo({
                  center: coords,
                  zoom: zoom
                });
              }
            );
            el.addEventListener('mouseleave', function (e) {
              e.target.style.cursor = "";
            })
          })
          marker = markers[id] = new mapboxgl.Marker({
            element: el, anchor: 'bottom'
          }).setLngLat(coords);
        }
        this.donutMarkers.push(marker);
        newMarkers[id] = marker;
        if (!markersOnScreen[id]) marker.addTo(this.props.map);
      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].remove();
      }
      markersOnScreen = newMarkers;
    }
  }

  addClusteredResources = (data, layerName) => {
    if (this.props.loadMap && this.props.map) {
      // add a clustered GeoJSON source for a sample set of incidents
      if (!this.props.map.getSource(layerName)) {
        this.props.map.addSource(layerName, {
          'type': 'geojson',
          'data': data,
          'cluster': true,
          'clusterRadius': 40,
          'clusterProperties': {
            // keep separate counts for each magnitude category in a cluster
            'highStatus': ['+', ['case', highStatusExp, 1, 0]],
            'mediumStatus': ['+', ['case', mediumsStatusExp, 1, 0]],
            'lowStatus': ['+', ['case', lowStatusExp, 1, 0]]
          }
        });

        // circle and symbol layers for rendering individual incidents (unclustered points)
        this.props.map.addLayer({
          'id': layerName,
          'type': 'symbol',
          'source': layerName,
          filter: ['!', ['has', 'point_count']],
          layout: {// Make the layer visible by default.
            "text-field": ['get', 'textField'],
            "text-anchor": "center",
            "text-size": 12,
            "text-justify": "center",
            'visibility': 'visible',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'symbol-placement': 'point',
            "text-offset": [
              "match",
              [
                "get",
                "deviceType"
              ],
              [trackingDeviceDeclarations.selectedStatus], ['literal', [-0.1, -2]],
              [trackingDeviceDeclarations.idleSelectedStatus], ['literal', [-0.1, -2.1]],
              [trackingDeviceDeclarations.restrictSelectedStatus], ['literal', [-0.1, -2.1]],
              [trackingDeviceDeclarations.idleStatus], ['literal', [-0.1, -2.1]],
              [trackingDeviceDeclarations.restrictedStatus], ['literal', [-0.1, -2.1]],
              ['literal', [-0.1, -2]]
            ],
            "icon-image": [
              "match",
              [
                "get",
                "deviceType"
              ],
              [trackingDeviceDeclarations.selectedStatus], 'device_selected',
              [trackingDeviceDeclarations.idleSelectedStatus], 'icon_idle_timer_marker_selected',
              [trackingDeviceDeclarations.restrictSelectedStatus], 'icon_geo_restrict_marker_selected',
              [trackingDeviceDeclarations.idleStatus], 'icon_idle_time_marker',
              [trackingDeviceDeclarations.restrictedStatus], 'icon_geo_restrict_marker',
              'device_active'
            ],
            "icon-size": 0.8,
            'icon-anchor': 'bottom'
          },
          "paint": {
            // "text-color": "#000"
            "text-color": [
              "match",
              [
                "get",
                "deviceType"
              ],
              [trackingDeviceDeclarations.selectedStatus], "#000",
              [trackingDeviceDeclarations.idleSelectedStatus], "#fff",
              [trackingDeviceDeclarations.restrictSelectedStatus], "#fff",
              [trackingDeviceDeclarations.idleStatus], "#fff",
              [trackingDeviceDeclarations.restrictedStatus], "#fff",
              "#000"
            ]
          }

        });
        this.props.map.on("mousemove", layerName, () => {
          this.props.map.getCanvas().style.cursor = "pointer";
        });
        this.props.map.on("mouseleave", layerName, () => {
          if (this.props.isMeasurementOn) {
            this.props.map.getCanvas().style.cursor = 'crosshair';
          } else {
            this.props.map.getCanvas().style.cursor = '';
          }
        });
      } else { // If the layer has already been added then just update it with the new data
        this.props.map.getSource(layerName).setData(data);
      }
    }
  }

  flyToDefaultZoom = (centerPosition, zoomLevel) => {
    if (this.props.loadMap && this.props.map) {
      this.props.map.flyTo({
        center: centerPosition,
        zoom: zoomLevel,
        bearing: 0,
        speed: 1.2, // make the flying slow
        curve: 4, // change the speed at which it zooms out
        easing: function (t) { return t; }
      });
    }
  }

  handleDeviceFilterSelection = (e) => {
    this.autoZoom = true;
    let filterDeviceList = [];
    trackingDeviceDeclarations.selectedDeviceType = e.target.value;
    if (e.target.value !== 'allDeviceTypes') {
      filterDeviceList = this.props.devicesList.filter(device => {
        return device.type === e.target.value && device.status === trackingDeviceDeclarations.activeStatus
      });
    }
    else {
      filterDeviceList = this.props.devicesList;
    }
    this.setState({
      showDeviceList: filterDeviceList,
      seletedTypeValue: e.target.value
    }, () => {
      this.displayDevicesList();
    })
  }

  removeLayer = (layerName) => {
    if (this.props.map.getLayer(layerName)) {
      this.props.map.removeLayer(layerName);
    }
  }

  removeSource = (layerName) => {
    if (this.props.map.getSource(layerName)) {
      this.props.map.removeSource(layerName);

    }
  }

  clearDeviceLayers = () => {
    if (this.props.loadMap && this.props.map) {
      this.removeLayer(devicesLayerId);
      this.removeSource(devicesLayerId);
    }
  }

  setBounds = (bounds, zoomLevel) => {
    var bbox = new mapboxgl.LngLatBounds();
    if (this.props.map) {
      if (bounds && bounds.length && bounds.length === 1) {
        this.props.map.flyTo({
          center: bounds[0],
          zoom: zoomLevel,
          bearing: 0,
          speed: 1.2, // make the flying slow
          curve: 4, // change the speed at which it zooms out
          easing: function (t) { return t; }
        });
      } else if (bounds && bounds.length && bounds.length > 1) {
        bounds.forEach((coordinate) => {
          bbox.extend(coordinate);
        });
        this.props.map.fitBounds(bbox, {
          maxZoom: 19,
          padding: { top: 120, bottom: 100, left: 50, right: 75 }
        });
      }
    }
  }

  removeDonuts = () => {
    if (this.donutMarkers.length) {
      this.donutMarkers.map(donut => {
        donut.remove();
      })
    }
    markers = {};
  }

  componentWillUnmount() {
    this.clearDeviceLayers();
    this.removeDonuts();
    this.removeLines()
    clearInterval(this.trackingIntervalId);
  }

  isPositionUpdated = (newDevices) => {
    const { devicesList } = this.props;
    if (!newDevices || !newDevices.length || !devicesList || !devicesList.length) {
      return false;
    }
    if (devicesList.length !== newDevices.length) {
      return true;
    }
    var positionUpdated = false;
    newDevices.forEach((newDevice, index) => {
      let newPosition = newDevice.geometry.coordinates[newDevice.geometry.coordinates.length - 1];

      devicesList.forEach((oldDevice, index) => {
        if (newDevice.id === oldDevice.id) {
          if (oldDevice.geometry.coordinates.length != newDevice.geometry.coordinates.length) {
            positionUpdated = true
            return
          } else {
            let oldPosition = oldDevice.geometry.coordinates[oldDevice.geometry.coordinates.length - 1];
            if (oldPosition && newPosition && oldPosition[0] != newPosition[0] && oldPosition[1] != newPosition[1]) {
              positionUpdated = true
              return
            }
          }
        }
      })

      if(!positionUpdated) {
        positionUpdated = true
        return true
      }

      if (positionUpdated) {
        return positionUpdated
      }
    })
    return positionUpdated

  }

  /* add breadcrumb */
  addLine = (device) => {
    let updateJson = { ...device };
    let bounds = [];
    let geometry = [];
    let coordinateArray = [];
    let opacity = [0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
    let deviceUpdateJson = {
      type: "FeatureCollection",
      features: []
    };
    if (device.geometry.coordinates) {
      if (device.geometry.coordinates.length > 0) {
        if (device.geometry.coordinates.length > 8) {
          coordinateArray = device.geometry.coordinates.slice(-8);
        } else {
          coordinateArray = device.geometry.coordinates;
        }
        coordinateArray.forEach((coordinate, ind) => {
          if (coordinate && coordinate.length > 1) {
            updateJson.opacity = opacity[ind];
            updateJson.coordinates = coordinate;
            deviceUpdateJson.features.push(UpdatePrepareClustersGeojson(updateJson));
            geometry.push([coordinate[1], coordinate[0]]);
            if (geometry.length >= 8) {
              return
            }
          }
        });
      }
    }
    let layers = this.breadcrumbLayers;
    let circleId = 'initCircle' + device.id;
    let lineId = 'circles' + device.id;
    let polylineId = 'polyline' + device.id;
    layers.push(lineId, circleId, polylineId);
    this.breadcrumbLayers = layers;
    // if (this.props.map.getLayer(lineId)) {
    //     this.props.map.removeLayer(lineId);
    //     this.props.map.removeSource(lineId);
    // }
    if (!this.props.map.getSource(lineId)) {
      this.props.map.addSource(lineId, {
        'type': 'geojson',
        'data': deviceUpdateJson
      });
      this.props.map.addLayer({
        'id': lineId,
        "type": "circle",
        "source": lineId,
        "paint": {
          "circle-radius": 5,
          "circle-color": blueColor,
          "circle-opacity": ["get", "opacity"],
        }
      });
    } else { // If the layer has already been added then just update it with the new data
      this.props.map.getSource(lineId).setData(deviceUpdateJson);
    }

    let lngLat = new mapboxgl.LngLat(device.geometry.coordinates[device.geometry.coordinates.length - 1][1], device.geometry.coordinates[device.geometry.coordinates.length - 1][0]);
    bounds.push(lngLat);
    let updateMarkerBbox = calculateUpdateMarkerBBbox(device, this.props.map);
    if (!updateMarkerBbox && bounds.length) {
      this.setBounds(bounds, this.props.map.getZoom());
    }
  }

  removeLines = () => {
    let breadCrumbList = this.breadcrumbLayers;
    if (breadCrumbList && breadCrumbList.length) {
      for (var i = 0; i < breadCrumbList.length; i++) {
        if (this.props.map.getLayer(breadCrumbList[i])) {
          this.props.map.removeLayer(breadCrumbList[i]);
          this.props.map.removeSource(breadCrumbList[i]);
        }
      }
    }
    this.breadcrumbLayers = [];
  }

  render() {
    return (
      <>
        <div>
          <div className='incident-list'>
            {
              !this.props.isLoading && this.state.showDeviceList ?
                this.state.showDeviceList.length ?
                  this.state.showDeviceList.map((device, index) => {
                    let showIdleUi = false;
                    let deviceStatusImage = this.getDeviceIconType(device);
                    let restructedStatus;
                    let idleStatus;
                    let textColorWhite = false;
                    let currentTime = device.recorded_time;
                    let hours = '00'
                    let minutes = '00';
                    let seconds = '00';
                    if (device && device.hasOwnProperty('restricted') && device.restricted && device.restricted.status === 'in') {
                      idleStatus = ''
                      textColorWhite = true;
                      currentTime = device.restricted.inTimestamp
                      restructedStatus = " entered into " + device.restricted.geofenceName
                      let diff = moment(new Date()).diff(device.restricted.inTimestamp);
                      let dateObj = moment.duration(diff);
                      minutes = dateObj._data.minutes < 10 ? '0' + dateObj._data.minutes : dateObj._data.minutes;
                      seconds = dateObj._data.seconds < 10 ? '0' + dateObj._data.seconds : dateObj._data.seconds;
                    } else if (device && device.hasOwnProperty('idle') && device.idle) {
                      showIdleUi = true;
                      restructedStatus = ''
                      let diff = ''
                      if (device.idle.status === 'out') {
                        if(device.idle.outTimestamp) {
                          currentTime = device.idle.outTimestamp
                          diff = moment(new Date()).diff(device.idle.outTimestamp);
                          diff = diff + device.idle.idleTime
                          let dateObj = moment.duration(diff);
                          hours = dateObj._data.hours < 10 ? '0' + dateObj._data.hours : dateObj._data.hours;
                          minutes = dateObj._data.minutes < 10 ? '0' + dateObj._data.minutes : dateObj._data.minutes;
                          seconds = dateObj._data.seconds < 10 ? '0' + dateObj._data.seconds : dateObj._data.seconds;
                        } 
                      } else {
                        currentTime = device.idle.inTimestamp
                        diff = diff + device.idle.idleTime
                        let dateObj = moment.duration(device.idle.idleTime);
                        hours = dateObj._data.hours < 10 ? '0' + dateObj._data.hours : dateObj._data.hours;
                        minutes = dateObj._data.minutes < 10 ? '0' + dateObj._data.minutes : dateObj._data.minutes;
                        seconds = dateObj._data.seconds < 10 ? '0' + dateObj._data.seconds : dateObj._data.seconds;
                      }
                      
                      if ((device.idle.count && device.idle.count > 2 ) || diff > idleTimeLimit) {
                        let time = ''
                        if(diff > idleTimeLimit && device.idle.count < 2) {
                          time = " away more than 2 minutes"
                          idleStatus = time
                        } else {
                          idleStatus = " exited " + device.idle.count + " times from " + device.idle.geofenceName
                        }
                        // idleStatus = " exited " + device.idle.count + " times from " + device.idle.geofenceName + time
                        textColorWhite = true;
                      }
                    } else {
                      if(device && device.hasOwnProperty('useCase') && device.useCase === "Factory") {
                        showIdleUi = true;
                      }
                      restructedStatus = ''
                      idleStatus = ''
                      textColorWhite = false;
                    }
                    return (
                      <div key={device.id} className={`hand-cursor device-list ${device.showAddress ? 'active' : ''}`} >
                        <div onClick={this.handleSelect.bind(this, device)}>
                          <div >
                            <div className='py-2'>{moment(currentTime).format('MM-DD-YYYY hh:mm:ss A')}</div>
                            <div className='d-flex align-items-center'>
                              <img src={deviceStatusImage} className='call-icon' />
                              <div className={`text-count-normal ${textColorWhite ? 'text-count-rest' : ''}`}>{device.textField}</div>
                              <div className='ms-2'>
                                <div className='call-mdn'><b>{device.label ? device.label : ''}</b></div>
                                {showIdleUi ? <div className="device-address">{'time away : ' + hours + ':' + minutes + ':' + seconds}</div>
                                  : <div className="device-address">{device.type.trimEnd() === 'Watch' ? 'Restricted access' : device.type.trimEnd() === 'Traxmate app' ? 'Unrestricted access' : device.type.trimEnd()}</div>}
                              </div>
                            </div>
                            <div className='mt-2 d-flex align-items-center'>
                              {!showIdleUi ? <>{minutes === '00' && seconds === '00' ? '' : <div className='display-counter'>{minutes + ':' + seconds}</div>}</> : ''}
                              {restructedStatus ? <div className='restricted'>{restructedStatus}</div> : ''}
                              {idleStatus ? <div className='idle'>{idleStatus}</div> : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                  : <div className='no-data-found'>No Data Found</div>
                :
                <Spinner animation="border" variant="primary">
                  <span className="sr-only"></span>
                </Spinner>
            }

          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapUrl: state.mapUrl ? state.mapUrl.mapUrl : state.mapUrl,
    devicesList: state.devicesList.devicesList,
    isLoading: state.analytics.isLoading,
    error: state.analytics.error,
    loadMap: state.loadMap.mapLoaded
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDevicesListData: (val) => dispatch(setDevicesListData(val)),
    updateFloorChangeData: (val) => dispatch(updateFloorChangeData(val))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FactoryTracking);
