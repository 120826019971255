// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ruler-marker {
  background: #fff;
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid;
  border-color: #263238;
}`, "",{"version":3,"sources":["webpack://./src/components/ruler/Ruler.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,iBAAA;EACA,qBAAA;AACF","sourcesContent":[".ruler-marker{\n  background: #fff;\n  width: 12px;\n  height: 12px;\n  cursor: pointer;\n  border-radius: 50%;\n  box-sizing: border-box;\n  border: 2px solid;\n  border-color: #263238;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
