import { CLEAR_SEARCH, DISPLAY_SEARCH_PANEL, HIDE_SEARCH_PANEL,SET_SEARCH, SET_SEARCH_FLAG } from '../actions/actionTypes';


const initialState = {
    isLoading: false,
    searchData:[],
    searchString:  ""
}

export const SearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case DISPLAY_SEARCH_PANEL:
            return { ...state, searchData:action.searchData, showSearch : action.showSearch, searchString: action.searchString }
        case HIDE_SEARCH_PANEL :
                return {...state,searchData: action.searchData, showSearch : action.showSearch, searchString: action.searchString}

        default:
            return state;
    }
}

export const SearchFlagReducer = (state = false, action) => {
    switch(action.type) {
        case SET_SEARCH_FLAG:
            return {
                ...state,
                toggleSearch: action.data
            }
        default: 
            return state;
    }
}