import axios from 'axios';
import { serverconfigs, configTimers, groupFeatures } from '../constants';

export default class DiscrepancyService {
    getReport(id, type, idToken) {
        if (groupFeatures)
            return axios.get(serverconfigs.discrepancyUrl + "/search/" + groupFeatures.gis_id + '/' + id,
            {
                headers: {
                    'Authorization': 'Bearer '+ idToken,
                    'Content-Type': 'application/json'
                }
            }, {timeout: configTimers.discrepencyReportTimeOut})
    }
    sendReport(id, params, idToken) {
        if (groupFeatures)
            return axios.post(serverconfigs.discrepancyUrl + "/report/" + groupFeatures.gis_id + '/' + id,
            params,
            {
                headers: {
                    'Authorization': 'Bearer '+ idToken,
                    'Content-Type': 'application/json'
                }
            }, {timeout: configTimers.discrepencyReportTimeOut})
    }
}