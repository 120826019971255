import React from "react";
import Alert from "react-bootstrap/Alert";
import  { Amplify, Auth } from "aws-amplify";
import {primaryRegion, secondaryRegion} from "../awsexport-client";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { featureLabels, serverUrl, errorMessages} from "../constants";
import { toast } from 'react-toastify';
import { find } from 'lodash';
import { setStationIdInLocalStorage, getEndCallTimeFromLocalStorage, setEndCallTimeInLocalStorage, getStationIdFromLocalStorage, consoleLog } from "../utils/commonUtils.js";

Auth.configure({ storage: sessionStorage });
const toastId = "toast-id";
class SignIn extends React.Component {
  constructor(props) {
    super(props);
    const sessionGroupProfile = sessionStorage.getItem("groupProfile");
    this.state = {
      username: "",
      password: "",
      submitted: false,
      loader: false,
      alertMsg: "",
      alertType: "",
      formState: "signIn",
      showpwd: false,
      pwdText: "password",
      showStationList: false,
      stationList: (sessionGroupProfile !== null && sessionGroupProfile !== 'null')
      ? JSON.parse(sessionStorage.getItem("groupProfile")).stationID
      : [] ,
      selectBtn: "",
    };
    this.userInfo;
    this.doSignIn = this.doSignIn.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  toggleIcon = (val, e) => {
    if (val === "show") {
      this.setState({
        showpwd: true,
        pwdText: "text",
      });
    } else {
      this.setState({
        showpwd: false,
        pwdText: "password",
      });
    }
  };

  onSuccess() {
    this.setState({ formState: "signIn" });
  }
  
  doSignIn(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    if (this.state.username != "" && this.state.password != "") {
      this.setState({ loader: true, alertMsg: "" });

      Auth.signIn(this.state.username.toLowerCase(), this.state.password)
        .then((user) => {
          this.setState({ loader: false });
          this.userInfo = user;
          // masking sensitive data
          var n = sessionStorage.length;
          while (n--) {
            var key = sessionStorage.key(n);
            if (/userData/.test(key)) {
              let currentData = sessionStorage.getItem(key);
              let maskedData = currentData.replace(
                '"' + user.attributes["email"] + '"',
                '"xxxx"'
              );
              maskedData = maskedData.replace(
                '"' + user.attributes["custom:company"] + '"',
                '"xxxx"'
              );
              maskedData = maskedData.replace(
                '"' + user.attributes["family_name"] + '"',
                '"xxxx"'
              );
              sessionStorage.setItem(key, maskedData);
            }
          }
          let groupFeatures = user.attributes["custom:group_profile"];
          try{
            JSON.parse(groupFeatures)
            sessionStorage.setItem(
              "groupProfile",
              groupFeatures ? groupFeatures : null
            );
          } catch(error){
            groupFeatures = null
            sessionStorage.setItem(
              "groupProfile",
               null
            );
            consoleLog(':::::::::::: Uploaded Invalid Group features file ::::::: ');
          }
          sessionStorage.setItem("showNotifications", true);
          if(groupFeatures != null){
            this.setState({
              stationList: groupFeatures
                ? JSON.parse(sessionStorage.getItem("groupProfile")).stationID
                : [],
            }, () => {
          
          if (this.state.stationList && this.state.stationList.length > 0 &&
            Array.isArray(this.state.stationList) &&
            JSON.parse(
              sessionStorage.getItem("groupProfile")
            ).features.includes(featureLabels.call)
          ) {
            if (
              localStorage.getItem("stationIdPref") &&
              _.find(
                [...JSON.parse(localStorage.getItem("stationIdPref"))],
                ["userName", this.userInfo.username]
              )
            ) {
              this.storeUserInfo();
              window.location.href = "/calls";
            } else {
              this.setState({
                showStationList: true,
              });
            }
          } else {
                this.storeUserInfo();
                window.location.href = "/calls";
              }
            });
          } else {
            this.storeUserInfo();
            window.location.href = "/calls";
          }
        })
        .catch((err) => {
          this.setState({ loader: false });
          switch (err.code) {
            case "UserLambdaValidationException": {
              let msg;
              if (err.message.includes("PreAuthentication failed with error")) {
                msg = err.message.split(":")[1];
              } else {
                msg = err.message;
              }
              this.setState({ alertMsg: msg, alertType: "danger" });
              break;
            }
            case "UserNotConfirmedException": {
              this.setState({ alertMsg: err.message, alertType: "danger" });
              this.setState({ submitted: false });
              this.setState({ formState: "confirmSignUp" });
              break;
            }
            case "UserNotFoundException": // User not found
            case "NotAuthorizedException": // Password wrong
            case "InvalidParameterException": // Entered username is invalid e.g. has space
            default: {
              this.setState({
                alertMsg: "Incorrect username or password.",
                alertType: "danger",
              });
              this.usernameInput.focus();
              break;
            }
          }
          this.clearSessionStorage()
        });
    }
  }

  handleStation = (e) => {
    this.setState({
      selectBtn: e.target.value,
    });
  };

  componentDidMount() {
    var currentRegion = localStorage.getItem('currentRegion')
    if((currentRegion != null || currentRegion) && currentRegion === secondaryRegion.aws_project_region ){
      Amplify.configure(secondaryRegion);
    } else {
      Amplify.configure(primaryRegion);
    }
  };

  submitStation = (e) => {
    e.preventDefault();
    let currentUser = sessionStorage.getItem("currentUser");
    if(currentUser === null || currentUser === 'false') {
      this.storeUserInfo();
    } 
    setStationIdInLocalStorage(sessionStorage.getItem("currentUser"), this.state.selectBtn);
    window.location.href = "/calls";
  };
  
  clearSessionStorage = () => {
    for (let key in sessionStorage) {
      sessionStorage.removeItem(sessionStorage.key(key));
    }
  }

  storeUserInfo = () => {
    var currentRegion = localStorage.getItem('currentRegion')
    if((currentRegion != null || currentRegion) && currentRegion === secondaryRegion.aws_project_region ){
      serverUrl.serverBaseUrl = secondaryRegion.baseUrl
      sessionStorage.setItem("base", secondaryRegion.baseUrl);
      localStorage.setItem('currentRegion', secondaryRegion.aws_project_region)
    } else {
      serverUrl.serverBaseUrl = primaryRegion.baseUrl
      sessionStorage.setItem("base", primaryRegion.baseUrl);
      localStorage.setItem('currentRegion', primaryRegion.aws_project_region)
    }
    sessionStorage.setItem("currentUser", this.userInfo.username);
    sessionStorage.setItem("user", this.userInfo.username);
    sessionStorage.setItem(
      "api_key",
      this.userInfo.attributes["custom:ls_api_key"]
    );
    let idTokenFeatures = this.userInfo.signInUserSession["idToken"];
    sessionStorage.setItem("idToken", idTokenFeatures.jwtToken);
    var endCall = getEndCallTimeFromLocalStorage();
    if (endCall === null || endCall === '') {
      setEndCallTimeInLocalStorage(this.userInfo.username, JSON.parse(sessionStorage.getItem("groupProfile")).endCallTimer);
    }
    let groups =
      this.userInfo.signInUserSession.idToken.payload["cognito:groups"];
    if (groups) {
      for (let group in groups) {
        if (groups[group] != "admin" && groups[group] != "SAUsers") {
          sessionStorage.setItem("groupName", groups[group]);
        }
      }
    }
  };

  render() {
    return (
      <div className="mh-100">
        <Alert
          show={this.state.alertMsg}
          key="signInAlert"
          variant={this.state.alertType}
        >
          {this.state.alertMsg}
        </Alert>
        <>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="sign-in">
                <h3 className="py-3">Sign in</h3>
                <form>
                  {!this.state.showStationList ? (
                    <>
                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                          type="text"
                          name="username"
                          autoComplete="username"
                          defaultValue={this.state.username}
                          onChange={(e) =>
                            this.setState({
                              username: e.target.value.toLowerCase(),
                            })
                          }
                          ref={(input) => {
                            this.usernameInput = input;
                          }}
                          disabled={this.state.loader}
                          className={`form-control ${
                            this.state.submitted && !this.state.username
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {this.state.submitted && !this.state.username ?
                          <div className="invalid-feedback">
                          Username is required
                        </div>
                          : ''}
                      </div>

                      <div className="form-group position-relative">
                        <label htmlFor="password">Password</label>
                        <input
                          type={this.state.pwdText}
                          disabled={this.state.loader}
                          defaultValue={this.state.password}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                          name="password"
                          autoComplete="current-password"
                          className={`form-control ${
                            this.state.submitted && !this.state.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {this.state.showpwd ? (
                          <FaEyeSlash
                            className="position-absolute show-pwd cursor-txt"
                            onClick={this.toggleIcon.bind(this, "hide")}
                          >
                            hide
                          </FaEyeSlash>
                        ) : (
                          <FaEye
                            className="position-absolute show-pwd cursor-txt"
                            onClick={this.toggleIcon.bind(this, "show")}
                          >
                            show
                          </FaEye>
                        )}
                        {this.state.submitted && !this.state.password ?
                          <div className="invalid-feedback">
                          Password is required
                        </div>
                          : ''}
                      </div>
                      <div className="form-group">
                        <button
                          disabled={this.loader}
                          className="comtech-btn comtech-btn-primary btn-lg"
                          onClick={this.doSignIn}
                          type="submit"
                        >
                          <span>Sign In </span>
                        </button>
                        {this.state.loader ?
                        <div
                          className="p-10 mx-3 spinner-border align-middle text-primary"
                            role="status">
                          <span className="sr-only"></span>
                        </div>
                          : ''}
                      </div>
                    </>
                  ) : (
                    <div className="choose-station">
                      <div className="form-group position-relative">
                        <label className="mb-1">Choose MCW Position</label>
                        <select
                          className="form-control form-select"
                          onChange={this.handleStation}
                        >
                          <option hidden>Position ID</option>
                          {Array.isArray(this.state.stationList) &&
                          this.state.stationList.length
                            ? this.state.stationList.map((stationId) => {
                                return (
                                  <option value={stationId} key={stationId}>
                                    {stationId}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                      <div className="form-group">
                        <button
                          disabled={this.state.selectBtn !== "" ? false : true}
                          className="comtech-btn comtech-btn-primary btn-lg"
                          onClick={this.submitStation}
                          type="submit"
                        >
                          <span>Select</span>
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="col-md-6 hidden-sm-down"></div>
          </div>
        </>
      </div>
    );
  }
}

export default SignIn;
