import React from 'react';
import '../styles/incident.scss';
import MoreInfoPanel from './MoreInfoPanel';
import mapboxgl from 'mapbox-gl';
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import printSvg from '../assets/print.svg';
import refreshSvg from '../assets/refresh.svg';
import uberSvg from '../assets/uber.svg';
import location from '../assets/icon-location.png';
import { connect } from 'react-redux';
import { fetchIncident, fetchNENA, fetchUber, fetchAddInfo, fetchMapBound, setPauseZoom, setSearchZoom, setIncidentDependencies, setIncidentAutoZoom, setFilteredPeers } from '../redux/actions/incidentActions';
import { serverconfigs, incidentConfigs, markerImages, customToastId, NO_DATA, groupFeatures, tabUrls, callDeclarations } from '../constants';
import { toast } from 'react-toastify';
import {getEndCallTimeFromLocalStorage, consoleLog} from "../utils/commonUtils";

var locationUpdated = false, isSelectedMarkerExists = false, findPeersNENA = false, findIncidentNENA = false;
class IncidentPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isNENAFound : false,
            boundsApi: this.setBounds.bind(this),
            groupBounds: this.handleGroupBounds.bind(this)
        };

        this.markers = [];
        this.peerMarkers = [];
        this.breadcrumbLayers = [];
        this.peersBreadcrumbLayers = [];
        this.circleLayers = [];
        this.peersCircleLayers = [];
        this.rapidSOSDottedLineLayers = [];
        this.peersRapidSOSDottedLineLayers = [];
        this.rapidSOSBreadcrumbLayers = [];
        this.peersRapidSOSBreadcrumbLayers = [];
        this.rapidSOSCircleLayers = [];
        this.peersRapidSOSCircleLayers = [];
        this.filteredPeersList = [];
        this.filterBounds = [];
        this.incidentBounds = [];
        this.peerIncidents = [];
    }

    componentDidMount = () => {
       this.props.setSearchZoom(false);
       callDeclarations.idleTabName = tabUrls.incident
    }
    
    componentDidUpdate = (prevProps) => {
        const { showUncertainity } = this.props;

        if (prevProps.showUncertainity && prevProps.showUncertainity != showUncertainity) {
            this.handleUncertainity();
        }
    }

    handleUncertainity = () => {
        if (!this.state.showUncertainity) {
            this.removeCircles();
        }
    }

    static getDerivedStateFromProps(props, state) {
        state.isNENAFound = false;
        let incidentBounds = [];
        let peerIncidentBounds = [];
        let IncidentCount = 0;
        let NENAFound, el = document.querySelector('#incident-scroll');
        if (props.incidents) {
            incidentBounds = [];
            props.incidents.map((item, ind) => {
                if(item && item.nenaIncidentId){
                    NENAFound = (props.NENA.NENA === item.nenaIncidentId && props.NENA.NENA !== '');
                }
                if (NENAFound) {
                    if (el) {
                        el.scrollTop = (ind * incidentConfigs.incidentTileHeight);
                    }
                    try {
                        if (props.selectIncident && props.selectIncident.incidentData && props.selectIncident.incidentData.geometry) {
                            let previousMarker = props.selectIncident.incidentData.geometry.coordinates[props.selectIncident.incidentData.geometry.coordinates.length - 1];
                            let presentMarker = item.geometry.coordinates[item.geometry.coordinates.length - 1];
                            if (previousMarker[0] != presentMarker[0] && previousMarker[1] != presentMarker[1]) {
                                locationUpdated = true;
                            }
                        }
                    } catch (e) {
                        // do nothing
                    }
                    props.selectIncident.incidentData = item;    
                }
                if (item.geometry && item.geometry.coordinates && item.geometry.coordinates.length) {
                    let lngLat = new mapboxgl.LngLat(item.geometry.coordinates[item.geometry.coordinates.length - 1][0], item.geometry.coordinates[item.geometry.coordinates.length - 1][1]);
                    if (item.callState === incidentConfigs.activeStatus) {
                        incidentBounds.push(lngLat);
                    }
                }
            })

            props.incidents.map((item) => {
                IncidentCount++;
                if(item.nenaIncidentId === props.NENA.NENA)
                    state.isNENAFound = true;
            })

            if(IncidentCount === props.incidents.length && state.isNENAFound === false && el){
                el.scrollTop = 0;
            }

            let findNENA = props.incidents.find(o => {
                if(o && o.nenaIncidentId){
                    o.nenaIncidentId === props.NENA.NENA
                }
            });
            if (findNENA === undefined && (props.addInfo || props.uber)) {
                props.NENA.NENA = '';
            }

            if(props.incidents.length){
                props.incidents.map((item) => {
                    if(item.nenaIncidentId === props.NENA.NENA){
                        findIncidentNENA = true;
                        return
                    }
                })
            }
            else if(findIncidentNENA && props.incidents.length === 0){
                isSelectedMarkerExists = false;
                findIncidentNENA = false;
            }
        }
        if(props.peersApiResp && props.peersApiResp.length && props.selectedPeersValue && props.selectedPeersValue.peersData && props.selectedPeersValue.peersData.length){
            peerIncidentBounds = [];
            props.peersApiResp.map((peerList) => {
                if(peerList && peerList.results && peerList.results.length){
                    peerList.results.map((item) => {
                            if(item.nenaIncidentId === props.NENA.NENA){
                                findPeersNENA = true;
                            }
                        if (item.geometry && item.geometry.coordinates && item.geometry.coordinates.length) {
                            let lngLat = new mapboxgl.LngLat(item.geometry.coordinates[item.geometry.coordinates.length - 1][0], item.geometry.coordinates[item.geometry.coordinates.length - 1][1]);
                            if (item.callState === incidentConfigs.activeStatus) {
                                peerIncidentBounds.push(lngLat);
                            }
                        }
                    })
                }
            })
        }
        else{
            peerIncidentBounds = [];
            if(findPeersNENA){
                // props.NENA.NENA === '';
                // props.fetchNENA('');
                findPeersNENA = false;
                isSelectedMarkerExists = false;
            }
        }
        //If new data comes, scrolling incident view to the top
        if(props.NENA.NENA === '' || props.NENA.NENA === undefined || props.NENA.NENA !== ''){
            if (el && (props.NENA.NENA === '' || props.NENA.NENA === undefined)) {
                el.scrollTop = 0;
            }
            if (props.isSearchZoom) {
                isSelectedMarkerExists = true;
            }
            else if(props.NENA === '' || props.NENA.NENA === ''){
                isSelectedMarkerExists = false;
            } 
            if (!isSelectedMarkerExists || (!props.isSearchZoom && !isSelectedMarkerExists)) {
                if(!props.incidentAutoZoom && props.updateTrackBounds && !props.showReportBounds){
                    if(incidentBounds.length && peerIncidentBounds.length < 1){
                        state.boundsApi(incidentBounds);
                    }
                    else if(peerIncidentBounds.length && incidentBounds.length < 1){
                        state.boundsApi(peerIncidentBounds);
                    }
                    else if(incidentBounds.length && peerIncidentBounds.length){
                        incidentBounds.push(...peerIncidentBounds);
                        state.boundsApi(incidentBounds);
                    }
                    else if (!isSelectedMarkerExists && !props.isSearchZoom  && !props.incidentAutoZoom && !props.showReportBounds){
                        state.groupBounds();
                    }
                }
            }
        }
        return state;

    }

    removeLines = (isPeers) => {
        let breadCrumbList = [];
        if(isPeers){
            breadCrumbList = this.peersBreadcrumbLayers;
        } else {
            breadCrumbList = this.breadcrumbLayers; 
        }

        if(breadCrumbList && breadCrumbList.length){
            for (var i = 0; i < breadCrumbList.length; i++) {
                if (this.props.map.getLayer(breadCrumbList[i])) {
                    this.props.map.removeLayer(breadCrumbList[i]);
                    this.props.map.removeSource(breadCrumbList[i]);
                }
            }
        }
        if(isPeers){
            this.peersBreadcrumbLayers = [];
            this.removeRapisSOSLines(true);
        } else {
            this.breadcrumbLayers = [];
            this.removeRapisSOSLines();
        }
    }

    removeRapisSOSLines = (isPeers) => {
        let rapidSOSList = [];
        if(isPeers){
            rapidSOSList = this.peersRapidSOSBreadcrumbLayers;
        } else
        rapidSOSList = this.rapidSOSBreadcrumbLayers;
       
        if(rapidSOSList && rapidSOSList.length){
            for (var i = 0; i < rapidSOSList.length; i++) {
                if (this.props.map.getLayer(rapidSOSList[i])) {
                    this.props.map.removeLayer(rapidSOSList[i]);
                    this.props.map.removeSource(rapidSOSList[i]);
                }
            }
        }

        if(isPeers)
            this.peersRapidSOSBreadcrumbLayers = [];
        else 
            this.rapidSOSBreadcrumbLayers = [];
    }

    removeMarkers = (isPeers = false) => {
        if(isPeers){
            this.peerMarkers.forEach(marker => {
                marker.remove();
            });
            this.peerMarkers = [];
            this.removeDotedLines(true);
            this.removeLines(true);
            this.peerIncidents = [];
        } else {
            this.markers.forEach(marker => {
                marker.remove();
            });
            this.markers = [];
            this.incidentBounds = [];
            this.removeDotedLines();
        }
    }

    removeLinesMarkers = () => {
        this.removeMarkers();
        this.removeLines();
    }

    componentWillUnmount = () => {
        // this.props.setIncidentAutoZoom(false);
        this.removeLinesMarkers();
        this.removeMarkers(true);
        this.removeCircles();
        callDeclarations.idleTabName = ''
        this.removeCircles(true);
        if(this.props.showMap){
            this.props.setIncidentDependencies({
                'mapZoom': this.props.map.getZoom(),
                'location': this.props.map.getCenter()
            });
        }
    }

    /* add breadcrumb */
    addLine = (list, val, isPeers) => {
        let layers = this.breadcrumbLayers;
        let peersLayers = this.peersBreadcrumbLayers
        let circleId = isPeers ? 'peerInitCircle' + val : 'initCircle' + val;
        let lineId = isPeers ? 'peerCircles' + val : 'circles' + val;
        let polylineId = isPeers ? 'peerPolyline' + val : 'polyline' + val;

        if(isPeers){
            peersLayers.push(lineId, circleId, polylineId);
            this.peersBreadcrumbLayers = peersLayers;
        } else {
            layers.push(lineId, circleId, polylineId);
            this.breadcrumbLayers = layers;
        }
        if (this.props.map.getLayer(lineId)) {
            this.props.map.removeLayer(lineId);
            this.props.map.removeSource(lineId);

        }
        this.props.map.addSource(lineId, {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': list.geometry.coordinates
                }
            }
        });
        this.props.map.addLayer({
            'id': lineId,
            "type": "circle",
            "source": lineId,
            "paint": {
                "circle-radius": 5,
                "circle-color": '#888'

            }
        });
        if (this.props.map.getLayer(polylineId)) {
            this.props.map.removeLayer(polylineId);
            this.props.map.removeSource(polylineId);

        }
        this.props.map.addSource(polylineId, {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': list.geometry.coordinates
                }
            }
        });
        this.props.map.addLayer({
            'id': polylineId,
            "type": "line",
            "source": polylineId,
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': '#888',
                'line-width': 2
            }
        });
        if (this.props.map.getLayer(circleId)) {
            this.props.map.removeLayer(circleId);
            this.props.map.removeSource(circleId);
        }
        this.props.map.addSource(circleId, {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "Point",
                            coordinates: list.geometry.coordinates[0]
                        }
                    }
                ]
            }
        });
        this.props.map.addLayer({
            "id": circleId,
            "type": "circle",
            "source": circleId,
            "paint": {
                "circle-radius": 8,
                "circle-color": '#888'

            }
        });
    }

    connectFunctions = (list) => {
        this.props.fetchIncident(list);
        this.handleSelectIncident(list);
        if(list.nenaIncidentId !== this.props.NENA.NENA){
            isSelectedMarkerExists = true;
            this.props.fetchNENA(list.nenaIncidentId);
        }
        else if(list.nenaIncidentId === this.props.NENA.NENA){
            isSelectedMarkerExists = false;
            this.props.fetchNENA('');
        }
    }

    /* Additional Info */
    handleAddInfo = (list, val, e) => {
        e.stopPropagation();
        this.connectFunctions(list);
        this.props.fetchUber(false);
        this.props.fetchAddInfo(true);
    }

    /* Uber Info */
    handleUber = (list, val, e) => {
        e.stopPropagation();
        this.connectFunctions(list);
        this.props.fetchUber(true);
        this.props.fetchAddInfo(false);
    }

    /* close panel */
    handleClose = () => {
        this.props.fetchNENA('');
    }

    /* select Incident */
    handleSelect = (list) => {
        this.props.setSearchZoom(false);
        this.props.setPauseZoom(true);
        this.props.fetchUber(false);
        this.props.fetchAddInfo(false);
        this.props.fetchMapBound(false);
        this.connectFunctions(list);
    }

    handleSelectIncident = (place) => {
        // if(!this.props.loadMap)
        //     return;
        let bounds = [];
        if(this.props.map && (callDeclarations.idleTabName === tabUrls.incident)){
            if (this.isRapidSOSDataAvailable(place)) {
                if(place.geometry && place.geometry.coordinates && place.geometry.coordinates.length){
                    let lngLat = new mapboxgl.LngLat(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1]);
                    bounds.push(lngLat);
                }            
                let sosLngLat = new mapboxgl.LngLat(place.rapidSOS.geometry.coordinates[place.rapidSOS.geometry.coordinates.length - 1][0], place.rapidSOS.geometry.coordinates[place.rapidSOS.geometry.coordinates.length - 1][1]);
                bounds.push(sosLngLat);
                this.setBounds(bounds);
            } else if (place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
                this.props.map.flyTo({
                    center: place.geometry.coordinates[place.geometry.coordinates.length - 1],
                    zoom: 17,
                    bearing: 0,
                    speed: 1.2, // make the flying slow
                    curve: 4, // change the speed at which it zooms out
                    easing: function (t) { return t; }
                });
            }
        }
    }

    /* add markers */
    showIncidents = (list, isPeers) => {
        list.forEach((place) => {
            let el = document.createElement('div');
            let indexClass = place.callState === incidentConfigs.activeStatus ? 'inProgress' : place.callState === incidentConfigs.waitingStaus ? 'queued' : '';
            el.className = `${isPeers ? `peers-marker ${indexClass}` : `incident-marker ${indexClass}`}`;
            if (place.classOfService) {
                if (markerImages.activeMarkers[place.classOfService]) {
                    if (place.callState === incidentConfigs.activeStatus) {
                        el.style.backgroundImage = `url(${markerImages.activeMarkers[place.classOfService]})`;
                    }
                    else {
                        el.style.backgroundImage = `url(${markerImages.queuedMarkers[place.classOfService]})`;
                    }
                }
            }
            let options = { element: el, anchor: 'bottom' };
            if (place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
                let lngLat = new mapboxgl.LngLat(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1]);
                let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                if(isPeers){
                    this.peerMarkers.push(marker);
                } else {
                    this.markers.push(marker);
                }
            }
        });
    }

    createMarkers = (incidents, isPeers) => {
        let bounds = [] ;
        this.showIncidents(incidents, isPeers);
        incidents.forEach((place, ind) => {
            let el = document.createElement('div');
            el.addEventListener('click', this.handleIncident.bind(this, place, ind), {passive:false});
            el.addEventListener('touchstart', this.handleIncident.bind(this, place, ind), {passive:false});
            let indexClass = place.callState === incidentConfigs.activeStatus ? 'inProgress' : place.callState === incidentConfigs.waitingStaus ? 'queued' : '';
            el.className = `${isPeers ? `peers-marker ${indexClass}` : `incident-marker ${indexClass}`}`;
            if (place && place.nenaIncidentId && this.props.NENA.NENA === place.nenaIncidentId) {
                if (place.callState === incidentConfigs.activeStatus){
                    isSelectedMarkerExists = true;
                }
                else{
                    isSelectedMarkerExists = false;
                }
                el.className = `incident-selected-overlay ${isPeers ? 'peers-marker' : 'incident-marker'} `;
                if (markerImages.selectMarkers[place.classOfService]) {
                    el.style.backgroundImage = `url(${markerImages.selectMarkers[place.classOfService]})`;
                }
                else {
                    el.style.backgroundImage = `url(${markerImages.selectMarkers['default']})`;
                }
                if (place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
                    let options = { element: el, anchor: 'bottom' };
                    let lngLat = new mapboxgl.LngLat(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1]);
                    let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                    if (isPeers) {
                        this.peerMarkers.push(marker);
                    } else {
                        this.markers.push(marker);
                        if (locationUpdated && !this.props.incidentAutoZoom && this.props.map) {
                            this.props.map.flyTo({
                                center: lngLat,
                                zoom: 17
                            });
                            locationUpdated = false;
                        }
                    }
                }
                this.props.map.once('idle', () => {
                    consoleLog(":::::::::: incident panel selected idle ::::: "  + callDeclarations.idleTabName )
                    if(!this.props.incidentAutoZoom){
                        if (isSelectedMarkerExists && this.props.NENA && this.props.NENA.NENA !== '' && !this.props.isSearchZoom && (callDeclarations.idleTabName === tabUrls.incident)) {
                            this.handleSelectIncident(place);
                        }
                    }
                });

            }
            else {
                if (markerImages.activeMarkers[place.classOfService]) {
                    if (place.callState === incidentConfigs.activeStatus) {
                        el.style.backgroundImage = `url(${markerImages.activeMarkers[place.classOfService]})`;
                    } else if (place.callState === incidentConfigs.endStaus) {
                        el.style.backgroundImage = `url(${markerImages.endedMarkers[place.classOfService]})`;
                    } else {
                        el.style.backgroundImage = `url(${markerImages.queuedMarkers[place.classOfService]})`;
                    }
                }
                else {
                    if (place.callState === incidentConfigs.activeStatus) {
                        el.style.backgroundImage = `url(${markerImages.activeMarkers['default']})`;
                    } else if (place.callState === incidentConfigs.endStaus) {
                        el.style.backgroundImage = `url(${markerImages.endedMarkers['default']})`;
                    } else {
                        el.style.backgroundImage = `url(${markerImages.queuedMarkers['default']})`;
                    }
                }
            }
            let options = { element: el, anchor: 'bottom' };
            if (place.geometry && place.geometry.coordinates && place.geometry.coordinates.length) {
                let lngLat = new mapboxgl.LngLat(place.geometry.coordinates[place.geometry.coordinates.length - 1][0], place.geometry.coordinates[place.geometry.coordinates.length - 1][1]);
                let marker = new mapboxgl.Marker(options).setLngLat(lngLat).addTo(this.props.map);
                if (isPeers) {
                    this.peerMarkers.push(marker);
                } else {
                    this.markers.push(marker);
                } 
                if(isPeers){
                    if (place.callState === incidentConfigs.activeStatus) {
                        this.peerIncidents.push(lngLat);
                    }
                }
                else{                  
                    if (place.counter === serverconfigs.initialCount && place.callState !== incidentConfigs.endStaus) {
                        this.incidentBounds.push(lngLat);
                    }
                    else if (place.callState === incidentConfigs.activeStatus) {
                        this.incidentBounds.push(lngLat);
                    }
                }
            }
            //place.nenaIncidentId to create rapidsos lines 
            this.createRapidSOSMarkers(place, place.nenaIncidentId, isPeers);
        });

        if((this.props.NENA.NENA !== '' || (this.props.NENA === '' || this.props.NENA.NENA === '')) && this.props.updateBound){      
            if (this.props.isSearchZoom) {
                isSelectedMarkerExists = true;
            }
            else if(this.props.NENA === '' || this.props.NENA.NENA === ''){
                isSelectedMarkerExists = false;
            }  
            if(this.props.vehicleType && this.props.vehicleType.length){
                if (!isSelectedMarkerExists || (!this.props.isSearchZoom && !isSelectedMarkerExists)) {
                    if(!this.props.incidentAutoZoom && this.props.updateTrackBounds && !this.props.showReportBounds){
                        this.props.trackingBounds();
                    }
                }
            }  
            else if (this.incidentBounds.length ) {
                if (!isSelectedMarkerExists || (!this.props.isSearchZoom && !isSelectedMarkerExists)) {
                    if(!this.props.incidentAutoZoom && this.props.updateTrackBounds && !this.props.showReportBounds){
                        if(this.peerIncidents.length){
                            this.incidentBounds.push(...this.peerIncidents);
                            this.setBounds(this.incidentBounds);
                        }
                        else{
                            this.setBounds(this.incidentBounds);
                        }
                    }
                }
            }
            else if (this.peerIncidents.length) {
                if (!isSelectedMarkerExists || (!this.props.isSearchZoom && !isSelectedMarkerExists)) {
                    if(!this.props.incidentAutoZoom && this.props.updateTrackBounds && !this.props.showReportBounds){
                        this.setBounds(this.peerIncidents);
                    }
                }
            }
            else {
                if (!isSelectedMarkerExists && !this.props.isSearchZoom  && !this.props.incidentAutoZoom && !this.props.showReportBounds) {
                    this.handleGroupBounds();
                }
            }
        }

    }

    createRapidSOSMarkers = (place, index, isPeers) => {
        if (this.isRapidSOSDataAvailable(place)) {
            let sosElement = document.createElement('div');
            sosElement.addEventListener('click', this.handleIncident.bind(this, place, index), {passive:false});
            sosElement.addEventListener('touchstart', this.handleIncident.bind(this, place, index), {passive:false});
            let indexClass = place.callState === incidentConfigs.activeStatus ? 'inProgress' : place.callState === incidentConfigs.waitingStaus ? 'queued' : '';
            sosElement.className = `incident-marker-sos ${isPeers ? `peers-marker ${indexClass}` : `incident-marker ${indexClass}`}`;
            if (this.props.NENA.NENA === place.nenaIncidentId) {
                sosElement.className = `incident-marker-sos incident-selected-overlay ${isPeers ? 'peers-marker' : 'incident-marker'}`; 
                sosElement.style.backgroundImage = `url(${markerImages.rapidSOSMarkers['selected']})`;
            } else if (place.callState && (place.callState === incidentConfigs.activeStatus)) {
                sosElement.style.backgroundImage = `url(${markerImages.rapidSOSMarkers['active']})`;
            } else if (place.callState && (place.callState === incidentConfigs.endStaus)) {
                sosElement.style.backgroundImage = `url(${markerImages.rapidSOSMarkers['ended']})`;
            } else {
                sosElement.style.backgroundImage = `url(${markerImages.rapidSOSMarkers['waiting']})`;
            }

            let sosOptions = { element: sosElement, anchor: 'bottom' };
            let lngLat = new mapboxgl.LngLat(place.rapidSOS.geometry.coordinates[place.rapidSOS.geometry.coordinates.length - 1][0], place.rapidSOS.geometry.coordinates[place.rapidSOS.geometry.coordinates.length - 1][1]);
            let marker = new mapboxgl.Marker(sosOptions).setLngLat(lngLat).addTo(this.props.map);
            if(isPeers){
                this.peerMarkers.push(marker);
                this.addDotedLine(place, index, true);
            } else {
                this.markers.push(marker);
                this.addDotedLine(place, index);
            }
        }
    }

    isRapidSOSDataAvailable = (place) => {
        if (this.props.rapidSOS && place.rapidSOS && place.rapidSOS.geometry && place.rapidSOS.geometry.coordinates && place.rapidSOS.geometry.coordinates.length) {
            return true;
        } else {
            return false;
        }
    }

    addDotedLine = (place, index, isPeers) => {
        let dottedLineId = isPeers ? 'peerDotCircles' + index : 'dotCircles' + index;
        let layers = this.rapidSOSDottedLineLayers;
        let peersLayers = this.peersRapidSOSDottedLineLayers;
        if(isPeers){
            peersLayers.push(dottedLineId);
            this.peersRapidSOSDottedLineLayers = peersLayers;
        } else {
            layers.push(dottedLineId);
            this.rapidSOSDottedLineLayers = layers;
        }
        
        if (this.isRapidSOSDataAvailable(place)) {
            if (this.props.map.getLayer(dottedLineId)) {
                this.props.map.removeLayer(dottedLineId);
                this.props.map.removeSource(dottedLineId);
            }
            this.props.map.addLayer({
                "id": dottedLineId,
                "type": "line",
                "source": {
                    "type": "geojson",
                    "data": {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "LineString",
                            'coordinates': [place.rapidSOS.geometry.coordinates[place.rapidSOS.geometry.coordinates.length - 1], place.geometry.coordinates[place.geometry.coordinates.length - 1]]
                        }
                    }
                },
                "layout": {
                    "line-join": "round",
                    "line-cap": "round"
                },
                "paint": {
                    "line-color": "#3d81c3",
                    "line-width": {
                        'stops': [
                            [2, 4],
                            [24, 4],
                        ],
                    },
                    "line-dasharray": [0, 1.5]
                }
            });
        }
        this.addRapidSOSLine(place, index, isPeers);
    }

    addRapidSOSLine = (place, index, isPeers) => {
        if (this.isRapidSOSDataAvailable(place)) {
            if (place.rapidSOS.geometry.coordinates.length > 1) {
                let layers = this.rapidSOSBreadcrumbLayers;
                let peersLayers = this.peersRapidSOSBreadcrumbLayers;
                let circleId = isPeers ? 'peerSosinitCircle' + index : 'sosinitCircle' + index;
                let lineId = isPeers ? 'peerSoscircles' + index : 'soscircles' + index;
                let polylineId = isPeers ? 'peerSospolyline' + index : 'sospolyline' + index;
                if(isPeers){
                    peersLayers.push(lineId, circleId, polylineId);
                    this.peersRapidSOSBreadcrumbLayers = peersLayers;
                } else {
                    layers.push(lineId, circleId, polylineId);
                    this.rapidSOSBreadcrumbLayers = layers;
                }
                if (this.props.map.getLayer(lineId)) {
                    this.props.map.removeLayer(lineId);
                    this.props.map.removeSource(lineId);
                }
                if (this.props.map.getLayer(polylineId)) {
                    this.props.map.removeLayer(polylineId);
                    this.props.map.removeSource(polylineId);
                }
                this.props.map.addSource(lineId, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': place.rapidSOS.geometry.coordinates
                        }
                    }
                });

                this.props.map.addSource(polylineId, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': place.rapidSOS.geometry.coordinates
                        }
                    }
                });

                this.props.map.addLayer({
                    'id': lineId,
                    "type": "circle",
                    "source": lineId,
                    "paint": {
                        "circle-radius": 5,
                        "circle-color": '#ea0e0e'

                    }
                });
                this.props.map.addLayer({
                    'id': polylineId,
                    "type": "line",
                    "source": polylineId,
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': '#888',
                        'line-width': 2
                    }
                }, lineId);

                if (this.props.map.getLayer(circleId)) {
                    this.props.map.removeLayer(circleId);
                    this.props.map.removeSource(circleId);
                }
                this.props.map.addSource(circleId, {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                geometry: {
                                    type: "Point",
                                    coordinates: place.rapidSOS.geometry.coordinates[0]
                                }
                            }
                        ]
                    }
                });
                this.props.map.addLayer({
                    "id": circleId,
                    "type": "circle",
                    "source": circleId,
                    "paint": {
                        "circle-radius": 8,
                        "circle-color": '#ea0e0e'

                    }
                });
            }
        }
    }

    setBounds = (bounds) => {
        // if(!this.props.loadMap)
        //     return;
        var bbox = new mapboxgl.LngLatBounds();
        if(this.props.map){
            if (bounds && bounds.length && bounds.length === 1) {
                this.props.map.flyTo({
                    center: bounds[0],
                    zoom: 17,
                    bearing: 0,
                    speed: 1.2, // make the flying slow
                    curve: 4, // change the speed at which it zooms out
                    easing: function (t) { return t; }
                });
            } else if (bounds && bounds.length && bounds.length > 1) {
                bounds.forEach((coordinate) => {
                    bbox.extend(coordinate);
                });
                this.props.map.fitBounds(bbox, {
                    maxZoom: 17,
                    padding: { top: 120, bottom: 100, left: 50, right: 75 }
                });
            }
        }
    }

    /* select markers */
    handleIncident = (list, ind, event) => {
        event.preventDefault();
        this.handleSelect(list);
    }

    addCircle(geojson, radius, ind, isPeers) {
        if (geojson.geometry && geojson.geometry.coordinates && geojson.geometry.coordinates.length) {
            let layers = this.circleLayers;
            let peersLayers = this.peersCircleLayers;
            let circleId = isPeers ? 'PeerCircle' + ind : 'circle' + ind;
            let circleBorder = isPeers ? 'peerBorder' + ind : 'border' + ind;
            if(isPeers){
                peersLayers.push(circleId, circleBorder);
                this.peersCircleLayers = peersLayers;
            } else{
                layers.push(circleId, circleBorder);
                this.circleLayers = layers;
            }
            let polygonPoints = this.createGeoJSONCircle(
                [Number(geojson.geometry.coordinates[geojson.geometry.coordinates.length - 1][0]), Number(geojson.geometry.coordinates[geojson.geometry.coordinates.length - 1][1])],
                radius
            );
            if (!this.props.map.getLayer(circleBorder)) {
                this.props.map.addSource(circleBorder, polygonPoints);
                this.props.map.addLayer({
                    id: circleBorder,
                    type: "line",
                    source: circleBorder,
                    layout: {},
                    paint: {
                        'line-color': '#5bb0d8',
                        'line-width': 1
                    }
                });
            } else {
                this.props.map.getSource(circleBorder).setData(polygonPoints.data);
            }
            if (!this.props.map.getSource(circleId)) {
                this.props.map.addSource(circleId, polygonPoints);
                this.props.map.addLayer({
                    id: circleId,
                    type: "fill",
                    source: circleId,
                    layout: {},
                    paint: {
                        "fill-color": "#9fcbf9",
                        "fill-opacity": 0.5,
                        'fill-outline-color': '#5bb0d8',
                    }
                });

            } else {
                this.props.map.getSource(circleId).setData(polygonPoints.data);
            }
        }
        if(isPeers)
            this.addRapidSOSCircle(geojson, ind, true);
        else
        this.addRapidSOSCircle(geojson, ind);
    }

    addRapidSOSCircle(geojson, ind, isPeers) {
        if (this.isRapidSOSDataAvailable(geojson)) {
            let layers = this.rapidSOSCircleLayers;
            let peersLayers = this.peersRapidSOSCircleLayers;
            let circleId = isPeers ? "peerRapid" + 'circle' + (ind) : "rapid" + 'circle' + (ind);
            let circleBorder = isPeers ? "peerRapid" + 'border' + (ind) : "rapid" + 'border' + (ind);
            if(isPeers){
                peersLayers.push(circleId, circleBorder);
                this.peersRapidSOSCircleLayers= peersLayers;
            } else {
                layers.push(circleId, circleBorder);
                this.rapidSOSCircleLayers = layers;
            }
            let polygonPoints = this.createGeoJSONCircle(
                [Number(geojson.rapidSOS.geometry.coordinates[geojson.rapidSOS.geometry.coordinates.length - 1][0]), Number(geojson.rapidSOS.geometry.coordinates[geojson.rapidSOS.geometry.coordinates.length - 1][1])],
                (geojson.rapidSOS.radius / 1000)
            );
            if (!this.props.map.getLayer(circleBorder)) {
                this.props.map.addSource(circleBorder, polygonPoints);
                this.props.map.addLayer({
                    id: circleBorder,
                    type: "line",
                    source: circleBorder,
                    layout: {},
                    paint: {
                        'line-color': '#5bb0d8',
                        'line-width': 1
                    }
                });
            } else {
                this.props.map.getSource(circleBorder).setData(polygonPoints.data);
            }
            if (!this.props.map.getSource(circleId)) {
                this.props.map.addSource(circleId, polygonPoints);
                this.props.map.addLayer({
                    id: circleId,
                    type: "fill",
                    source: circleId,
                    layout: {},
                    paint: {
                        "fill-color": "#9fcbf9",
                        "fill-opacity": 0.5,
                        'fill-outline-color': '#5bb0d8',
                    }
                });

            } else {
                this.props.map.getSource(circleId).setData(polygonPoints.data);
            }
        }
    }

    removeCircles = (isPeers) => {
        let circleLayer = [];
        if(isPeers){
            circleLayer = this.peersCircleLayers;
        } else{
            circleLayer = this.circleLayers;
        }

        for (var i = 0; i < circleLayer.length; i++) {
            if (this.props.map.getLayer(circleLayer[i])) {
                this.props.map.removeLayer(circleLayer[i]);
                this.props.map.removeSource(circleLayer[i]);
            }
        }
        if(isPeers){
            this.peersCircleLayers = [];
            this.removeRapidSOSCircles(true);
        } else {
            this.circleLayers = [];
            this.removeRapidSOSCircles();
        }
    }

    removeRapidSOSCircles = (isPeers) => {
        let rapidSOSCircle = [];
        if(isPeers){
            rapidSOSCircle = this.peersRapidSOSCircleLayers;
        } else
            rapidSOSCircle = this.rapidSOSCircleLayers;

        for (var i = 0; i < rapidSOSCircle.length; i++) {
            if (this.props.map.getLayer(rapidSOSCircle[i])) {
                this.props.map.removeLayer(rapidSOSCircle[i]);
                this.props.map.removeSource(rapidSOSCircle[i]);
            }
        }
        if(isPeers)
            this.peersRapidSOSCircleLayers = [];
        else    
            this.rapidSOSCircleLayers = [];
    }

    removeDotedLines = (isPeers) => {
        let rapidSOSList = [];
        if(isPeers){
            rapidSOSList = this.peersRapidSOSDottedLineLayers;
        } else
            rapidSOSList = this.rapidSOSDottedLineLayers;

        if(rapidSOSList && rapidSOSList.length){
            for (var i = 0; i < rapidSOSList.length; i++) {
                if (this.props.map.getLayer(rapidSOSList[i])) {
                    this.props.map.removeLayer(rapidSOSList[i]);
                    this.props.map.removeSource(rapidSOSList[i]);
                }
            }
        }

        if(isPeers){
            this.peersRapidSOSDottedLineLayers = [];
        } else 
            this.rapidSOSDottedLineLayers = [];
    }
    clearRapidSOSLayers = (isPeers) => {
        if(isPeers){
            this.removeRapisSOSLines(true);
            this.removeRapidSOSCircles(true);
            this.removeDotedLines(true);
        } else {
            this.removeRapisSOSLines();
            this.removeRapidSOSCircles();
            this.removeDotedLines();
        }
    }

    /**
     * Create resizable circle matching the given radius
     * @param {*} center
     * @param {*} radiusInKm
     * @param {*} points
     */
    createGeoJSONCircle(center, radiusInKm, points) {
        if (!points) points = 64;
        const coords = {
            latitude: center[1],
            longitude: center[0]
        };
        const km = radiusInKm;
        const ret = [];
        const distanceX =
            km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
        const distanceY = km / 110.574;
        let theta, x, y;
        for (var i = 0; i < points; i++) {
            theta = (i / points) * (2 * Math.PI);
            x = distanceX * Math.cos(theta);
            y = distanceY * Math.sin(theta);
            ret.push([coords.longitude + x, coords.latitude + y]);
        }
        ret.push(ret[0]);
        return {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: [
                    {
                        type: "Feature",
                        geometry: {
                            type: "Polygon",
                            coordinates: [ret]
                        }
                    }
                ]
            }
        };
    }

    handleGroupBounds = () => {
        let groupLoc, groupZoom;
        if (groupFeatures) {
            if (groupFeatures.map) {
                groupLoc = groupFeatures.map.center && groupFeatures.map.center.length ? groupFeatures.map.center : groupLocations.default.center;
                groupZoom = groupFeatures.map.zoom ? groupFeatures.map.zoom : groupLocations.default.zoom;
            }
            else {
                groupLoc = groupLocations.default.center;
                groupZoom = groupLocations.default.zoom;
            }
        }
        else {
            groupLoc = groupLocations.default.center;
            groupZoom = groupLocations.default.zoom;
        }
        if(this.props.map){
            this.props.map.flyTo({
                center: groupLoc,
                zoom: groupZoom,
                bearing: 0,
                speed: 1.2, // make the flying slow
                curve: 4, // change the speed at which it zooms out
                easing: function (t) { return t; }
            });
        }
    }

    filteredPeersObj = () => {
      let filteredPeersList = [];
      const peerApiResp = this.props.peersApiResp;
      const selectedPeers = this.props.selectedPeersValue;
        if (peerApiResp && peerApiResp.length) {
            if (selectedPeers.peersData && selectedPeers.peersData.length) {
                peerApiResp.map(peersData => {
                    selectedPeers.peersData.map(selectedpeerData => {
                        if (selectedpeerData.name === peersData.peerName && (peersData.results && peersData.results.length) ) {
                            filteredPeersList.push(peersData);
                        }
                    })
                })
            }
        }
      this.filteredPeersList = filteredPeersList;
      this.props.setFilteredPeers(filteredPeersList);
    }

    checkSelectedNenaId = () => {
      var endCallTimer = getEndCallTimeFromLocalStorage();
      if((endCallTimer == null || endCallTimer === '' || endCallTimer === 0) && (this.props.NENA && this.props.NENA.NENA)){
        var isNENAExist = false;  
        if((this.props.incidents && this.props.incidents.length)){
          this.props.incidents.map(item => {
                 if(item.nenaIncidentId === this.props.NENA.NENA){
                   isNENAExist = true;
                   return;
                 }
            })
        }
        if(!isNENAExist){
            if(this.props.peersApiResp && this.props.peersApiResp.length && this.props.selectedPeersValue && this.props.selectedPeersValue.peersData && this.props.selectedPeersValue.peersData.length){
                this.props.peersApiResp.map((peerList) => {
                    if(peerList && peerList.results && peerList.results.length){
                        peerList.results.map((item) => {
                            if(item.nenaIncidentId === this.props.NENA.NENA){
                                isNENAExist = true;
                                return;
                              }
                        })
                    }
                })
            }
           isSelectedMarkerExists = isNENAExist;
          //  this.props.fetchNENA('');
        }
      }
    }

    render() {
        let movingCallers = [];
        let peersMovingCallers = [];
        
        if (this.props.showMap && this.props.incidents) {
            if (this.props.incidents.length) {
                let activeCallers = [];
                if (!this.props.rapidSOS) {
                    this.clearRapidSOSLayers();
                }
                if (this.props.showUncertainity) {
                    if (this.props.incidents.length) {
                        this.removeCircles();
                        this.props.incidents.map(location => {
                            if (location.geometry && location.geometry.coordinates && location.geometry.coordinates.length > 0) {
                                if (location.callState === incidentConfigs.activeStatus) {
                                    activeCallers.push(location);
                                }
                            }
                        })
                        for (let i = 0; i < activeCallers.length; i++) {
                            this.addCircle(activeCallers[i], activeCallers[i].radius / 1000, i + 1);
                        }
                    }
                    else {
                        this.removeCircles();
                    }
                }
                this.removeLines();
                this.props.incidents.map(location => {
                    if (location.geometry && location.geometry.coordinates && location.geometry.coordinates.length > 1) {
                        if (location.callState === incidentConfigs.activeStatus) {
                            movingCallers.push(location);
                        }
                    }
                })
                for (let i = 0; i < movingCallers.length; i++) {
                    this.addLine(movingCallers[i], i + 1);
                }
                this.removeMarkers();
                this.incidentBounds = [];
                this.peerIncidents = [];
                this.checkSelectedNenaId();
                this.createMarkers(this.props.incidents, false);
                this.props.map.once('idle', () => {
                    if(this.props.incidentAutoZoom && (callDeclarations.idleTabName === tabUrls.incident)){
                        if (this.props.incidentDependencies && this.props.incidentDependencies.mapZoom && this.props.incidentDependencies.location) {
                            this.props.map.flyTo({
                                center: this.props.incidentDependencies.location,
                                zoom: this.props.incidentDependencies.mapZoom
                            });
                            this.props.setIncidentDependencies({
                                'mapZoom': undefined,
                                'location' : null
                            });
                        }
                    }
                });
            }
            else if (this.props.error) {
                this.removeCircles();
                this.removeLinesMarkers();
                toast.error(this.props.error, { toastId: customToastId });
            }
            else {
                this.removeCircles();
                this.removeLinesMarkers();
                toast.warning(NO_DATA, { toastId: customToastId });        
                if (!this.props.isSearchZoom && !this.props.incidentAutoZoom && this.props.updateTrackBounds && !this.filteredPeersList.length && !this.props.showReportBounds) {
                    this.handleGroupBounds();
                }
            }
        }
        else{
            if (this.props.error) {
                toast.error(this.props.error, { toastId: customToastId });
            }
        }
        if(this.props.showMap  && this.props.selectedPeersValue && this.props.selectedPeersValue.peersData && this.props.selectedPeersValue.peersData.length){
            this.filteredPeersObj();
            if(this.filteredPeersList.length){
                let activeCallers = [];
                if (!this.props.rapidSOS) {
                    this.clearRapidSOSLayers(true);
                }
                if (this.props.showUncertainity) {
                    if (this.filteredPeersList.length) {
                        this.removeCircles(true);
                        this.filteredPeersList.map(peersList => {
                            // check for geometry condition and length
                            peersList.results.map(peersData => {
                                if (peersData.geometry && peersData.geometry.coordinates && peersData.geometry.coordinates.length > 0) {
                                    if (peersData.callState === incidentConfigs.activeStatus) {
                                        activeCallers.push(peersData);
                                    }
                                }
                            });
                        });
                        for (let i = 0; i < activeCallers.length; i++) {
                            this.addCircle(activeCallers[i], activeCallers[i].radius / 1000, i + 1, true);
                        }
                    }
                    else {
                        this.removeCircles(true);
                    }
                } else {
                    this.removeCircles(true);
                }

                this.removeMarkers(true);
                this.filteredPeersList.map(peersList => {
                    // check for geometry condition and length
                    peersList.results.map(peersData => {
                        if (peersData.geometry && peersData.geometry.coordinates && peersData.geometry.coordinates.length > 1) {
                            if (peersData.callState === incidentConfigs.activeStatus) {
                                peersMovingCallers.push(peersData);
                            }
                        }
                    });

                    for (let i = 0; i < peersMovingCallers.length; i++) {
                        this.addLine(peersMovingCallers[i], i + 1, true);
                    }
                    this.checkSelectedNenaId();
                    if(peersList && peersList.results && peersList.results.length){
                        this.createMarkers(peersList.results, true);
                    }
                    this.props.map.once('idle', () => {
                        consoleLog(":::::::::: incidents peers auto zoom ::::: ")
                        if(this.props.incidentAutoZoom && (callDeclarations.idleTabName === tabUrls.incident)){
                            if (this.props.incidentDependencies && this.props.incidentDependencies.mapZoom && this.props.incidentDependencies.location) {
                                this.props.map.flyTo({
                                    center: this.props.incidentDependencies.location,
                                    zoom: this.props.incidentDependencies.mapZoom
                                });
                                this.props.setIncidentDependencies({
                                    'mapZoom': undefined,
                                    'location' : null
                                });
                            }
                        }
                    });
                })
                this.filterBounds = [];
            } else {
                this.removeMarkers(true);
                this.removeCircles(true);
                this.filteredPeersList = [];
                this.props.setFilteredPeers([]);
            }
        }else{
            // TODO
            this.peerIncidents = [];
            this.removeMarkers(true);
            this.removeCircles(true);
            this.filteredPeersList = [];
            this.props.setFilteredPeers([]);
        }



        return (
            <>
                <div className='incident-panel'>
                    <h4 className='incident-title'>{tabUrls.incident}</h4>
                    <div className='incident-list' id='incident-scroll'>
                        {
                            !this.props.isLoading && this.props.incidents ?
                                this.props.incidents.length ?
                                    this.props.incidents.map((incident, index) => {
                                        let diff = moment(new Date()).diff(incident.connectTime);
                                        let dateObj = moment.duration(diff);
                                        let minutes = dateObj._data.minutes < 10 ? '0' + dateObj._data.minutes : dateObj._data.minutes;
                                        let seconds = dateObj._data.seconds < 10 ? '0' + dateObj._data.seconds : dateObj._data.seconds;
                                        return (
                                            <div className={`list hand-cursor ${incident.callState === incidentConfigs.activeStatus ? incidentConfigs.activeStatus : incident.callState} ${incident.nenaIncidentId ? incident.nenaIncidentId === this.props.NENA.NENA ? 'active' : '': ''} `} key={index}
                                                onClick={this.handleSelect.bind(this, incident)} >
                                                <div className='tower-icon d-flex align-items-center'>
                                                    <div>
                                                        {incident.classOfService ?
                                                            markerImages.incidentIcons[incident.classOfService] ?
                                                                <img className='landline' src={markerImages.incidentIcons[incident.classOfService]} />
                                                                :
                                                                <img className='others' src={markerImages.incidentIcons['default']} />
                                                            : <img className='others' src={markerImages.incidentIcons['default']} />

                                                        }
                                                    </div>
                                                    <div className='incident-tower'>
                                                        <div>{incident.connectTime ? moment(incident.connectTime).format('MM-DD-YYYY hh:mm:ss A') : ''}</div>
                                                    </div>
                                                </div>
                                                <div className='py-2'>
                                                    {(incident.classOfService === 'RESD' || incident.classOfService === 'BUSN') ?
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className='address'>Call back#: {incident.ani ? incident.ani.includes('+') ? <span>{incident.ani.slice(0, 2)} {incident.ani.slice(2, incident.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                                                incident.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''}</div>
                                                                {(incident.callState === incidentConfigs.activeStatus) && incident.connectTime ?
                                                                    <div className='address'>{minutes + ':' + seconds}</div>
                                                                    : ''}
                                                            </div>
                                                            :
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className='address p-0'>MDN: {incident.ani ? incident.ani.includes('+') ? <span>{incident.ani.slice(0, 2)} {incident.ani.slice(2, incident.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                                                incident.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''}</div>
                                                                {(incident.callState === incidentConfigs.activeStatus) && incident.connectTime ?
                                                                    <div className='address p-0'>{minutes + ':' + seconds}</div>
                                                                    : ''}
                                                            </div>
                                                        }
                                                    <div className="d-flex justify-content-between align-items-center py-2">
                                                        {incident.address ?
                                                            <div>
                                                                <div className='address'>{incident.address.address1}{incident.address.address1 && (incident.address.address2 || incident.address.city || incident.address.state || incident.address.country) ? '' : ''}</div>
                                                                <div className='address'>{incident.address.address2}{incident.address.address2 && (incident.address.city || incident.address.state || incident.address.country) ? '' : ''}</div>
                                                                <div className='address'>{incident.address.city}{incident.address.city && (incident.address.state || incident.address.country) ? ',' : ''} {incident.address.state}{incident.address.state && incident.address.country ? ',' : ''} {incident.address.country}</div>
                                                            </div>
                                                            : ''}
                                                        {/* <img className='refresh-icon' src=refreshSvg /> */}
                                                    </div>
                                                    <div className='py-2'>
                                                        {incident.geometry && incident.geometry.coordinates && incident.geometry.coordinates.length ?
                                                            <span className='coordinates'>[{parseFloat(incident.geometry.coordinates[incident.geometry.coordinates.length - 1][1]).toFixed(6)}, {parseFloat(incident.geometry.coordinates[incident.geometry.coordinates.length - 1][0]).toFixed(6)}]</span>
                                                            : <div className='unknown-loc'>Location Unknown</div>}
                                                        {incident.radius ?
                                                            <span className='radius'> UNC: {incident.radius ? parseFloat(incident.radius).toFixed() : ""} m</span> : ""}
                                                    </div>
                                                </div>
                                                <div className='more-info'>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className='title'>
                                                            <div>
                                                                {incident.classOfService ?
                                                                    markerImages.serviceList[incident.classOfService] ?
                                                                        markerImages.serviceList[incident.classOfService]
                                                                        :
                                                                        <img src={markerImages.serviceList['default']} />

                                                                    : ''}
                                                            </div>
                                                            <div>Provider ID: {incident.serviceProvider}</div>
                                                        </div>
                                                        {/* <div className={`add-btn ${incident.nenaIncidentId === this.props.NENA.NENA && this.props.addInfo ? 'active' : ''}`} onClick={this.handleAddInfo.bind(this, incident, index)}>Additional Info</div>
                                                        {incident.properties.uber.license !== '' ?
                                                            <img className='uber' src=uberSvg onClick={this.handleUber.bind(this, incident, index)} />
                                                            : ''} */}
                                                        {/* <div className='print-icon'><img src=printSvg /></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                                :
                                <Spinner animation="border" variant="primary">
                                    <span className="sr-only"></span>
                                </Spinner>
                        }
                        {this.filteredPeersList && this.filteredPeersList.length ?
                            <h4 className='incident-title peers-heading'>Peers {tabUrls.incident}</h4> : ''
                        }
                        {
                            this.filteredPeersList && this.filteredPeersList.length ?
                            this.filteredPeersList.map((peersList) => {
                               return(
                                    <div key={peersList.peerName}>
                                        {peersList && peersList.results && peersList.results.length ? 
                                        <div className='peer-incident-title group-peers text-white'>
                                        <img className='landline icon-location' src={location} />
                                            {peersList.peerName}
                                        </div>
                                        : ''}
                                        {peersList && peersList.results && peersList.results.length ?
                                            peersList.results.map((peerIncident) => {
                                                let diff = moment(new Date()).diff(peerIncident.connectTime);
                                                let dateObj = moment.duration(diff);
                                                let minutes = dateObj._data.minutes < 10 ? '0' + dateObj._data.minutes : dateObj._data.minutes;
                                                let seconds = dateObj._data.seconds < 10 ? '0' + dateObj._data.seconds : dateObj._data.seconds;
                                                return (
                                                    <>                                                    
                                                    <div className={`list hand-cursor ${peerIncident.callState === incidentConfigs.activeStatus ? incidentConfigs.activeStatus : peerIncident.callState} ${peerIncident.nenaIncidentId ? peerIncident.nenaIncidentId === this.props.NENA.NENA ? 'active' : '': ''} `} key={peerIncident.id}
                                                        onClick={this.handleSelect.bind(this, peerIncident)} >
                                                        <div className='tower-icon d-flex align-items-center'>
                                                            <div>
                                                                {peerIncident.classOfService ?
                                                                    markerImages.incidentIcons[peerIncident.classOfService] ?
                                                                        <img className='landline' src={markerImages.incidentIcons[peerIncident.classOfService]} />
                                                                        :
                                                                        <img className='others' src={markerImages.incidentIcons['default']} />
                                                                    : <img className='others' src={markerImages.incidentIcons['default']} />
        
                                                                }
                                                            </div>
                                                            <div className='incident-tower'>
                                                                <div>{peerIncident.connectTime ? moment(peerIncident.connectTime).format('MM-DD-YYYY hh:mm:ss A') : ''}</div>
                                                            </div>
                                                        </div>
                                                        <div className='py-2'>
                                                            {(peerIncident.classOfService === 'RESD' || peerIncident.classOfService === 'BUSN') ?
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div className='address'>Call back#: {peerIncident.ani ? peerIncident.ani.includes('+') ? <span>{peerIncident.ani.slice(0, 2)} {peerIncident.ani.slice(2, peerIncident.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                                                        peerIncident.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''}</div>
                                                                        {(peerIncident.callState === incidentConfigs.activeStatus) && peerIncident.connectTime ?
                                                                            <div className='address'>{minutes + ':' + seconds}</div>
                                                                            : ''}
                                                                    </div>
                                                                    :
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <div className='address'>MDN: {peerIncident.ani ? peerIncident.ani.includes('+') ? <span>{peerIncident.ani.slice(0, 2)} {peerIncident.ani.slice(2, peerIncident.ani.length).replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3')}</span> :
                                                                        peerIncident.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''}</div>
                                                                        {(peerIncident.callState === incidentConfigs.activeStatus) && peerIncident.connectTime ?
                                                                            <div className='address'>{minutes + ':' + seconds}</div>
                                                                            : ''}
                                                                    </div>
                                                                }
                                                            <div className="d-flex justify-content-between align-items-center py-2">
                                                                {peerIncident.address ?
                                                                    <div>
                                                                        <div className='address'>{peerIncident.address.address1}{peerIncident.address.address1 && (peerIncident.address.address2 || peerIncident.address.city || peerIncident.address.state || peerIncident.address.country) ? '' : ''}</div>
                                                                        <div className='address'>{peerIncident.address.address2}{peerIncident.address.address2 && (peerIncident.address.city || peerIncident.address.state || peerIncident.address.country) ? '' : ''}</div>
                                                                        <div className='address'>{peerIncident.address.city}{peerIncident.address.city && (peerIncident.address.state || peerIncident.address.country) ? ',' : ''} {peerIncident.address.state}{peerIncident.address.state && peerIncident.address.country ? ',' : ''} {peerIncident.address.country}</div>
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                            <div className='py-2'>
                                                                {peerIncident.geometry && peerIncident.geometry.coordinates && peerIncident.geometry.coordinates.length ?
                                                                    <span className='coordinates'>[{parseFloat(peerIncident.geometry.coordinates[peerIncident.geometry.coordinates.length - 1][1]).toFixed(6)}, {parseFloat(peerIncident.geometry.coordinates[peerIncident.geometry.coordinates.length - 1][0]).toFixed(6)}]</span>
                                                                    : <div className='unknown-loc'>Location Unknown</div>}
                                                                {peerIncident.radius ?
                                                                    <span className='radius'> UNC: {peerIncident.radius ? parseFloat(peerIncident.radius).toFixed() : ""} m</span> : ""}
                                                            </div>
                                                        </div>
                                                        <div className='more-info'>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <div className='title'>
                                                                    <div>
                                                                        {peerIncident.classOfService ?
                                                                            markerImages.serviceList[peerIncident.classOfService] ?
                                                                                markerImages.serviceList[peerIncident.classOfService]
                                                                                :
                                                                                <img src={markerImages.serviceList['default']} />
        
                                                                            : ''}
                                                                    </div>
                                                                    <div>Provider ID: {peerIncident.serviceProvider}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                )
                                            })
                                            : ''
                                        }
                                    </div>
                               )
                            })
                            : ''
                        }
                    </div>
                    {/* <MoreInfoPanel className={(this.props.addInfo || this.props.uber) && this.props.NENA.NENA !== '' ? 'open-panel' : 'close-panel'} close={this.handleClose}
                        moreInfo={this.props.selectIncident.incidentData} uberInfo={this.props.uber} addInfo={this.props.addInfo}></MoreInfoPanel> */}
                </div>
                {/* <div title={this.state.enableAutoZoom ? 'Auto Zoom Out Off' : 'Auto Zoom Out On'} className={`auto-zoom-icon hand-cursor ${this.state.enableAutoZoom ? 'disable-auto-zoom' : 'enable-auto-zoom'}`} onClick={this.handleAutoZoom}></div> */}

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        incidents: state.incidents.incidents,
        isLoading: state.incidents.isLoading,
        NENA: state.NENA,
        uber: state.uber.uber,
        addInfo: state.addInfo.addInfo,
        selectIncident: state.selectIncident,
        updateBound: state.updateBound.mapBound,
        loadMap: state.loadMap.mapLoaded,
        error: state.incidents.error,
        isPauseZoom: state.pauseZoom ? state.pauseZoom.isPauseZoom : false,
        isSearchZoom: state.searchPauseZoom ? state.searchPauseZoom.isSearchZoom : false,
        rapidSOS: state.rapidSOS ? state.rapidSOS.rapidSOS : state.rapidSOS,
        incidentDependencies: state.incidentDependencies.dependencies,
        tabName: state.tab ? state.tab.tabName : state.tab,
        incidentAutoZoom:  state.incidentAutoZoom ? state.incidentAutoZoom.incidentAutoZoom :  state.incidentAutoZoom,
        peersApiResp: state.peersApiResp.peersApiResp,
        selectedPeersValue: state.selectedPeersValue,
        vehicleType: state.showTracking ? state.showTracking.vehicleType : state.showTracking
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNENA: (val) => dispatch(fetchNENA(val)),
        fetchUber: (val) => dispatch(fetchUber(val)),
        fetchAddInfo: (val) => dispatch(fetchAddInfo(val)),
        fetchIncident: (list) => dispatch(fetchIncident(list)),
        fetchMapBound: (val) => dispatch(fetchMapBound(val)),
        setPauseZoom: (isPauseZoom) => dispatch(setPauseZoom(isPauseZoom)),
        setSearchZoom: (val) => dispatch(setSearchZoom(val)),
        setIncidentDependencies: (val) => dispatch(setIncidentDependencies(val)),
        // setIncidentAutoZoom: (val) => dispatch(setIncidentAutoZoom(val))
        setFilteredPeers: (val) => dispatch(setFilteredPeers(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IncidentPanel);
