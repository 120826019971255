import React from 'react';
import { Table } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { BsX } from "react-icons/bs";
import moment from "moment";
import RouteIcon from '../../assets/routepopup-icon.png';
import { tabUrls } from '../../constants';
import '../../styles/discrepancy.scss';
class TrackingPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            followMe: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        state.popupData = props.popupData
        return state
    }

    handleFollowMe = () => {
        this.setState({
            followMe: !this.state.followMe
        })
    }

    render() {
        const place = this.props.popupData;
        return (
            <>
                {this.props.show ?
                    <Modal className={`discrepancy-popup track-popup ${this.props.tabName === tabUrls.incident ? 'incident-track-popup' : ''}`} enforceFocus={false} backdrop={false} show={this.props.show} onHide={this.props.hide} animation={false}>
                        <Modal.Body>
                            <div>
                                <h4>{place.properties.label}</h4>
                                {/* <div className='close-popup'>
                                    <BsX className="hand-cursor" onClick={this.props.hide}>close</BsX>
                                </div> */}
                                <div className='tracking-content'>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td className='street-label'>Serial#:</td>
                                                <td>{place.properties.trackId}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Lat/Lon:</td>
                                                <td>{parseFloat(place.geometry.coordinates[1]).toFixed(6)}, {parseFloat(place.geometry.coordinates[0]).toFixed(6)}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Speed:</td>
                                                <td>{place.properties.speed ? place.properties.speed : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Heading:</td>
                                                <td>{place.properties.heading ? place.properties.heading : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Ignition:</td>
                                                <td>{place.properties.ignition ? place.properties.ignition : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Speed Label:</td>
                                                <td>{place.properties.speed_label ? place.properties.speed_label : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Odometer:</td>
                                                <td>{place.properties.odometer ? place.properties.odometer : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Runtime:</td>
                                                <td>{place.runtime_minutes ? place.runtime_minutes : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Recorded:</td>
                                                <td>{moment(place.properties.recorded_time).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Current:</td>
                                                <td>{moment(place.properties.current_time).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Verified Time:</td>
                                                <td>{moment(place.properties.verified_time).format('MM-DD-YYYY hh:mm:ss A')}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Address:</td>
                                                <td>{place.properties.address ? place.properties.address : '--'}</td>
                                            </tr>
                                            <tr>
                                                <td className='street-label'>Speed Limit:</td>
                                                <td>{place.properties.speed_limit ? place.properties.speed_limit : '--'}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className='mt-2 report-footer'>
                                    <span className='hand-cursor cancel' onClick={this.props.hide}>{this.props.tabName === tabUrls.call ? 'Cancel' : 'Close'}</span>
                                    {this.props.tabName === tabUrls.call ?
                                        <span className='ms-5 hand-cursor report-submit' onClick={this.props.handleRouteTo.bind(this, place)}>Route To</span>
                                        : ''}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ''}
            </>
        );
    }
}

export default TrackingPopup;