// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-polygon .modal-dialog {
  max-width: 400px;
  top: 50%;
  margin-top: -80px;
  position: fixed;
  left: 330px;
  right: 0;
}
.delete-polygon .modal-dialog .modal-body {
  padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/deleteModal.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,QAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,QAAA;AAAR;AACQ;EACI,cAAA;AACZ","sourcesContent":[".delete-polygon{\n    .modal-dialog{\n        max-width: 400px;\n        top:50%;\n        margin-top: -80px;\n        position: fixed;\n        left: 330px;\n        right: 0;\n        .modal-body{\n            padding-top: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
