import {
    FTECH_MAP_THEME_REQUEST, FTECH_MAP_THEME_SUCCESS, FTECH_MAP_THEME_FAIL, FETCH_MAP_THEME_URL,
    FETCH_RENDER_Theme, FETCH_TRAFFIC, FETCH_WEATHER, FETCH_RENDER_WEATHER, RENDER_CALLS_PANEL, FETCH_UNCERTAINITY,
    FETCH_RENDER_UNCERTAINITY, FTECH_SPLIT_SCREEN, FETCH_ROUTING, FETCH__RENDER_ROUTING, FETCH_CAMERA, SET_RAPID_SOS,
    RENDER_RAPID_SOS, FETCH_PSAP_BOUNDS_LAYERS, FETCH_ALL_MAP_LAYers, FTECH_MAP_THEME_CANCEL, FETCH_LOGIN_STATUS, FETCH_ALL_SOURCE_LAYERS, FETCH_TRACKING_DATA,SHOW_TRACKING_LIST, SET_SELECTED_SOURCE_VALUE, FETCH_NETWORK_STATUS, SET_FILTERED_SOURCE_VALUE,
    FETCH_STREAMING_STATUS, FETCH_INDOOR_MAP, SET_DEVICES_DATA, FETCH_HEAT_MAP, SHOW_HEATMAP_OPTION, UPDATE_FLOOR_DATA
} from '../actions/actionTypes';


const initialState = {
    error: '',
    isLoading: false
}

export const MapThemesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FTECH_MAP_THEME_REQUEST:
            return { ...state, isLoading: action.isLoading }
        case FTECH_MAP_THEME_SUCCESS:
            return { isLoading: action.isLoading, data: action.data, error: '' }
        case FTECH_MAP_THEME_FAIL:
            return { error: action.error, isLoading: false }
        case FTECH_MAP_THEME_CANCEL:
            return { cancelError: action.error, callEnable: action.retires, isLoading: false }
        default:
            return state;
    }
}

const mapUrl = '';
const styleLoad = false;
const reloadStyle = false;

export const MapThemeUrlReducer = (state = mapUrl, action) => {
    switch (action.type) {
        case FETCH_MAP_THEME_URL:
            return {
                ...state,
                mapUrl: action.data,
                styleLoad: true
            }
        default:
            return state;
    }
}

const mapRender = 0;

export const MapRenderReducer = (state = mapRender, action) => {
    switch (action.type) {
        case FETCH_RENDER_Theme:
            return {
                ...state,
                mapRender: action.data
            }
        default:
            return state;
    }
}

const traffic = '';

export const TrafficReducer = (state = traffic, action) => {
    switch (action.type) {
        case FETCH_TRAFFIC:
            return {
                ...state,
                traffic: action.data
            }
        default:
            return state;
    }
}

const uncertainity = '';
export const UncertainityReducer = (state = uncertainity, action) => {
    switch (action.type) {
        case FETCH_UNCERTAINITY:
            return {
                ...state,
                uncertainity: action.data
            }
        default:
            return state;
    }
}

const renderUncertainity = 0;
export const UncertainityRenderReducer = (state = renderUncertainity, action) => {
    switch (action.type) {
        case FETCH_RENDER_UNCERTAINITY:
            return {
                ...state,
                renderUncertainity: action.data
            }
        default:
            return state;
    }
}

const rapidSOS = { rapidSOS: true };
export const RapidSOSReducer = (state = rapidSOS, action) => {
    switch (action.type) {
        case SET_RAPID_SOS:
            return {
                ...state,
                rapidSOS: action.data
            }
        default:
            return state;
    }
}

const renderRapidSOS = 0;
export const RapidSOSRenderReducer = (state = renderRapidSOS, action) => {
    switch (action.type) {
        case RENDER_RAPID_SOS:
            return {
                ...state,
                renderRapidSOS: action.data
            }
        default:
            return state;
    }
}

const weather = '';

export const WeatherReducer = (state = weather, action) => {
    switch (action.type) {
        case FETCH_WEATHER:
            return {
                ...state,
                weather: action.data
            }
        default:
            return state;
    }
}

const renderWeather = 0;

export const RenderWeatherReducer = (state = renderWeather, action) => {
    switch (action.type) {
        case FETCH_RENDER_WEATHER:
            return {
                ...state,
                renderWeather: action.data
            }
        default:
            return state;
    }
}

const splitScreen = { isSplitScreen: false };
export const SplitScreenReducer = (state = splitScreen, action) => {
    switch (action.type) {
        case FTECH_SPLIT_SCREEN:
            return {
                ...state,
                isSplitScreen: action.data
            }
        default:
            return state;
    }
}

const routing = '';
export const RoutingReducer = (state = routing, action) => {
    switch (action.type) {
        case FETCH_ROUTING:
            return {
                ...state,
                routing: action.data
            }
        default:
            return state;
    }
}

const renderRouting = 0;
export const RoutingRenderReducer = (state = renderRouting, action) => {
    switch (action.type) {
        case FETCH__RENDER_ROUTING:
            return {
                ...state,
                renderRouting: action.data
            }
        default:
            return state;
    }
}

const showCamera = { showCamera: false }
export const CameraReducer = (state = showCamera, action) => {
    switch (action.type) {
        case FETCH_CAMERA:
            return {
                ...state,
                showCamera: action.data
            }
        default:
            return state;
    }
}

const boundLayer = {}
export const PsapBoundsReducer = (state = boundLayer, action) => {
    switch (action.type) {
        case FETCH_PSAP_BOUNDS_LAYERS:
            return {
                ...state,
                boundLayer: action.data.name, showPsapLayer: action.data.show, id: action.data.id
            }
        default:
            return state;
    }
}

const allMapLayers = {allMapLayers : []};
export const AllMapLayersReducer = (state = allMapLayers, action) => {
    switch (action.type) {
        case FETCH_ALL_MAP_LAYers:
            return {
                ...state,
                allMapLayers: action.data
            }
        default:
            return state;
    }
}

const trackingList = [];
export const TrackingReducer = (state = trackingList, action) => {
    switch (action.type) {
        case FETCH_TRACKING_DATA:
            return {
                ...state,
                trackingList: action.data
            }
        default:
            return state;
    }
}

const showTracking = {}
export const ShowTrackingReducer = (state = showTracking, action) => {
    switch (action.type) {
        case SHOW_TRACKING_LIST:
            return {
                ...state,
                vehicleType: action.vehicleType, renderCount: action.renderCount, showTracking: action.showTracking
            }
        default:
            return state;
    }
}


const allSourceLayers = [];
export const AllSourceLayersReducer = (state = allSourceLayers, action) => {
    switch (action.type) {
        case FETCH_ALL_SOURCE_LAYERS:
            return {
                ...state,
                allSourceLayers: action.data
            }
            default:
                return state;
    }
}

const loggedIn = { loggedIn: false };
export const LoggedInReducer = (state = loggedIn, action) => {
    switch (action.type) {
        case FETCH_LOGIN_STATUS:
            return {
                ...state,
                loggedIn: action.data
            }
        default:
            return state;
    }
}

const isOnline = { isOnline: true };
export const NetworkCheckReducer = (state = isOnline, action) => {
    switch (action.type) {
        case FETCH_NETWORK_STATUS:
            return {
                ...state,
                isOnline: action.data
            }
        default:
            return state;
    }
}

const selectedSourceLayers = [];
export const SelectedSourceLayersReducer = (state = selectedSourceLayers, action) => {
    switch(action.type){
        case SET_SELECTED_SOURCE_VALUE:
            return [...state, action.data];
        default:
            return state
    }
}

const filteredSourceLayers = [];
export const FilteredSourceLayersReducer = (state = filteredSourceLayers, action) => {
    switch(action.type){
        case SET_FILTERED_SOURCE_VALUE:
            return [...state, action.data];
        default:
            return state
    }
}

const isStreaming = { isStreaming: false };
export const StreamingReducer = (state = isStreaming, action) => {
    switch (action.type) {
        case FETCH_STREAMING_STATUS:
            return {
                ...state,
                isStreaming: action.data
            }
        default:
            return state;
    }
}

const isIndoorMap = { isIndoorMap: true };
export const IndoorMapReducer = (state = isIndoorMap, action) => {
    switch (action.type) {
        case FETCH_INDOOR_MAP:
            return {
                ...state,
                isIndoorMap: action.data
            }
        default:
            return state;
    }
}

const devicesList = [];
export const DevicesReducer = (state = devicesList, action) => {
    switch (action.type) {
        case SET_DEVICES_DATA:
            return {
                ...state,
                devicesList: action.data
            }
        default:
            return state;
    }
}

const heatmap = { isHeatMap: false, showHeatmapOpt: false };
export const HeatMapReducer = (state = heatmap, action) => {
    switch (action.type) {
        case FETCH_HEAT_MAP:
            return {
                ...state,
                isHeatMap: action.data
            }
        case SHOW_HEATMAP_OPTION:
            return {
                ...state,
                showHeatmapOpt: action.data
            }
        default:
            return state;
    }
}

const floorData = {floorData: 0};
export const FloorDataReducer = (state = floorData, action) => {
    switch (action.type) {
        case UPDATE_FLOOR_DATA:
            return {
                ...state,
                floorData: action.data
            }
        default:
            return state;
    }
}