import React, { Component } from "react";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button'
import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui';
import mapboxgl from 'mapbox-gl';
import { FaRegCalendarAlt, FaRegClock } from 'react-icons/fa';
import { serverconfigs, NO_DATA, customToastId, featureLabels } from "../constants.js";
import moment from "moment";
import DateUtils from '../DateUtils';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { getIncidentsHistory, fetchHistoryDates, fetchRenderHistory, fetchHistoryIntervals, fetchHistorySliderValue } from '../redux/actions/historyActions';
import playIcon from '../assets/play.svg';
import pauseIcon from '../assets/pause.svg'
import refreshIcon from '../assets/refresh_weather.svg';
import '../styles/historypanel.scss';
import { isFeatureAllowed } from '../utils/groupFeatureUtils';

var featureCollection = require("turf-featurecollection");
const unclusteredPoint = 'unclustered-point';
const clusterCount = "cluster-count";
const toastId = "toast-id";
const textRefresh = 'Refresh';
const textUpdate = "Update";
const displayDateTimeFormat = "MM-DD-YYYY hh:mm A";

class HistoricalData extends Component {
  constructor(props) {
    super(props);
    this.dateUtils = new DateUtils();
    this.state = {
      slideVal: 0,
      sliderTicCount: '',
      sliderTotalTimeInMIn: 0,
      clusterData: [],
      showSpinner: false,
      interval: this.props.intervals ? this.props.intervals.interval : serverconfigs.interval,
      currentZoom: serverconfigs.zoom,
      startDate: this.props.dates ? (this.props.dates.startDate) : null,
      endDate: this.props.dates ? (this.props.dates.endDate) : null,
      showLocalTime: this.props.dates ? this.props.dates.showLocalTime : false,
      mapSpinner: false,
      dateTimeBtnTxt: textRefresh,
      animateHistoryData: false,
      selectedCall: "",
    };
    this.startDateTime = '';
    this.endDateTime = '';
    this.animateInterval;
  }

  getHistoryData = (startTs, endTs) => {
    this.setState({
      dateTimeBtnTxt: textRefresh,
    })
    this.props.getIncidentsHistory(startTs, endTs);
  }

  hideReqSpinner = () => {
    this.setState({
      mapSpinner: false
    })
  }

  displayALLHistory = (response) => {
    toast.dismiss();
    this.setState({
      dateTimeBtnTxt: textRefresh
    })
    if (response.features && response.features.length > 0) {
      if (this.props.isLoading) { this.hideReqSpinner(); }
      // we are looping all the records from response to set the bounding box. This may take some time when pagination code is added.
      if (this.props.render == 1) {
        let bbox = new mapboxgl.LngLatBounds();
        response.features.forEach((feature, index) => {
          bbox.extend(feature.geometry.coordinates);
        });
        this.props.map.fitBounds(bbox, {
          padding: { top: 10, bottom: 25, left: 180, right: 5 }
        });
      }
      var diff = moment.duration(moment(this.state.endDate).diff(moment(this.state.startDate)));
      var sliderTotalTimeInMIn = Math.ceil((diff._data.days * 1440) + (diff._data.hours * 60) + diff._data.minutes);
      var sliderInterval = this.props.intervals ? this.props.intervals.interval : serverconfigs.interval;
      var ticCount = Math.ceil(sliderTotalTimeInMIn / sliderInterval);
      this.setState({
        interval: sliderInterval,
        sliderTotalTimeInMIn: sliderTotalTimeInMIn,
        sliderTicCount: ticCount,
      }, () => {
        this.setPropsData();
        this.setUTCDateTime();
        this.loadMap(response);
      });
    }
    else if (this.props.error !== '') {
      this.hideReqSpinner();
      if (this.props.render === 3) {
        toast.error(this.props.error, { toastId: toastId });
        this.setState({
          sliderTicCount: 0
        })
      } else if (this.props.render === 4) {
        toast.warning(NO_DATA, { toastId: toastId });
        this.setState({
          sliderTicCount: 0
        })
        this.removeAllLayers();
      }
    }
    else if(response.features && response.features.length === 0){
      // if (this.props.render === 3) {
        toast.warning(NO_DATA, { toastId: toastId });
        this.setState({
          sliderTicCount: 0
        })
        this.hideReqSpinner();
      // }
    }
  }

  // clear all cluster related data from map
  removeAllLayers() {
    this.removePopup(this);
    this.removeMarker(this);
    if(this.props.showMap){
      let mpSource = this.props.map.getSource("customSource");
      if (typeof mpSource !== "undefined") {
        this.props.map.removeLayer("clusters");
        this.props.map.removeLayer(clusterCount);
        this.props.map.removeLayer(unclusteredPoint);
        this.props.map.removeSource("customSource");
      }
    }
    this.setState({selectedCall: ""});
    this.removePopup(this.props.map);
  }

  validateStateAndEndDate = () => {
    let validateMsg = this.dateUtils.isValidDateTime(this.state.startDate, this.state.endDate);
    if (validateMsg === 'valid') {
      return true;
    } else {
      toast.error(validateMsg, { toastId: toastId });
    }
    return false;
  }

  refreshBtnClick = () => {
    this.removePopup(this.props.map);
    if (this.validateStateAndEndDate()) {
        this.setState({
          animateHistoryData: false
        })
        clearInterval(this.animateInterval);
        if (this.state.showLocalTime) {
          this.handleLocalTime();
        } else {
          this.handleUTC();
        }
    }
  }

  // store the state value status in props, which will be used when tab is switched or theam changed
  setPropsData = () => {
    this.removeAllLayers();
    this.props.fetchHistoryDates({
      'startDate': this.state.startDate,
      'endDate': this.state.endDate,
      'showLocalTime': this.state.showLocalTime
    });
    this.props.fetchHistoryIntervals({
      'interval': this.state.interval,
      'rangeValue': this.state.slideVal,
      'sliderTotalTimeInMIn': this.state.sliderTotalTimeInMIn,
      'mapSpinner': this.state.mapSpinner
    });    
  }

  // this method will filter the records based on slider interval
  getFeatureCollection = (collection, newStartDateTime, newEndDateTime) => {
    var newFC = featureCollection([]);
    for (let i = 0; i < collection.features.length; i++) {
      if (
        collection.features[i].connectTimeStamp >= newStartDateTime &&
        collection.features[i].connectTimeStamp <= newEndDateTime
      ) {
        newFC.features.push(collection.features[i]);
      }
    }
    return newFC;
  };

  removePopup = (that) => {
    if (that.popup) {
      that.popup.remove();
    }
  };

  removeMarker = (that) => {
    if (that.marker) {
      that.marker.remove();
      that.setState({ clusterData: [] });
    }
  }

  handleSliderChange = (event, val) => {
    this.setState({
      clusterData: []
    });
    this.removeMarker(this);
    this.removePopup(this);
    this.props.fetchRenderHistory(0);
    if (this.props.incidentsHistory) {
      let slideVal;
      if (event && event.target) {
        slideVal = event.target.value;
      } else {
        slideVal = this.state.slideVal;
      }
      this.setState({
        slideVal: slideVal,
        showProfile: false
      }, () => {
        this.props.fetchHistoryIntervals({
          'interval': this.state.interval,
          'rangeValue': this.state.slideVal,
          'sliderTotalTimeInMIn': this.state.sliderTotalTimeInMIn
        });
        if (this.props.error === '') {
          this.handleSliderData(this.props.incidentsHistory, Number(this.state.slideVal));
        }
      });
    } else {
      this.setState({
        showProfile: false
      });
    }
  };

  handleSliderInterval = (e) => {
    let interval = parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value);
    if (interval !== '' && interval <= this.state.sliderTotalTimeInMIn) {
      this.setState({
        interval: interval ,
        slideVal: 1,
        sliderTicCount: Math.ceil(this.state.sliderTotalTimeInMIn / interval)
      }, () => {
        this.handleSliderChange(null, interval);
      })
    }
    else if (interval === '') {
      this.setState({
        slideVal: 0,
        interval: ''
      })
    }
  }

  animateIntervalSlider = () => {
    this.setState({
      animateHistoryData: !this.state.animateHistoryData
    }, () => {      
    if(this.state.animateHistoryData){
      this.animateInterval = setInterval(() => {
        let loopCount = this.state.sliderTicCount;
        let presentSliderPosition = Number(this.state.slideVal);
        if (presentSliderPosition === loopCount) {
          this.setState({ slideVal: 0 }, () => {
            this.handleSliderChange(null, this.state.interval);
          });
        } else {
          this.setState({ slideVal: (presentSliderPosition + 1)}, () => {
            this.handleSliderChange(null, this.state.interval);
          });
        }
        
      }, 1000);
    }
    else{
      let obj = {
        target: {
          value: Number(this.state.slideVal)
        }
      }
      this.setState({
        animateHistoryData: false
      })
      clearInterval(this.animateInterval);
      this.handleSliderChange(obj, this.state.interval);
    }
    })
  }

  resetIntervalSlider = () => {
    this.setState({ animateHistoryData: false, slideVal: 0 },() => {
      this.props.fetchHistoryIntervals({
        'rangeValue': 0
      });
      clearInterval(this.animateInterval);
      this.handleSliderChange(null, this.state.interval);
    });
  }

  loadMap = (json) => {
    this.removeAllLayers();
    this.handleSliderData(json, this.props.intervals ? Number(this.props.intervals.rangeValue) : Number(0));
  };

  handleSliderData = (historyData, sliderValue) => {
    let newStartDateTime = this.startDateTime + (sliderValue * this.state.interval * 60 * 1000);
    let newEndDateTime = newStartDateTime + (this.state.interval * 60 * 1000);
    // When interval value exceeds the end time set it with end time.
    if (newStartDateTime > this.endDateTime) {
      newStartDateTime = this.endDateTime;
    }
    if (newEndDateTime > this.endDateTime) {
      newEndDateTime = this.endDateTime;
    }
    this.displayActiveHours(newStartDateTime);

    let initialFC = this.getFeatureCollection(
      historyData,
      newStartDateTime,
      newEndDateTime
    );
    this.addClusterLayers(initialFC);
  }

  handleCallClick = (item) => {
    var showPopup = true;
    if(this.state.selectedCall != "") {
      if((this.state.selectedCall.ani === item.ani) && (this.state.selectedCall.connectTime === item.connectTime)) {
        this.setState({selectedCall: ""});
        this.removePopup(this.props.map);
        showPopup = false;
      }
    }
    if(showPopup) {
      this.removePopup(this.props.map);
      if(this.state.selectedCall != item){
        this.setState({selectedCall: item});
      }
      var coordinates = item.geometry.coordinates.slice();
      let popupTime;
      if (!this.state.showLocalTime) {
        popupTime = moment(item.properties.connectTime).format('hh:mm:ss A')
      }
      else {
        popupTime = moment.utc(item.properties.connectTime).local().format('hh:mm:ss A')
      }
      let popMDN = item.ani;
      let popupCoord = this.getCoordinates(item.geometry.coordinates[1], item.geometry.coordinates[0]);
      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(item.geometry.coordinates.lng - coordinates[0]) > 180) {
        coordinates[0] += item.geometry.coordinates.lng > coordinates[0] ? 360 : -360;
      }
      this.props.map.popup = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML('<div class="historyPopup"><div class="incTime">' + popupTime + '</div><div class="popupRow"><div class="popupLabel">MDN: </div><div class="value">' + popMDN + '</div></div><div class="popupRow"><div class="popupLabel">Lat/Lon: </div><div >' + popupCoord + '</div></div><div>')
        .on('close', () => {
          if(this.state.selectedCall != ""){
            this.setState({selectedCall: ""});
          } 
        })
        .addTo(this.props.map);
      this.props.map.easeTo(
        {
          center: item.geometry.coordinates,
        },
        { data: "custom" }
      );
    }
  }


  addClusterLayers = (featureCollection) => {
    if (this.props.showMap) {
      if (this.props.map.getSource("customSource")) {
        this.props.map.getSource("customSource").setData(featureCollection);
        return;
      }
      this.props.map.addSource("customSource", {
        type: "geojson",
        data: featureCollection,
        cluster: true
      });
      this.props.map.addLayer({
        id: "clusters",
        type: "circle",
        source: "customSource",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 20
          //   * Yellow, 30px circles when point count is between 20 and 50
          //   * Pink, 40px circles when point count is greater than or equal to 50
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            20,
            '#f1f075',
            50,
            '#f28cb1',
          ],
          'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 50, 40]
        }
      });

      this.props.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'customSource',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      this.props.map.addLayer({
        id: unclusteredPoint,
        type: 'circle',
        source: 'customSource',
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#11b4da',
          'circle-radius': 6,
          'circle-stroke-width': 3,
          'circle-stroke-color': '#fff'
        }
      });
    }
  }

  getCoordinates = (lat, lon) => {
    let latitude = lat.toString().split('.');
    let longitude = lon.toString().split('.');
    latitude[1] = latitude[1].slice(0, 6);
    longitude[1] = longitude[1].slice(0, 6);
    return latitude[0] + '.' + latitude[1] + ', ' + longitude[0] + '.' + longitude[1];
  };

  getRadius(point_count) {
    let radius = 0;
    if (point_count < 20) {
      //2 * radius in layer for <20 points circle
      radius = 2 * 20;
    }
    else if (point_count >= 20 && point_count < 50) {
      //2* radius in layer for 20 to 50 points circle
      radius = 2 * 30;
    }
    else {
      radius = 2 * 40;
    }
    return radius;
  }

  clusterClick = (e) => {
    this.setState({selectedCall: ""});
    let that = this;
    if (that.pendingClick) {
      clearTimeout(that.pendingClick);
      that.pendingClick = 0;
    }
    that.removeMarker(that);
    var features = that.props.map.queryRenderedFeatures(e.point, {
      layers: ["clusters"],
    });
    var clusterId = features[0].properties.cluster_id,
      point_count = features[0].properties.point_count,
      clusterSource = that.props.map.getSource("customSource");
    if (e.originalEvent.detail === 1 || (e.originalEvent.detail === 0 && e.type === "touchstart")) {
      that.pendingClick = setTimeout(function () {
        //single click action here
        clusterSource.getClusterLeaves(clusterId, point_count, 0, function (
          err,
          aFeatures
        ) {
          that.setState({
            clusterData: [],
          });
          if (aFeatures.length <= 20) {
            aFeatures.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return (moment(a.properties.connectTime, 'YYYY-MM-DD hh:mm:ss').toDate() - moment(b.properties.connectTime, 'YYYY-MM-DD hh:mm:ss').toDate());
              // return new Date(a.properties.connectTime) - new Date(b.properties.connectTime);
            });
            let radius = that.getRadius(point_count) + 5;
            that.marker = document.createElement('div');
            that.marker.className = 'markerCustom';
            that.marker.style =
              'height:' + radius + 'px;width:' + radius + 'px';
            // make a marker for each feature and add to the map
            new mapboxgl.Marker(that.marker)
              .setLngLat(features[0].geometry.coordinates)
              .addTo(that.props.map);

            that.setState({
              clusterData: aFeatures,
              showSpinner: false,
            });
            that.props.map
              .getSource('customSource')
              .getClusterExpansionZoom(clusterId, function (error, zoom) {
                that.clusterZoom = zoom;
              });
          } else {
            that.setState({
              clusterData: [],
            });
            that.props.map
              .getSource("customSource")
              .getClusterExpansionZoom(clusterId, function (error, zoom) {
                that.props.map.easeTo(
                  {
                    center: features[0].geometry.coordinates,
                    zoom: zoom,
                  },
                  { data: 'custom' }
                );
              });
          }
        });
      }, 200);
    }
  };

  unclusterClick = (e) => {
    let that = this;
    // sorting the clusters before showing the popup helps to pick the latest one and show to user.
    e.features.sort(function (a, b) {
      return (moment(a.properties.connectTime, 'YYYY-MM-DD hh:mm:ss').toDate() - moment(b.properties.connectTime, 'YYYY-MM-DD hh:mm:ss').toDate());
    });
    var clusterPoint = e.features[e.features.length -1]
    var coordinates = clusterPoint.geometry.coordinates.slice();
    let popupTime;
    if (!this.state.showLocalTime) {
      popupTime = moment(clusterPoint.properties.connectTime).format('hh:mm:ss A')
    }
    else {
      popupTime = moment.utc(clusterPoint.properties.connectTime).local().format('hh:mm:ss A')
    }

    let popMDN = clusterPoint.properties.ani;
    let popupCoord = that.getCoordinates(clusterPoint.geometry.coordinates[1], clusterPoint.geometry.coordinates[0]);
    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    that.popup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML('<div class="historyPopup"><div class="incTime">' + popupTime + '</div><div class="popupRow"><div class="popupLabel">MDN: </div><div class="value">' + popMDN + '</div></div><div class="popupRow"><div class="popupLabel">Lat/Lon: </div><div >' + popupCoord + '</div></div><div>')
      .addTo(that.props.map);
    that.props.map.easeTo(
      {
        center: clusterPoint.geometry.coordinates,
      },
      { data: "custom" }
    );
  }

  zoomFunction = (event) => {
    let that = this;
    if (event.data === "style") {
      that.setState({
        clusterData: [],
        slideVal: 0
      });
    }
    let zoom = that.props.map.getZoom();
    if (zoom < that.state.currentZoom) {
      //zoom out
      that.removePopup(that);
      that.removeMarker(that);
    } else { //zoom in
      if (zoom >= that.clusterZoom) {
        that.removeMarker(that);
      }
    }
    that.setState({
      currentZoom: zoom,
    });
  }

  mouseEnterFn = () => {
    let that = this;
    that.props.map.getCanvas().style.cursor = "pointer";
  }

  mouseLeaveFn = () => {
    let that = this;
    that.props.map.getCanvas().style.cursor = "";
  }

  onInputClick = (event) => {
    event.target.value = '';
  }

  setClusterListeners = () => {
    if (this.props.showMap) {
      let that = this;
      that.props.map.on('click', 'clusters', that.clusterClick);
      that.props.map.on('touchstart', 'clusters', that.clusterClick);
      that.props.map.on('click', unclusteredPoint, that.unclusterClick);
      that.props.map.on('zoom', that.zoomFunction);
      that.props.map.on('touchmove', that.zoomFunction);
      that.props.map.on('mouseenter', 'clusters', that.mouseEnterFn);
      that.props.map.on('mouseleave', 'clusters', that.mouseLeaveFn);
      that.props.map.on('touchend', 'clusters', that.mouseLeaveFn);
      that.props.map.on('mouseenter', unclusteredPoint, that.mouseEnterFn);
      that.props.map.on('mouseleave', unclusteredPoint, that.mouseLeaveFn);
    } else {
      setTimeout(() => {
        this.setClusterListeners();
      }, 1000);
    }
  }

  componentDidMount() {
    if (this.props.intervals) {
      this.setState({
        sliderTotalTimeInMIn: this.props.intervals.sliderTotalTimeInMIn,
        slideVal: Number(this.props.intervals.rangeValue),
        sliderTicCount: Math.ceil(this.props.intervals.sliderTotalTimeInMIn / this.props.intervals.interval),
        showLocalTime: this.props.dates ? this.props.dates.showLocalTime : false,
        interval: this.props.intervals.interval,
        mapSpinner: this.props.intervals.mapSpinner
      });
      this.props.fetchRenderHistory(1);
    } else {
      this.setState({
        interval: this.props.intervals ? this.props.intervals.interval : serverconfigs.interval
      });
    }
    this.setClusterListeners();
  }

  componentWillUnmount() {
    let that = this;
    if (that.props.showMap) {
      that.props.map.off("click", "clusters", that.clusterClick);
      that.props.map.off('click', unclusteredPoint, that.unclusterClick);
      that.props.map.off("zoom", that.zoomFunction);
      that.props.map.off('mouseenter', 'clusters', that.mouseEnterFn);
      that.props.map.off('mouseleave', 'clusters', that.mouseLeaveFn);
      that.props.map.on('mouseenter', unclusteredPoint, that.mouseEnterFn);
      that.props.map.on('mouseleave', unclusteredPoint, that.mouseLeaveFn);
      this.removeAllLayers();
      clearInterval(this.animateInterval);
    }
  }

  handleUTC = () => {
    this.setState({
      interval: serverconfigs.interval,
      sliderTotalTimeInMIn: Number(0),
      sliderTicCount: Number(0),
      slideVal: 0,
      mapSpinner: true,
      showLocalTime: false,
      animateHistoryData: false
    }, () => {
      if (this.validateStateAndEndDate()) {
        clearInterval(this.animateInterval);
        let startTs = this.dateUtils.getUtcTimeStamp(this.state.startDate);
        let endTs = this.dateUtils.getUtcTimeStamp(this.state.endDate);
        this.setPropsData();
        this.getHistoryData(startTs, endTs);
      }
    })
  }

  getDayFromDate = (value) => {
    return moment(value).format('DD');
  }

  setUTCDateTime = () => {
    let dateTime = this.state.startDate;
    let enddateTime = this.state.endDate;
    if (!this.state.showLocalTime) {
      dateTime = this.dateUtils.getUtcTimeStamp(dateTime) * 1000;
      enddateTime = this.dateUtils.getUtcTimeStamp(enddateTime) * 1000;
    }
    this.startDateTime = dateTime;
    this.endDateTime = enddateTime;
  }

  displayActiveHours = (displayDateTime) => {
    let startDay = this.getDayFromDate(this.state.startDate);
    let endDay = this.getDayFromDate(this.state.endDate);
    let activeHourVal;
    if (this.state.showLocalTime) {
      if (startDay != endDay) {
        activeHourVal = displayDateTime ? moment(new Date(displayDateTime)).format('MM-DD-YYYY hh:mm A') : '';
      } else {
        activeHourVal = displayDateTime ? moment(new Date(displayDateTime)).format('hh:mm A') : '';
      }
    } else {
      if (startDay != endDay) {
        activeHourVal = displayDateTime ? moment.utc(new Date(displayDateTime)).format('MM-DD-YYYY hh:mm A') : '';
      } else {
        activeHourVal = displayDateTime ? moment.utc(new Date(displayDateTime)).format('hh:mm A') : '';
      }
    }
    this.setState({
      activeHour: activeHourVal
    })
  }

  handleLocalTime = () => {
    this.setState({
      interval: serverconfigs.interval,
      sliderTotalTimeInMIn: Number(0),
      sliderTicCount: Number(0),
      slideVal: 0,
      mapSpinner: true,
      showLocalTime: true,
      animateHistoryData: false
    }, () => {
      if (this.validateStateAndEndDate()) {
        clearInterval(this.animateInterval);
        this.setPropsData();
        this.getHistoryData(this.state.startDate / 1000, this.state.endDate / 1000);
      }
    })
  }

  setEndDate = (date) => {
    this.setState({
      endDate: date
    });
    setTimeout(() => this.updateDateBtnText(), 500);
  }

  setStartDate = (date) => {
    this.setState({
      startDate: date
    });
    setTimeout(() => this.updateDateBtnText(), 500);
  }

  updateDateBtnText = () => {
    if (this.props.dates && this.props.dates.startDate) {
      if (this.state.startDate !== this.props.dates.startDate || this.state.endDate !== this.props.dates.endDate) {
        this.setState({
          dateTimeBtnTxt: textUpdate
        });
      } else {
        this.setState({
          dateTimeBtnTxt: textRefresh
        });
      }
    } else if (this.state.startDate <= this.state.endDate) {
      this.setState({
        dateTimeBtnTxt: textUpdate
      });
    } else {
      this.setState({
        dateTimeBtnTxt: textRefresh
      });
    }
  }

  render() {
    const { startDate, endDate, dateTimeBtnTxt } = this.state;
    var timezone = moment.tz(moment.tz.guess()).zoneAbbr();
    if (this.props.incidentsHistory && this.props.render > 0 && this.props.showMap) {
      this.displayALLHistory(this.props.incidentsHistory);
      this.props.fetchRenderHistory(0);
    }
    else{
      if(this.props.error && this.props.render === 3){
        toast.error(this.props.error, { toastId: customToastId });
      }
    }

    return (
      <>
        {this.props.intervals && this.props.intervals.mapSpinner && this.props.error === '' ?
          <Spinner animation="border" variant="primary" className='spinner-map'>
            <span className="sr-only"></span>
          </Spinner>
          : ''}
        <div className="top-container">
          <div className="datePick">
            <div className='date-range-picker'>
              <div className='d-flex align-items-baseline'>
                <FaRegCalendarAlt className='calMargin'>Cal</FaRegCalendarAlt>
                <div className="date-range-control d-flex">
                  <div className="date-range-control-label"><p>From:</p><p>To:</p></div>
                  <EuiDatePickerRange
                    fullWidth={false}
                    startDateControl={
                      <EuiDatePicker
                        dateFormat={displayDateTimeFormat}
                        selected={startDate}
                        onChange={this.setStartDate}
                        startDate={startDate}
                        endDate={endDate}
                        allowSameDay={true}
                        isInvalid={endDate && startDate >= endDate}
                        aria-label="Start date"
                        timeIntervals={15}
                        showTimeSelect
                      />
                    }
                    endDateControl={
                      <EuiDatePicker
                        dateFormat={displayDateTimeFormat}
                        selected={endDate}
                        onSelect={this.setEndDate}
                        startDate={startDate}
                        endDate={endDate}
                        allowSameDay={true}
                        isInvalid={startDate && endDate && startDate >= endDate}
                        aria-label="End date"
                        timeIntervals={15}
                        showTimeSelect
                      />
                    }
                  />
                </div>
              </div>
              <div className="text-end">
                <Button variant="outline-primary" size="sm" onClick={this.refreshBtnClick} className='update-button'>{textUpdate}</Button>
              </div>
                <div className='time-switch'>
                  <span className={`hand-cursor ${!this.state.showLocalTime ? 'active' : ''}`} onClick={this.handleUTC}>UTC</span>
                  <span className='time'>|</span>
                  <span className={`hand-cursor time ${this.state.showLocalTime ? 'active' : ''}`} onClick={this.handleLocalTime}>{timezone}</span>
                </div>
              
            </div>
          </div>
            <>
              <div className={`d-flex align-items-center play-history mt-2 position-relative ${this.state.sliderTicCount > 0 ? '' : 'disabledbutton'}`}>
                <img className='refresh-icon hand-cursor' src={refreshIcon} onClick={this.resetIntervalSlider} />

                <div className='animate-slider hand-cursor'
                  onClick={this.animateIntervalSlider}>
                  <img src={playIcon} className={`${this.state.animateHistoryData ? 'd-none' : 'play-icon'}`} />
                  <img src={pauseIcon} className={`${this.state.animateHistoryData ? 'pause-icon' : 'd-none'}`} />
                </div><input
                  id="range"
                  type="range"
                  min={this.state.min}
                  max={this.state.sliderTicCount}
                  value={this.props.intervals ? this.props.intervals.rangeValue : 0}
                  onChange={this.handleSliderChange}
                  disabled={!this.props.isLoading}
                  step="1"
                />
              </div>

              <div
                className="range-value"
                id="rangeV"
              >
                {this.state.activeHour ? this.state.activeHour : ''}
              </div>
              <br />
              <div className="info d-flex align-items-center">
                {/* <FontAwesomeIcon className="clock" icon={faClock} /> */}
                <FaRegClock className="clock" >Clock</FaRegClock>
                Interval <input id="interval" type='number' className='form-control interval' min={1} max={this.state.sliderTotalTimeInMIn}
                  value={this.props.intervals ? this.props.intervals.interval : serverconfigs.interval}
                  onChange={this.handleSliderInterval}
                  disabled={!this.props.isLoading} /> min
              </div>
              <hr style={{ marginBottom: 0 + "em" }} />
            </>
         
        </div>
        <div className="dataContainer">
          <div className="listView">
            {this.state.clusterData.map((user, index) => (
              <div key={index} className={`containerBox m-1 hand-cursor ${(this.state.selectedCall.ani === user.ani) && (this.state.selectedCall.connectTime === user.connectTime) ? 'active' : ''}`} onClick={this.handleCallClick.bind(this, user)}>
                <p className="noMargin">
                  {/* <span>MDN:</span>{" "} */}
                  <span className="grey-font">
                    {user.properties.ani && user.properties.ani ? user.properties.ani.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3') : ''}
                  </span>
                </p>
                <p className="noMargin d-flex justify-content-end display-date">

                  <span className="text-uppercase time">
                    {!this.state.showLocalTime ?
                      moment(user.connectTime).format('MM-DD-YYYY hh:mm:ss A')
                      :
                      moment.utc(user.connectTime).local().format('MM-DD-YYYY hh:mm:ss A')
                    }
                  </span>
                </p>
              </div>
            ))}
          </div>

        </div>
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    incidentsHistory: state.analytics.incidentsHistory,
    isLoading: state.analytics.isLoading,
    dates: state.dates.dates,
    render: state.historyRender.render,
    intervals: state.historyIntervals.intervals,
    sliderVal: state.sliderVal.sliderVal,
    error: state.analytics.error
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIncidentsHistory: (startTs, EndTs) => dispatch(getIncidentsHistory(startTs, EndTs)),
    fetchHistoryDates: (val) => dispatch(fetchHistoryDates(val)),
    fetchRenderHistory: (val) => dispatch(fetchRenderHistory(val)),
    fetchHistoryIntervals: (val) => dispatch(fetchHistoryIntervals(val)),
    fetchHistorySliderValue: (val) => dispatch(fetchHistorySliderValue(val))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalData);
