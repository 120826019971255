import {
    DISPLAY_SEARCH_PANEL, HIDE_SEARCH_PANEL, SET_SEARCH,CLEAR_SEARCH, SET_SEARCH_FLAG
 } from './actionTypes';




 export function displaySearchPanel(data,str) {
    return {
        type: DISPLAY_SEARCH_PANEL,
        searchData: data,
        showSearch : true,
        searchString:  str
    }
}

export function hideSearchPanel() {
    return {
        type: HIDE_SEARCH_PANEL,
        searchData: [],
        searchString:  '',
        showSearch : false
    }
}

export function toggleSearchFlag(val) {
    return {
        type: SET_SEARCH_FLAG,
        data: val
    }
}
