import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';


export const PrivateRoute = ({
    children,
  }) => {   
            let currentUser = sessionStorage.getItem('currentUser');

            if (currentUser === "undefined" || currentUser === null) {
                // not logged in so redirect to login page with the return url
        return <Navigate to={{ pathname: '/auth' }} />
            }
    return children ? <>
        {children}
                </>
        : <Outlet />;
  };

export default PrivateRoute;