import React, { Component } from "react";
// import { AmplifyEventBus } from "aws-amplify-react";
// import Alert from "react-bootstrap/Alert";
import ReactDirective from "react-directive";
import Header from './Header';
// import PropTypes from 'prop-types';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { Auth } from 'aws-amplify';
// import Animated from 'animate.css-react';
import '../styles/auth.scss';
import { createBrowserHistory } from "history";
import { getStationIdFromLocalStorage, consoleLog } from "../utils/commonUtils"

const path = createBrowserHistory().location.pathname;

class Authenticate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { formState: 'signIn' };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        if (this.state.formState === "signUp") {
            this.setState({ 'formState': 'signIn' });
        } else {
            this.setState({ 'formState': 'signUp' });
            window.location.href = "create";
        }
    }

    componentDidMount = () => {
        document.body.className = '';
        window.addEventListener('popstate', this.handleLogout());
    }
    componentWillUnmount(){
        window.removeEventListener('popstate', this.handleLogout());
    }

    handleLogout = () => {
        if (sessionStorage.getItem('idToken') && sessionStorage.getItem('currentUser') && (path === '/auth' || path === '/')) {
            this.signOut();
        }
    }

    signOut = () =>  {
        Auth.signOut()
          .then(() => {
            let idTokenFeatures = sessionStorage.getItem('idToken');
            let currentUser = sessionStorage.getItem('currentUser');
            var groupFeatures = sessionStorage.getItem('groupProfile');
            var showNotifications = sessionStorage.getItem('showNotifications');
            sessionStorage.currentUser = undefined;
            sessionStorage.notAdmin = undefined;
            sessionStorage.removeItem('createUser');
            sessionStorage.clear();
            if (isFeatureAllowed(featureLabels.call) && !callDeclarations.isSimulationEnabled) {
              sessionStorage.setItem('isSimulationEnabled', callDeclarations.isSimulationEnabled);
              sessionStorage.setItem('idToken', idTokenFeatures);
              sessionStorage.setItem('user', currentUser);
              sessionStorage.setItem('groupProfile', groupFeatures);
              sessionStorage.setItem('showNotifications', showNotifications);
            }
            window.location.href = "/auth";
          })
          .catch(e => {
            consoleLog(e);
          });
      }




    
    render() {
        const path = createBrowserHistory().location.pathname;
        return (
            <>

                <div className={(sessionStorage.getItem('idToken') && sessionStorage.getItem('currentUser') && path === '/auth') ? 'psap-content' : 'main container'}>
                    <div className="auth mh-100">
                        {(sessionStorage.getItem('currentUser') === null) || (getStationIdFromLocalStorage() === null) ?
                            this.state.formState === 'signIn' ? <SignIn></SignIn> : ''
                            : ''
                        }
                        <br />
                    </div>
                </div>
            </>
        )
    }
}
export default Authenticate;
