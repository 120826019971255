import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";
import '../../styles/modal.scss';
import '../../styles/drawPolygon.scss';
import icTrash from '../../assets/icon_trash.png';
import { DeleteModal } from './DeletModal';

export default class EditPolygon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandModal: false,
            name: '',
            description: '',
            submitted: false,
            showDeleteDialog: false,
            isGeofence: false,
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (this.props.polygon.id !== props.polygon.id) {
            this.setState({
                name: props.polygon.name,
                description: props.polygon.description,
                id: props.polygon.id,
                submitted: false,
                isGeofence: props.polygon.isGeofence || false,
            })
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.polygon.id === nextProps.polygon.id) {
            return false;
        } else {
            return true;
        }
    }

    handleChange = (e) => {
        this.forceUpdate();
        if (e.target.name === 'name') {
            this.setState({
                name: e.target.value
            })
        }
        else {
            this.setState({
                description: e.target.value
            })
        }
    }

    handleStart = () => {
        this.forceUpdate();
        this.setState({
            submitted: true
        })
        let isGeofence = null;

        if (this.state.name !== '') {
            this.props.hide();
            if(this.props.polygon.name != '') {
                isGeofence = this.state.isGeofence
            } else if(this.state.isGeofence) {
                isGeofence = this.state.isGeofence
            } else {
                isGeofence = null
            }
            this.props.onExit(this.state.name, this.state.description, this.state.id, isGeofence);
        }
    }

    deletePolygon = () => {
        this.forceUpdate();
        this.props.delete(this.state.id);
        this.setState({
            showDeleteDialog: false
        })
    }

    hideDelete = () => {
        this.forceUpdate();
        this.props.hide();
        this.setState({
            showDeleteDialog: false
        })
    }

    handleDelete = () => {
        this.forceUpdate();
        this.props.hideDeletePolygon();
        this.setState({
            showDeleteDialog: true
        })
    }

    handleClose = () => {
        this.forceUpdate();
        this.props.hide();
    }

    onUseGeoFenceSelect = (e) => {
        this.forceUpdate();
        this.setState({ isGeofence: e.target.checked });
    }

    render() {
        return (
            <>
                <Modal className='edit-polygon-dialog' backdrop={false} show={this.props.show} onHide={this.props.hide}>
                    <Modal.Header closeButton onClick={this.handleClose}></Modal.Header>
                    <Modal.Body>
                        <div className='right-content'>
                            <div className='text-left form-field position-relative'>
                                <input type='text' name='name' className='form-control polygon-name' placeholder='Name'
                                    onChange={this.handleChange} value={this.state.name} />
                                <img className='icon-trash hand-cursor' src={icTrash} onClick={this.handleDelete} />
                                {this.state.name === '' && this.state.submitted ?
                                    <div className='error-msg'>please enter Name</div>
                                    : ''}
                            </div>
                            <div className='text-left form-field'>
                                <textarea rows="2" name='description' className="form-control"
                                    placeholder='Description' onChange={this.handleChange} value={this.state.description}></textarea>
                            </div>
                            <div className='form-field geofenceText'>
                                <Form.Check
                                    type="checkbox"
                                    label="Use as Geofence"
                                    checked={this.state.isGeofence}
                                    onChange={this.onUseGeoFenceSelect}
                                />
                            </div>
                            <div className='mt-4'>
                                <Button variant="light" onClick={this.handleClose}>Close</Button>
                                <Button variant="primary" onClick={this.handleStart}>Save</Button>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
                <DeleteModal show={this.state.showDeleteDialog} hide={this.hideDelete} delete={this.deletePolygon}
                    title={this.state.name} />
            </>
        )
    }
}
