import { SET_TAB_NAME } from '../actions/actionTypes';
import {tabUrls, featureLabels} from '../../constants';
import { isFeatureAllowed } from '../../utils/groupFeatureUtils';

const initialState = {
    tabName: isFeatureAllowed(featureLabels.call) ? tabUrls.call : isFeatureAllowed(featureLabels.incident) ? tabUrls.incident : isFeatureAllowed(featureLabels.device_tracking) ?
    tabUrls.tracking : isFeatureAllowed(featureLabels.tracking_factory) ? tabUrls.tracking_factory : (isFeatureAllowed(featureLabels.analytics) || isFeatureAllowed(featureLabels.device_analytics)) ? tabUrls.analytics : tabUrls.call
}

export const TabReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAB_NAME:
            return { ...state, tabName: action.data }        
        default:
            return state;
    }
}